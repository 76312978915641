import React, {useEffect,useState} from 'react';
import {FaCreditCard, FaShippingFast, FaStore, FaList, FaSearch,FaTrashAlt } from "react-icons/fa";
import StoreIcon from "@mui/icons-material/Store";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import { Link, useNavigate, useLocation,useSearchParams } from 'react-router-dom';
import "../../../assets/css/account.css";
import '../../../assets/css/orders.css'
import { Seo } from '../../utilities/Seo';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useRefreshToken from '../../../hooks/useRefreshToken';
import Spinner from "../../utilities/Spinner";
import useCheckAuthWithRedirect from "../../../hooks/useCheckAuthWithRedirect";
import Badge from '@material-ui/core/Badge';
import Checkbox from '@material-ui/core/Checkbox';
import Pagination from '@mui/material/Pagination';
import emptyOrderImg from '../../../assets/images/empty-cart.png';
import { RoundToTwo } from "../../utilities/RoundToTwo";
import CustomBsModal from '../../utilities/CustomBsModal';
import useCurrencyRate from '../../../hooks/useCurrencyRate';
import setLocalStorage from '../../functions/setLocalStorage';
import {Exchanger } from '../../functions/Helpers';
import { encryptData } from "../../functions/DataSecurity";






export const  Index = () => {
  
  //check for authentication on the page
  useCheckAuthWithRedirect();

  const [searchParams] = useSearchParams();
  const urlPage = searchParams.get('page');
  const [page, setPage] = useState(parseInt(urlPage) ? parseInt(urlPage) : 1);
  const orderStatus = searchParams.get("orderStatus");

  const [modalShow, setModalShow] = useState(false);
  const [orderCounts, setOrderCounts] = useState({});
  const [orderList, setOrderList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [orderId, setOrderId] = useState("");
  const [orderIdList, setOrderIdList] = useState([]);
  
  const [reloadOnUpdate, setReloadOnUpdate] = useState(false);
  const [reload, setReload] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const refresh = useRefreshToken();
  const navigate = useNavigate();

  const [activeModal, setActiveModal] = useState("");
  const [openModal, setOpenModal] = useState(false);
 
  const {currency, rate, symbol} = useCurrencyRate();
  const defaultCurrency = "CNY";


  //get new token for the reques
useEffect(()  =>{
  const getRefreshToken = async () => {
      await refresh();
  }
  
  getRefreshToken();
},[]);

//set page title
useEffect(() => {
  const pageTitle =  "Orders  || Checkstall.com";
  Seo({
    title: pageTitle,
    metaDescription: 'Order List',
  });
}, []);

useEffect(() =>{
  getOrderCounts();
},[reloadOnUpdate]);

useEffect(() =>{
  getOrderList(orderStatus, page);
},[orderStatus,page,reloadOnUpdate]);


const handleCloseModal = () => setOpenModal(false);
const handleOpenModal = (key)  => {     
setActiveModal(key);
setOpenModal(true);
}

const getOrderCounts = () =>{
  let isMounted = true;
  const controller = new AbortController();

  const doGetCount = async () => {
      setModalShow(true);
      try {
            const response = await axiosPrivate.post("v1/order/get-order-counts", {
              signal: controller.signal
            });

               //console.log(response?.data?.message)
               isMounted && setOrderCounts(response?.data?.message);
               //isMounted && setOrderInfo(response?.data?.message);
      }catch(err){
          console.log(err.response?.data);  
      }
      setModalShow(false);
  }

  doGetCount();
  return () => {
    isMounted = false;
    controller.abort();
  }
}

const handleClick = () =>  {
  setPage(1);
  setReloadOnUpdate(true);
};

const getOrderList = (orderStatus, page) =>{
  const doGetOrderList = async (orderStatus, page) => {
      setModalShow(true);
      try {
            const response = await axiosPrivate.post("/v1/order/get-order-list?orderStatus="+orderStatus+"&page="+page);

               setOrderList(response?.data?.message);
               setPageCount(response?.data?.pageCount);
               setReloadOnUpdate(false);
      }catch(err){
          console.log(err.response?.data);  
      }
      setModalShow(false);
  }

  doGetOrderList(orderStatus,page);
  
}

const getSpecProps = (string) => {
  let spec = '';
  if(string){
     spec = string.split(';');
  }

  let prop1 = "";
  let prop2 = "";
  if(spec[0]){
    prop1 = spec[0].slice(4);
  }
   
  if(spec[1]){
    prop2 = spec[1].slice(4);
  }

  return prop1+" ; "+prop2;
}

const orderStatusList = {
  '1': 'Unreviewd',
  '2': 'Pending Payment',
  '3': 'Processing',
  '4': 'Purchased',
  '5': 'Delivered',
  '6': 'Prepared',
  '7': 'Shipped out',
  '8': 'Arrived Lagos Facility',
  '9': 'shipment fee paid',
  '10': 'Received',
  '11': 'Refunded',
  '12': 'Success',
  '13': 'Closed',
  '14': 'Global Warehouse',
  '15': 'Local Warehouse',
}

const getTotaltype = (sku) =>{
  let keys = Object.keys(sku);
  return keys.length;
}

const getTotalQuantity = (sku) =>{
  let t_q = 0;
  Object.values(sku).map((val) => {
      if(!isNaN(val.quantity)){
          t_q += parseInt(val.quantity);
      }
    });
  
    return t_q;
  
}

const getTotalPrice = (sku) =>{
  let total_price = 0;
  Object.values(sku).map((val) => {
    if(!isNaN((parseFloat(val?.total_price_with_discount) || parseFloat(val?.total_price)))){
      total_price += Number((parseFloat(val?.total_price_with_discount) || parseFloat(val?.total_price)));
    }
  });

  return total_price;
}

const getServiceFee = (sku) =>{
  let total = 0;
  Object.values(sku).map((val) => {
    if(!isNaN(parseFloat(val?.service_fee))){
      total += Number(parseFloat(val?.service_fee));
    }
  });

  return total;
}

const payOrder = (event) =>{
  let orderId = event.target.id;
  let actionType = "singleOrderPayment";
  let link = `/order/payment?orderId=${orderId}&actionType=${actionType}&fromType=order`;
  //navigate(`/order/payment?orderId=${orderId}&actionType=${actionType}&fromType=orderList`, {state:{refresh:true}});
  window.open(link, '_blank')
}

const payBulkOrder = () =>{
  let actionType = "bulkOrderPayment";
  let link = `/order/payment?actionType=${actionType}&fromType=order`;

  setLocalStorage('orderIdList', encryptData(orderIdList));
  window.open(link, '_blank')
}

const handleChange = (e, value) => {
  setPage(value);
  navigate(`/members/orders/index/?orderStatus=${orderStatus}&page=${value}`)
};

const updateCloseOrder = (orderId) =>{
    let isMounted = true;
    const controller = new AbortController();
  
    const doCloseOrder = async (orderId) => {
        let order_number = orderId;
        let order_status = "close";
        setModalShow(true);
        try {
              const response = await axiosPrivate.post("v1/order/update-status",
                JSON.stringify({ order_number,order_status}), {
                signal: controller.signal
              });
  
            //handle response
            //console.log(response?.data?.message);
              if(response?.data?.message == 101 ){
                 setReloadOnUpdate(true)
              }
        }catch(err){
      
            console.log(err.response?.data);
            
        }
        setModalShow(false);
    }
  
    doCloseOrder(orderId);
    return () => {
      isMounted = false;
        controller.abort();
    }
  
}

const handleUpdateAction = (event) => {
  //if user choose to close order,  
  updateCloseOrder(orderId);

  //close the modal
  handleCloseModal(true)
}

const handleCloseOrder = (event) =>{
  event.preventDefault();
  //set order number of order to be updated in state
  let id =  event.target.id;
  setOrderId(id);
  //show modal
  handleOpenModal("close");

}

const handleDeleteOrder = (event) =>{
  event.preventDefault();
  //set order number of order to be updated in state
  let id =  event.target.id;
  setOrderId(id);
  //show modal
  handleOpenModal("delete");

}

const handleDeleteAction = (event) => {
  //if user choose to close order,  
  deleteOrder(orderId);

  //close the modal
  handleCloseModal(true)
}

const deleteOrder = (orderId) =>{
  let isMounted = true;
  const controller = new AbortController();

  const doDeleteOrder = async (orderId) => {
      let order_number = orderId;
      setModalShow(true);
      try {
            const response = await axiosPrivate.post("v1/order/delete-order",
              JSON.stringify({ order_number}), {
              signal: controller.signal
            });

          //handle response
          //console.log(response?.data?.message);
            if(response?.data?.message == 101 ){
               setReloadOnUpdate(true)
            }
      }catch(err){
    
          console.log(err.response?.data);
          
      }
      setModalShow(false);
  }

  doDeleteOrder(orderId);
  return () => {
    isMounted = false;
      controller.abort();
  }

}

const getOrderIdList = (event) =>{
  setModalShow(true);
  const {checked, value, id } = event.target;

  if(parseInt(id) === 2){//status is paid can add to order List
    if(checked){
      orderIdList.push(value)
      setOrderIdList(orderIdList);
  
      setReload(true)
    }else{
      let index = orderIdList.indexOf(value);
      if (index > -1) { // only splice array when item is found
        orderIdList.splice(index, 1); // 2nd parameter means remove one item only
      }
      setOrderIdList(orderIdList);
      setReload(true)
    }
  }else{
    alert("Invalid Selection");
    event.target.checked = false;
  }
  
  const timer = setTimeout(() => {
    setModalShow(false);
  }, 200);
  return () => clearTimeout(timer);
}

useEffect(() =>{
  setReload(false)
},[reload]);

  return (
    <>
      <Spinner cssClass={"semi-dark-bg"} show={modalShow} />

      <CustomBsModal
        show={activeModal === "close" && openModal}
        onHide={handleCloseModal}
        handleClose={handleCloseModal}
        handleAction={handleUpdateAction}
        headingText={"Close Order"}
        bodyText={"Do you want to close this order?"}
        closeButtonText={"No"}
        saveButtonText={"Yes"}
        closeButtonVariant={"secondary"}
        saveButtonVariant={"danger"}
        dialogClassName="modal-w"
        backdrop="static"
        keyboard={false}
      />

      <CustomBsModal
        show={activeModal === "delete" && openModal}
        onHide={handleCloseModal}
        handleClose={handleCloseModal}
        handleAction={handleDeleteAction}
        headingText={"Delete Order"}
        bodyText={"Do you want to delete this Order?"}
        closeButtonText={"Cancel"}
        saveButtonText={"Delete"}
        closeButtonVariant={"secondary"}
        saveButtonVariant={"danger"}
        dialogClassName="modal-w"
        backdrop="static"
        keyboard={false}
      />

      <div className="">
        <div className="container" style={{ display: "flex" }}>
          <div className="col-md-12 inlineBlock">
            <div className="">
              <div className="myOrdersCard">
                <h4>My Orders</h4>
                <div className="orderPosition">
                  <div className="Oposit">
                    <Link
                      to="/members/orders/index?orderStatus=all"
                      id="all"
                      onClick={handleClick}
                      className={orderStatus === "all" ? "active-tab" : ""}
                    >
                      <FaList
                        style={{ fontSize: "x-large", fontWeight: "900" }}
                      />
                      <p>All</p>
                    </Link>
                  </div>
                  {/* <Badge
                    badgeContent={
                      orderCounts.unreviewed
                        ? parseInt(orderCounts.unreviewed)
                        : 0
                    }
                    color="secondary"
                    max={99}
                    overlap="circular"
                  >
                    <div className="Oposit">
                      <Link
                        to="/members/orders/index?orderStatus=unreviewed"
                        id="unreviewed"
                        onClick={handleClick}
                        className={
                          orderStatus === "unreviewed" ? "active-tab" : ""
                        }
                      >
                        <FaCreditCard
                          style={{ fontSize: "x-large", fontWeight: "900" }}
                        />
                        <p>Unreviewed</p>
                      </Link>
                    </div>
                  </Badge> */}
                  <Badge
                    badgeContent={
                      orderCounts.unpaid ? parseInt(orderCounts.unpaid) : 0
                    }
                    color="secondary"
                    max={99}
                    overlap="circular"
                  >
                    <div className="Oposit">
                      <Link
                        to="/members/orders/index?orderStatus=unpaid"
                        id="unpaid"
                        onClick={handleClick}
                        className={orderStatus === "unpaid" ? "active-tab" : ""}
                      >
                        <FaCreditCard
                          style={{ fontSize: "x-large", fontWeight: "900" }}
                        />
                        <p>Unpaid</p>
                      </Link>
                    </div>
                  </Badge>
                  <Badge
                    badgeContent={
                      orderCounts.processing
                        ? parseInt(orderCounts.processing)
                        : 0
                    }
                    color="secondary"
                    max={99}
                    overlap="circular"
                  >
                    <div className="Oposit">
                      <Link
                        to="/members/orders/index?orderStatus=processing"
                        id="processing"
                        onClick={handleClick}
                        className={
                          orderStatus === "processing" ? "active-tab" : ""
                        }
                      >
                        <FaStore
                          style={{ fontSize: "x-large", fontWeight: "900" }}
                        />
                        <p>Processing</p>
                      </Link>
                    </div>
                  </Badge>
                  <Badge
                    badgeContent={
                      orderCounts.purchased
                        ? parseInt(orderCounts.purchased)
                        : 0
                    }
                    color="secondary"
                    max={99}
                    overlap="circular"
                  >
                    <div className="Oposit">
                      <Link
                        to="/members/orders/index?orderStatus=purchased"
                        id="purchased"
                        onClick={handleClick}
                        className={
                          orderStatus === "purchased" ? "active-tab" : ""
                        }
                      >
                        <StoreIcon sx={{ fontSize: "3.5rem", width: "3rem" }} />
                        <p>Purchased</p>
                      </Link>
                    </div>
                  </Badge>
                  <Badge
                    badgeContent={
                      orderCounts.delivered
                        ? parseInt(orderCounts.delivered)
                        : 0
                    }
                    color="secondary"
                    max={99}
                    overlap="circular"
                  >
                    <div className="Oposit">
                      <Link
                        to="/members/orders/index?orderStatus=in_local_facility&page=1"
                        id="delivered"
                        onClick={handleClick}
                        className={
                          orderStatus === "in_local_facility"
                            ? "active-tab"
                            : ""
                        }
                      >
                        <WarehouseIcon
                          sx={{ fontSize: "2.5rem", fontWeight: "900" }}
                        />
                        <p>Local Warehouse</p>
                      </Link>
                    </div>
                  </Badge>
                  <Badge
                    badgeContent={
                      orderCounts.prepared ? parseInt(orderCounts.prepared) : 0
                    }
                    color="secondary"
                    max={99}
                    overlap="circular"
                  >
                    <div className="Oposit">
                      <Link
                        to="/members/orders/index?orderStatus=prepared"
                        id="prepared"
                        onClick={handleClick}
                        className={
                          orderStatus === "prepared" ? "active-tab" : ""
                        }
                      >
                        <ReceiptIcon
                          sx={{ fontSize: "x-large", fontWeight: "900" }}
                        />
                        <p>Prepared</p>
                      </Link>
                    </div>
                  </Badge>
                  <Badge
                    badgeContent={
                      orderCounts.shippedOut
                        ? parseInt(orderCounts.shippedOut)
                        : 0
                    }
                    color="secondary"
                    max={99}
                    overlap="circular"
                  >
                    <div className="Oposit">
                      <Link
                        to="/members/orders/index?orderStatus=shipped_out"
                        id="shipped_out"
                        onClick={handleClick}
                        className={
                          orderStatus === "shipped_out" ? "active-tab" : ""
                        }
                      >
                        <FaShippingFast
                          style={{ fontSize: "x-large", fontWeight: "900" }}
                        />
                        <p>Shipped Out</p>
                      </Link>
                    </div>
                  </Badge>
                  <Badge
                    badgeContent={
                      orderCounts.delivered
                        ? parseInt(orderCounts.delivered)
                        : 0
                    }
                    color="secondary"
                    max={99}
                    overlap="circular"
                  >
                    <div className="Oposit">
                      <Link
                        to="/members/orders/index?orderStatus=in_global_warehouse&page=1"
                        id="delivered"
                        onClick={handleClick}
                        className={
                          orderStatus === "in_global_warehouse"
                            ? "active-tab"
                            : ""
                        }
                      >
                        <WarehouseIcon
                          sx={{ fontSize: "x-large", fontWeight: "900" }}
                        />
                        <p>Global Warehouse</p>
                      </Link>
                    </div>
                  </Badge>
                  <Badge
                    badgeContent={
                      orderCounts.delivered
                        ? parseInt(orderCounts.delivered)
                        : 0
                    }
                    color="secondary"
                    max={99}
                    overlap="circular"
                  >
                    <div className="Oposit">
                      <Link
                        to="/members/orders/index?orderStatus=recieved&page=1"
                        id="delivered"
                        onClick={handleClick}
                        className={
                          orderStatus === "recieved" ? "active-tab" : ""
                        }
                      >
                        <CallReceivedIcon
                          sx={{ fontSize: "x-large", fontWeight: "900" }}
                        />
                        <p
                          style={{
                            width: "5rem",
                            textAlign: "center",
                            fontSize: "0.8rem",
                          }}
                        >
                          Received
                        </p>
                      </Link>
                    </div>
                  </Badge>
                  <Badge
                    badgeContent={
                      orderCounts.delivered
                        ? parseInt(orderCounts.delivered)
                        : 0
                    }
                    color="secondary"
                    max={99}
                    overlap="circular"
                  >
                    <div className="Oposit">
                      <Link
                        to="/members/orders/index?orderStatus=refunded&page=1"
                        id="delivered"
                        onClick={handleClick}
                        className={
                          orderStatus === "refunded" ? "active-tab" : ""
                        }
                      >
                        <AssignmentReturnIcon
                          sx={{ fontSize: "x-large", fontWeight: "900" }}
                        />
                        <p
                          style={{
                            width: "5rem",
                            textAlign: "center",
                            fontSize: "0.8rem",
                          }}
                        >
                          Refunded
                        </p>
                      </Link>
                    </div>
                  </Badge>
                </div>
                <div className="">
                  <div className="orderHead col-lg-12 col-md-12 inlineBlock">
                    <div
                      className="fieldy col-lg-9 col-md-9 col-sm-9"
                      id="searchform"
                    >
                      <input
                        type="text"
                        id="searchterm"
                        placeholder="Order Number, date"
                      />
                      <button type="button" id="search">
                        <FaSearch /> &nbsp; 
                      </button>
                    </div>
                  </div>
                  <div className="order-list">
                    <div className="order-list-header d-flex justify-content-between">
                      <div className="select-all" style={{ flexBasis: "3%" }}>
                        <Checkbox
                          size="small"
                          type="checkbox"
                          className="selectAllItem"
                          name="selectAllItem"
                        /> 
                        &nbsp;  All
                      </div>
                      <div
                        className="order-header-item"
                        style={{ flexBasis: "35%", textAlign: "left" }}
                      >
                        Item
                      </div>
                      <div
                        className="order-header-quantity"
                        style={{ flexBasis: "12%" }}
                      >
                        Quantity
                      </div>
                      <div
                        className="order-header-listed-price"
                        style={{ flexBasis: "8%" }}
                      >
                        Price
                      </div>
                      <div
                        className="order-header-total"
                        style={{ flexBasis: "8%" }}
                      >
                        Total
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {orderList && orderList.length > 0 ? (
                orderList.map((value, key) => {
                  return (
                    <div
                      className="order-container d-flex flex-column"
                      key={key}
                    >
                      <div
                        style={{
                          backgroundColor: "#ffffff",
                          padding: "10px 10px 10px 10px",
                        }}
                      >
                        <div className="order-num-wrap d-flex justify-content-start">
                          <div
                            className="order-select"
                            style={{ flexBasis: "2%" }}
                          >
                            <Checkbox
                              size="small"
                              type="checkbox"
                              className="bulkOrder"
                              name="bulkOrder"
                              value={value?.order_number}
                              onClick={(e) => getOrderIdList(e, key)}
                              id={value?.status}
                            />
                          </div>
                          <div>
                            Order Number: &nbsp; 
                            <span
                              style={{
                                fontWeight: "bolder",
                                marginRight: "10px",
                              }}
                            >
                              {value?.order_number}
                            </span>
                          </div>
                          <div
                            className="order-time"
                            style={{ marginRight: "10px" }}
                          >
                            {value?.created_at}
                          </div>
                          <div
                            className="order-status"
                            style={{ flexBasis: "15%" }}
                          >
                            Status: &nbsp; 
                            <span className="status-color">
                              {orderStatusList[value?.status]}
                            </span>
                          </div>
                          <div className="order-action d-flex justify-content-end">
                            {value?.status == 2 && (
                              <>
                                <button
                                  className="pay-btn"
                                  onClick={payOrder}
                                  id={value?.order_number}
                                  target="_blank"
                                >
                                  pay now
                                </button>
                              </>
                            )}

                            {(value?.status == 1 || value?.status == 2) && (
                              <>
                                <button>change address</button>
                                <button
                                  id={value?.order_number}
                                  onClick={handleCloseOrder}
                                >
                                  close order
                                </button>
                              </>
                            )}

                            {value?.status == 13 && (
                              <button
                                id={value?.order_number}
                                onClick={handleDeleteOrder}
                              >
                                delete
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      {value.sku.map((val, index) => {
                        return (
                          <div
                            className="order-list d-flex justify-content-between"
                            key={index}
                          >
                            <div
                              className="order-header-item d-flex justtify-content-start"
                              style={{ flexBasis: "45%" }}
                            >
                              <div
                                className="o-img"
                                style={{ flexBasis: "20%" }}
                              >
                                <img src={val?.url} alt="" />
                              </div>
                              <div
                                className="o-info d-flex flex-column"
                                style={{ flexBasis: "70%" }}
                              >
                                <div className="o-title">{value?.title}</div>
                                <span className="o-props">
                                  {getSpecProps(val?.properties_name)}
                                </span>
                              </div>
                            </div>
                            <div
                              className="order-header-quantity"
                              style={{ flexBasis: "12%", textAlign: "center" }}
                            >
                              x{val?.quantity}
                            </div>
                            <div
                              className="order-header-listed-price"
                              style={{ flexBasis: "8%", textAlign: "center" }}
                            >
                              {symbol}
                              {RoundToTwo(
                              Exchanger(
                                defaultCurrency,
                                currency,
                                val?.price,
                                rate
                              )
                            )}
                            </div>
                            <div
                              className="order-header-total"
                              style={{ flexBasis: "8%", textAlign: "center" }}
                            >
                              {symbol}
                              {RoundToTwo(
                              Exchanger(
                                defaultCurrency,
                                currency,
                                (val?.total_price_with_discount || val?.total_price),
                                rate
                              )
                            )}
                            </div>
                          </div>
                        );
                      })}
                      <div
                        style={{
                          backgroundColor: "#ffffff",
                          padding: "10px 10px 10px 10px",
                        }}
                      >
                        <div className="order-num-wrap d-flex justify-content-between">
                          <div>
                            Total Types: <span>{getTotaltype(value.sku)}</span>
                          </div>
                          <div>
                            Total quantity: &nbsp;
                            <span>{getTotalQuantity(value.sku)}</span>
                          </div>
                          <div>
                            Total Price: {symbol}
                            <span>

                              {RoundToTwo(
                              Exchanger(
                                defaultCurrency,
                                currency,
                                getTotalPrice(value.sku),
                                rate
                              )
                            )}
                            </span>
                          </div>
                          <div>
                            Service fee: {symbol}
                            <span>

                              {RoundToTwo(
                              Exchanger(
                                defaultCurrency,
                                currency,
                                getServiceFee(value.sku),
                                rate
                              )
                            )}
                            </span>
                          </div>
                          <div>
                            Local shipping fee: {symbol}
                            <span>
                              {RoundToTwo(
                              Exchanger(
                                defaultCurrency,
                                currency,
                                value.post_fee,
                                rate
                              )
                            )}
                            </span>
                          </div>
                          <div>
                            Total Amount: {symbol}
                            <span>

                              {RoundToTwo(
                              Exchanger(
                                defaultCurrency,
                                currency,
                                (getServiceFee(value.sku) +
                                  getTotalPrice(value.sku) +
                                  parseFloat(+value.post_fee)),
                                rate
                              )
                            )}
                            </span>
                          </div>
                          {/* <div className='order-trash'><FaTrashAlt title='close order'/></div> */}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="empty-order-wrap">
                  <img src={emptyOrderImg} alt="" />
                  <div>
                    Your Order List is empty Click{" "}
                    <Link to="/" style={{ textDecoration: "none" }}>
                      &nbsp;Here&nbsp;
                    </Link>
                    to start shopping
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            marginLeft: 10,
            marginRight: 10,
            display: "block",
            marginTop: 10,
            backgroundColor: "#fff",
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <Pagination
            count={pageCount}
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
      {orderIdList.length > 0 && (
        <div className="order-checkout-box d-flex justify-content-between shadow">
          <div>
            Total Item Selected: <span>{orderIdList.length}</span>
          </div>
          <div>
            <button onClick={payBulkOrder}>Continue</button>
          </div>
        </div>
      )}
    </>
  );

};

