export const guideData = [
  {
    id: 1,
    imageuRL: "/images/howtobuy/2.png",
    guide: "Type what you're looking for in the search box",
  },
  {
    id: 2,
    imageuRL: "/images/howtobuy/3.png",
    guide: "Select your desired product",
  },
  {
    id: 3,
    imageuRL: "/images/howtobuy/5.png",
    guide: "Click on Add to cart",
  },
  {
    id: 4,
    imageuRL: "/images/howtobuy/4.png",
    guide: "Select specification and quantity",
  },
  {
    id: 5,
    imageuRL: "/images/howtobuy/7.png",
    guide: "after add Go to cart and select item for checkout",
  },
  {
    id: 6,
    imageuRL: "/images/howtobuy/8.png",
    guide: "if it is your first time you would need to set your address first.",
  },
  {
    id: 7,
    imageuRL: "/images/howtobuy/2.png",
    guide: "Click on Add new address.",
  },
  {
    id: 8,
    imageuRL: "/images/howtobuy/10.png",
    guide: "Fill in your address information and click save.",
  },
  {
    id: 9,
    imageuRL: "/images/howtobuy/11.png",
    guide: "Now your address has bring added.",
  },
  {
    id: 10,
    imageuRL: "/images/howtobuy/7.png",
    guide: "Now go back to cart and selet Item to checkout and click submit.",
  },
  {
    id: 11,
    imageuRL: "/images/howtobuy/12.png",
    guide: "Click submit order.",
  },
  {
    id: "11a",
    imageuRL: "/images/howtobuy/13.png",
  },
  {
    id: 12,
    imageuRL: "/images/howtobuy/14.png",
    guide: " If this is your first time you would need to set a payment PIN.",
  },
  {
    id: 13,
    imageuRL: "/images/howtobuy/15.png",
    guide: "Click on Set wallet PIN.",
  },
  {
    id: 14,
    imageuRL: "/images/howtobuy/16.png",
    guide: `Click on Get Code to receive a confirmation code to your
              Registered email. Please note email might take few minute before
              you receive kindly check your promo box or spam box if you did not
              see code`,
  },
  {
    id: 15,
    imageuRL: "/images/howtobuy/17.png",
    guide: "Copy the code form your email.",
  },
  {
    id: "15a",
    imageuRL: "/images/howtobuy/18.png",
  },
  {
    id: "15b",
    imageuRL: "/images/howtobuy/19.png",
  },
  {
    id: "15c",
    imageuRL: "/images/howtobuy/20.png",
  },
  {
    id: 16,
    imageuRL: "/images/howtobuy/21.png",
    guide: "Continue with your payment.",
  },
  {
    id: 17,
    imageuRL: "/images/howtobuy/22.png",
    guide:
      "If your wallet balance is not enough you would need to top up your wallet. Clicl Top Up",
  },
  {
    id: 18,
    imageuRL: "/images/howtobuy/23.png",
    guide: "Put top up amount.",
  },
  {
    id: 19,
    imageuRL: "/images/howtobuy/24.png",
    guide: "Send us the transfer receipt to our email or WhatsApp.",
    noMargin: "no margin",
  },
  {
    id: 20,
    imageuRL: "/images/howtobuy/25.png",
    guide: "Click done and return home.",
    noMargin: "no margin",
  },
];