import React from 'react';

function Details() {
  return (
    <React.Fragment>
    <div className='checkstall-partner-member d-flex flex-column'>
        <h3>Overview</h3>
        <div className='d-flex justify-content-between overview-wrap'>
          <div className='d-flex flex-column overview-label-wrap'>
              <div className='overview-value'>₦ 43785989</div>
              <div className='overview-label'>Total Order</div> 
          </div>
          <div className='d-flex flex-column overview-label-wrap'>
              <div className='overview-value'>₦ 43785</div>
              <div className='overview-label'>Total Commision</div>
          </div>
          <div className='d-flex flex-column overview-label-wrap'>
              <div className='overview-value'>₦ 43785</div>
              <div className='overview-label'>Expected Commision</div>
          </div>
          <div className='d-flex flex-column overview-label-wrap'>
              <div className='overview-value'>50%</div>
              <div className='overview-label'>Commision rate</div>
          </div>
        </div>
        <div className='d-flex flex-column x-status-wrap'>
           <div className='d-flex justify-content-between x-status'>
              <div>Order Status</div>
              <div>Pending</div>
           </div>
           <div className='d-flex justify-content-between x-status'>
              <div>Order Time</div>
              <div>2023-04-12</div>
           </div>
        </div>
    </div>
</React.Fragment>
  )
}

export default Details;
//export {Details as PartnerDetails};