import shipping from "../../assets/shipping.avif";
import delivery from '../../assets/delivery.jpg';
import service from '../../assets/customer.avif'

const data = [
  {
    id: 1,
    title: "from China to Nigeria shippment",
    imageUrl: shipping,

  },
  {
    id: 2,
    title: "perfect delivery experience",
    imageUrl: delivery,
  },
  {
    id: 3,
    title: "good customer experience",
    imageUrl: service,
  },
];

export default data;
