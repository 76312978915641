import React, { useState, useEffect } from "react";
import classes from "../../../../assets/css/changePassword.module.css";
import { useNavigate } from "react-router-dom";
import {
  MailOutlined,
  CheckCircleOutline,
  DoneOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Form from "react-bootstrap/Form";
import { Seo } from "../../../utilities/Seo";
import { maskEmail, maskPhoneNumner } from "../../../functions/Helpers";
import Termii from "../../../../api/Termii";
import Encryption from "../../../functions/Encryption";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Navbar from "../../../navbar/Navbar";

export const ChangePassword = () => {
  const [errorText, setErrorText] = useState("Loading...");
  const [isLoading, setIsLoading] = useState(false);
  const [isTransparentLoading, setIsTransparentLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState({});
  const [successMessage, setSuccessMessage] = useState(false);
  const [code, setCode] = useState("");
  const [isCountDown, setIsCountDown] = useState(false);
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [phoneFormatError, setPhoneFormartError] = useState("");
  const [pinId, setPinId] = useState("");

  const axiosPrivate = useAxiosPrivate();
  const [isChecking, setIsChecking] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [count, setCount] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const pageTitle = "Change Login Password || Checkstall.com";
    Seo({
      title: pageTitle,
      metaDescription: "Change Login Password ",
    });
  });

  const getValues = (e, key) => {
    if (key === "email") {
      setEmail(e?.target?.value);
      if (e.target.value) {
        setError((preValue) => {
          return {
            ...preValue,
            email: "",
          };
        });
      } else {
        setError((preValue) => {
          return {
            ...preValue,
            email: "Eamil cannot be empty",
          };
        });
      }
    } else if (key === "password") {
      setPassword(e?.target?.value);
      if (e.target.value) {
        setError((preValue) => {
          return {
            ...preValue,
            password: "",
          };
        });
      } else {
        setError((preValue) => {
          return {
            ...preValue,
            password: "Password cannot be empty",
          };
        });
      }
    } else if (key === "confirmPassword") {
      setConfirmPassword(e?.target?.value);
      if (e.target.value) {
        setError((preValue) => {
          return {
            ...preValue,
            confirmPassword: "",
          };
        });
      } else {
        setError((preValue) => {
          return {
            ...preValue,
            confirmPassword: "Confirm  Password cannot be empty",
          };
        });
      }
    } else if (key === "code") {
      setCode(e?.target?.value);
      if (e.target.value) {
        setError((preValue) => {
          return {
            ...preValue,
            code: "",
          };
        });
      } else {
        setError((preValue) => {
          return {
            ...preValue,
            code: "Code  cannot be empty",
          };
        });
      }
    }
  };

  const africaCode = ["ng", "gh", "ke", "ug", "za"];
  const africaDailingCode = {
    ng: "234",
    gh: "233",
    ke: "254",
    ug: "256",
    za: "27",
  };
  const handleOnChange = (value, data, event, formattedValue) => {
    let phone = "";
    let country = data.countryCode;
    let val = value.target.value.replace(/ +/g, "").replace("+", "");
    let withOutDailingCode = val.slice(data.dialCode.length);
    //console.log(withOutDailingCode);
    if (africaCode.includes(country)) {
      let count = withOutDailingCode.replace(/ +/g, "").length;
      if (count === 11) {
        //user prefix  0 to number, we slice it off
        let t =
          withOutDailingCode.slice(0, 0) +
          withOutDailingCode.slice(1, withOutDailingCode.length);
        phone = africaDailingCode[country] + t;
        setPhoneNumber(phone);
        setPhoneFormartError("");
      } else if (count === 10) {
        //user doesn't prefix  0 to number, we set the value to phone number
        phone = africaDailingCode[country] + withOutDailingCode;
        setPhoneNumber(phone);
        setPhoneFormartError("");
      } else {
        setPhoneFormartError("Invalid format");
      }
    } else if (country === "cn") {
      // we set the value to phone number
      let count = withOutDailingCode.replace(/ +/g, "").replace("-", "").length;
      if (count === 11) {
        let t =
          withOutDailingCode.slice(0, 2) +
          withOutDailingCode.slice(3, withOutDailingCode.length);
        phone = "86" + t;
        setPhoneNumber(phone);
        setPhoneFormartError("");
      } else {
        setPhoneFormartError("Invalid format");
      }
    } else if (country === "gb") {
      // we set the value to phone number
      let count = withOutDailingCode.length;
      if (count === 10) {
        phone = "44" + withOutDailingCode;
        setPhoneNumber(phone);
        setPhoneFormartError("");
      } else {
        setPhoneFormartError("Invalid format");
      }
    } else if (country === "us" || country === "ca") {
      // we set the value to phone number
      let count = withOutDailingCode.length;
      if (count === 10) {
        phone = "1" + withOutDailingCode;
        setPhoneNumber(phone);
        setPhoneFormartError("");
      } else {
        setPhoneFormartError("Invalid format");
      }
    } else if (country === "ae") {
      // we set the value to phone number
      let count = withOutDailingCode.length;
      if (count === 9) {
        phone = "971" + withOutDailingCode;
        setPhoneNumber(phone);
        setPhoneFormartError("");
      } else {
        setPhoneFormartError("Invalid format");
      }
    }
  };

  const resetPassword = () => {
    let isMounted = true;
    const controller = new AbortController();
    setIsTransparentLoading(true);

    const doResetPassword = async () => {
      const encryption = new Encryption();
      const pass = encryption.encrypt(password, encryption.key);
      const confirmPass = encryption.encrypt(confirmPassword, encryption.key);
      const resetCode = encryption.encrypt(code, encryption.key);
      try {
        const response = await axiosPrivate.post(
          "v1/users/reset-password",
          JSON.stringify({ pass, confirmPass, resetCode }),
          {
            signal: controller.signal,
          }
        );

        if (response?.data?.code == 2001) {
          setSuccessMessage(response?.data?.message);
          setPassword("");
          setConfirmPassword("");
          setCode("");
          setCount("");
          setIsVerified(false);
          setIsTransparentLoading(false);
          const timer = setTimeout(() => {
            setSuccessMessage(false);
            navigate("/login");
          }, 1000);
          return () => clearTimeout(timer);
        } else {
          isMounted && setError(response?.data?.message);
        }
      } catch (err) {}
      setIsTransparentLoading(false);
    };

    doResetPassword();
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  // const handleEmailBlur = (e) =>{
  //   setEmail(e.target.value)
  //   if(!e.target.value){
  //       setError(preValue =>{
  //         return{
  //         ...preValue,
  //         "email":"Eamil cannot be empty"
  //         }
  //     })
  //   }else if(!e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
  //       setError(preValue =>{
  //         return{
  //         ...preValue,
  //         "email":"Invalid email"
  //         }
  //     })
  //   }else{
  //       setError(preValue =>{
  //         return{
  //         ...preValue,
  //         "email":""
  //         }
  //     })
  //   }
  // }

  const handleSetPasswrod = (e) => {
    e.preventDefault();
    if (!password) {
      setError((preValue) => {
        return {
          ...preValue,
          password: "Password field cannot be empty",
        };
      });
    } else if (password.length < 6) {
      setError((preValue) => {
        return {
          ...preValue,
          password: "Password must be must be atleast 6 characters",
        };
      });
      return;
    } else if (password !== confirmPassword) {
      setError((preValue) => {
        return {
          ...preValue,
          confirmPassword: "Confirm password don't match",
        };
      });
      return;
    } else if (!isVerified) {
      setError((preValue) => {
        return {
          ...preValue,
          code: "Incorrect / invalid code",
        };
      });
    } else {
      resetPassword();
    }
  };

  const countDown = (limit) => {
    let counts = setInterval(() => {
      setCount(--limit);
      if (limit === 0) {
        clearInterval(counts);
        setIsCountDown(false);
        setCount("");
      }
    }, 1000);
  };

  const sendSms = () => {
    setIsCountDown(true);
    countDown(60);

    const termiiInstance = Termii();

    //Send OTP
    termiiInstance.send_verification_otp(phone_number).then((response) => {
      if (response.error == 101) {
        setError((preValue) => {
          return {
            ...preValue,
            phoneError: response.message,
          };
        });
        return;
      }

      if (parseInt(response.message.status) === 200) {
        setPinId(response.message.pinId);
      }
    });
  };

  const verifySms = () => {
    const termiiInstance = Termii();
    setIsChecking(true);
    //verify OTP
    if (pinId && code) {
      termiiInstance.verify_otp(pinId, code).then((response) => {
        if (response.message.verified == true) {
          setIsVerified(true);
          setIsChecking(false);
        } else if (response.message.verified == false) {
          setError((preValue) => {
            return {
              ...preValue,
              code: "Incorrect / invalid code",
            };
          });
          setIsChecking(false);
        } else if (
          response.message.status == 400 &&
          response.message.verified == "Expired"
        ) {
          setError((preValue) => {
            return {
              ...preValue,
              code: "Code already Expired",
            };
          });
          setIsChecking(false);
        }
      });
    } else {
      setError((preValue) => {
        return {
          ...preValue,
          code: "Please get another code",
        };
      });
      setIsChecking(false);
    }
  };

  // const sendCode = ()=>{
  //     setIsCountDown(true);
  //     countDown( 60);

  //     const termiiInstance = Termii();
  //     //Send OTP
  //     let endpoint = "/v1/api/api-password-reset-code";
  //   termiiInstance.send_verify_code(endpoint, email).then(response => {
  //     if(response.error == 101){
  //       setError(response.message);
  //       setError(preValue =>{
  //         return{
  //         ...preValue,
  //         "email":response.message
  //         }
  //       })
  //     }

  //   });
  // }
  // const verifyCode = ()=>{
  //   const termiiInstance = Termii();
  //   setIsChecking(true);
  //   //verify OTP
  //   if(code){
  //       termiiInstance.verify_email_otp(code).then(response => {

  //          if(response.verified == true){
  //              setIsVerified(true);
  //              setIsChecking(false);
  //              setCount("");
  //          }else{
  //                 setError(preValue =>{
  //                   return{
  //                   ...preValue,
  //                   "code":"Incorrect / invalid code"
  //                   }
  //               })
  //               setIsChecking(false);
  //         }

  //       });
  //   }else{
  //     setError(preValue =>{
  //       return{
  //       ...preValue,
  //       "code":"Please get another code"
  //       }
  //   })
  //     setIsChecking(false);
  //   }

  // }

  useEffect(() => {
    if (code.length === 6) {
      verifySms();
    }
  }, [code]);

  return (
    <React.Fragment>
      <Navbar />
      <div className={classes.wrapper}>
        <div className={classes.titleWrapper}>
          <h3 className={classes.title}>Reset your Password</h3>
          <p>Did you forget your Password?</p>
          <p>Not to worry, we can help you recover it in just one click.</p>
          <p>Input your registerd phone number in the form below.</p>
        </div>
        <Form onSubmit={handleSetPasswrod} className={classes.formContainer}>
          <div className={classes.formFieldWrapper}>
            {/* <div className='d-flex flex-column' >
                            <div className='setting-wrap d-flex justify-content-between'>
                              <div className='setting-name'>Your email</div>
                              <div className='input-field'>
                                <input 
                                    type="text"
                                    value={email}
                                    onBlur={handleEmailBlur}
                                    onChange={(e) => getValues(e, "email")}
                                    placeholder="Enter your email"
                                />
                              </div>
                            </div>
                            <div className='regErr'>{error?.email}</div>
                        </div> */}
            <Form.Group
              size="lg"
              controlId="phone_number"
              className={classes.phoneInputBox}
            >
              <PhoneInput
                country={"ng"}
                value={phone_number}
                onBlur={handleOnChange}
                onlyCountries={[
                  "ng",
                  "gb",
                  "gh",
                  "ke",
                  "ca",
                  "us",
                  "ug",
                  "ae",
                  "za",
                  "cn",
                ]}
                preferredCountries={["ng"]}
                countryCodeEditable={false}
              />
              <span className="regErr">{error?.phoneError}</span>
              <span className="regErr">{phoneFormatError}</span>
            </Form.Group>

            <div className={classes.getCodeWrapper}>
              <div className={classes.getCodeContainer}>
                <div className={classes.getCode}>
                  Get code{" "}
                  <MailOutlined sx={{ color: "crimson" }} onClick={sendSms} />
                  &nbsp;&nbsp;
                  {isVerified && (
                    <span className="">
                      <DoneOutlined sx={{ color: "green" }} />
                    </span>
                  )}
                  {isChecking && (
                    <span className="">
                      <img src="/images/loader/reload.gif" />
                    </span>
                  )}
                  {isCountDown && (
                    <span id="counter" style={{ color: "crimson" }}>
                      {count}
                    </span>
                  )}
                </div>
                <input
                  className={classes.inputField}
                  type="number"
                  value={code}
                  required
                  maxLength={6}
                  onChange={(e) => getValues(e, "code")}
                  placeholder="Enter code"
                />
              </div>

              <span className={classes.regErr}>{error?.code}</span>
            </div>
            <div className={classes.pinInputBox}>
              <div className={classes.pinInputContainer}>
                <label className={classes.pinLabel}>Enter New Password</label>
                <input
                  className={classes.pinInputField}
                  type="password"
                  value={password}
                  required
                  onChange={(e) => getValues(e, "password")}
                  // placeholder="Enter New password"
                />
              </div>
              <div className={classes.regErr}>{error?.password}</div>
            </div>
            <div className={classes.pinInputBox}>
              <div className={classes.pinInputContainer}>
                <label className={classes.pinLabel}>Confirm Password</label>
                <input
                  className={classes.pinInputField}
                  type="password"
                  onChange={(e) => getValues(e, "confirmPassword")}
                  value={confirmPassword}
                  // placeholder="Confirm Password"
                  required
                />
              </div>
              <div className={classes.regErr}>{error?.confirmPassword}</div>
            </div>
            <button
              className={classes.btn}
              type="submit"
              onClick={handleSetPasswrod}
            >
              Update
            </button>
          </div>
        </Form>

        {/* <div className="log-out-wrap add-new-add d-flex justify-content-center">
          <button type="submit" onClick={handleSetPasswrod}>
            Update
          </button>
        </div> */}
      </div>
    </React.Fragment>
  );
};
