import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Form from "react-bootstrap/Form";
import bannerImg from "../../assets/images/bannerImg.png";
import "../../assets/css/login.css";
import "../../assets/css/signin.css";
import { Link } from 'react-router-dom';
import {Seo} from "../utilities/Seo";
import Spinner from "../utilities/Spinner";
import axios from "../../api/axios";
import {setCookie } from '../functions/CustomCookies';
import LoginNavbar from '../loginNavbar/LoginNavbar';
import { FcGoogle } from "react-icons/fc";
import AllowPhoneSignup from './signup/AllowPhoneSignup';
import AllowEmailSignup from './signup/AllowEmailSignup';
import { axiosPublic } from "../../api/axios";


const SignUp = () => {
  const allowPhoneRegistration = ["OM","SG"];
  useEffect(() => {
    // userRef.current.focus();
    Seo({
      title: 'Sign up || Buy & sell electronics, cars, clothes, collectibles & more on checkstall, the world\'s online marketplace. Top brands, low prices ; free shipping on many items Checkstall.com',
      metaDescription: 'Checkstall Register page || Buy & sell electronics, cars, clothes, collectibles & more on checkstall, the world\'s online marketplace. Top brands, low prices ; free shipping on many items Checkstall.com'
    });
  }, [])

  const [state, setState] = useState({
    countryName: "",
    countryCode: "",
  });
  //"/v1/helpers/signup-with-phone"
  const [result, setResult] = useState([]);

  const getGeoInfo = () => {
    axiosPublic
      .get("https://freeipapi.com/api/json")
      .then((response) => {
        let data = response.data;
        setState({
          ...state,
          countryCode: data.countryCode,
          countryName: data.countryName,
        });
        setResult(response.data);
      })
      .catch((error) => {
        //console.log(error);
      });

  };



  useEffect(() => {
    getGeoInfo();
  }, []);


  return (

    <React.Fragment>
        {allowPhoneRegistration.includes(state.countryCode)  ? (
             <AllowEmailSignup />
        ) : (
             <AllowPhoneSignup />
        )}
       
        
    </React.Fragment>

  );

}

export default SignUp;