import React, { Component } from 'react';
import Header from '../functions/Header';
import Footer from '../functions/Footer';
import SideBar from '../functions/SideBar';
import '../../assets/css/shippingadd.css'
import { FaTrash, FaEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';


class ShippingAdd extends Component {
  render() {
    return (
        <>
        <Header />
        {/* <Sidebarmobile /> */}
      <div className="container mt5" >
      
        <div className='row spacebtw'> 
        
          <div className='col-lg-4  col-md-3 inlineBlock dView'>
            <SideBar />
          </div>
          <div className='shippingcard inlineBlock col-lg-8 col-md-7 reduceContainer'>
              <h5 className='col-lg-8 '>Shipping Address</h5>
              <div className='col-lg-8 shipCard'>
                  <div >
                      <p>Lorem iahgh 1325 street 546, Lagos, Ikeja 106101</p>
                      <div className='addressBtn'>
                          <Link to="#" className='iconsEdit' ><FaEdit /> <p>Edit</p></Link>
                          <Link to="#" style={{color: '#fff', backgroundColor:'#cf0000'}}><FaTrash /> <p>Delete</p></Link>
                      </div>
                      <p className='defAdd'>default address</p>
                  </div>
              </div>
              <div className='addAddress AddAdd'><Link to="#"><p>Add Address</p></Link></div>
          </div> 
        </div>       
      </div>
      <Footer />
      </>
    )
  }
}
export default ShippingAdd;

