import React from 'react';
import classes from './Shop.module.css';
import { productsData } from './shopData';
import ShopItems from '../shopItems/ShopItems';
import ProductItems from "../productItems/ProductItems";


const Shop = ({
  flashSales,
  hotDeals,
  mensClothing,
  womensClothing,
  computerAccessories,
  kidsToys,
  phoneTablet,
  footWears,
  hairAndWigs,
  homeAppliances,
  jewelryAndWatches,
  bagsAndLuggages,
}) => {
  
  

  return (
    <section className={classes.wrapper}>
      {/* flashSales */}
      {flashSales &&
      flashSales !== "" &&
      flashSales !== undefined &&
      flashSales.length > 0 ? (
        <div className={classes.container}>
          <h1 className={classes.title}>flash sales</h1>
          <div className={classes.productsContainer}>
            {flashSales.map((item) => {
              return <ProductItems key={item.item_id} item={item} />;
            })}
          </div>
        </div>
      ) : null}
      {/* hotDeals */}
      {hotDeals &&
      hotDeals !== "" &&
      hotDeals !== undefined &&
      hotDeals.length > 0 ? (
        <div className={classes.container}>
          <h1 className={classes.title}>hot deals</h1>
          <div className={classes.productsContainer}>
            {hotDeals.map((item) => {
              return <ProductItems key={item.item_id} item={item} />;
            })}
          </div>
        </div>
      ) : null}
      {/* mens clothing */}
      {mensClothing &&
      mensClothing !== "" &&
      mensClothing !== undefined &&
      mensClothing.length > 0 ? (
        <div className={classes.container}>
          <h1 className={classes.title}>men's clothing</h1>
          <div className={classes.productsContainer}>
            {mensClothing.map((item) => {
              return <ProductItems key={item.item_id} item={item} />;
            })}
          </div>
        </div>
      ) : null}
      {/* womensClothing */}
      {womensClothing &&
      womensClothing !== "" &&
      womensClothing !== undefined &&
      womensClothing.length > 0 ? (
        <div className={classes.container}>
          <h1 className={classes.title}>women's clothing</h1>
          <div className={classes.productsContainer}>
            {womensClothing.map((item) => {
              return <ProductItems key={item.item_id} item={item} />;
            })}
          </div>
        </div>
      ) : null}
      {/* computerAccessories */}
      {computerAccessories &&
      computerAccessories !== "" &&
      computerAccessories !== undefined &&
      computerAccessories.length > 0 ? (
        <div className={classes.container}>
          <h1 className={classes.title}>computer accessories</h1>
          <div className={classes.productsContainer}>
            {computerAccessories.map((item) => {
              return <ProductItems key={item.item_id} item={item} />;
            })}
          </div>
        </div>
      ) : null}
      {/* kidsToys */}
      {kidsToys &&
      phoneTablet !== "" &&
      kidsToys !== undefined &&
      kidsToys.length > 0 ? (
        <div className={classes.container}>
          <h1 className={classes.title}>kids and toys</h1>
          <div className={classes.productsContainer}>
            {kidsToys.map((item) => {
              return <ProductItems key={item.item_id} item={item} />;
            })}
          </div>
        </div>
      ) : null}
      {/* phoneTablet */}
      {phoneTablet &&
      phoneTablet !== "" &&
      phoneTablet !== undefined &&
      phoneTablet.length > 0 ? (
        <div className={classes.container}>
          <h1 className={classes.title}>phones and tablets</h1>
          <div className={classes.productsContainer}>
            {phoneTablet.map((item) => {
              return <ProductItems key={item.item_id} item={item} />;
            })}
          </div>
        </div>
      ) : null}

      {/* foot wears */}
      {footWears &&
      footWears !== "" &&
      footWears !== undefined &&
      footWears.length > 0 ? (
        <div className={classes.container}>
          <h1 className={classes.title}>foot wears</h1>
          <div className={classes.productsContainer}>
            {footWears.map((item) => {
              return <ProductItems key={item.item_id} item={item} />;
            })}
          </div>
        </div>
      ) : null}
      {/* hairAndWigs */}
      {hairAndWigs &&
      hairAndWigs !== "" &&
      hairAndWigs !== undefined &&
      hairAndWigs.length > 0 ? (
        <div className={classes.container}>
          <h1 className={classes.title}>hairs and wigs</h1>
          <div className={classes.productsContainer}>
            {hairAndWigs.map((item) => {
              return <ProductItems key={item.item_id} item={item} />;
            })}
          </div>
        </div>
      ) : null}
      {/* homeAppliances */}
      {homeAppliances &&
      homeAppliances !== "" &&
      homeAppliances !== undefined &&
      homeAppliances.length > 0 ? (
        <div className={classes.container}>
          <h1 className={classes.title}>home appliances</h1>
          <div className={classes.productsContainer}>
            {homeAppliances.map((item) => {
              return <ProductItems key={item.item_id} item={item} />;
            })}
          </div>
        </div>
      ) : null}
      {/* jewelryAndWatches */}
      {jewelryAndWatches &&
      jewelryAndWatches !== "" &&
      jewelryAndWatches !== undefined &&
      jewelryAndWatches.length > 0 ? (
        <div className={classes.container}>
          <h1 className={classes.title}>jewelry and watches</h1>
          <div className={classes.productsContainer}>
            {jewelryAndWatches.map((item) => {
              return <ProductItems key={item.item_id} item={item} />;
            })}
          </div>
        </div>
      ) : null}
      {/* bagsAndLuggages */}
      {bagsAndLuggages &&
      bagsAndLuggages !== "" &&
      bagsAndLuggages !== undefined &&
      bagsAndLuggages.length > 0 ? (
        <div className={classes.container}>
          <h1 className={classes.title}>bags and luggages</h1>
          <div className={classes.productsContainer}>
            {bagsAndLuggages.map((item) => {
              return <ProductItems key={item.item_id} item={item} />;
            })}
          </div>
        </div>
      ) : null}
    </section>
  );
};
 
export default Shop
