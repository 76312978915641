import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import { Seo } from '../../utilities/Seo';
import '../../../assets/css/success.css';
import Header from "../../functions/Header";
import Footer from "../../functions/Footer";


export const  Completed  = () => {

    //set page title
useEffect(() => {
    const pageTitle =  "Payment  Successfully || Checkstall.com";
    Seo({
      title: pageTitle,
      metaDescription: 'Payment  Successfully',
    });
}, []);

    return (
        <>
            <Header />
                <div className='success-alert'>
                    <div className="alert alert-success" role="alert">
                        <div>Transaction Successful </div>
                        <div className="d-flex justify-content-start">
                            <div>  Go  <Link to="/" style={{textDecoration:'none'}}>Home &nbsp;</Link> </div>
                            <div>  or go to&nbsp;<Link to="/members/orders/index?orderStatus=all" style={{textDecoration:'none'}}>My Order</Link> to know  your order status</div>
                        </div>
                    </div>
                </div>
            <Footer />
        </>
    )
};
