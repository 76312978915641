import React, { useState } from "react";
import "./assets/css/App.css";
import { Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaAngleUp } from "react-icons/fa";
import MessageIcon from "@mui/icons-material/Message";

// pages
import Login from "./components/pages/Login";
import Home from "./components/pages/Home";
import SignUp from "./components/pages/SignUp";
import NotFound from "./components/pages/NotFound";

// import Wallet from './components/pages/Wallet';
import ShippingAdd from "./components/pages/ShippingAdd";
import Shop from "./components/pages/Shop";
import Item from "./components/pages/Item";
import Unauthorized from "./components/pages/Unauthorized";
import Cart from "./components/pages/Cart";
import Users from "./components/pages/Users";
import CreateOrder from "./components/pages/order/CreateOrder";
import Payment from "./components/pages/order/Payment";
import { Completed as SuccessCompleted } from "./components/pages/order/Completed";
import { Index as AccountIndex } from "./components/pages/account/Index";
import Settings from "./components/pages/account/Settings";
import ShippingAddress from "./components/pages/account/ShippingAddress";
import Transaction from "./components/pages/wallet/Transaction";
import BalancePrivacy from "./components/pages/wallet/BalancePrivacy";
import WalletPassword from "./components/pages/wallet/WalletPassword";
import History from "./components/pages/wallet/History";
import TopUp from "./components/pages/wallet/TopUp";
import Withdraw from "./components/pages/wallet/Withdraw";
import WithdrawalInformation from "./components/pages/wallet/WithdrawalInformation";
import { Index as WalletIndex } from "./components/pages/wallet/Index";
import { Index as OrderIndex } from "./components/pages/order/Index";
import { Index as HomeIndex } from "./components/pages/home/Index";
import { Completed as PaymentCompleted } from "./components/pages/dajupay/Completed";
import ProcessPayment from "./components/pages/dajupay/ProcessPayment";
import { ChangePassword } from "./components/pages/settings/security/ChangePassword";
import Invoice from "./components/pages/Invoice";

// functins
import RequireAuth from "./components/functions/RequireAuth";
import PersistLogin from "./components/functions/PersistLogin";
import FixedSideBar from "./components/functions/FixedSideBar";

// outlet
import Order from "./components/outlets/Order";
import Orders from "./components/outlets/Orders";
import Members from "./components/outlets/Members";
import Account from "./components/outlets/Account";
import { Home as MemberHome } from "./components/outlets/Home";
import Wallet from "./components/outlets/Wallet";
import Dajupay from "./components/outlets/Dajupay";
import Completed from "./components/pages/Completed";
import RegistrationSuccessful from "./components/pages/RegistrationSuccessful";
import WalletTopup from "./components/pages/dajupay/WalletTopup";
import { TransactionPending } from "./components/pages/dajupay/TransactionPending";
import Footer from "./components/functions/Footer";
import PartnerDetails from "./components/pages/partner/Details";
import CheckstallPartner from "./components/pages/partner/CheckstallPartner";
import Register from "./components/pages/partner/Register";
import Index from "./components/pages/partner/Index";
import RefereeList from "./components/pages/partner/RefereeList";
import { Withdraw as InAppWithdraw } from "./components/pages/partner/Withdraw";
import Profile from "./components/pages/account/Profile";
import PasswordLoginUpdate from "./components/pages/account/PasswordLoginUpdate";
import ContactUs from "./components/pages/contactUs/ContactUs";
import CookiesPolicy from "./components/policy/cookiesPolicy/CookiesPolicy";
import PrivacyPolicy from "./components/policy/privacyPolicy/PrivacyPolicy";
import Messages from "./components/pages/messages/Messages";
// import MobileFooter from "./components/mobileFooter/MobileFooter";
import ShoppingGuide from "./components/howToBuyFolder/shoppingGuide/ShoppingGuide";
import ShoppingNotice from "./components/howToBuyFolder/purchaseNotice/ShoppingNotice";
import HowToBuy from "./components/howToBuyFolder/howToBuy/HowToBuy";
import ParcelManagement from "./components/pages/parcelManagement/ParcelManagement";
import Calculator from "./components/pages/calculator/Calculator";
import SizeComparison from "./components/pages/sizeComparisonFolder/sizeComparison/SizeComparison";
import ItemNotFound from "./components/pages/itemNotFound/ItemNotFound";
// import ShoppingGuide from "./components/pages/ShoppingGuide";
// import ShoppingNotice from "./components/pages/ShoppingNotice";

const ROLES = {
  user: 10,
  level_one: 20,
  level_two: 30,
  top_level_admin: 40,
  agent: 11,
};

function App() {
  const [arrowScrollUp, setArrowScrollUp] = useState(false);
  const [messageUp, setMessageUp] = useState(false);
  const [showMessages, setShowMessages] = useState(true);

  const handleScroll = () => {
    if (window.scrollY >= 50) {
      setArrowScrollUp(true);
      setMessageUp(true);
    } else {
      setArrowScrollUp(false);
      setMessageUp(false);
    }
  };

  window.addEventListener("scroll", handleScroll);

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  //  const handleUpMessage = () => {
  //    window.scrollTo({
  //      top: 0,
  //      behavior: "smooth",
  //    });
  //  };
  return (
    <>
      <div className="wrapper">
        <div
          className={`back-to-top ${arrowScrollUp && "show-arrow"}`}
          onClick={handleScrollTop}
        >
          <FaAngleUp size={25} />
        </div>
        <div
          className={`${
            showMessages ? "message-notification" : "remove-messages"
          } ${messageUp && "message-up"}`}
          onClick={() => setShowMessages(false)}
        >
          <Link to="/messages" className="messages-link">
            <MessageIcon sx={{ fontSize: "1.5rem" }} />
            <span>Messages(0)</span>
          </Link>
        </div>
        {/* <div className="fixed-side-bar">
        <div className="fixed-side-bar-link-box">
          <Link to="/members/home/index" className="fixed-side-bar-link">
            <AccountBoxIcon sx={{ fontSize: "2rem", color: "white" }} />
          </Link>
          <div className="fixed-hover-box">
            <span className="box-content">User</span>
            <span className="arrow-direct">
              <ArrowRightIcon sx={{ fontSize: "2.5rem", color: "#444851" }} />
            </span>
          </div>
        </div>

        <div className="fixed-side-bar-link-box">
          <Link to="/cart" className="fixed-side-bar-link">
            <ShoppingCartIcon sx={{ fontSize: "2rem", color: "white" }} />
          </Link>
          <div className="fixed-hover-box">
            <span className="box-content">Cart</span>
            <span className="arrow-direct">
              <ArrowRightIcon sx={{ fontSize: "2.5rem", color: "#444851" }} />
            </span>
          </div>
        </div>
        <div className="fixed-side-bar-link-box">
          <Link to="/checkstall-partner" className="fixed-side-bar-link">
            <HandshakeIcon sx={{ fontSize: "2rem", color: "white" }} />
          </Link>
          <div className="fixed-hover-box">
            <span className="box-content">Partner Program</span>
            <span className="arrow-direct">
              <ArrowRightIcon sx={{ fontSize: "2.5rem", color: "#444851" }} />
            </span>
          </div>
        </div>
      </div> */}
        <FixedSideBar />
        <Routes>
          {/* public routes */}
          <Route path="/" exact element={<Home />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/freightcalculator" element={<Calculator />} />
          <Route path="/sizecomparison" element={<SizeComparison />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/item" element={<Item />} />
          <Route path="/item/notfound" element={<ItemNotFound />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/cookies" element={<CookiesPolicy />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="/checkstall-partner" element={<CheckstallPartner />} />
          <Route
            path="/registration-successful"
            element={<RegistrationSuccessful />}
          />
          <Route
            path="account-security/change-password"
            element={<ChangePassword />}
          />

          {/* this should be commented out after you create howtobuy component */}
          <Route path="/howtobuy" element={<HowToBuy />} />
          <Route path="/howtobuy/shopping-guide" element={<ShoppingGuide />} />
          <Route
            path="/howtobuy/shopping-notice"
            element={<ShoppingNotice />}
          />

          {/* protected these routes */}
          <Route element={<PersistLogin />}>
            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.user,
                    ROLES.top_level_admin,
                    ROLES.level_two,
                    ROLES.level_one,
                    ROLES.agent,
                  ]}
                />
              }
            >
              <Route path="/payment" element={<Payment />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.user,
                    ROLES.top_level_admin,
                    ROLES.level_two,
                    ROLES.level_one,
                    ROLES.agent,
                  ]}
                />
              }
            >
              <Route path="/orders" element={<Orders />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.user,
                    ROLES.top_level_admin,
                    ROLES.level_two,
                    ROLES.level_one,
                    ROLES.agent,
                  ]}
                />
              }
            >
              <Route path="/order" element={<Order />}>
                <Route path="create_order" element={<CreateOrder />} />
                <Route path="payment" element={<Payment />} />
                <Route path="completed" element={<SuccessCompleted />} />
              </Route>
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.user,
                    ROLES.top_level_admin,
                    ROLES.level_two,
                    ROLES.level_one,
                    ROLES.agent,
                  ]}
                />
              }
            >
              <Route path="/members" element={<Members />}>
                <Route path="home" element={<MemberHome />}>
                  <Route path="index" element={<HomeIndex />} />
                </Route>
                <Route path="account" element={<Account />}>
                  <Route path="index" element={<AccountIndex />} />
                  <Route path="userProfile" element={<Profile />} />
                  <Route path="settings" element={<Settings />} />
                  <Route
                    path="shipping-address"
                    element={<ShippingAddress />}
                  />
                  <Route
                    path="updateloginpassword"
                    element={<PasswordLoginUpdate />}
                  />
                </Route>
                <Route path="wallet" element={<Wallet />}>
                  <Route path="index" element={<WalletIndex />} />
                  <Route path="top-up" element={<TopUp />} />
                  <Route path="withdraw" element={<Withdraw />} />
                  <Route
                    path="withdrawal-information"
                    element={<WithdrawalInformation />}
                  />
                  <Route path="history" element={<History />} />
                  <Route path="transaction" element={<Transaction />} />
                  <Route
                    path="set-wallet-password"
                    element={<WalletPassword />}
                  />
                  <Route path="privacy" element={<BalancePrivacy />} />
                </Route>
                <Route path="orders" element={<Orders />}>
                  <Route path="index" element={<OrderIndex />} />
                </Route>
                <Route path="invoice" element={<Invoice />}></Route>
                <Route path="parcel" element={<ParcelManagement />} />
              </Route>
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.user,
                    ROLES.top_level_admin,
                    ROLES.level_two,
                    ROLES.level_one,
                    ROLES.agent,
                  ]}
                />
              }
            >
              <Route path="/dajupay" element={<Dajupay />}>
                <Route path="completed" element={<PaymentCompleted />} />
                <Route path="wallet-topup" element={<WalletTopup />} />
                <Route path="process-payment" element={<ProcessPayment />} />
                <Route
                  path="transaction-pending"
                  element={<TransactionPending />}
                />
              </Route>
            </Route>
            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.user,
                    ROLES.top_level_admin,
                    ROLES.level_two,
                    ROLES.level_one,
                    ROLES.agent,
                  ]}
                />
              }
            >
              <Route path="/shipping-address" element={<ShippingAdd />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.user,
                    ROLES.top_level_admin,
                    ROLES.level_two,
                    ROLES.level_one,
                    ROLES.agent,
                  ]}
                />
              }
            >
              <Route path="/completed" element={<Completed />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.user,
                    ROLES.top_level_admin,
                    ROLES.level_two,
                    ROLES.level_one,
                    ROLES.agent,
                  ]}
                />
              }
            >
              <Route path="/account" element={<Account />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.user,
                    ROLES.top_level_admin,
                    ROLES.level_two,
                    ROLES.level_one,
                    ROLES.agent,
                  ]}
                />
              }
            >
              <Route path="/cart" element={<Cart />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.user,
                    ROLES.top_level_admin,
                    ROLES.level_two,
                    ROLES.level_one,
                    ROLES.agent,
                  ]}
                />
              }
            >
              <Route path="/users" element={<Users />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.top_level_admin,
                    ROLES.level_two,
                    ROLES.agent,
                  ]}
                />
              }
            >
              <Route path="checkstall-partner/index" element={<Index />} />
              <Route
                path="checkstall-partner/details/:id"
                element={<PartnerDetails />}
              />
              <Route
                path="checkstall-partner/referee-list"
                element={<RefereeList />}
              />
              <Route
                path="checkstall-partner/withdraw"
                element={<InAppWithdraw />}
              />
            </Route>
            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.user,
                    ROLES.top_level_admin,
                    ROLES.level_two,
                    ROLES.level_one,
                    ROLES.agent,
                  ]}
                />
              }
            >
              <Route
                path="/checkstall-partner/register"
                element={<Register />}
              />
            </Route>
          </Route>

          {/* catch all */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
