import React from "react";
import classes from './MessageModal.module.css';

const MessageModal = ({ item }) => {
  console.log(item);
  return (
    <>
      <h4 className={classes.ModalTitle}>{item.title}</h4>
      <div className={classes.infoBox}>
        <p className={classes.modalInfo}>
          {item.notice}
        </p>
      </div>
      <div className={classes.modalDate}>{item.time}</div>
    </>
  );
};

export default MessageModal;
