import React from "react";
import classes from "./HowToBuy.module.css";
import Navbar from "../../navbar/Navbar";
import { Link } from "react-router-dom";

const HowToBuy = () => {
  return (
    <>
      <Navbar />
      <main className={classes.wrapper}>
        <section className={classes.container}>
          <div className={classes.heading}>
            <h1 className={classes.title}>Welcome to Checkstall user guide</h1>
            <p className={classes.intro}>
              This Section will guide you on how to shop on checkstall with
              ease. Buying high quality and relaible items from abroad
            </p>
          </div>
          <div className={classes.detailBox}>
            <h3>how to purchase</h3>
            <p>
              You can get the best and easiest user experience of our platform
              by reading through this page carefully. To make a purchase, simply
              search for any item you want to purchase from Checkstall. You can
              also paste a link from 1688 on the search input space to get the
              particular item from Checkstall. Our platform offers a very simple
              and easy procedures without any stress. For full details on how to
              make purchase, please checkout the links below:
            </p>
            <div className={classes.linkBox}>
              <Link to="/howtobuy/shopping-guide" className={classes.link}>
                Checkstall shopping guide
              </Link>
              <Link to="/howtobuy/shopping-notice" className={classes.link}>
                Checkstall purchasing notice
              </Link>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default HowToBuy;
