import React from "react";
import { Outlet } from 'react-router-dom';

function Wallet (){
    return(
        <>
            <Outlet />
        </>
    )
}

export default Wallet;