import React, { useState, useEffect, useRef } from "react";
import classes from "../../../assets/css/updateProfilePhoneNumber.module.css";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Form from "react-bootstrap/Form";
import { Seo } from "../../utilities/Seo";
import {
  CheckCircleOutline,
  MailOutlined,
  DoneOutlined,
} from "@mui/icons-material";
import Encryption from "../../functions/Encryption";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { maskEmail, maskPhoneNumner } from "../../functions/Helpers";
import Termii from "../../../api/Termii";
import PhoneInput from "react-phone-input-2";
import Spinner from "../../utilities/Spinner";

export const UpdatePhone = () => {
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isTransparentLoading, setIsTransparentLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [full_name, setFullname] = useState("");
  const [nameError, setNameError] = useState("");
  const [isChecking, setIsChecking] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isCountDown, setIsCountDown] = useState(false);
  const [isOtpCountDown, setIsOtpCountDown] = useState(false);
  const [code, setCode] = useState("");
  const [count, setCount] = useState("");
  const [countOtp, setCountOtp] = useState(20);
  const [userInfo, setUserInfo] = useState({});
  const [codeError, setCodeError] = useState("");
  const [pinId, setPinId] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [phoneFormatError, setPhoneFormartError] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const phoneRef = useRef(null);

  useEffect(() => {
    const pageTitle = "Update phone number || Checkstall.com";
    Seo({
      title: pageTitle,
      metaDescription: "Update phone number ",
    });
  });

  const handleSetCode = (e) => {
    setCodeError("");
    setCode(e.target.value);
  };

  const handleUpdatePhoneNumber = (e) => {
    e.preventDefault();
    if (!code && !isVerified) {
      setCodeError("Code not set ");
      return;
    }

    if (!isVerified) {
      setCodeError("Code not invalid ");
      return;
    } else {
      updateInfo();
    }
  };

  const updateInfo = () => {
    let isMounted = true;
    const controller = new AbortController();
    setModalShow(true);

    const doupdateInfo = async () => {
      try {
        const response = await axiosPrivate.post(
          "v1/users/update-info",
          JSON.stringify({ phone_number }),
          {
            signal: controller.signal,
          }
        );

        if (response?.data?.code == 2001) {
          setSuccessMessage(response?.data?.message);
          setIsTransparentLoading(false);
          setPhoneNumber("");
          const timer = setTimeout(() => {
            setModalShow(false);
            setSuccessMessage(false);
            navigate("/members/account/userProfile");
          }, 1000);
          return () => clearTimeout(timer);
        } else {
          isMounted && setNameError(response?.data?.message);
        }
      } catch (err) {}
      // setIsTransparentLoading(false);
      setModalShow(false);
    };

    doupdateInfo();
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const sendCode = () => {
    setIsCountDown(true);
    countDown("counter", 60);

    const termiiInstance = Termii();

    //Send OTP
    let endpoint = "/v1/api/api-send-verify-code";
    termiiInstance
      .send_verify_code(endpoint, userInfo?.email)
      .then((response) => {
        if (response.error === 101) {
          setPhoneError(response.message);
          setIsCountDown(false);
          setCount("");
          return;
        }
        if (parseInt(response.message.status) === 200) {
          setPinId(response.message.pinId);
        }
      });
  };

  const sendOtp = () => {
    setIsOtpCountDown(true);
    otpCountDown("counter", 60);

    const termiiInstance = Termii();

    //Send OTP
    termiiInstance.send_otp(userInfo.phone_number).then((response) => {
      if(response.error === 101){
        setPhoneError(response.message);
        setIsOtpCountDown(false);
        setCountOtp("");
        return;
      }
      if (parseInt(response.message.status) === 200) {
        setPinId(response.message.pinId);
      }
    });
  };

  const verifyCode = () => {
    const termiiInstance = Termii();
    setIsChecking(true);
    //verify OTP
    if (code) {
      termiiInstance.verify_email_otp(code).then((response) => {
        if (response.verified == true) {
          setIsVerified(true);
          setIsChecking(false);
          setCount("");
          setCountOtp("");
        } else {
          setCodeError("Incorrect / invalid code");
          setIsChecking(false);
        }
      });
    } else {
      setCodeError("Please get another code");
      setIsChecking(false);
    }
  };

  const verifyOtp = () => {
    const termiiInstance = Termii();
    setIsChecking(true);
    //verify OTP
    if (pinId && code) {
      termiiInstance.verify_otp(pinId, code).then((response) => {
        if (response.message.verified == true) {
          setIsVerified(true);
          setIsChecking(false);
        } else if (response.message.verified == false) {
          setCodeError("Incorrect code");
          setIsChecking(false);
        } else if (
          response.message.status == 400 &&
          response.message.verified == "Expired"
        ) {
          setCodeError("Code already Expired");
          setIsChecking(false);
        }
      });
    } else {
      setCodeError("Please get another code");
      setIsChecking(false);
    }
  };

  useEffect(() => {
    if (code.length === 6) {
      if (userInfo.phone_number) {
        verifyOtp();
      } else {
        verifyCode();
      }
    }
  }, [code]);

  const getUserInfo = () => {
    let isMounted = true;
    const controller = new AbortController();
    setIsTransparentLoading(true);
    setErrorText("Loading...");
    const doGetUser = async () => {
      try {
        const response = await axiosPrivate.post("v1/users/get-user-info", {
          signal: controller.signal,
          withCredentials: true,
        });

        isMounted && setUserInfo(response?.data?.user);
      } catch (err) {}
      setIsTransparentLoading(false);
    };

    doGetUser();
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const countDown = (selectorId, limit) => {
    let counts = setInterval(() => {
      let count = document.getElementById(selectorId);
      //count.innerHTML=--limit;
      setCount(--limit);
      if (limit === 0) {
        clearInterval(counts);
        setIsCountDown(false);
        setCount("");
      }
      if(phoneError !== ''){
        clearInterval(counts);
      }
    }, 1000);
  };
   const otpCountDown = (selectorId, limit) => {
     let counts = setInterval(() => {
       let count = document.getElementById(selectorId);
       //count.innerHTML=--limit;
       setCount(--limit);
       if (limit === 0) {
         clearInterval(counts);
         setIsOtpCountDown(false);
         setCount("");
       }
       if (phoneError !== "") {
         clearInterval(counts);
       }
     }, 1000);
   };

  //handle phone number
  const africaCode = ["ng", "gh", "ke", "ug", "za"];
  const africaDailingCode = {
    ng: "234",
    gh: "233",
    ke: "254",
    ug: "256",
    za: "27",
  };
  const handleOnChange = (value, data, event, formattedValue) => {
    let phone = "";
    let country = data.countryCode;
    let val = value.target.value.replace(/ +/g, "").replace("+", "");
    let withOutDailingCode = val.slice(data.dialCode.length);
    //console.log(withOutDailingCode);
    if (africaCode.includes(country)) {
      let count = withOutDailingCode.replace(/ +/g, "").length;
      if (count === 11) {
        //user prefix  0 to number, we slice it off
        let t =
          withOutDailingCode.slice(0, 0) +
          withOutDailingCode.slice(1, withOutDailingCode.length);
        phone = africaDailingCode[country] + t;
        setPhoneNumber(phone);
        setPhoneFormartError("");
      } else if (count === 10) {
        //user doesn't prefix  0 to number, we set the value to phone number
        phone = africaDailingCode[country] + withOutDailingCode;
        setPhoneNumber(phone);
        setPhoneFormartError("");
      } else {
        setPhoneFormartError("Invalid format");
      }
    } else if (country === "cn") {
      // we set the value to phone number
      let count = withOutDailingCode.replace(/ +/g, "").replace("-", "").length;
      if (count === 11) {
        let t =
          withOutDailingCode.slice(0, 2) +
          withOutDailingCode.slice(3, withOutDailingCode.length);
        phone = "86" + t;
        setPhoneNumber(phone);
        setPhoneFormartError("");
      } else {
        setPhoneFormartError("Invalid format");
      }
    } else if (country === "gb") {
      // we set the value to phone number
      let count = withOutDailingCode.length;
      if (count === 10) {
        phone = "44" + withOutDailingCode;
        setPhoneNumber(phone);
        setPhoneFormartError("");
      } else {
        setPhoneFormartError("Invalid format");
      }
    } else if (country === "us" || country === "ca") {
      // we set the value to phone number
      let count = withOutDailingCode.length;
      if (count === 10) {
        phone = "1" + withOutDailingCode;
        setPhoneNumber(phone);
        setPhoneFormartError("");
      } else {
        setPhoneFormartError("Invalid format");
      }
    } else if (country === "ae") {
      // we set the value to phone number
      let count = withOutDailingCode.length;
      if (count === 9) {
        phone = "971" + withOutDailingCode;
        setPhoneNumber(phone);
        setPhoneFormartError("");
      } else {
        setPhoneFormartError("Invalid format");
      }
    }
  };

  return (
    <React.Fragment>
      <Spinner show={modalShow} cssClass={"semi-dark-bg"} />
      <div className={classes.wrapper}>
        <Form onSubmit={handleUpdatePhoneNumber} className={classes.container}>
          <div className={classes.phoneUpdateWrapper}>
            <Form.Group
              size="lg"
              controlId="phone_number"
              className={classes.phoneNumberBox}
            >
              <PhoneInput
                className={classes.phoneUpdateBox}
                country={"ng"}
                value={phone_number}
                onBlur={handleOnChange}
                onlyCountries={[
                  "ng",
                  "gb",
                  "gh",
                  "ke",
                  "ca",
                  "us",
                  "ug",
                  "ae",
                  "za",
                  "cn",
                ]}
                preferredCountries={["ng"]}
                countryCodeEditable={false}
                ref={phoneRef}
              />
              <span className={classes.regErr}>{phoneError}</span>
              <span className={classes.regErr}>{phoneFormatError}</span>
            </Form.Group>
            <div className={classes.codeContainer}>
              <div className={classes.codeBox}>
                <div className={classes.getCode} onClick={sendOtp}>
                  Get code <MailOutlined sx={{ color: "crimson" }} />
                  &nbsp;&nbsp;
                  {isVerified && (
                    <span className="">
                      <DoneOutlined sx={{ color: "green" }} />
                    </span>
                  )}
                  {isChecking && (
                    <span className="">
                      <img src="/images/loader/reload.gif" />
                    </span>
                  )}
                  {isCountDown && (
                    <span id="counter" style={{ color: "crimson" }}>
                      {countOtp}
                    </span>
                  )}
                </div>
                <div className={classes.codeInput}>
                  <input
                    type="number"
                    value={code}
                    required
                    maxLength={6}
                    onChange={handleSetCode}
                    placeholder="Enter code"
                  />
                </div>
              </div>

              <span className={classes.regErr}>{codeError}</span>
            </div>
            {!isVerified && (
              <div className={classes.verifiedBox}>
                <Form.Group
                  size="lg"
                  controlId="phone_number"
                  className={classes.phoneNumberBox}
                >
                  <PhoneInput
                    className={classes.phoneUpdateBox}
                    country={"ng"}
                    value={phone_number}
                    onBlur={handleOnChange}
                    onlyCountries={[
                      "ng",
                      "gb",
                      "gh",
                      "ke",
                      "ca",
                      "us",
                      "ug",
                      "ae",
                      "za",
                      "cn",
                    ]}
                    preferredCountries={["ng"]}
                    countryCodeEditable={false}
                    ref={phoneRef}
                  />
                  <span className={classes.regErr}>{phoneError}</span>
                  <span className={classes.regErr}>{phoneFormatError}</span>
                </Form.Group>
                <div className={classes.codeContainer}>
                  <div className={classes.codeBox}>
                    <div
                      className={classes.getCode}
                      onClick={sendCode}
                    >
                      Get code <MailOutlined sx={{ color: "crimson" }} />
                      &nbsp;&nbsp;
                      {isVerified && (
                        <span className="">
                          <DoneOutlined sx={{ color: "green" }} />
                        </span>
                      )}
                      {isChecking && (
                        <span className="">
                          <img src="/images/loader/reload.gif" />
                        </span>
                      )}
                      {isCountDown && (
                        <span id="counter" style={{ color: "crimson" }}>
                          {count}
                        </span>
                      )}
                    </div>
                    <div className={classes.codeInput}>
                      <input
                        type="number"
                        value={code}
                        required
                        maxLength={6}
                        onChange={handleSetCode}
                        placeholder="Enter code"
                      />
                    </div>
                  </div>

                  <span className={classes.regErr}>{codeError}</span>
                </div>
              </div>
            )}
          </div>
        </Form>
        <button
          type="submit"
          className={classes.btn}
          onClick={handleUpdatePhoneNumber}
        >
          Update
        </button>
      </div>
    </React.Fragment>
  );
};
