import { isJSON } from "./Helpers";
import { encryptData, decryptData } from "./DataSecurity";


export const  setCookie = (name, value, expire) => {
  var d = new Date();
  d.setTime(d.getTime() + (expire*60*60*1000));
  var expires = "expires=" + d.toGMTString();
  document.cookie = name + "=" + encryptData(value) + ";" + expires + ";path=/";
}

export const  getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return isJSON(decryptData(c.substring(name.length, c.length))) ? 
               JSON.parse(decryptData(c.substring(name.length, c.length))) : 
               decryptData(c.substring(name.length, c.length));
    }
  }
  return "";
}

export const  deleteCookie = (cname) => {
  document.cookie = cname+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export const  checkCookie = (cname) => {
  let name = getCookie(cname);
  return (name != "" && name !=null && name != undefined) ? true : false;
}