export const mensShirtSizeData = [
  {
    shirtSize: "Clothing Size",
    xs: 37,
    s: 38,
    m: 39,
    l: 40,
    xl: 41,
    xxl: 43,
  },
  {
    shirtSize: "International model",
    xs: "160/80A",
    s: "165/84A",
    m: "170/88A",
    l: "175/92A",
    xl: "180/96A",
    xxl: "180/98A",
  },
  {
    shirtSize: "Shoulder width / cm",
    xs: "42-43",
    s: "44-45",
    m: "46-47",
    l: "47-48",
    xl: "49-50",
    xxl: "51-53",
  },
  {
    shirtSize: "bust / cm",
    xs: "98-101",
    s: "102-105",
    m: "106-109",
    l: "110-113",
    xl: "114-117",
    xxl: "118-121",
  },
  {
    shirtSize: "Length / cm",
    xs: 72,
    s: 74,
    m: 76,
    l: 78,
    xl: 80,
    xxl: 82,
  },
  {
    shirtSize: "height / cm",
    xs: 160,
    s: 165,
    m: 170,
    l: 175,
    xl: 180,
    xxl: 185,
  },
];

export const mensSuitSizeData = [
  {
    size: "2R48",
    specification: "165 / 96C",
    version: "Overweight",
    length: 70,
    bust: 106,
    shoulderWidth: 44.7,
  },
  {
    size: "2R50",
    specification: "170 / 100C",
    version: "Overweight",
    length: 72,
    bust: 110,
    shoulderWidth: 45.9,
  },
  {
    size: "2R52",
    specification: "175 / 104C",
    version: "Overweight",
    length: 74,
    bust: 114,
    shoulderWidth: 47.1,
  },
  {
    size: "2R54",
    specification: "180 / 108C",
    version: "Overweight",
    length: 76,
    bust: 118,
    shoulderWidth: 48.3,
  },
  {
    size: "2R56",
    specification: "185 / 112C",
    version: "Overweight",
    length: 78,
    bust: 122,
    shoulderWidth: 49.5,
  },
  {
    size: "4R46",
    specification: "165 / 92B",
    version: "standard",
    length: 70,
    bust: 102,
    shoulderWidth: 43.5,
  },
  {
    size: "4R48",
    specification: "170 / 96B",
    version: "standard",
    length: 72,
    bust: 106,
    shoulderWidth: 44.7,
  },
  {
    size: "4R50",
    specification: "175 / 100B",
    version: "standard",
    length: 74,
    bust: 110,
    shoulderWidth: 45.9,
  },
  {
    size: "4R54",
    specification: "185 / 108B",
    version: "standard",
    length: 78,
    bust: 118,
    shoulderWidth: 48.3,
  },
  {
    size: "6R44",
    specification: "165 / 88A",
    version: "Thin",
    length: 70,
    bust: 78,
    shoulderWidth: 42.3,
  },
  {
    size: "6R46",
    specification: "170 / 92A",
    version: "Thin",
    length: 72,
    bust: 102,
    shoulderWidth: 43.5,
  },
  {
    size: "6R48",
    specification: "175 / 96A",
    version: "Thin",
    length: 74,
    bust: 106,
    shoulderWidth: 44.7,
  },
  {
    size: "6R50",
    specification: "180 / 100A",
    version: "Thin",
    length: 76,
    bust: 110,
    shoulderWidth: 45.9,
  },
  {
    size: "6R52",
    specification: "185 / 104A",
    version: "Thin",
    length: 78,
    bust: 114,
    shoulderWidth: 47.1,
  },
];

export const mensPantSizeData = [
  {
    size: "Clothing size",
    s: "170/72A-170-74A",
    m: "170/76A-175/80A",
    l: "175/82A-175/84A",
    xl: "180/86A-180/90A",
    xxl: "185/92A-185/94B",
    xxxl: "190/98B-195/102B",
  },
  {
    size: "size / inch",
    s: "29-30",
    m: "311-32",
    l: "33-34",
    xl: "35-36",
    xxl: "37-38",
    xxxl: "40-42",
  },
  {
    size: "Hip circumference / cm",
    s: "97.5-100",
    m: "102.5-105",
    l: "107.5-110",
    xl: "112.5-100",
    xxl: "117.5-120",
    xxxl: "122.5-130",
  },
  {
    size: "waistline / cm",
    s: "73.7-76.2",
    m: "78.7-81.3",
    l: "83.8-110",
    xl: "89-91.4",
    xxl: "93.3-96.5",
    xxxl: "101.6-106.6",
  },
  {
    size: "waistline / Cloth ruler",
    s: "2 feet 2 inches - 2 feet 3 inches",
    m: "2 feet 4 inches",
    l: "2 feet 5 inches - 2 feet 6 inches",
    xl: "2 feet 6 inches - 2 feet 7 inches",
    xxl: "2 feet 8 inches - 2 feet 9 inches",
    xxxl: "3 feet 1 inches - 3 feet 2 inches",
  },
];