import React,{useEffect, useState} from "react";
import { Seo } from '../../utilities/Seo';
import '../../../assets/css/wallet.css';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useRefreshToken from '../../../hooks/useRefreshToken';
import Spinner from "../../utilities/Spinner";
import useCheckAuthWithRedirect from "../../../hooks/useCheckAuthWithRedirect";
import {useNavigate } from 'react-router-dom';
import {setCookie} from "../../functions/CustomCookies";


function TopUp (){
 
    const [modalShow, setModalShow] = useState(false);
    const [amount, setAmount] = useState(0);
    const [isHovering, setIsHovering] = useState({});
    const [isClicked, setIsClicked] = useState({});
    const [paymentMethodId, setPaymentMethodId] = useState(0);
    const [topupMethod, setTopupMethod] = useState({});

    const axiosPrivate = useAxiosPrivate();
    const refresh = useRefreshToken();
    const navigate = useNavigate();

    //set page title
    useEffect(() => {
        const pageTitle =  "Top up || Checkstall.com";
        Seo({
        title: pageTitle,
        metaDescription: 'Top up',
        });
    }, []);

//get new token for the reques
useEffect(()  =>{
    const getRefreshToken = async () => {
        await refresh();
    }  
    getRefreshToken();
  },[]);

   //check for authentication on the page
 useCheckAuthWithRedirect();
  
    useEffect(() =>{
        getPaymentMethod();
    },[]);
    const handleMouseOver = ( key) => event => {
        setIsHovering(
          {
            [key]: true
          }
        );
      
        
      };
      
      const handleMouseOut = (key) => event =>{
        setIsHovering(
          {
            [key]: false
          }
        );
      };

      const handleClick = (key) => event =>{

        if(event.type === "click"){
          if(!event.target.parentNode.classList.contains("wm-selected")){
            setIsClicked(
              {
                [key]: true
              }
            );
            setPaymentMethodId(event.target.parentNode.id);
          }else{
            setIsClicked(
              {
                [key]: false
              }
            );
            setPaymentMethodId(0);
          }
          
        }
        
      };

      const getPaymentMethod = () =>{
        let isMounted = true;
        const controller = new AbortController();
      
        const doGetPaymentMethod = async () => {
            setModalShow(true);
            try {
                  const response = await axiosPrivate.post("v1/payment-method/get-topup-method",
                   {
                    signal: controller.signal
                  });
                  
                  //console.log(response?.data?.message);
                  if(response?.data?.message){
                     isMounted && setTopupMethod(response?.data?.message);
                  }
      
            }catch(err){
          
                console.log(err.response?.data);
                
            }
            setModalShow(false);
        }
      
        doGetPaymentMethod();
        return () => {
          isMounted = false;
            controller.abort();
        }
      }
      
      const handleTopUp = () =>{
        setCookie("topUpAmount", amount, 0.5);
        setCookie("topUpMethod", paymentMethodId, 0.5);
        navigate('/dajupay/wallet-topup', {state:{refresh:true}});
      }


    return (
      <>
        <Spinner cssClass={"semi-dark-bg"} show={modalShow} />
        <div
          className="container-fluid wallet-wrap"
          style={{ minHeight: "100%" }}
        >
          <div className="row clearfix">
            <div className="col-xs-12 col-sm-12 ">
              <div className="col-md-6 col-md-offset-3 shadow wallet-body topup-box">
                <p className="pull-left topup-title">Wallet Top Up</p>
                <br />
                <div className="col-md-12 topup-amt">
                  <div id="topup-input">
                    <input
                      type="number"
                      name="topup-amount"
                      placeholder="Enter Top up amount"
                      onKeyUp={(e) => setAmount(e.target.value)}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-12 ft-amt no-padding-side">
                  {amount > 0 && <span>₦ </span>}
                  <span id="ft-amt"> {amount || ""}</span>
                </div>
                <div
                  className="col-md-12 wm text-muted text-center"
                  style={{ marginTop: 30, marginBottom: 20 }}
                >
                  Select Top Up Method(Recommended Method)
                </div>
                <div className="row ">
                  {topupMethod != null && topupMethod.length > 0 ? (
                    topupMethod.map((value, key) => {
                      return (
                        <div
                          className="col-sm-4 col-xs-4 col-md-4 no-padding-side pm"
                          key={key}
                        >
                          <div
                            className={`with-method ${
                              isHovering[key] ? "wm-hover" : ""
                            } ${isClicked[key] ? "wm-selected" : ""}`}
                            onMouseOver={handleMouseOver(key)}
                            onMouseOut={handleMouseOut(key)}
                            onClick={handleClick(key)}
                            id={value.id}
                          >
                            <img src={value.url} alt="" />
                            <div>{value.name}</div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <div>No default payment method</div>
                    </>
                  )}
                </div>
                <div
                  className="col-md-12 no-padding-side"
                  style={{ marginTop: 30 }}
                >
                  <button
                    disabled={`${
                      amount > 0 && paymentMethodId ? "" : "disabled"
                    }`}
                    className={`trans-btn topup-btn ${
                      amount > 0 && paymentMethodId ? "trans-btn-active" : ""
                    } `}
                    onClick={handleTopUp}
                  >
                    Top Up
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default TopUp;