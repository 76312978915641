import React, { useState } from "react";
import classes from "./Messages.module.css";
import Navbar from "../../navbar/Navbar";
import DoneIcon from "@mui/icons-material/Done";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import styled from "@emotion/styled";
import { Box, Modal, Pagination } from "@mui/material";

import { messageData } from "./messageData";
import MessageModal from "./messageModal/MessageModal";

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: 'none',
  outline: 'none'
});
const StyledBox = styled(Box)({
  width: "750px",
  height: "400px",
  backgroundColor: "white",
  color: "black",
  borderRadius: "0.3rem",
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'center',
  justifyContent: 'space-between',
  border: "none",
  outline: "none",
});

const Messages = () => {
  const [open, setOpen] = useState(null);
  const [readStatus, setReadStatus] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 2;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const itemsToDisplay = messageData.slice(startIndex, endIndex);

   const handlePageChange = (event, page) => {
     setCurrentPage(page);
   };

  const handleOpenModal = (obj, itemId) => {
    setOpen(obj);
    setReadStatus((prevStatus) => ({
      ...prevStatus,
      [itemId]: true,
    }));
    // setRead(true);
  }
  
  const handleCloseModal = () => {
    setOpen(null);
  }
  return (
    <>
      <Navbar />
      <main className={classes.wrapper}>
        <section className={classes.container}>
          <h3 className={classes.title}>Latest Updates</h3>
          <table className={classes.tableWrapper}>
            <thead>
              <tr>
                <th className={classes.largerWidth}>notice</th>
                <th className={classes.time}>time</th>
                <th>status</th>
                <th>operating</th>
              </tr>
            </thead>
            {itemsToDisplay.map((value) => {
              return (
                <tbody key={value.id}>
                  <tr>
                    <td className={classes.subject}>{value.title}</td>
                    <td>{value.time}</td>
                    {readStatus[value.id] ? (
                      <td className={readStatus[value.id] && classes.read}>
                        {" "}
                        <DoneIcon />
                        Read
                      </td>
                    ) : (
                      <td className={!readStatus[value.id] && classes.unread}>
                        <MarkChatUnreadIcon /> Unread
                      </td>
                    )}
                    <td>
                      <span
                        className={classes.view}
                        onClick={() => handleOpenModal(value, value.id)}
                      >
                        view
                      </span>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
          <div className={classes.pageBox}>
            <Pagination
              count={Math.ceil(messageData.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </section>
      </main>
      <StyledModal
        open={open !== null}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledBox>{open && <MessageModal item={open} />}</StyledBox>
      </StyledModal>
    </>
  );
};

export default Messages;
