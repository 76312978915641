
import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";

const USERS_URL = "/v1/users/index";

const Users = () => {
    const [user, setUsers] = useState();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try {
                const response = await axiosPrivate.get(USERS_URL, {
                    signal: controller.signal
                });
                console.log(response?.data?.balance);
                isMounted && setBalance(response?.data?.balance)
                isMounted && setUsers(response?.data?.user);
            } catch (err) {
                console.log(err.response);
                navigate('/login', { state: { from: location }, replace: true });
            }
        }

        getUsers();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [location,axiosPrivate,navigate])

    return (
        <article>
            <h2>User Information:</h2>
            <div>Username: {user?.username}</div>
            <div>Email: {user?.email}</div>
            <div>Joined On: {user?.created_at}</div>
            <div>Balance: {balance}</div>
        </article>
    );
};

export default Users;
