import axios from "./axios";

async function Translate(text,fromLang,toLang){
    try {
        let params = {text, fromLang, toLang}
        const response = await axios.post('/v1/api/api-translate-search-string', 
            JSON.stringify({params}),
        );

        return response?.data?.message?.data?.translations[0]?.translatedText;
    } catch (error) {
        return error;
    }
}

export default Translate;

 export const TranslateJson = async (text,fromLang,toLang) => {
    try {
        let params = {text, fromLang, toLang}
        const response = await axios.post('/v1/api/api-translate-json', 
            JSON.stringify({params}),
        );

        //return response?.data?.message?.data?.translations[0]?.translatedText;
        return (response?.data?.message);
    } catch (error) {
        return error;
    }
}

// async function Translate(text,fromLang,toLang){
//     try {
//         const response = await fetch('/v1/api/api-translate-search-string', {
//             method: "POST",
//             body: JSON.stringify({
//                 text,
//                 fromLang,
//                 toLang,
//             }),
//             headers: {
//                 "Content-Type": "Application/json",
//                 Accept: "Application/json",
//             },
//         });
//         const responseData = await response.json();
//         return responseData?.message?.data?.translations[0]?.translatedText;
//     } catch (error) {
//         return console.warn(error);
//     }
//   }

//   export default Translate;