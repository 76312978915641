import React from 'react'

function setLocalStorage(name,value) {
    try {
        localStorage.setItem(name, value);
      } catch (e) {
        console.error(e);
      }
}

export default setLocalStorage