import React, { useState, useEffect } from "react";
import classes from "./UserHeadProfile.module.css";
import { BsHandThumbsUp, BsTruck } from "react-icons/bs";
import { AiOutlineShoppingCart } from "react-icons/ai";
import getLocalStorage from "../functions/getLocalStorage";
import { getCookie } from "../functions/CustomCookies";
import { isJSON } from "../functions/Helpers";
import { maskEmail } from "../functions/Helpers";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import { Link } from "react-router-dom";
import HeaderAcoount from "../headerAccount/HeaderAcoount";

const UserHeadProfile = () => {
  const [orderCounts, setOrderCounts] = useState({});
  const [totalInvoice, setTotalInvoice] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);

  const axiosPrivate = useAxiosPrivate();

  let userObj = getLocalStorage("user");
  let userInfo =
    userObj && userObj !== undefined && isJSON(userObj)
      ? JSON.parse(userObj)
      : getCookie("user");
  let email = userInfo?.email;
  let username = userInfo?.username;
  let fullName = userInfo?.full_name ? userInfo.full_name.split(" ")[0] : null;

  // get cart total
  const getTotalCart = async () => {
    try {
      const response = await axiosPrivate.post("v1/cart/get-total-cart");
      setCartTotal(response.data.total);
    } catch (error) {
      // console.log(error.message);
    }
  };

  useEffect(() => {
    getTotalCart();
  }, []);

  useEffect(() => {
    getOrderCount();
  }, []);

  const getOrderCount = () => {
    let isMounted = true;
    const controller = new AbortController();

    const doGetOrder = async () => {
      try {
        const response = await axiosPrivate.get("v1/order/order-count", {
          signal: controller.signal,
          withCredentials: true,
        });

        isMounted && setOrderCounts(response?.data?.orderCount);
        isMounted && setTotalInvoice(response?.data?.totalInvoice);
      } catch (err) {}
    };

    doGetOrder();
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  return (
    <>
      {userInfo ? (
        <aside className={classes.wrapper}>
          <div className={classes.container}>
            <div className={classes.accountIcon}>
              <div className={classes.icon}>
                <BsHandThumbsUp size={20} />
              </div>
              {userInfo && (
                <h3 className={classes.miniTitle}>
                  Welcome &nbsp;
                  {fullName || maskEmail(username) || maskEmail(email)}!
                </h3>
              )}
            </div>
            <div className={classes.profileBox}>
              <Link
                to="/members/orders/index?orderStatus=unpaid"
                className={classes.profileOptionsBox}
              >
                <span className={classes.circle}>
                  {orderCounts?.unpaid ? parseInt(orderCounts?.unpaid) : 0}
                </span>
                <span className={classes.profileTitle}>unpaid</span>
              </Link>
              <Link
                to="/members/orders/index?orderStatus=processing"
                className={classes.profileOptionsBox}
              >
                <span className={classes.circle}>
                  {orderCounts?.processing
                    ? parseInt(orderCounts?.processing)
                    : 0}
                </span>
                <span className={classes.profileTitle}>processing</span>
              </Link>
              <Link
                to="/members/orders/index?orderStatus=purchased"
                className={classes.profileOptionsBox}
              >
                <span className={classes.circle}>
                  {orderCounts?.purchased
                    ? parseInt(orderCounts?.purchased)
                    : 0}
                </span>
                <span className={classes.profileTitle}>purchased</span>
              </Link>
              <Link to="/members/invoice" className={classes.profileOptionsBox}>
                <span className={classes.circle}>{totalInvoice}</span>
                <span className={classes.profileTitle}>Invoice</span>
              </Link>
            </div>
          </div>
        </aside>
      ) : (
        <HeaderAcoount />
      )}
    </>
  );
};

export default UserHeadProfile;
