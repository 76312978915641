import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';

export const SideBarData = [
  {
    title: 'Account',
    path: '/Account',
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Overview',
        path: '/Overview',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Setting',
        path: '/Settings',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Shipping Address',
        path: '/ShippingAdd',
        icon: <IoIcons.IoIosPaper />
      }
    ]
  },
  {
    title: 'Wallet',
    path: '/Wallet',
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

  },
  {
    title: 'Order',
    path: '/Orders',
    icon: <FaIcons.FaCartPlus />
  },
  
];
