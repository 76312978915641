import React, { useEffect, useState } from "react";
import { Seo } from "../utilities/Seo";
import "../../assets/css/invoice.css";
import useCheckAuthWithRedirect from "../../hooks/useCheckAuthWithRedirect";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Spinner from "../utilities/Spinner";
import Pagination from "@mui/material/Pagination";
import ProcessPayment from "./dajupay/ProcessPayment";
import { Box, Modal } from "@mui/material";

function Invoice() {
  const [openPay, setOpenPay] = useState(null);
    const handleOpenModal = (object) => {
      //to make sure we can use title on process payment page without needing to check for souce
      object['title'] = object.subject;
      object['endpoint'] = "v1/process-payment/pay-invoice";
      object['fromType'] = "invoice";
      object['transaction_type'] = process.env.REACT_APP_TRANSACTION_TYPE_INVOICE;
      object['payment_method'] = process.env.REACT_APP_WALLET_PAY_METHOD;
      setOpenPay(object);
    };
     const handleCloaseModal = () => {
       setOpenPay(null);
     };


  useCheckAuthWithRedirect();

  useEffect(() => {
    Seo({
      title: "Unpaid Invoice || Checkstall Your choice for goods Importation",
      metaDescription: "Checkstall Your choice for goods Importation",
    });
  }, []);
  // console.log(openPay);

  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [invoiceList, setInvoiceList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getInvoiceList();
  }, []);

  const getInvoiceList = () => {
    const doGetList = async (page) => {
      setModalShow(true);
      try {
        const response = await axiosPrivate.post(
          "/v1/invoice/get-invoice-list?page=" + page
        );

        if (response?.data?.message?.length > 0) {
          let array = [];
          response?.data?.message?.map((value) => {
            array.push(value);
          });
          setInvoiceList((prevItems) => [...prevItems, ...array]);
          setPage(parseInt(page) + 1);
          setPageCount(response?.data?.pageCount);
        }
        //  setPageCount(response?.data?.pageCount);
      } catch (err) {
        //console.log(err.response?.data);
      }
      setModalShow(false);
    };

    doGetList(page);
  };
  const handleChange = (e, value) => {
    setPage(value);
    navigate(`/members/invoice/${value}`);
  };

  return (
    <React.Fragment>
      <Spinner cssClass={"semi-dark-bg"} show={modalShow} />
      <div className="invoice-wrap d-flex flex-column">
        <div
          className=""
          style={{ marginTop: "1rem", marginBottom: "1rem", color: "#59595a" }}
        >
          Below are the list of your Unpaid order, please complete any unpaid
          invoice to avoid any delay in the process of your order
        </div>
        <div className="d-flex justify-content-between">
          <h4>Unpaid Invoice</h4>
        </div>
        <div className="d-flex justify-content-between invoice-heading">
          <div>Amount</div>
          <div>Subject</div>
          <div>Time</div>
          <div>Status</div>
          <div>Action</div>
        </div>

        <div className="d-flex flex-column ">
          {invoiceList && invoiceList?.length > 0
            ? invoiceList.map((value, key) => {
                return (
                  <div
                    className="invoice-list d-flex justify-content-between"
                    key={key}
                  >
                    <div>₦ {value.amount}</div>
                    <div>{value.subject}</div>
                    <div>{value.created_at}</div>
                    <div>{value.status}</div>
                    <div>
                      <button onClick={() => handleOpenModal(value)}>
                        Pay Now
                      </button>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
        {/* </InfiniteScroll> */}
        <div
          style={{
            marginLeft: 10,
            marginRight: 10,
            display: "block",
            marginTop: 10,
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <Pagination
            count={pageCount}
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
            page={page}
            onChange={handleChange}
          />
        </div>
      </div>
      <Modal
        open={openPay !== null}
        onClose={handleCloaseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "none",
          outline: "none",
        }}
      >
        <Box
          width={400}
          height={300}
          bgcolor={"background.default"}
          color={"text.primary"}
          p={2}
          borderRadius={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {openPay && <ProcessPayment data={openPay} />}
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default Invoice;
