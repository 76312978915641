import React from 'react';
import classes from './Underwear.module.css';
import {
  braSizeData,
  womensUnderwearSizeData,
  mensUnderwearSizeData,
} from "./underwearData";

const UnderWear = () => {
  return (
    <section className={classes.wrapper}>
      <div className={classes.title}>
        <div className={classes.line} />
        <h2>Underwear size chart</h2>
        <div className={classes.line} />
      </div>
      <div className={classes.tableContainer}>
        <h4 className={classes.shirtTitle}>Bra size comparison table</h4>
        <table className={classes.tableHolder}>
          <thead>
            <tr>
              <th>Lower bust / cm</th>
              <th>Upper bust / cm</th>
              <th>International Size</th>
            </tr>
          </thead>
          {braSizeData.map((value, index) => {
            return (
              <tbody key={index}>
                <tr>
                  <td className={classes.tabledataTitle}>{value?.lower}</td>
                  <td>{value?.upper}</td>
                  <td>{value?.international}</td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
      <div className={classes.tableContainer}>
        <h4 className={classes.shirtTitle}>
          Women's underwear size comparison table
        </h4>
        <table className={classes.tableHolder}>
          <thead>
            <tr>
              <th className={classes.shirtSize}>size</th>
              <th>S</th>
              <th>M</th>
              <th>L</th>
              <th>XL</th>
            </tr>
          </thead>
          {womensUnderwearSizeData.map((value, index) => {
            return (
              <tbody key={index}>
                <tr>
                  <td className={classes.tabledataTitle}>{value?.size}</td>
                  <td>{value?.s}</td>
                  <td>{value?.m}</td>
                  <td>{value?.l}</td>
                  <td>{value?.xl}</td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
      <div className={classes.tableContainer}>
        <h4 className={classes.shirtTitle}>Men's underwear size comparison table</h4>
        <table className={classes.tableHolder}>
          <thead>
            <tr>
              <th className={classes.shirtSize}>size</th>
              <th>M</th>
              <th>L</th>
              <th>XL</th>
              <th>XXL</th>
            </tr>
          </thead>
          {mensUnderwearSizeData.map((value, index) => {
            return (
              <tbody key={index}>
                <tr>
                  <td className={classes.tabledataTitle}>{value?.size}</td>
                  <td>{value?.m}</td>
                  <td>{value?.l}</td>
                  <td>{value?.xl}</td>
                   <td>{value?.xxl}</td>
                </tr>
              </tbody>
            );
          })}
        </table>
        <div className={classes.tableTitle}>
          Men's underwear size comparison table info1
        </div>
      </div>
    </section>
  );
}

export default UnderWear
