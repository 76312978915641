import React from "react";
import classes from "./ProductItems.module.css";
import { Link } from "react-router-dom";
import useCurrencyRate from "../../hooks/useCurrencyRate";
import {Exchanger } from '../functions/Helpers';
import { RoundToTwo } from "../utilities/RoundToTwo";

const ProductItems = ({ item }) => {
  const { imgUrl, title, price, item_id } = item;
  const { currency, rate, symbol } = useCurrencyRate();
  const defaultCurrency = "CNY";
  return (
    <div className={classes.wrapper}>
      <Link to={`/item/?site=1688&item=${item_id}`} target="blank" className={classes.productLink}>
        <div className={classes.background}>
          <img src={imgUrl} alt="" />
        </div>
        <div className={classes.productInfo}>
          <span
            to={`/item/?site=1688&item=${item_id}`}
            target="blank"
            className={classes.name}
          >
            {title}
          </span>
          <span className={classes.price}>
            {symbol}
            {RoundToTwo(Exchanger(defaultCurrency, currency, price , rate))}
          </span>
        </div>
      </Link>
    </div>
  );
};

export default ProductItems;
