import CryptoJS from "crypto-js";

const SECRET_PASS = "BY32*P%@XkhZG4fW2t2W";

export const encryptData = (text) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      SECRET_PASS
    ).toString();

    return data;
};

export const decryptData = (text) => {
    const bytes = CryptoJS.AES.decrypt(text, SECRET_PASS);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
};

export const encryptPassword = (valueStringHex) => {
    const value =  CryptoJS.enc.Hex.parse(valueStringHex);
    const key = CryptoJS.enc.Hex.parse("2a2abbcf76458d20e56bf27c533e9e40");
    const iv =  CryptoJS.enc.Hex.parse("4c9ddc224188e5a82f0a720528d3298f");
    const encrypted = CryptoJS.AES.encrypt(value, key, {iv, padding: CryptoJS.pad.ZeroPadding});
    //and the ciphertext put to base64
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);   
    //return encrypted;  
};

export const decryptPassword = (text) => {
    const bytes = CryptoJS.AES.decrypt(text, SECRET_PASS);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
};

