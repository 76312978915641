import React ,{ createContext, useState, useMemo } from "react";

const CompletedContext = createContext({});

export const CompletedProvider = ({ children }) => {
    const [completed, setCompleted] = useState({});
    const value = useMemo(() => ({ completed, setCompleted }), [completed, setCompleted]);

    return (
        <CompletedContext.Provider value={value}>{children}</CompletedContext.Provider>
    )
}

export default CompletedContext;