import React from 'react';
import '../../assets/css/walletcard.css'
import { Link } from 'react-router-dom';

function WalletCard(props){
    return (
      <div className="cardW col-md-12 ">
        <div className='wallVi'>
            <h5><b>Wallet</b></h5>
            <Link to="#" ><p>View More</p></Link>
        </div>
        <div className='walletFigure'>
            <h1>{props.balance}</h1>
            <p>Naira</p>
        </div>
        <div className='addWallet'>
            <Link to="#" ><p>Add to Wallet</p></Link>
        </div>
      </div>
    )
  
}
export default WalletCard;

