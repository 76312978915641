import React,{useEffect} from "react";
import { Seo } from '../utilities/Seo';
import '../../assets/css/success.css';
import Header from "../functions/Header";
import Footer from "../functions/Footer";
import {getCookie} from "../functions/CustomCookies";




function RegistrationSuccessful (){
   //set page title

   useEffect(() => {
    const pageTitle =  "Regisration Successful || Checkstall.com";
    Seo({
      title: pageTitle,
      metaDescription: "Regisration Successful || Checkstall.com",
    });
}, []);

    return (
        <>
            <Header />
            <div className='success-alert'>
                <div className="alert alert-success" role="alert">
                    <div>Welcome to Checkstall, your account has been created successfully!</div>
                    <div className="">
                        A verification code has just been sent to your registered email <b> {getCookie('email')}</b>. please verify your email to continue.
                    </div>                 
                </div>
            </div>
            <Footer />
        </>
    )
}
export default RegistrationSuccessful;