import React from 'react';
import classes from './ShoeSize.module.css';
import {
  womensShoeSizeData,
  mensShoeSizeData,
  childrenShoeSizeData,
} from "./ShoeSizeData";

const ShoeSize = () => {
  return (
    <section className={classes.wrapper}>
      <div className={classes.title}>
        <div className={classes.line} />
        <h2>Shoe code comparison table</h2>
        <div className={classes.line} />
      </div>
      <div className={classes.tableContainer}>
        <h4 className={classes.shirtTitle}>
          Women's shoes yardstick code comparison table
        </h4>
        <table className={classes.tableHolder}>
          <thead>
            <tr>
              <th>Foot length / cm</th>
              <th>Europe EUR</th>
              <th>United States US</th>
              <th>United Kingdom UK</th>
            </tr>
          </thead>
          {womensShoeSizeData.map((value, index) => {
            return (
              <tbody key={index}>
                <tr>
                  <td className={classes.tabledataTitle}>{value?.foot}</td>
                  <td>{value?.europe}</td>
                  <td>{value?.us}</td>
                  <td>{value?.uk}</td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
      <div className={classes.tableContainer}>
        <h4 className={classes.shirtTitle}>
          Male shoes code size comparison table
        </h4>
        <table className={classes.tableHolder}>
          <thead>
            <tr>
              <th>Foot length / cm</th>
              <th>Europe EUR</th>
              <th>United States US</th>
              <th>United Kingdom UK</th>
            </tr>
          </thead>
          {mensShoeSizeData.map((value, index) => {
            return (
              <tbody key={index}>
                <tr>
                  <td className={classes.tabledataTitle}>{value?.foot}</td>
                  <td>{value?.europe}</td>
                  <td>{value?.us}</td>
                  <td>{value?.uk}</td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
      <div className={classes.tableContainer}>
        <h4 className={classes.shirtTitle}>
          Children's shoes code size comparison table
        </h4>
        <table className={classes.tableHolder}>
          <thead>
            <tr>
              <th className={classes.shirtSize}>Suitable for age</th>
              <th>cm</th>
              <th>inch</th>
              <th>size</th>
            </tr>
          </thead>
          {childrenShoeSizeData.map((value, index) => {
            return (
              <tbody key={index}>
                <tr>
                  <td className={classes.tabledataTitle}>{value?.age}</td>
                  <td>{value?.cm}</td>
                  <td>{value?.inch}</td>
                  <td>{value?.size}</td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
    </section>
  );
}

export default ShoeSize
