import React from 'react';
import classes from './Category.module.css';
import { FaTshirt, FaBaby, FaLaptop } from "react-icons/fa";
import { GiTShirt, GiMirrorMirror } from "react-icons/gi";
import { BsWatch, BsHandbagFill } from "react-icons/bs";
import { MdChair } from "react-icons/md";
import { BiFootball } from "react-icons/bi";

const Category = () => {
  return (
    <aside className={classes.wrapper}>
      <h1 className={classes.title}>categories</h1>
      <div className={classes.options}>
        <span className={classes.option}><GiTShirt size={20} className={classes.icon} /> Women's Fashion</span>
        <span className={classes.option}><FaTshirt size={20} className={classes.icon} /> Men's Fashion</span>
        <span className={classes.option}><BsWatch size={20} className={classes.icon} /> Jewelry & Watches</span>
        <span className={classes.option}><MdChair size={20} className={classes.icon} /> Home, Pet & Appliances</span>
        <span className={classes.option}><BsHandbagFill size={20} className={classes.icon} /> Bags & Shoes</span>
        <span className={classes.option}><FaBaby size={20} className={classes.icon} /> Toys , Kids & Babies</span>
        <span className={classes.option}><GiMirrorMirror size={20} className={classes.icon} /> Beauty, Health & Hair</span>
        <span className={classes.option}><FaLaptop size={20} className={classes.icon} /> Computer, Office & Security</span>
        <span className={classes.option}><BiFootball size={20} className={classes.icon} />  Outdoor Fun & Sports</span>
      </div>
    </aside>
  );
}

export default Category
