import { Outlet } from "react-router-dom";
import React,{ useState, useEffect } from "react";
import useRefreshToken from '../../hooks/useRefreshToken';
import useAuth from '../../hooks/useAuth';
import Spinner from "../utilities/Spinner";


const PersistLogin = () => {
    const [modalShow, setModalShow] = useState(false);
    const refresh = useRefreshToken();
    const { auth, isLoggedIn } = useAuth();

    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refresh();
            }
            catch (err) {
                //console.error(err);
            }
            finally {
                isMounted && setModalShow(false);
            }
        }

        // Avoids unwanted call to verifyRefreshToken
        !auth?.accessToken && isLoggedIn ? verifyRefreshToken() : setModalShow(false);

        return () => isMounted = false;
    }, [])


    return (
        <>
            {!isLoggedIn
                ? <Outlet />
                : modalShow
                    ? <Spinner cssClass={"semi-dark-bg"} show={modalShow} />
                    : <Outlet />
            }
        </>
    )
}

export default PersistLogin