import React, { useState, useEffect } from "react";
import {
  Link,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { Body, SortWrap } from "../../assets/css/AppStyles";
import { RoundToTwo } from "../utilities/RoundToTwo";
import Pagination from "@mui/material/Pagination";
import "../../assets/css/shop.css";
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";
import Spinner from "../utilities/Spinner";
import { Seo } from "../utilities/Seo";
import useCurrencyRate from "../../hooks/useCurrencyRate";
import Navbar from "../navbar/Navbar";
import { Exchanger } from "../functions/Helpers";
import axios from "../../api/axios";
import Translate from "../../api/Translate";
import InfiniteScroll from "react-infinite-scroll-component";
import spinner from "../../assets/images/Spinner.gif";

function Shop() {
  const [searchParams, setSearchParams] = useSearchParams();
  const keyword = searchParams.get("keyword");
  const site = searchParams.get("site");
  const urlPage = searchParams.get("page");
  const urlSort = searchParams.get("sort");
  const location = useLocation();
  const [page, setPage] = useState(parseInt(urlPage) ? parseInt(urlPage) : 1);
  const navigate = useNavigate();
  const [activeLinkIndex, setActiveLinkIndex] = useState(0);
  const [sort, setSort] = useState(urlSort ? urlSort : "");
  const [modalShow, setModalShow] = useState(false);
  const { currency, rate, symbol } = useCurrencyRate();
  const [reloadLimit, setReloadLimit] = useState(1);
  const searchtype = searchParams.get("searchtype");
  const defaultCurrency = "CNY";
  const [isTranslated, setIsTranslated] = useState(false);
  const [translatedTitles, setTranslatedTitles] = useState([]);
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    storeProducts();
  }, [location.state]);

  //set page title
  useEffect(() => {
    const pageTitle = keyword ? keyword : "Checkstall || Shop ";
    Seo({
      title: pageTitle,
      metaDescription: "Shop",
    });
  }, [keyword]);

  const [products, setProducts] = useState({
    page: "",
    real_total_results: 0,
    total_results: 0,
    page_size: 0,
    pagecount: 0,
    item: [],
  });

  const storeProducts = async () => {
    if (page == 1) {
      setModalShow(true);
    }

    try {
      let params = { site, keyword, page, sort, searchtype };
      const response = await axios.post(
        "/v1/api/api-get-item-list",
        JSON.stringify({ params })
      );

      if (response.data.message.error_code == "5000") {
        while (reloadLimit < 5) {
          storeProducts();
        }
        setReloadLimit(reloadLimit + 1);
      } else {
        setProducts(response.data.message.items);
        getNewData(response.data.message.items);
        setPage(page + 1);
        setReloadLimit(0);
      }
    } catch (error) {
      if (error.response) {
      }
    }
    setModalShow(false);
  };

  const itemsPerPage = products.page_size + 1;
  const totalCount = products.total_results;
  const totalPages = products.pagecount;

  const handleChange = (e, value) => {
    setPage(value);
    navigate(
      `/shop/?site=${site}&keyword=${keyword}&page=${value}&searchtype=${searchtype}`
    );
    storeProducts();
  };

  const handleSortClick = (index) => (event) => {
    setActiveLinkIndex(index);
    let sort_id = event.target.id;
    setSort(sort_id);
    navigate(
      `/shop/?site=${site}&keyword=${keyword}&page=${page}&sort=${sort_id}&searchtype=${searchtype}`
    );
    storeProducts();
  };

  const handleRedirect = (itemId) => {
    navigate(`/item/?site=${site}&item=${itemId}`);
  };

  const getAllTitle = (item) => {
    let titles = [];

    item.map((value, i) => {
      titles[i] = { title: value.title };
    });

    return titles;
  };
  const getNewData = (data) => {
    if (data.item.length > 0) {
      //create new object
      //doTranslate(data);
      const array = [];
      data.item.map((value) => {
        const newItem = {
          src: value.pic_url,
          alt: value.title,
          current_price: value.price,
          url: `/item/?site=${site}&item=${value.num_iid}`,
          title: value.title,
          sales: value.sales,
        };

        array.push(newItem);
      });

      setCardData((prevItems) => [...prevItems, ...array]);
    }
  };

  useEffect(() => {
    setCardData([]);
  }, [keyword])


  useEffect(() => {
    if (products?.item) {
      let string = getAllTitle(products?.item);
      let fromLang = "zh-CN";
      let toLang = "en";
      Translate(JSON.stringify(string), fromLang, toLang).then((response) => {
        let items = JSON.parse(response);
        setTranslatedTitles(prevItems => [...prevItems, ...items]);

      });
    }
  }, [products?.item]);



  return (
    <>
      <Spinner cssClass={"semi-dark-bg"} show={modalShow} />

      <Navbar />
      <Body.Wrapper>
        <SortWrap.Wrapper className="d-flex justify-content-between">
          <div className="d-flex justify-content-between">
            <span style={{ padding: "5px" }}>Sort: </span>
            <div className="sort-btn">
              <button
                id=""
                onClick={handleSortClick(0)}
                className={activeLinkIndex === 0 ? "btn active" : "btn "}
              >
                default
              </button>
            </div>
            <div className="sort-btn">
              <button
                id="bid"
                onClick={handleSortClick(1)}
                className={activeLinkIndex === 1 ? "btn active" : "btn "}
              >
                price &nbsp;
                <FaArrowAltCircleUp />
              </button>
            </div>
            <div className="sort-btn">
              <button
                id="_bid"
                onClick={handleSortClick(2)}
                className={activeLinkIndex === 2 ? "btn active" : "btn "}
              >
                price &nbsp;
                <FaArrowAltCircleDown />
              </button>
            </div>
            <div className="sort-btn">
              <button
                id="_sale"
                onClick={handleSortClick(3)}
                className={activeLinkIndex === 3 ? "btn active" : "btn "}
              >
                sale &nbsp;
                <FaArrowAltCircleDown />
              </button>
            </div>
            <div className="sort-btn">
              <button
                id="_credit"
                onClick={handleSortClick(4)}
                className={activeLinkIndex === 4 ? "btn active" : "btn "}
              >
                credibility &nbsp;
                <FaArrowAltCircleDown />
              </button>
            </div>
          </div>
          <div className="">Total result: {totalCount}</div>
        </SortWrap.Wrapper>

        <InfiniteScroll
          dataLength={cardData?.length}
          next={storeProducts}
          hasMore={totalPages >= page}
          scrollThreshold={0.7}
          loader={
            <h4 className="loading-more">
              {" "}
              <img
                src={spinner}
                alt="/"
                style={{ height: "1.5rem", width: "1.5rem"}}
              />
              Loading more...
            </h4>
          }
          endMessage={<h4 style={{ marginLeft: "2rem" }}>no more data</h4>}
        >
          <div className="shop-search-item-container">
            {cardData?.map((val, key) => {
              return (
                <div className="product-list-item-wrapper" key={key}>
                  <Link
                    to={val.url}
                    className="product-item-link"
                    target="_blank"
                  >
                    <div className="product-item-background">
                      <img src={val.src} alt="" />
                    </div>
                    <div className="product-item-Info">
                      <span className="product-item-name">
                        {translatedTitles[key]?.title || val.title}
                      </span>
                      <span className="product-item-price">
                        {symbol}
                        {RoundToTwo(
                          Exchanger(
                            defaultCurrency,
                            currency,
                            val.current_price,
                            rate
                          )
                        )}
                      </span>
                      {val.sales ? (
                        <span className="product-item-sales">
                          sale: {val.sales}
                        </span>
                      ) : null}
                    </div>
                    {/* <ProductList.Container>
                    <ProductList.ImgContainer className="productImage">
                      <ProductList.Img src={val.pic_url} alt={val.title} />
                    </ProductList.ImgContainer>

                    <div className="cardDetail">
                      <ProductList.Title>
                        <p>{val.title}</p>
                      </ProductList.Title>
                      <ProductList.Price>
                        <b>
                          {currency} {RoundToTwo(parseFloat(val.price) * rate)}
                        </b>
                      </ProductList.Price>
                      <div>
                        Sales: <span>{val.sales}</span>
                      </div>
                    </div>
                  </ProductList.Container> */}
                  </Link>
                </div>
              );
            })}
          </div>
        </InfiniteScroll>

        {/* <div style={{ display: "block", padding: 30 }}>
          <Pagination
            count={products.pagecount}
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
            page={page}
            onChange={handleChange}
          />
        </div> */}
      </Body.Wrapper>
    </>
  );
}

export default Shop;
