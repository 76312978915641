import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router';
import images1 from '../../../assets/data/images1';
import '../../../assets/css/wallet.css';
import { Seo } from '../../utilities/Seo';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useRefreshToken from '../../../hooks/useRefreshToken';
import Spinner from "../../utilities/Spinner";
import useCheckAuthWithRedirect from "../../../hooks/useCheckAuthWithRedirect";
import { RoundToTwo } from "../../utilities/RoundToTwo";



export const  Index = () => {
  
   //check for authentication on the page
   useCheckAuthWithRedirect();
   
  const [modalShow, setModalShow] = useState(false);
  const[walletBalance, setWalletBalance] = useState(0);


  const axiosPrivate = useAxiosPrivate();
  const refresh = useRefreshToken();
  const navigate = useNavigate();


  useEffect(()  =>{
    const getRefreshToken = async () => {
        await refresh();
    }
    
    getRefreshToken();
  },[]);

  //set page title
useEffect(() => {
  const pageTitle =  "My Wallet || Checkstall.com";
  Seo({
    title: pageTitle,
    metaDescription: 'My Wallet',
  });
}, []);


useEffect(() =>{
  getWalletBalance();
},[]);

const getWalletBalance = () =>{
  let isMounted = true;
  const controller = new AbortController();

  const doGetBalance = async () => {
      setModalShow(true);
      try {
            const response = await axiosPrivate.post("v1/users/get-balance", {
              signal: controller.signal
            });

               //console.log(response?.data)
               isMounted && setWalletBalance(response?.data?.message);
      }catch(err){
    
          console.log(err.response?.data);
          
      }
      setModalShow(false);
  }

  doGetBalance();
  return () => {
    isMounted = false;
      controller.abort();
  }
}

  const handleTopUp = () =>{
    navigate('/members/wallet/top-up', {state:{refresh:true}});
  }

  const handleWithdrawal = () =>{
    navigate('/members/wallet/withdraw', {state:{refresh:true}});
  }


  return (
    
    <>
      <Spinner
            cssClass={'semi-dark-bg'}
            show={modalShow}
        />
        <div className="container-fluid wallet-wrap" style={{minHeight: '100%'}} >
          <div className="row clearfix">
            <div className="col-xs-12 col-sm-12 ">
                <div className="col-md-6 col-md-offset-3 shadow wallet-body" >
                  <div className="wallet-logo">
                    <img src={images1[0].src} alt="logo" />
                  </div>
                  <div className="balance-header text-muted">Available Balance</div>
                  <div className="balance">₦ {RoundToTwo(walletBalance)}</div>
                  <div className="row wallet-action">
                    <div className="col-md-6" >
                      <button onClick={handleTopUp} className="action-btn" id="topUp">Top Up</button>
                    </div>
                    <div className="col-md-6" >
                      <button onClick={handleWithdrawal} className="action-btn" id="withdraw">Withdraw</button>
                    </div>						
                  </div>
                </div>
            </div>
          </div>
        </div>
    </>

  )

};

