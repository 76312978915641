import React from 'react';
import classes from './SizeComparison.module.css';
import Navbar from '../../../navbar/Navbar';
import MensSize from '../mensSize/MensSize';
import WomensSize from '../womensSize/WomensSize';
import ChildrensSize from '../childrensSize/ChildrensSize';
import UnderWear from '../underwear/UnderWear';
import ShoeSize from '../shoeSize/ShoeSize';
// import BodySizeMeasurement from '../bodySizeMeasurement/BodySizeMeasurement';

const SizeComparison = () => {
  return (
    <>
      <Navbar />
      <main className={classes.wrapper}>
        <section className={classes.container}>
          <WomensSize />
          <MensSize />
          <ChildrensSize />
          <UnderWear />
          <ShoeSize />
          {/* <BodySizeMeasurement /> */}
        </section>
      </main>
    </>
  );
}

export default SizeComparison
