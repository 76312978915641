import React, { useEffect } from "react";
import classes from "./ParcelManagement.module.css";
import Navbar from "../../navbar/Navbar";
import { parcelData } from "./parcelData";
import DescriptionIcon from "@mui/icons-material/Description";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import FlagIcon from "@mui/icons-material/Flag";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Modal, Pagination } from "@mui/material";
import styled from "@emotion/styled";
import { useState } from "react";
import { Seo } from "../../utilities/Seo";

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "none",
  outline: "none",
});
const StyledBox = styled(Box)({
  width: "450px",
  minHeight: "400px",
  backgroundColor: "white",
  color: "black",
  borderRadius: "0.3rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // justifyContent: "space-between",
  padding: "1.5rem",
  border: "none",
  outline: "none",
});

const ParcelManagement = () => {
  const [open, setOpen] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const itemsToDisplay = parcelData.slice(startIndex, endIndex);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleOpenModal = (obj) => {
    setOpen(obj);
  };

  const handleCloseModal = () => {
    setOpen(null);
  };

  useEffect(() => {
    Seo({
      title:
        "Parcel Management System  || Monitor and track the shippment of your products up to the point of arrival and delivery",
      metaDescription:
        "Monitor and track the progress of the shippment of your products up to the point of arrival and delivery, when you buy products from Checkstall, the world's online marketpalce",
    });
  }, []);

  return (
    <>
      <main className={classes.wrapper}>
        <section className={classes.container}>
          <div className={classes.titleBox}>
            <h1 className={classes.title}>parcel managment system</h1>
            <p className={classes.titleInfo}>
              We help you to monitor and keep track of your goods. With our
              system, you are sure of the safe shipping and arrival of your
              goods and friendly shipping rates and custom fees
            </p>
          </div>
          <div className={classes.overviewBox}>
            <h2 className={classes.overview}>overview</h2>
            <div className={classes.overviewHighlightBox}>
              <div className={classes.overviewHighlight}>
                <div className={classes.highlightInfo}>
                  <span className={classes.orderNum}>100</span>
                  <span className={classes.highlightDescription}>
                    total order
                  </span>
                </div>
                <div className={classes.highlightIconBox}>
                  <DescriptionIcon sx={{ fontSize: "3rem", color: "#fff" }} />
                </div>
              </div>
              <div className={classes.overviewHighlight}>
                <div className={classes.highlightInfo}>
                  <span className={classes.orderNum}>20</span>
                  <span className={classes.highlightDescription}>
                    processing
                  </span>
                </div>
                <div className={classes.highlightIconBox}>
                  <ShoppingCartCheckoutIcon
                    sx={{ fontSize: "3rem", color: "#fff" }}
                  />
                </div>
              </div>
              <div className={classes.overviewHighlight}>
                <div className={classes.highlightInfo}>
                  <span className={classes.orderNum}>58</span>
                  <span className={classes.highlightDescription}>delivery</span>
                </div>
                <div className={classes.highlightIconBox}>
                  <LocalShippingIcon sx={{ fontSize: "3rem", color: "#fff" }} />
                </div>
              </div>
              <div className={classes.overviewHighlight}>
                <div className={classes.highlightInfo}>
                  <span className={classes.orderNum}>15</span>
                  <span className={classes.highlightDescription}>arrived</span>
                </div>
                <div className={classes.highlightIconBox}>
                  <FlagIcon sx={{ fontSize: "3rem", color: "#fff" }} />
                </div>
              </div>
            </div>
            <table className={classes.tableContainer}>
              <thead>
                <tr>
                  <th className={classes.largerWidth}>parcel no</th>
                  <th className={classes.date}>date</th>
                  <th className={classes.name}>customer's name</th>
                  <th className={classes.phone}>customer's phone</th>
                  <th className={classes.name}>receiver's name</th>
                  {/* <th className={classes.package}>total package</th> */}
                  <th className={classes.status}>parcel status</th>
                  <th className={classes.more}>more</th>
                </tr>
              </thead>
              {itemsToDisplay.map((value) => {
                let statusColor = classes.color;
                if (value.status === "arrived") {
                  statusColor = classes.arrived;
                } else if (value?.status === "processing") {
                  statusColor = classes.processing;
                } else if (value?.status === "delivered") {
                  statusColor = classes.delivered;
                } else {
                  statusColor = classes.notReceived;
                }
                return (
                  <tbody key={value.parcel_number}>
                    <tr onClick={() => handleOpenModal(value)}>
                      <td>{value?.parcel_number}</td>
                      <td>{value?.date}</td>
                      <td>{value?.customer_name}</td>
                      <td>{value?.customer_number}</td>
                      <td>{value?.receiver}</td>
                      {/* <td>{value?.total_package}</td> */}
                      <td className={statusColor}>{value?.status}</td>
                      <td>
                        <ChevronRightIcon />
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
          <div className={classes.pageBox}>
            <Pagination
              count={Math.ceil(parcelData.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </section>
      </main>
      <StyledModal
        open={open !== null}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledBox>
          {open && (
            <>
              <h3 className={classes.modalTitle}>more parcel details</h3>
              <div className={classes.modalDetailBox}>
                <div className={classes.modalDetail}>
                  <span className={classes.detailName}> Total package</span>
                  <span className={classes.detailValue}>
                    {open?.total_package}
                  </span>
                </div>
                <div className={classes.modalDetail}>
                  <span className={classes.detailName}> Weight in Kg</span>
                  <span className={classes.detailValue}>
                    {open?.weight_in_kg}
                  </span>
                </div>
                <div className={classes.modalDetail}>
                  <span className={classes.detailName}> Weight in cbm</span>
                  <span className={classes.detailValue}>
                    {open?.weight_in_cbm}
                  </span>
                </div>
                <div className={classes.modalDetail}>
                  <span className={classes.detailName}> Amount per Kg</span>
                  <span className={classes.detailValue}>
                    ₦{open?.amount_per_kg}
                  </span>
                </div>
                <div className={classes.modalDetail}>
                  <span className={classes.detailName}> Amount per cbm</span>
                  <span className={classes.detailValue}>
                    ₦{open?.amount_per_cbm}
                  </span>
                </div>
                <div className={classes.modalDetail}>
                  <span className={classes.detailName}> local shipping</span>
                  <span className={classes.detailValue}>
                    ₦{open?.local_shipping}
                  </span>
                </div>
                <div className={classes.modalDetail}>
                  <span className={classes.detailName}>
                    {" "}
                    International freight
                  </span>
                  <span className={classes.detailValue}>
                    ₦{open?.international_freight}
                  </span>
                </div>
                <div className={classes.modalDetail}>
                  <span className={classes.detailName}> Custom clearance</span>
                  <span className={classes.detailValue}>
                    ₦{open?.custom_clearance}
                  </span>
                </div>
                <div className={classes.modalDetail}>
                  <span className={classes.detailName}>
                    {" "}
                    select shipping address
                  </span>
                  <span className={classes.detailValueIcon}>
                    <EditIcon sx={{ fontSize: "2rem" }} />
                  </span>
                </div>
              </div>
            </>
          )}
        </StyledBox>
      </StyledModal>
    </>
  );
};

export default ParcelManagement;
