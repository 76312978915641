import React from 'react'

function removeLocalStorage(name) {
    try {
        localStorage.removeItem(name);
      } catch (e) {
        console.error(e);
      }
}

export default removeLocalStorage