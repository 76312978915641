import React, { useState } from "react";
import classes from "./AccountBox.module.css";
import { FcManager } from "react-icons/fc";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { Link } from "react-router-dom";

const AccountBox = ({ isMobile, userRole }) => {
  // console.log(userRole);
  const [open, setOpen] = useState(false);
  let roleValue = Number(process.env.REACT_APP_ROLE_AGENT);
  // console.log({roleValue});

  return (
    <div className={classes.wrapper}>
      <div
        className={`${classes.accountBox} ${
          isMobile && classes.mobileAccountBox
        }`}
        onClick={() => setOpen(!open)}
      >
        {/* <FcManager className={classes.accountIcon} /> */}
        <span>my checkstall</span>
        {open ? <FaAngleUp size={20} /> : <FaAngleDown size={20} />}
      </div>
      <div className={`${classes.options} ${open && classes.openBox}`}>
        <Link
          to="/members/home/index"
          className={classes.option}
          onClick={() => setOpen(!open)}
        >
          Dashboard
        </Link>
        {userRole && userRole === roleValue ? (
          <Link
            to="/checkstall-partner/index"
            className={classes.option}
            onClick={() => setOpen(!open)}
          >
            Partner Dashboard
          </Link>
        ) : (
          ""
        )}
        <Link className={classes.option} onClick={() => setOpen(!open)}>
          my recent purchase
        </Link>
        <Link
          to="/members/orders/index"
          className={classes.option}
          onClick={() => setOpen(!open)}
        >
          orders
        </Link>
        <Link className={classes.option} onClick={() => setOpen(!open)}>
          saved items
        </Link>
        <Link className={classes.option} onClick={() => setOpen(!open)}>
          personal deals
        </Link>
      </div>
    </div>
  );
};

export default AccountBox;
