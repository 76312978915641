import React, { useState, useMemo, createContext } from 'react';
import { getCookie } from '../components/functions/CustomCookies';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
    let cartItem = getCookie("cartTotal");
    const [cart, setCart] = useState(cartItem != undefined && parseInt(cartItem) > 0 ? parseInt(cartItem) :  0);
    const value = useMemo(() => ({ cart, setCart }), [cart, setCart]);

    return (
    <CartContext.Provider value={value}>{children}</CartContext.Provider>
    );
 };

 export default CartContext;