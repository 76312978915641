import React from 'react';
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const PlayIcon = (props) => {
  return (
    <div className={`${props.size == 'sm' ? 'play-icon-sm' : 'play-icon'}`}>
      <PlayArrowIcon sx={{fontSize:props.size == 'sm' ? '1.5rem' : '3rem', color: '#cf0000'}} />
    </div>
  )
}

export default PlayIcon
