import React from "react";
import classes from "./ContactUs.module.css";
import Navbar from "../../navbar/Navbar";
import Address from "./address/Address";
import ContactForm from "./contactForm/ContactForm";

const ContactUs = () => {
  return (
    <main className={classes.wrapper}>
      <Navbar />
      <section className={classes.container}>
        <h1 className={classes.title}>get to know us</h1>
        <div className={classes.ourInfoBox}>
          <Address />
          <ContactForm />
        </div>
      </section>
    </main>
  );
};

export default ContactUs;
