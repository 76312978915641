import { Outlet, Link } from "react-router-dom";
import Header from "../functions/Header";
import Footer from "../functions/Footer";
import logo1 from "../../assets/checkstall RED.png";
import smallLogo from "../../assets/logo_no_letters.png";

import React, { useState } from "react";
import "../../assets/css/layout.css";

//import icons from react icons
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ReceiptIcon from "@mui/icons-material/Receipt";
import {
  FaGem,
  FaHeart,
  FaWallet,
  FaRegSun,
  FaAddressCard,
} from "react-icons/fa";
import {
  FaList,
  FaRegHeart,
  FaUser,
  FaRegDotCircle,
  FaStore,
} from "react-icons/fa";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import SecurityIcon from "@mui/icons-material/Security";
import LoginIcon from "@mui/icons-material/Login";
import {
  FiHome,
  FiLogOut,
  FiArrowLeftCircle,
  FiArrowRightCircle,
} from "react-icons/fi";
import { RiPencilLine } from "react-icons/ri";
import { BiCog } from "react-icons/bi";

//import react pro sidebar components

import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import Navbar from "../navbar/Navbar";

const Layout = () => {
  const { collapseSidebar, collapsed } = useProSidebar();
  const [isActive, setisActive] = useState(false);

  return (
    <>
      {/* <Header /> */}
      <Navbar />
      <div className="layout-container d-flex justify-content-between">
        <Sidebar backgroundColor={"#ffffff"}>
          {collapsed ? (
            <div className="biglogotext">
              <img src={smallLogo} alt="logo" />
            </div>
          ) : (
            <div className="biglogotext">
              <img src={logo1} alt="logo" />
              {/* <p>{collapsed ? "" : ""}</p> */}
            </div>
          )}
          <div className="closemenu" onClick={() => collapseSidebar()}>
            {collapsed ? <FiArrowRightCircle /> : <FiArrowLeftCircle />}
          </div>
          <Menu closeOnClick={true}>
            <MenuItem
              icon={<FiHome />}
              active={true}
              routerLink={<Link to="/members/home/index" />}
            >
              Home
            </MenuItem>
            <SubMenu label="Account Setting" icon={<FaUser />}>
              <MenuItem
                icon={<FaRegDotCircle />}
                routerLink={<Link to="/members/account/index" />}
              >
                Overview
              </MenuItem>
              <MenuItem
                icon={<AccountBoxIcon />}
                routerLink={<Link to="/members/account/userProfile" />}
              >
                user Profile
              </MenuItem>
              <MenuItem
                icon={<FaRegSun />}
                routerLink={<Link to="/members/account/settings" />}
              >
                Settings
              </MenuItem>
              <MenuItem
                icon={<FaAddressCard />}
                routerLink={<Link to="/members/account/shipping-address" />}
              >
                Address Management
              </MenuItem>
              <SubMenu label="Security" icon={<SecurityIcon />}>
                <MenuItem
                  icon={<LoginIcon />}
                  routerLink={
                    <Link to="/members/account/updateloginpassword" />
                  }
                >
                  Update Password
                </MenuItem>
              </SubMenu>
            </SubMenu>
            <SubMenu label="Wallet" icon={<FaWallet />}>
              <MenuItem routerLink={<Link to="/members/wallet/index" />}>
                Balance
              </MenuItem>
              <MenuItem routerLink={<Link to="/members/wallet/top-up" />}>
                Top-up
              </MenuItem>
              <MenuItem routerLink={<Link to="/members/wallet/withdraw" />}>
                Withdraw
              </MenuItem>
              <MenuItem
                routerLink={
                  <Link to="/members/wallet/withdrawal-information" />
                }
              >
                Withdrawal Information
              </MenuItem>
              <MenuItem routerLink={<Link to="/members/wallet/history" />}>
                History
              </MenuItem>
              <MenuItem
                routerLink={<Link to="/members/wallet/transaction?type=all" />}
              >
                Transaction
              </MenuItem>
              <SubMenu label="Settings" icon={<FaRegSun />}>
                <MenuItem
                  routerLink={<Link to="/members/wallet/set-wallet-password" />}
                >
                  Wallet Pin
                </MenuItem>
                <MenuItem routerLink={<Link to="/members/wallet/privacy" />}>
                  Balance Privacy
                </MenuItem>
              </SubMenu>
            </SubMenu>
            <SubMenu label="Orders" icon={<FaStore />}>
              <MenuItem routerLink={<Link to="/members/orders/index" />}>
                Order
              </MenuItem>
            </SubMenu>
            <SubMenu label="parcel management" icon={<LocalShippingIcon />}>
              <MenuItem routerLink={<Link to="/members/parcel" />}>
                Parcel
              </MenuItem>
            </SubMenu>
            <SubMenu label="Invoice" icon={<ReceiptIcon />}>
              <MenuItem routerLink={<Link to="/members/invoice" />}>
                Invoice
              </MenuItem>
            </SubMenu>

            <MenuItem> E-commerce</MenuItem>
          </Menu>
        </Sidebar>
        <main className="d-flex flex-column">
          <Outlet />
        </main>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Layout;
