import React from 'react'

function getLocalStorage(name) {
    try {
        const stateStr = localStorage.getItem(name);
        return stateStr ? stateStr : undefined;
      } catch (e) {
        console.error(e);
        return undefined;
      }
}

export default getLocalStorage