import React from 'react';
import { Outlet } from 'react-router-dom';


function  Orders(){
  
  return (
    
    <Outlet />

  )

}
export default Orders;




