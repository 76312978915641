import React from 'react';



export const  Index = () => {
  
  return (
    
    <div>Account index page</div>

  )

};

