export const womensShoeSizeData = [
  {
    foot: 22.5,
    europe: "36 2/3",
    us: 5.5,
    uk: 4.5,
  },
  {
    foot: 23,
    europe: "37 1/3",
    us: 6,
    uk: 5,
  },
  {
    foot: 23.5,
    europe: 38,
    us: 6.5,
    uk: 5.5,
  },
  {
    foot: 24,
    europe: "38 2/3",
    us: 7,
    uk: 6,
  },
  {
    foot: 24.5,
    europe: "39 1/3",
    us: 7.5,
    uk: 6.5,
  },
  {
    foot: 25,
    europe: 40,
    us: 8,
    uk: 7,
  },
  {
    foot: 25.5,
    europe: "40 2/3",
    us: 8.5,
    uk: 7.5,
  },
  {
    foot: 26,
    europe: "41 1/3",
    us: 9,
    uk: 8,
  },
];

export const mensShoeSizeData = [
  {
    foot: 24.5,
    europe: "39 1/3",
    us: 6.5,
    uk: 6,
  },
  {
    foot: 24,
    europe: 40,
    us: 7,
    uk: 6.5,
  },
  {
    foot: 25.5,
    europe: "40 2/3",
    us: 7.5,
    uk: 7,
  },
  {
    foot: 26,
    europe: "41 1/3",
    us: 8,
    uk: 7.5,
  },
  {
    foot: 26.5,
    europe: "42",
    us: 8.5,
    uk: 8,
  },
  {
    foot: 27,
    europe: "42 2/3",
    us: 9,
    uk: 8.5,
  },
  {
    foot: 27.5,
    europe: "43 1/3",
    us: 9.5,
    uk: 9,
  },
  {
    foot: 28,
    europe: 44,
    us: 10,
    uk: 9.5,
  },
];

export const childrenShoeSizeData = [
  {
    age: "Under 1 year old",
    cm: 8,
    inch: 3.1,
    size: 14,
  },
  {
    age: "Under 1 year old",
    cm: 8.7,
    inch: 3.4,
    size: 15,
  },
  {
    age: "Under 1 year old",
    cm: 9.4,
    inch: 3.7,
    size: 16,
  },
  {
    age: "Under 1 year old",
    cm: 10,
    inch: 3.9,
    size: 17,
  },
  {
    age: "Under 1 year old",
    cm: 10.8,
    inch: 4.3,
    size: 18,
  },
  {
    age: "Under 1 year old",
    cm: 11.5,
    inch: 4.5,
    size: 19,
  },

  {
    age: "Under 1 year old",
    cm: 12.2,
    inch: 4.75,
    size: 20,
  },
  {
    age: "Under 1 year old",
    cm: 12.75,
    inch: 5,
    size: 21,
  },
  {
    age: "Under 1 year old",
    cm: 13.5,
    inch: 5.3,
    size: 22,
  },
  {
    age: "1years old",
    cm: 14.2,
    inch: 5.5,
    size: 23,
  },
  {
    age: "2years old",
    cm: 14.8,
    inch: 5.75,
    size: 24,
  },
  {
    age: "2and a half years old",
    cm: 15.5,
    inch: 6,
    size: 25,
  },
  {
    age: "3years old",
    cm: 16,
    inch: 6.4,
    size: 26,
  },
  {
    age: "3and a half years old",
    cm: 16.8,
    inch: 6.6,
    size: 27,
  },
  {
    age: "4years old",
    cm: 17.4,
    inch: 6.9,
    size: 28,
  },
  {
    age: "5years old",
    cm: 18,
    inch: 7.5,
    size: 29,
  },
  {
    age: "6years old",
    cm: 18.75,
    inch: 7.4,
    size: 30,
  },
  {
    age: "6-7years old",
    cm: 19.5,
    inch: 7.6,
    size: 31,
  },
  {
    age: "7and a half years old",
    cm: 20.1,
    inch: 7.9,
    size: 32,
  },
  {
    age: "8years old",
    cm: 20.7,
    inch: 8.2,
    size: 33,
  },
  {
    age: "9years old",
    cm: 21.5,
    inch: 8.5,
    size: 34,
  },
  {
    age: "10years old",
    cm: 22.2,
    inch: 8.7,
    size: 35,
  },
  {
    age: "10and a half years old",
    cm: 22.8,
    inch: 9,
    size: 36,
  },
  {
    age: "11years old",
    cm: 23.5,
    inch: 9.25,
    size: 37,
  },
  {
    age: "12years old",
    cm: 24.1,
    inch: 9.5,
    size: 38,
  },
  {
    age: "13years old",
    cm: 24.8,
    inch: 9.75,
    size: 39,
  },
];
