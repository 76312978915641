import useAuth from './useAuth';
import removeLocalStorage from "../components/functions/removeLocalStorage";
import {deleteCookie} from "../components/functions/CustomCookies";
import { useNavigate,useLocation } from "react-router-dom";
import getLocalStorage from '../components/functions/getLocalStorage';
import { isJSON } from '../components/functions/Helpers';




const useCheckAuthWithRedirect = () => {
    const {auth, persist } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    

    if(Boolean(persist)){
        const localAuth = isJSON(getLocalStorage('auth')) ?  JSON.parse(getLocalStorage('auth')) : {};
        if(localAuth?.accessToken === undefined || localAuth?.accessToken === ""){
            removeLocalStorage('user');
            removeLocalStorage('cartTotal');
            removeLocalStorage('orderItem');
    
            navigate('/login', { state: { from: location }, replace: true });
        } 
    }else{
        if(auth.accessToken === undefined){
            deleteCookie('user');
            deleteCookie('cartTotal');
            removeLocalStorage('orderItem');
            navigate('/login', { state: { from: location }, replace: true });
        }
    }

};

export default useCheckAuthWithRedirect;
