import React,{useEffect, useState} from 'react';
import classes from '../../../assets/css/checkstallPartnerRefereeList.module.css';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Seo } from '../../utilities/Seo';
import Navbar from '../../navbar/Navbar';

function RefereeList() {

    const [referre, setReferre] = useState([]);
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        Seo({
            title: 'Checkstall Partner Referree List || The Checkstall Affliate Program',
            metaDescription: 'Checkstall Partner || The Checkstall Affliate Program'
        });
    }, []);

    useEffect(() => {
        getReferree();
     }, []);
    const getReferree= () =>{

        const doGetReferree = async () => {
        
            try {
                  const response = await axiosPrivate.get("v1/agent/get-referrees");
                  setReferre(response?.data?.message);
            }catch(err){  
            }
        }
      
        doGetReferree();
      
      }

  return (
    <React.Fragment>
      <Navbar />
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <h2 className={classes.refHistoryTitle}>List of Referees</h2>

          {referre && referre.length > 0 ? (
            <table className={classes.refTable}>
              <thead>
                <tr>
                  <th className={classes.sn}>s/n</th>
                  <th className={classes.email}>email</th>
                  <th>date</th>
                </tr>
              </thead>
              {referre.map((value, index) => {
                if (index < 5) {
                  //show only five recors
                  return (
                    <tbody
                      // className="referee-list d-flex justify-content-between"
                      key={index}
                    >
                      <tr>
                        <td>{index + 1}</td>
                        <td>{value.referee_email}</td>
                        <td>{value.reffered_time}</td>
                      </tr>
                    </tbody>
                  );
                }
              })}
            </table>
          ) : (
            <div className={classes.noReferee}>
              You have no referee yet, share your code with friends to invite
              them
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default RefereeList