import React, { useEffect, useState } from "react";
import {
  FaCreditCard,
  FaShippingFast,
  FaStore,
  FaArrowRight,
  FaArrowLeft,
  FaFileInvoice,
} from "react-icons/fa";
// import ReceiptIcon from "@mui/icons-material/Receipt";
import WalletCard from "../../functions/WalletCard";
import { Link } from "react-router-dom";
import user from "../../../assets/images/user.jpg";
import "../../../assets/css/account.css";
import { Seo } from "../../utilities/Seo";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useRefreshToken from "../../../hooks/useRefreshToken";
import Spinner from "../../utilities/Spinner";
import useCheckAuthWithRedirect from "../../../hooks/useCheckAuthWithRedirect";
import Badge from "@material-ui/core/Badge";
import useCurrencyRate from "../../../hooks/useCurrencyRate";
import { RoundToTwo } from "../../utilities/RoundToTwo";

export const Index = () => {
  //check for authentication on the page
  useCheckAuthWithRedirect();

  const [modalShow, setModalShow] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [totalInvoice, setTotalInvoice] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  const [orderCounts, setOrderCounts] = useState({});
  const [recentTransaction, setRecentTransaction] = useState({});
  const { currency, symbol } = useCurrencyRate();

  const axiosPrivate = useAxiosPrivate();
  const refresh = useRefreshToken();

  //get new token for the reques
  // useEffect(()  =>{
  //   const getRefreshToken = async () => {
  //       await refresh();
  //   }
  //   getRefreshToken();
  // },[]);

  //set page title
  useEffect(() => {
    const pageTitle = "Account  || Checkstall.com";
    Seo({
      title: pageTitle,
      metaDescription: "Account dashboard",
    });
  }, []);

  useEffect(() => {
    getWalletBalance();
    getUserInfo();
    getOrderCounts();
    getRecentTransaction();
  }, []);

  const getWalletBalance = () => {
    let isMounted = true;
    const controller = new AbortController();

    const doGetBalance = async () => {
      setModalShow(true);
      try {
        const response = await axiosPrivate.post("v1/users/get-balance", {
          signal: controller.signal,
        });

        //console.log(response?.data)
        isMounted && setWalletBalance(response?.data?.message);
      } catch (err) {
        //console.log(err.response?.data);
      }
      setModalShow(false);
    };

    doGetBalance();
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const getUserInfo = () => {
    let isMounted = true;
    const controller = new AbortController();

    const doGetUser = async () => {
      setModalShow(true);
      try {
        const response = await axiosPrivate.get("v1/users/index", {
          signal: controller.signal,
        });

        //console.log(response?.data)
        isMounted && setUserInfo(response?.data?.message);
        //isMounted && setOrderInfo(response?.data?.message);
      } catch (err) {
        console.log(err.response?.data);
      }
      setModalShow(false);
    };

    doGetUser();
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const getOrderCounts = () => {
    let isMounted = true;
    const controller = new AbortController();

    const doGetCount = async () => {
      setModalShow(true);
      try {
        const response = await axiosPrivate.get("v1/order/order-count", {
          signal: controller.signal,
        });

        //console.log(response?.data?.message)
        // isMounted && setOrderCounts(response?.data?.message);
        isMounted && setOrderCounts(response?.data?.orderCount);
        isMounted && setTotalInvoice(response?.data?.totalInvoice);
        //isMounted && setOrderInfo(response?.data?.message);
      } catch (err) {
        console.log(err.response?.data);
      }
      setModalShow(false);
    };

    doGetCount();
    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  // console.log({ totalInvoice, orderCounts });

  const getRecentTransaction = () => {
    let isMounted = true;
    const controller = new AbortController();

    const doGetTransaction = async () => {
      setModalShow(true);
      try {
        const response = await axiosPrivate.post(
          "v1/transaction/get-recent-transaction",
          {
            signal: controller.signal,
          }
        );

        //console.log(response?.data?.message)
        isMounted && setRecentTransaction(response?.data?.message);
      } catch (err) {
        console.log(err.response?.data);
      }
      setModalShow(false);
    };

    doGetTransaction();
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const INCOMING_TRANSACTION = [
    1, //TOP UP
    3, //REFUND
    5, //INCOME
  ];

  return (
    <>
      <Spinner cssClass={"semi-dark-bg"} show={modalShow} />

      <div className="mt5">
        <div className="container" style={{ display: "flex" }}>
          <div className="col-md-12 inlineBlock">
            <div className=" moneyCard">
              <div className="col-lg-4 userImgCard">
                <div className="gridy">
                  <img
                    className="userImg"
                    src={userInfo?.avater || user}
                    alt="accountImage"
                  />
                  <h5 className="userName">
                    {userInfo?.full_name || userInfo?.username}
                  </h5>
                </div>
              </div>
              <div className="col-md-7">
                <WalletCard balance={RoundToTwo(walletBalance)} />
              </div>
            </div>
            <div className="myOrdersCard" style={{ marginTop: "20px" }}>
              <div>
                <h4>Unpaid Invoice</h4>
                <div className="orderPosition">
                  <Badge
                    badgeContent={
                      // orderCounts.unreviewed
                      //   ? parseInt(orderCounts.unreviewed)
                      //   : 0
                      totalInvoice
                    }
                    color="secondary"
                    max={9}
                    overlap="circular"
                  >
                    <div className="Oposit">
                      <Link to="/members/invoice" id="unreviewed">
                        <FaFileInvoice
                          style={{ fontSize: "x-large", fontWeight: "900" }}
                        />
                        {/* <ReceiptIcon sx={{ fontSize: "2rem" }} /> */}
                        <p>Invoice</p>
                      </Link>
                    </div>
                  </Badge>
                </div>
              </div>

            </div>
            <div className="myOrdersCard" style={{ marginTop: "20px" }}>
              <div>
                <h4>My Orders</h4>
                <div className="orderPosition">
                  <Badge
                    badgeContent={
                      orderCounts.unreviewed
                        ? parseInt(orderCounts.unreviewed)
                        : 0
                    }
                    color="secondary"
                    max={9}
                    overlap="circular"
                  >
                    <div className="Oposit">
                      <Link
                        to="/members/invoice"
                        id="unreviewed"
                      >
                        <FaCreditCard
                          style={{ fontSize: "x-large", fontWeight: "900" }}
                        />
                        <p>Unreviewed</p>
                      </Link>
                    </div>

                  </Badge>
                  <Badge
                    badgeContent={
                      orderCounts.unpaid ? parseInt(orderCounts.unpaid) : 0
                    }
                    color="secondary"
                    max={9}
                    overlap="circular"
                  >
                    <div className="Oposit">
                      <Link
                        to="/members/orders/index?orderStatus=unpaid"
                        id="unpaid"
                      >
                        <FaCreditCard
                          style={{ fontSize: "x-large", fontWeight: "900" }}
                        />
                        <p>Unpaid</p>
                      </Link>
                    </div>
                  </Badge>
                  <Badge
                    badgeContent={
                      orderCounts.processing
                        ? parseInt(orderCounts.processing)
                        : 0
                    }
                    color="secondary"
                    max={9}
                    overlap="circular"
                  >
                    <div className="Oposit">
                      <Link
                        to="/members/orders/index?orderStatus=processing"
                        id="processing"
                      >
                        <FaStore
                          style={{ fontSize: "x-large", fontWeight: "900" }}
                        />
                        <p>Processing</p>
                      </Link>
                    </div>
                  </Badge>
                  <Badge
                    badgeContent={
                      orderCounts.purchased
                        ? parseInt(orderCounts.purchased)
                        : 0
                    }
                    color="secondary"
                    max={9}
                    overlap="circular"
                  >
                    <div className="Oposit">
                      <Link
                        to="/members/orders/index?orderStatus=purchased"
                        id="purchased"
                      >
                        <FaShippingFast
                          style={{ fontSize: "x-large", fontWeight: "900" }}
                        />
                        <p>Purchased</p>
                      </Link>
                    </div>
                  </Badge>
                </div>
              </div>
            </div>
            <div className="myOrdersCard" style={{ marginTop: "20px" }}>
              <div>
                <h5>Transaction</h5>

                {recentTransaction != null && recentTransaction.length > 0 ? (
                  recentTransaction.map((value, key) => {
                    return (
                      <div
                        className="d-flex flex-column history-wrap"
                        key={key}
                      >
                        <div className="d-flex flex-column">
                          <div className="trans-history d-flex justify-content-between">
                            <div className="subject">
                              <span
                                className={`arrow ${
                                  INCOMING_TRANSACTION.includes(value.type)
                                    ? "in"
                                    : "out"
                                }`}
                              >
                                {INCOMING_TRANSACTION.includes(value.type) ? (
                                  <FaArrowRight />
                                ) : (
                                  <FaArrowLeft />
                                )}
                              </span>
                              {value.subject}
                            </div>
                            <div
                              className={`amount ${
                                INCOMING_TRANSACTION.includes(value.type)
                                  ? "fund-added"
                                  : "fund-deducted"
                              }`}
                            >
                              {symbol + " "}
                              {RoundToTwo(parseFloat(value?.amount))}
                            </div>
                          </div>
                          <div
                            className={`date ${
                              INCOMING_TRANSACTION.includes(value.type)
                                ? "fund-added"
                                : "fund-deducted"
                            }`}
                          >
                            {value.date}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div style={{ textAlign: "center" }}>
                    You have No Transaction yet!
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
