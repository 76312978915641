
import classes from './SearchBox.module.css';
import React, {useState, useContext} from 'react';
import "../../assets/css/searchbar.css";
// import SearchSite from './SearchSite';
import SearchSiteContext from '../../context/SearchSiteProvider';
import { useNavigate } from "react-router-dom";
import Translate from '../../api/Translate';
import axios from '../../api/axios';
import Spinner from '../utilities/Spinner';


import {
  AiOutlineSearch,
} from "react-icons/ai";

const SearchBox = ({ isMobile }) => {
  const navigate = useNavigate();
  const {site} = useContext(SearchSiteContext);
  const [searchQuery, setSearchQuery] = useState("");
  //const {setSearchString} = useGlobalVariable();
  const [modalShow, setModalShow] = useState(false);




  

  const handleSubmit = (event) => {
    event.preventDefault()
    //setSearchString(searchQuery);
    //console.log(searchQuery);
    translate(searchQuery);
    
  }

  const handleSearch = (string) =>{

    if(filterSearchQuery(searchQuery) != null){
      handleRedirect(filterSearchQuery(searchQuery));
    }else if(getShortUrl(searchQuery)){
      getQueryString(getShortUrl(searchQuery))  
    }else{ //no id return we assume it's ordinatry string
      //addSearchHistory(searchQuery);
      navigate(`/shop/?site=${site}&keyword=${string}&searchtype=keyword`, {state:{refresh:true}});
      // const timer = setTimeout(() => {
        
      // }, 1000);
      // return () => clearTimeout(timer);

    }
  }


  
  const getShortUrl = (queryString)  =>{
    let query = queryString.trim().replaceAll('"', '');
    if(query.indexOf('https://qr') >= 0){
      query =  query.substring(query.indexOf('https://qr'));
      return query.split(' ')?.[0];
    }
  }

  const getQueryString = async (shortUrl)  =>{
    setModalShow(true);
        let url = "/v1/helpers/test-curl?link="+shortUrl;
        try {
          const response = await axios.get(url,{
            withCredentials: false,
          });

            if(response?.data?.error === null){
              setModalShow(true);
                handleRedirect(response?.data?.response);
            }else{
              alert("Network Error, Please try again");
            }

      }catch(err){
          //console.log(err.response);  
          alert("Server Error occur, Please try again");   
      }
      setModalShow(true);  
  }
  
  //redirect to item page
  const handleRedirect = (itemId) =>{
    navigate(`/item/?site=${site}&item=${itemId}`, {state:{refresh:true}})
  }


  const filterSearchQuery = (qeuryString) => {
    let query = qeuryString.trim().replaceAll('"', '');
    let searchString = null;
    let desktopLink = null;
    if(query.indexOf('offerId=') >= 0){ //target already parse and validate link
      desktopLink = query.substring(query.indexOf('offerId='));
      searchString = +(desktopLink ? desktopLink.split('=')[1] : -1);
    }else if(query.indexOf('1688.com/offer') >= 0){  
      desktopLink = query.substring(query.indexOf('1688.com/offer'), query.indexOf('.html'));
      searchString = +(desktopLink ? desktopLink.split('/')[2] : -1);
    }

    return searchString;  
  }



  // const doSearch = (event) => {
  //   let value = event.target.innerText;
  //   setSearchString(value);
  //   translate(value); 
  // }

  const translate = (text) =>{
    setModalShow(true);
    let fromLang = 'en';
    let toLang = 'zh-CN'; 

    Translate(text, fromLang,toLang).then(response => {
      if(response){
        handleSearch(response);
        setModalShow(false);
      }else{
        handleSearch(searchQuery);
        setModalShow(false);
      }
    })
    
  }


  return (
    <React.Fragment>
      <Spinner
          cssClass={'semi-dark-bg'}
          show={modalShow}
        />
      <form action="/shop" method="get" onSubmit={handleSubmit} autoComplete="off"  className={classes.wrapper}>
          <div className={`${classes.searchBox} ${isMobile && classes.mobileSearchBox}`}>
            <input
                type="text"
                className={classes.search}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                id="keyword" 
                placeholder="Enter keyword or url" 
                name="keyword"
            />
            <button className={classes.searchIcon} type="submit">
              <AiOutlineSearch size={20} className={classes.icon} />
            </button>
          </div>
      </form>
    </React.Fragment>
    
  );
}

export default SearchBox
