import React from 'react';
import classes from './HeaderAccount.module.css';
import { Link } from 'react-router-dom';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const HeaderAcoount = () => {
  return (
    <aside className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.accountIcon}>
              <AccountCircleIcon sx={{fontSize: '4rem'}} />
          <h3 className={classes.miniTitle}>Welcome to Checkstall</h3>
        </div>
        <div className={classes.RgisterBox}>
          <Link to="/signup" className={classes.register}>
            <span>register</span>
          </Link>
          <Link to="/login" className={classes.register}>
            <span>sign in</span>
          </Link>
        </div>
      </div>
    </aside>
  );
}

export default HeaderAcoount
