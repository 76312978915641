import React from 'react';
import classes from './ChildrensSize.module.css';
import {
  babysClothingSizeData,
  childrenChineseSizeData,
  bigChildrenSizeData,
  childrenLengthHeightSizeData,
} from "./childrensData";

const ChildrensSize = () => {
  return (
    <section className={classes.wrapper}>
      <div className={classes.title}>
        <div className={classes.line} />
        <h2>Children's clothing size comparison table</h2>
        <div className={classes.line} />
      </div>
      <div className={classes.tableContainer}>
        <h4 className={classes.shirtTitle}>
          Baby clothing size comparison table
        </h4>
        <table className={classes.tableHolder}>
          <thead>
            <tr>
              <th className={classes.shirtSize}>size / cm</th>
              <th>59</th>
              <th>66</th>
              <th>73</th>
              <th>80</th>
              <th>90</th>
            </tr>
          </thead>
          {babysClothingSizeData.map((value, index) => {
            return (
              <tbody key={index}>
                <tr>
                  <td className={classes.tabledataTitle}>{value?.size}</td>
                  <td>{value?.xs}</td>
                  <td>{value?.s}</td>
                  <td>{value?.m}</td>
                  <td>{value?.l}</td>
                  <td>{value?.xl}</td>
                </tr>
              </tbody>
            );
          })}
        </table>
        <div className={classes.tableTitle}>
          Baby clothing size comparison table info1
        </div>
      </div>
      <div className={classes.tableContainer}>
        <h4 className={classes.shirtTitle}>
          Chinese children's clothing size comparison table
        </h4>
        <table className={classes.tableHolder}>
          <thead>
            <tr>
              <th>size / cm</th>
              <th>100</th>
              <th>110</th>
              <th>120</th>
              <th>130</th>
            </tr>
          </thead>
          {childrenChineseSizeData.map((value, index) => {
            return (
              <tbody key={index}>
                <tr>
                  <td className={classes.tabledataTitle}>{value?.size}</td>
                  <td>{value?.xs}</td>
                  <td>{value?.s}</td>
                  <td>{value?.m}</td>
                  <td>{value?.l}</td>
                </tr>
              </tbody>
            );
          })}
        </table>
        <div className={classes.tableTitle}>
          Chinese children's clothing size comparison table info1
        </div>
      </div>
      <div className={classes.tableContainer}>
        <h4 className={classes.shirtTitle}>
          Big children's clothing size comparison table
        </h4>
        <table className={classes.tableHolder}>
          <thead>
            <tr>
              <th>size / cm</th>
              <th>130</th>
              <th>140</th>
              <th>150</th>
              <th>160</th>
            </tr>
          </thead>
          {bigChildrenSizeData.map((value, index) => {
            return (
              <tbody key={index}>
                <tr>
                  <td className={classes.tabledataTitle}>{value?.size}</td>
                  <td>{value?.xs}</td>
                  <td>{value?.s}</td>
                  <td>{value?.m}</td>
                  <td>{value?.l}</td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
      <div className={classes.tableContainer}>
        <h4 className={classes.shirtTitle}>Children's clothing length and height relationship table</h4>
        <table className={classes.tableHolder}>
          <thead>
            <tr>
              <th>Style variety</th>
              <th>Length</th>
            </tr>
          </thead>
          {childrenLengthHeightSizeData.map((value, index) => {
            return (
              <tbody key={index}>
                <tr>
                  <td className={classes.tabledataTitle}>{value?.style}</td>
                  <td>{value?.length}</td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
    </section>
  );
}

export default ChildrensSize
