import React ,{ createContext, useState, useMemo } from "react";
import getLocalStorage from "../components/functions/getLocalStorage";

const CurrencyRateContext = createContext({});

export const CurrencyRateProvider = ({ children }) => {
    const [currency, setCurrency] = useState(getLocalStorage('currency') || "NGN");
    const [symbol, setSymbol] = useState(getLocalStorage('symbol') || "₦");
    const [rate, setRate] = useState(getLocalStorage('rate'));
    const value = useMemo(() => ({ 
        currency, setCurrency , rate, setRate,symbol, setSymbol}), 
    [currency, setCurrency, rate, setRate,symbol, setSymbol]);

    return (
        <CurrencyRateContext.Provider value={value}>{children}</CurrencyRateContext.Provider>
    )
}

export default CurrencyRateContext;