export const messageData = [
  {
    id: 1,
    title: "update on exchange rate",
    notice:
      "adjustment as following Nigerian bank market (effective on 6th March). The currency exchange rate is 1 CNY = 115",
    time: "2023-03-05 16:45:56",
  },
  {
    id: 2,
    title: "update on exchange rate",
    notice:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat error, nihil quo quidem, quasi doloremque animi repellendus tenetur libero accusantium temporibus? Possimus, obcaecati.",
    time: "2023-03-05 16:45:56",
  },
  {
    id: 3,
    title: "update on exchange rate",
    notice:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat error, nihil quo quidem, quasi doloremque animi repellendus tenetur libero accusantium magni eveniet rem aliquam dolorem id corporis temporibus? Possimus, obcaecati.",
    time: "2023-03-05 16:45:56",
  },
  {
    id: 4,
    title: "update on exchange rate",
    notice:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat error, nihil quo quidem, quasi doloremque animi repellendus tenetur libero accusantium magni eveniet rem aliquam dolorem id corporis temporibus? Possimus, obcaecati. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat error, nihil quo quidem, quasi doloremque animi repellendus tenetur libero accusantium magni eveniet rem aliquam dolorem id corporis temporibus? Possimus, obcaecati.",
    time: "2023-03-05 16:45:56",
  },
  {
    id: 5,
    title: "update on exchange rate",
    notice:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugiat error, nihil quo quidem, quasi doloremque animi repellendus tenetur libero accusantium magni eveniet rem aliquam dolorem",
    time: "2023-03-05 16:45:56",
  },
];