import React, { useEffect, useState } from "react";
import classes from "../../../assets/css/userProfile.module.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "../../utilities/Spinner";
import useCheckAuthWithRedirect from "../../../hooks/useCheckAuthWithRedirect";
import { maskEmail } from "../../functions/Helpers";
import { maskPhoneNumner } from "../../functions/Helpers";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Box, Modal } from "@mui/material";
import { UpdateFullname } from "./UpdateFullname";
import { UpdatePhone } from "./UpdatePhone";

const Profile = () => {
  useCheckAuthWithRedirect();

  const [modalShow, setModalShow] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [openName, setOpenName] = useState(false);
   const [openPhone, setOpenPhone] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const getUserInfo = () => {
    let isMounted = true;
    const controller = new AbortController();
    setModalShow(true);
    const doGetUser = async () => {
      try {
        const response = await axiosPrivate.post("v1/users/get-user-info", {
          signal: controller.signal,
          withCredentials: true,
        });

        isMounted && setUserInfo(response?.data?.user);
      } catch (err) {}
      setModalShow(false);
    };

    doGetUser();
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  console.log(userInfo);

  const userFullName = userInfo?.full_name ? userInfo.full_name : "set now";
  const userEmail = userInfo?.email ? maskEmail(userInfo.email) : "";
  const userPhoneNumber = userInfo?.phone_number
    ? maskPhoneNumner(userInfo.phone_number)
    : "add phone number";

 const handleCloseModal = () => {
  setOpenName(false)
 }
 const handleClosePhoneModal = () => {
   setOpenPhone(false);
 };
 const handleOpenModal = () => {
  if(userInfo?.full_name && userInfo?.full_name !== ''){
    setOpenName(false);
    return;
  }else{
    setOpenName(true);
  }
 }
 const handleOpenPhoneModal = () => {
   if (userInfo?.phone_number && userInfo?.phone_number !== "") {
     setOpenPhone(false);
     return;
   } else {
     setOpenPhone(true);
   }
 };
  return (
    <>
      <Spinner show={modalShow} cssClass={"semi-dark-bg"} />
      <section className={classes.wrapper}>
        <div className={classes.container}>
          <h1 className={classes.title}>my profile details</h1>
          <div className={classes.profileInfo}>
            <div className={classes.detailEdith} onClick={() => setOpenName(true)}>
              <div className={classes.detailBoxEdith}>
                <span className={classes.userDetailTitle}>full name:</span>
                <span className={classes.userDetail}>{userFullName}</span>
              </div>
              <div className={classes.edithBox}>
                <EditIcon />
              </div>
            </div>
            <div className={classes.detailEdith}>
              <div className={classes.detailBoxEdith}>
                <span className={classes.userDetailTitle}>
                  checkstall username:
                </span>
                <span className={classes.userDetail}>{userEmail}</span>
              </div>
              <div className={classes.edithBox}>
                <EditIcon />
              </div>
            </div>
            <div className={classes.detailEdith} onClick={handleOpenPhoneModal}>
              <div className={classes.detailBoxEdith}>
                <span className={classes.userDetailTitle}>phone number:</span>
                <span className={classes.userDetail}>{userPhoneNumber}</span>
              </div>
              <div className={classes.edithBox}>
                <EditIcon />
              </div>
            </div>

            <div className={classes.detailBox}>
              <span className={classes.userDetailTitle}>account status:</span>
              <span
                className={`${classes.userDetail} ${
                  userInfo?.status === "Active" && classes.activeUser
                }`}
              >
                {userInfo?.status}
              </span>
            </div>

            <div className={classes.detailBox}>
              <span className={classes.userDetailTitle}>role:</span>
              <span className={classes.userDetail}>{userInfo?.role}</span>
            </div>

            <div className={classes.detailBox}>
              <span className={classes.userDetailTitle}>last login:</span>
              <span className={classes.userDetail}>{userInfo?.last_login}</span>
            </div>
          </div>
        </div>
      </section>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "none",
          outline: "none",
        }}
        open={openName}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          width={400}
          height={200}
          bgcolor={"background.default"}
          color={"text.primary"}
          p={3}
          borderRadius={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <UpdateFullname handleCloseModal={handleCloseModal} openName={openName} />
        </Box>
      </Modal>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "none",
          outline: "none",
        }}
        open={openPhone}
        onClose={handleClosePhoneModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          width={350}
          minHeight={200}
          bgcolor={"background.default"}
          color={"text.primary"}
          p={3}
          borderRadius={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <UpdatePhone handleCloseModal={handleCloseModal} />
        </Box>
      </Modal>
    </>
  );
};

export default Profile;
