import React,{useEffect, useState} from "react";
import { Seo } from '../../utilities/Seo';
import classes from '../../../assets/css/withdrawal.module.css';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useCheckAuthWithRedirect from "../../../hooks/useCheckAuthWithRedirect";
import {useNavigate, useLocation ,Link} from 'react-router-dom';
import Spinner from "../../utilities/Spinner";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import useCurrencyRate from '../../../hooks/useCurrencyRate';
import Encryption from '../../functions/Encryption';

const GET_INFORMATION = "/v1/withdraw-information/get-information";

function Withdraw (){
 
    useCheckAuthWithRedirect();


    const [modalShow, setModalShow] = useState(false);
    const [amount, setAmount] = useState(0);
    const [paymentMethodId, setPaymentMethodId] = useState(0);
    const [topupMethod, setTopupMethod] = useState({});
    const [walletNumber, setWalletNumber] = useState('');
    const [isClicked, setIsClicked] = useState({['0']:true});
    const [withdrawalInformation, setWithdrawalInformation] = useState([]);
    const [minimumWithdrawal, setMinimumWithdrawal] = useState(1000);
    const [withdrawalInformationId, setWithdrawalInformationId] = useState(withdrawalInformation[0]?.id);
    const {symbol} = useCurrencyRate();
    const [isWalletPasswordSet, setIsWalletPasswordSet] = useState(null);
    const [error, setError] = useState("");
    const [link, setLink] = useState("");
    const [linkTitle, setLinkTitle] = useState("");
    

    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const navigate = useNavigate();


  const [password, setPassword] = useState("");

    const [state, setState] = useState({
      top: false,
      left: false,
      bottom: false,
      right: false,
    });

    const [withdrawalState, setWithdrawalState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });

    //set page title
    useEffect(() => {
        const pageTitle =  "Withdrawal || Checkstall.com";
        Seo({
        title: pageTitle,
        metaDescription: 'Withdrawal',
        });
    }, []);

   //check for authentication on the page
 useCheckAuthWithRedirect();
  
    useEffect(() =>{
        getPaymentMethod();
        getWalletNumber();
    },[]);

      
    const toggleDrawer = (anchor, open) => (event) => {
        if (
          event &&
          event.type === 'keydown' &&
          (event.key === 'Tab' || event.key === 'Shift')
        ) {
          return;
        }

        if(event && event.target.closest('.drawer-body')?.id){
          let id = event.target.closest('.drawer-body')?.id
          setPaymentMethodId(id);
        }
        
        setState({ ...state, [anchor]: open });
      };

      const withdrawalToggleDrawer = (anchor, open) => (event) => {
        if (
          event &&
          event.type === 'keydown' &&
          (event.key === 'Tab' || event.key === 'Shift')
        ) {
          return;
        }

        
        setWithdrawalState({ ...withdrawalState, [anchor]: open });
      };

      const getPaymentMethod = () =>{
        let isMounted = true;
        const controller = new AbortController();
      
        const doGetPaymentMethod = async () => {
            try {
                  const response = await axiosPrivate.post("v1/payment-method/get-topup-method",
                   {
                    signal: controller.signal
                  });
                  
                  //console.log(response?.data?.message);
                  if(response?.data?.message){
                     isMounted && setTopupMethod(response?.data?.message);
                  }
      
            }catch(err){
          
                //console.log(err.response?.data);
                
            }
        }
      
        doGetPaymentMethod();
        return () => {
          isMounted = false;
            controller.abort();
        }
      }

      const getWalletNumber = () =>{
        let isMounted = true;
        const controller = new AbortController();
      
        const doGetWalletNumber = async () => {
            try {
                  const response = await axiosPrivate.post("v1/wallet/get-id",
                   {
                    signal: controller.signal
                  });
                  
                  //console.log(response?.data?.message);
                  if(response?.data?.id){
                     isMounted && setWalletNumber(response?.data?.id);
                  }
      
            }catch(err){
          
                console.log(err.response?.data);
                
            }
        }
      
        doGetWalletNumber();
        return () => {
          isMounted = false;
            controller.abort();
        }
      }
      
      const handleWithdrawal = () =>{
          if(!(amount >= minimumWithdrawal && paymentMethodId && withdrawalInformationId)){
              return;
          }

          const data = {
            amount:amount,
            transaction_type:2,
            payment_method:paymentMethodId,
            withdrawalInformationId: withdrawalInformationId,
            subject: "Withdrawal through Bank Transfer",
        };


        processPayment(data);
          // setCookie("topUpAmount", amount, 0.5);
          // setCookie("topUpMethod", paymentMethodId, 0.5);
          // navigate('/dajupay/wallet-topup', {state:{refresh:true}});
      }


    
    const handleinputChange =(event) =>{
      setAmount(event.target.value);
    }

    const handleClick = (key) => event =>{

        if(event.type === "click"){
          if(!event.target.classList.contains("pm-selected")){
              setWithdrawalInformationId(withdrawalInformation[key]?.id);
              setIsClicked(
              {
                  [key]: true
              }
              );
          }else{
              setIsClicked(
              {
                  [key]: false
              }
              );
          }
        
        }
        
    };

     useEffect(() =>{
        getWithdrawalInfortion();
      },[]);

    const getWithdrawalInfortion = () =>{
        const controller = new AbortController();
   
        const doGetInformation = async () => {
          setModalShow(true);
              try {
        
                  const response = await axiosPrivate.get(GET_INFORMATION, {
                      signal: controller.signal
                  });
   
                  setWithdrawalInformation(response?.data?.message);
                  setWithdrawalInformationId(response?.data?.message[0]?.id);
              } catch (err) {
                  if(err?.response?.data?.status === 401){
                   navigate('/login', { state: { from: location }, replace: true });
                  }
              }
        
              setModalShow(false);
          }
   
          doGetInformation();
   
        return () => {
            controller.abort();
        }
    }

    const OTPInput = (event) =>{
        //const inputs = document.querySelectorAll('#otp > *[id]');
        
        if(!event.target.value || event.target.value == '' ){
            if(event.target.previousSibling){
                event.target.previousSibling.focus();    
            }
        
        }else{ 
            if(event.target.nextSibling){
                event.target.nextSibling.focus();
            }
        }               
  
    } 
    
    const handleSubmit = (event) =>{
        setPassword(event.target.value);
    }

    const processPayment = (data) =>{
      let isMounted = true;
      const controller = new AbortController();
      setModalShow(true);
      const doProcessPayment = async () => {
              const encryption = new Encryption();
              let pass = encryption.encrypt(password, encryption.key);
          try {
                const response = await axiosPrivate.post("v1/process-payment/process-withdrawal",
                  JSON.stringify({ data, pass }), {
                  signal: controller.signal
                });
    
              //handle response
                if(response?.data?.code == 101 ){
                   let mes = response?.data?.message;
                   setError(mes);
                   setLink("/members/wallet/settings");
                   setLinkTitle("SET NOW");
                }else if(response?.data?.code == 102){
                  setError(response?.data?.message);
                }else if(response?.data?.code == 103){
                  navigate("/members/wallet/index", {state:{refresh:true}});
                }else if(response?.data?.code == 104){
                  let mes = response?.data?.message+ " Go to";
                  setError(mes);
                  setLink("/members/wallet/top-up");
                  setLinkTitle(" TOP UP");
                }else if(response?.data?.code == 105){
                   let mes = "Incorrect password, you have "+response?.data?.message+" attempt left."
                   setError(mes);
                }

          }catch(err){
        
              
          }
          setModalShow(false);
      }
    
      doProcessPayment();
      return () => {
        isMounted = false;
          controller.abort();
      }
    }

    return (
      <React.Fragment>
        <Spinner cssClass={"semi-dark-bg"} show={modalShow} />
        <div className={classes.wrapper}>
          <div className={classes.container}>
            <div className={classes.withrawIdBox}>
              {walletNumber && (
                <div className={classes.walletId}>
                  WALLET ID: {walletNumber}
                </div>
              )}
              <button className={classes.withdrawalType}>
                select a withdrawal method
              </button>
            </div>
            <div className={classes.topupBox}>
              <div className={classes.topupTitle}>SELECT TOP UP METHOD</div>
              <div className={classes.topupContent}>
                {topupMethod != null && topupMethod.length > 0 ? (
                  topupMethod.map((value, key) => {
                    return (
                      <div
                        className={`drawer-body ${classes.topupOption}`}
                        key={key}
                        onClick={toggleDrawer("right", true)}
                        id={value.id}
                      >
                        <div className={classes.imgWrapper}>
                          <img src={value.url} alt="" className={classes.img} />
                        </div>
                        <div className={classes.topupName}>{value.name}</div>
                      </div>
                    );
                  })
                ) : (
                  <div className={classes.noTopup}>
                    No default top up method
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          {["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              {withdrawalInformation && withdrawalInformation.length > 0 ? (
                <SwipeableDrawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                  PaperProps={{
                    sx: {
                      height: "470px",
                      width: "400px",
                      top: "25%",
                      left: "40%",
                      borderRadius: "10px",
                      padding: "10px",
                    },
                  }}
                >
                  <div className={classes.modalContainer}>
                    <h4 className={classes.modalTitle}>Withdrawal</h4>

                    <div className={classes.modalWrapper}>
                      <div className={classes.amountInputBox}>
                        <label
                          htmlFor="topupBox"
                          className={classes.inputLabel}
                        >
                          Minimum Withdrawal Amount: <b>{minimumWithdrawal}</b>
                        </label>
                        <div className={classes.inputNairaBox}>
                          <span className={classes.currencyIcon}>₦</span>
                          <input
                            type="number"
                            id="topupBox"
                            onChange={handleinputChange}
                            className={classes.amountInput}
                          />
                        </div>
                      </div>
                      <div className={classes.paymentInfoTitle}>
                        Select Payment Information:
                      </div>
                      <div className={classes.acctWrap}>
                        {withdrawalInformation.map((value, key) => {
                          return (
                            <div
                              className={`pm-wrap addrress-wrap d-flex flex-colum ${classes.acctBox}`}
                              key={key}
                            >
                              <div
                                className="d-flex justify-content-between"
                                style={{
                                  alignItems: "center",
                                  position: "relative",
                                }}
                              >
                                <div
                                  className={`d-flex flex-column with-payment-info   ${
                                    isClicked[key] ? "pm-selected" : ""
                                  }`}
                                  onClick={handleClick(key)}
                                  id={value.id}
                                >
                                  <div className="">
                                    Account Name:
                                    <b>{value?.full_name}</b>
                                  </div>
                                  <div className="">
                                    Account Number:
                                    <b>{value?.account_number}</b>
                                  </div>
                                  <div className="">
                                    Bank Name :<b>{value?.bank_name}</b>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <button
                        disabled={
                          !(
                            amount >= minimumWithdrawal &&
                            paymentMethodId &&
                            withdrawalInformationId
                          )
                        }
                        className={`${
                          amount >= minimumWithdrawal &&
                          paymentMethodId &&
                          withdrawalInformationId
                            ? classes.transBtnActive
                            : classes.transBtnBlur
                        } `}
                        onClick={withdrawalToggleDrawer("bottom", true)}
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </SwipeableDrawer>
              ) : (
                <SwipeableDrawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                  PaperProps={{
                    sx: {
                      height: "350px",
                      width: "400px",
                      top: "25%",
                      left: "40%",
                      borderRadius: "10px",
                      padding: "10px",
                    },
                  }}
                >
                  <div className={classes.modalContainer}>
                    <h4 className={classes.modalTitle}>Withdrawal</h4>

                    <div className={classes.modalWrapper}>
                      <div className={classes.amountInputBox}>
                        <label
                          htmlFor="topupBox"
                          className={classes.inputLabel}
                        >
                          Minimum Withdrawal Amount: <b>{minimumWithdrawal}</b>
                        </label>
                        <div className={classes.inputNairaBox}>
                          <span className={classes.currencyIcon}>₦</span>
                          <input
                            type="number"
                            id="topupBox"
                            onChange={handleinputChange}
                            className={classes.amountInput}
                          />
                        </div>
                      </div>
                      <div className={classes.paymentInfoTitle}>
                        Select Payment Information:
                      </div>
                      <div className={classes.emptyBankList}>
                        Bank Information List is empty
                      </div>
                      <button
                        disabled={
                          !(
                            amount >= minimumWithdrawal &&
                            paymentMethodId &&
                            withdrawalInformationId
                          )
                        }
                        className={`${
                          amount >= minimumWithdrawal &&
                          paymentMethodId &&
                          withdrawalInformationId
                            ? classes.transBtnActive
                            : classes.transBtnBlur
                        } `}
                        onClick={() => {
                          withdrawalToggleDrawer("bottom", true);
                          {
                            toggleDrawer(anchor, false);
                          }
                        }}
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </SwipeableDrawer>
              )}
            </React.Fragment>
          ))}
        </div>
          <div className={classes.paymentDetailsWrapper}>
          {["bottom"].map((anchor) => (
            <React.Fragment key={anchor}>
              <SwipeableDrawer
                anchor={anchor}
                open={withdrawalState[anchor]}
                onClose={withdrawalToggleDrawer(anchor, false)}
                onOpen={withdrawalToggleDrawer(anchor, true)}
                ModalProps={{
                  onBackdropClick: withdrawalToggleDrawer(anchor, true),
                }}
                PaperProps={{
                  sx: {
                    height: "300px",
                    top: "5%",
                    borderRadius: "10px",
                    width: "400px",
                    margin: "auto",
                    padding: "10px",
                  },
                }}
              >
                <div className={classes.paymentDrawerBox}>
                  <h4 className={classes.paymentPin}>Enter Payment PIN</h4>
                  <div className={classes.paymentAmountBox}>
                    You are about to withdraw
                    <span className={classes.paymentAmount}>
                      {symbol}
                      {amount}
                    </span>
                    from your wallet
                  </div>

                  {error && (
                    <div className={classes.errorBox}>
                      {error} {link && <Link to={link}>{linkTitle}</Link>}
                    </div>
                  )}
                  <div id="otp" className={classes.paymentInputContainer}>
                
                    <input
                      className={classes.passwordInput}
                      value={password}
                      type="password"
                      id="password"
                      maxLength="6"
                      placeholder="Enter payment Pin"
                      onChange={handleSubmit}
                    />
                  </div>
                  <button onClick={handleWithdrawal} className={classes.payNow}>Pay Now</button>
                  {isWalletPasswordSet && (
                    <Link
                      to="/members/wallet/settings"
                      className={classes.passwordLink}
                    >
                      Forget PIN?
                    </Link>
                  )}

                  {!isWalletPasswordSet && (
                    <Link
                      to="/members/wallet/settings"
                      className={classes.passwordLink}
                    >
                      Set new PIN
                    </Link>
                  )}
                </div>
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </div>
      </React.Fragment>
    );
}

export default Withdraw;