import React from 'react';
import classes from './LoginNavbar.module.css';
import logo from "../../assets/checkstall RED.png";
import { useNavigate } from 'react-router';

const LoginNavbar = ({title}) => {
  const navigate = useNavigate();
  return (
    <div className={classes.loginNavWrapper}>
      <div className={classes.loginNavbar}>
        <div className={classes.loginLogoBox}>
          <div className={classes.loginLogo} onClick={() => navigate('/')}>
            <img src={logo} alt="logo" />
          </div>
          <span className={classes.navTitle}>{title}</span>
        </div>
        <span className={classes.navLoginHelp}>Need help?</span>
      </div>
    </div>
  );
}

export default LoginNavbar
