import React, { Component } from 'react';
import checkstallRED from '../../assets/images/checkstallRED.png';
import { Link } from 'react-router-dom';

class Logo extends Component {
  render() {
    return (
      <div className="" style={{maxWidth: '14%'}}>
        <Link to="/">
          <img style={{cursor:'pointer' ,maxWidth: '71%', paddingTop: '2%', paddingBottom: '2%'}} src={checkstallRED} alt="Logo"/>
        </Link>
      </div>
    )
  }
}
export default Logo;



