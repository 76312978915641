export const parcelData = [
  {
    parcel_number: 11234,
    date: "06-05-2023",
    customer_name: "chuka obodozie",
    customer_number: 12345678910,
    weight_in_kg: 10,
    weight_in_cbm: 15,
    amount_per_kg: 20,
    amount_per_cbm: 30,
    local_shipping: 200,
    receiver: "emmanuel",
    total_package: 50,
    international_freight: 1000,
    custom_clearance: 3000,
    status: "arrived",
  },
  {
    parcel_number: 23145670,
    date: "06-05-2023",
    customer_name: "chuka obodozie",
    customer_number: 12345674328,
    weight_in_kg: 10,
    weight_in_cbm: 15,
    amount_per_kg: 20,
    amount_per_cbm: 30,
    local_shipping: 200,
    receiver: "emmanuel",
    total_package: 50,
    international_freight: 1000,
    custom_clearance: 3000,
    status: "processing",
  },
  {
    parcel_number: 32346790,
    date: "06-05-2023",
    customer_name: "chuka obodozie",
    customer_number: 1234567654,
    weight_in_kg: 10,
    weight_in_cbm: 15,
    amount_per_kg: 20,
    amount_per_cbm: 30,
    local_shipping: 200,
    receiver: "emmanuel",
    total_package: 50,
    international_freight: 1000,
    custom_clearance: 3000,
    status: "delivered",
  },
  {
    parcel_number: 4567893,
    date: "06-05-2023",
    customer_name: "chuka obodozie",
    customer_number: 12345675890,
    weight_in_kg: 10,
    weight_in_cbm: 15,
    amount_per_kg: 20,
    amount_per_cbm: 30,
    local_shipping: 200,
    receiver: "emmanuel",
    total_package: 50,
    international_freight: 1000,
    custom_clearance: 3000,
    status: "arrived",
  },
  {
    parcel_number: 512345678,
    date: "06-05-2023",
    customer_name: "chuka obodozie",
    customer_number: 12345676548,
    weight_in_kg: 10,
    weight_in_cbm: 15,
    amount_per_kg: 20,
    amount_per_cbm: 30,
    local_shipping: 200,
    receiver: "emmanuel",
    total_package: 50,
    international_freight: 1000,
    custom_clearance: 3000,
    status: "not recived",
  },
];