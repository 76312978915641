import React,{useEffect} from "react";
import {useSearchParams} from 'react-router-dom';
import "../../../assets/css/create-order.css";
import { Seo } from '../../utilities/Seo';
import useCheckAuthWithRedirect from "../../../hooks/useCheckAuthWithRedirect";
import SingleOrder from './SingleOrder';
import MultipleOrder from './MultipleOrder';
import getLocalStorage from "../../functions/getLocalStorage";
import { decryptData } from "../../functions/DataSecurity";








function CreateOrder (){
  //check for authentication on the page
  useCheckAuthWithRedirect();

  const [searchParams] = useSearchParams();
  const source = searchParams.get('s');

  const order = decryptData(getLocalStorage('orderItem'));

  const isSingleOrder = (source === 'detail' && order !== null) ? true : false;
  const isMultipleOrder = (source === 'cart' && order !== null) ? true : false;



//set page title
useEffect(() => {
  const pageTitle =  "Confirm Order || Checkstall.com";
  Seo({
    title: pageTitle,
    metaDescription: 'Confirm Order',
  });
}, []);

    return(
        <React.Fragment>
            {isSingleOrder && 
               <SingleOrder order={order}/>
            }

            {isMultipleOrder && 
               <MultipleOrder order={order}/>
              
            } 
            
          
        </React.Fragment>
       
    )
}

export default CreateOrder;