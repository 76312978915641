import React, { useState } from 'react';
import styled from 'styled-components';
import { SideBarData } from './SideBarData';
import SubMenu from './SubMenu';
import { IconContext } from 'react-icons/lib';

// const Nav = styled.div`
//   // background: #15171c;
//   height: 80px;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   background-color: #cf0000;
// `;

// const NavIcon = styled(Link)`
//   margin-left: 2rem;
//   font-size: 2rem;
//   height: 80px;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
// `;


const SidebarNav = styled.nav `
    background: #fff;
    height: 70vh; 
    width: ${({ sidebar }) => (sidebar ? '48px' : '279px')};
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: inline-div;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    /* position: fixed; */
    top: 0;
    left: 0;
    -webkit-transition: 350ms;
    transition: 350ms;
    z-index: 10;
    overflow: hidden;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);

  //const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }} className="dView">
      
        <SidebarNav sidebar={sidebar} >
          <SidebarWrap>
            
            {SideBarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;
