import React, { useEffect } from 'react';
import classes from './ShoppingGuide.module.css';
import { Seo } from '../../utilities/Seo';
import Navbar from '../../navbar/Navbar';
import { guideData } from './guideData';
import { Link } from 'react-router-dom';

const ShoppingGuide = () => {

   useEffect(() => {
     Seo({
       title:
         "Shopping Guide || Checkstall shopping guide on how to make purchase from checkstall",
       metaDescription:
         "Checkstall buy items ranging from Electronics, footwares, baby wares and many more from around the globe and get it shipped to your doorstep in Africa",
     });
   }, []);
  return (
    <>
      <Navbar />
      <main className={classes.wrapper}>
        <section className={classes.container}>
          <h2 className={classes.title}>How to Buy</h2>
          <div className={classes.detailsContainer}>
            {guideData.map((item) => {
              return (
                <div
                  key={item.id}
                  className={`${classes.detailBox} ${
                    item.noMargin && classes.noMargin
                  }`}
                >
                  <span>{item.id}</span>
                  <div className={classes.imgBox}>
                    <img src={item.imageuRL} />
                  </div>
                  <p>{item.guide}</p>
                </div>
              );
            })}
          </div>
          <div className={classes.linkBox}>
            <Link to="/howtobuy/shopping-notice">
              Also read our shopping notice
            </Link>
            <Link to="/howtobuy" className={classes.backLink}>
              Go back
            </Link>
          </div>
        </section>
      </main>
    </>
  );
}

export default ShoppingGuide
