import React, { useRef, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import bannerImg from "../../assets/images/bannerImg.png";
import "../../assets/css/login.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Seo } from "../utilities/Seo";
import Spinner from "../utilities/Spinner";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import setLocalStorage from "../functions/setLocalStorage";
import useCart from "../../hooks/useCart";
import { setCookie } from "../functions/CustomCookies";
import { FcGoogle } from "react-icons/fc";
import LoginNavbar from "../loginNavbar/LoginNavbar";
import { VisibilityOff, Visibility,Person , Lock } from '@mui/icons-material';
import Encryption from '../functions/Encryption';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


const LOGIN_URL = "/v1/auth/login";

const Login = () => {
  const [modalShow, setModalShow] = useState(false);


  const navigate = useNavigate();
  const location = useLocation();

  const userRef = useRef();
  const errRef = useRef();

  const [user, setUsername] = useState('');
  const [pass, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState("");  

  const { setAuth, persist, setPersist } = useAuth();

  const {setCart} = useCart();
  const [passwordVisible, setPasswordVisible] = useState(false);

  let from =  "";
  const path = location.state?.from?.pathname;
  if(path){
     let query = location.state?.from?.search || "";
     if(query){
        from = path + query;
     }else{
       from = path;
     }

  }else{
     from = "/";
  }
 

  useEffect(() => {
    Seo({
      title: 'Login',
      metaDescription: 'Checkstall Login page'
    });
  }, []);



useEffect(() => {
    setErrMsg('');
}, [user, pass])



const submitHandler = async (e) => {
  setModalShow(true);
  e.preventDefault();
  let encryption = new Encryption();
  let username = user ;
  let password = encryption.encrypt(pass, encryption.key);

  try {
    const response = await axios.post(LOGIN_URL,
        JSON.stringify({ username, password }),
    );

    if(parseInt(response?.data?.code) === 3001){
       setErrMsg(response.data.message);
       errRef.current.focus();
    }else{
        const accessToken = response?.data?.accessToken;
        const _user = response?.data?.user;
        const cartTotal = response?.data?.cartTotal;
    
        //set authenticated user basic infor into local storage
        

        if(persist){
          setLocalStorage('user', JSON.stringify(_user));
          setLocalStorage('cartTotal', cartTotal);
          setLocalStorage('auth', JSON.stringify({accessToken:accessToken,}));
        }else{
           setCookie('user', _user, 43800);
           setCookie('cartTotal', cartTotal, 43800);
           setAuth({
              accessToken:accessToken,
           });
        }
        
        
        setCart(cartTotal);

        setUsername('');
        setPassword('');

        navigate(from, { replace: true });
    }
    
    setModalShow(false);
}catch(err){
        //console.log(err.response);     
  }
  setModalShow(false);
}

const toggleVisibility = () =>{
  let el = document.getElementsByClassName("passwordInput");
  if(!passwordVisible){
      el[0].type = "text";
      setPasswordVisible(true);
  }else{
      el[0].type = "password";
      setPasswordVisible(false);
  }
}

useEffect(() => {
  setLocalStorage("persist", persist);
}, [persist]);

const handleChange =(event) =>{
  setPersist(event.target.checked);
}

  return (
    <>
      <Spinner cssClass={"semi-dark-bg"} show={modalShow} />
      {
        <div className="loginWrapper">
          <LoginNavbar title="log in" />

          <div className="login-body">
            <div className="login-body-conatiner">
              <div className="logImg-box mobily">
                <Link to="/" title="Go Home">
                  <img src={bannerImg} alt="bannerImg" />
                </Link>
              </div>
              <div className="loginForm">
                <Form className="formLog" onSubmit={submitHandler}>
                  <p
                    ref={errRef}
                    className={errMsg ? "errmsg" : "offscreen"}
                    aria-live="assertive"
                  >
                    {errMsg}
                  </p>
                  <h4 className="login-title">Log In</h4>
                  {/* <br /> */}
                  <Form.Group size="lg" controlId="email" style={{position:'relative'}}>
                    <span className="login-addon"><Person /></span>
                    <Form.Control
                      type="text"
                      value={user}
                      ref={userRef}
                      required                
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="Enter username/email/phone number"
                      style={{paddingLeft:'2.5rem'}}

                    />
                  </Form.Group>
                  <br />
                  <Form.Group size="lg" controlId="password" style={{position:'relative'}}>
                  <span className="login-addon"><Lock /></span>
                    <Form.Control
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={pass}
                      placeholder="Password"
                      required
                      style={{paddingLeft:'2.5rem'}}
                      className="passwordInput"
                    />
                    <span className="password-visibility" onClick={toggleVisibility}>{passwordVisible ? <VisibilityOff /> : <Visibility /> }</span>
                  </Form.Group>
                  {/* <br /> */}
                  <div className="d-flex justify-content-between">
                  <div className="d-flex justify-content-start">
                      <FormControlLabel
                        label="Remember Me"
                        control={
                          <Checkbox
                            checked={Boolean(persist)}
                            color="error"
                            onChange={handleChange}
                          />
                        }
                      />
                  </div>
                    <Link to="/account-security/change-password" className="forgotP">
                      Forgot Password
                    </Link>
                  </div>
                  <br />
                  <div className="LogButton">
                    <button
                      style={{ background: "#cf0000", color: "#ffffff" }}
                      className="btn mobileBtn"
                    >
                      Log In
                    </button>
                  </div>
                  {/* <br /> */}
                  {/* <div className="LogButton">
                    <Link to="/signup" className="mobileBtn signup">
                      <button
                        style={{
                          backgroundColor: "#ffffff",
                          textColor: "#000",
                          border: "1px solid #cf0000",
                        }}
                        className="btn mobileBtn "
                      >
                        Sign Up
                      </button>
                    </Link>
                  </div> */}
                  {/* <br /> */}
                  <div className="or-box">
                    <hr className="or-line" />
                    <span className="or">OR</span>
                    <hr className="or-line" />
                  </div>
                  {/* <br /> */}
                  <div className="google">
                    <FcGoogle size={25} />
                    <span>Google</span>
                  </div>
                  <br />
                  <p className="signup-link-box">
                    <span>New to Checkstall?</span>
                    <Link to="/signup" className="signup-page-link">
                      sign up
                    </Link>&nbsp;
                  </p>
                </Form>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default Login;
