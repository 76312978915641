import React, { useEffect, useState } from "react";
import "../../../assets/css/settings.css";
import { useNavigate, useLocation } from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Form from "react-bootstrap/Form";
import { Seo } from "../../utilities/Seo";
import Select from "react-select";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Spinner from "../../utilities/Spinner";

const BANKS_URL = "/v1/helpers/get-all-banks";
const ADD_INFO_URL = "/v1/withdraw-information/add-information";
const GET_INFORMATION = "/v1/withdraw-information/get-information";

function WithdrawalInformation() {
  const [modalShow, setModalShow] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const axiosPrivate = useAxiosPrivate();

  const [err, setErr] = useState({});

  const [full_name, setFullName] = useState("");
  const [account_number, setAccountNumber] = useState("");
  const [bank_id, setBankId] = useState(0);
  const [banks, setBanks] = useState([]);

  const [withdrawalInformation, setWithdrawalInformation] = useState([]);
  const [reloadOnUpdate, setReloadOnUpdate] = useState(false);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (!event) {
      getWithdrawalInfortion();
    }

    //incase user clicked on address detail before clicking add new set empty the state
    if (event && event.target.id == "addNew") {
      setFullName("");
      setBankId("");
      setAccountNumber("");
    }

    setState({ ...state, [anchor]: open });
  };

  //set page title
  useEffect(() => {
    const pageTitle = "Withdrawal information || Checkstall.com";
    Seo({
      title: pageTitle,
      metaDescription: " Withdrawal information",
    });
  }, []);

  useEffect(() => {
    getBanks();
  }, [axiosPrivate]);

  const getBanks = async () => {
    setModalShow(true);
    try {
      const response = await axiosPrivate.get(BANKS_URL);
      setBanks(response?.data?.banks);
    } catch (err) {
      if (err?.response?.data?.status === 401) {
        navigate("/login", { state: { from: location }, replace: true });
      }
    }

    setModalShow(false);
  };

  const getValues = (e, key) => {
    if (key === "full_name") {
      setFullName(e?.target?.value);
      if (e.target.value) {
        setErr((preValue) => {
          return {
            ...preValue,
            full_name: "",
          };
        });
      } else {
        setErr((preValue) => {
          return {
            ...preValue,
            full_name: "Receiver's Full Name cannot be empty",
          };
        });
      }
    } else if (key === "account_number") {
      setAccountNumber(e?.target?.value);
      if (e.target.value) {
        setErr((preValue) => {
          return {
            ...preValue,
            account_number: "",
          };
        });
      } else {
        setErr((preValue) => {
          return {
            ...preValue,
            account_number: "Receiver's account number cannot be empty",
          };
        });
      }
    } else if (key === "bank_id") {
      setBankId(e?.value);
      if (e.value) {
        setErr((preValue) => {
          return {
            ...preValue,
            bank_id: "",
          };
        });
      } else {
        setErr((preValue) => {
          return {
            ...preValue,
            bank_id: "Please Select a Bank",
          };
        });
      }
    }
  };

  const addInformation = () => {
    const controller = new AbortController();

    const doAddInformation = async () => {
      setModalShow(true);
      try {
        let info = { full_name, account_number, bank_id };
        const response = await axiosPrivate.post(
          ADD_INFO_URL,
          JSON.stringify(info),
          {
            signal: controller.signal,
          }
        );

        if (response?.data.message === "ok") {
          setFullName("");
          setAccountNumber("");
          setBankId("");
          setBanks([]);

          setState({ ...state, ["right"]: false });
          getWithdrawalInfortion();
        }
      } catch (err) {
        if (err?.response?.data?.status === 401) {
          navigate("/login", { state: { from: location }, replace: true });
        } else {
        }
      }

      setModalShow(false);
    };

    doAddInformation();

    return () => {
      controller.abort();
    };
  };

  const handleAddInformation = (event) => {
    event.preventDefault();
    if (!full_name) {
      setErr((preValue) => {
        return {
          ...preValue,
          full_name: "Receiver's Full Name cannot be empty",
        };
      });
    } else if (!account_number) {
      setErr((preValue) => {
        return {
          ...preValue,
          account_number: "Receiver's account number cannot be empty",
        };
      });
    } else if (!bank_id) {
      setErr((preValue) => {
        return {
          ...preValue,
          bank_id: "Please Select a Bank",
        };
      });
    } else if (err?.full_name || err?.account_number || err?.bank_id) {
      return;
    } else {
      //submit form
      addInformation();
    }
  };

  useEffect(() => {
    getWithdrawalInfortion();
  }, [reloadOnUpdate]);

  const getWithdrawalInfortion = () => {
    const controller = new AbortController();

    const doGetInformation = async () => {
      setModalShow(true);
      try {
        const response = await axiosPrivate.get(GET_INFORMATION, {
          signal: controller.signal,
        });

        setWithdrawalInformation(response?.data?.message);
        setReloadOnUpdate(false);
      } catch (err) {
        if (err?.response?.data?.status === 401) {
          navigate("/login", { state: { from: location }, replace: true });
        }
      }

      setModalShow(false);
    };

    doGetInformation();

    return () => {
      controller.abort();
    };
  };

  return (
    <React.Fragment>
      <Spinner cssClass={"semi-dark-bg"} show={modalShow} />
      <div className="account-settings-wrapper">
        <div className="account-settings">
          <h4 className="account-settings-title">
            your withdrawal information
          </h4>
          <div className="account-settings-map-container">
            {withdrawalInformation && withdrawalInformation.length > 0 ? (
              withdrawalInformation.map((value, key) => {
                return (
                  <div className="addrress-wrap" key={key} id={value.id}>
                    <div className="settings-item">
                      <div className="">
                        Account Name:
                        <b>{value?.full_name}</b>
                      </div>
                      <div className="">
                        Account Number:
                        <b>{value?.account_number}</b>
                      </div>
                      <div className="">
                        Bank Name :<b>{value?.bank_name}</b>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="empty-information">
                Your Bank Information List is empty
              </div>
            )}
          </div>
        </div>

        <div className="log-out-wrap add-new-add d-flex justify-content-center">
          <button id="addNew" onClick={toggleDrawer("right", true)}>
            Add Bank Information
          </button>
        </div>
      </div>

      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            PaperProps={{
              sx: {
                height: "420px",
                width: "400px",
                top: "25%",
                left: "40%",
                borderRadius: "10px",
                padding: "16px",
              },
            }}
          >
            <div className="add-acct-wrapper">
              <h3 className="add-acct-title">add an account</h3>
              <Form onSubmit={handleAddInformation} className="add-acct-form">
                <div className="add-acct-name-box">
                  <label className="setting-name-label">Account Name</label>
                  <input
                    type="text"
                    value={full_name}
                    onChange={(e) => getValues(e, "full_name")}
                    className="setting-name-input"
                  />
                  <div className="regErr">{err?.full_name}</div>
                </div>
                <div className="add-acct-name-box">
                  <label className="setting-name-label">Account Number</label>
                  <input
                    type="text"
                    value={account_number}
                    onChange={(e) => {
                      getValues(e, "account_number");
                    }}
                    className="setting-name-input"
                  />
                  <div className="regErr">{err?.account_number}</div>
                </div>
                <div className="select-bank-box">
                    <Select
                      options={banks}
                      defaultValue={banks[bank_id]}
                      placeholder="Select Bank"
                      onChange={(e) => getValues(e, "bank_id")}
                    />
                  <div className="regErr">{err?.bank_id}</div>
                </div>
              </Form>
                <button onClick={handleAddInformation} className="add-acct-btn">Add</button>
            </div>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
export default WithdrawalInformation;
