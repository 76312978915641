import React, { useState }  from 'react';
import classes from './Tools.module.css';
import { Link } from 'react-router-dom';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const Tools = () => {
   const [open, setOpen] = useState(false);
  return (
    <div className={classes.wrapper}>
      <div className={classes.accountBox} onClick={() => setOpen(!open)}>
        <span>tools</span>
        {open ? <FaAngleUp size={20} /> : <FaAngleDown size={20} />}
      </div>
      <div className={`${classes.options} ${open && classes.openBox}`}>
        <Link
          to="/freightcalculator"
          className={classes.option}
          onClick={() => setOpen(!open)}
        >
          freight
        </Link>
        <Link
          to="/freightcalculator"
          className={classes.option}
          onClick={() => setOpen(!open)}
        >
          calculation
        </Link>
        <Link
          to="/sizecomparison"
          className={classes.option}
          onClick={() => setOpen(!open)}
        >
          size comparison
        </Link>
      </div>
    </div>
  );
}

export default Tools
