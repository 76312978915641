import React, { useContext,useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavDropdown } from "react-bootstrap";
import { NavDropdownMenu } from "react-bootstrap-submenu";
import "../../assets/css/NavBar.css";
import "react-bootstrap-submenu/dist/index.css";
import Logo from './Logo';
import useAuth from "../../hooks/useAuth";
import useCart from "../../hooks/useCart";
import { Link } from 'react-router-dom';
import useLogout from "../../hooks/useLogout";
//import getLocalStorage from "./getLocalStorage";
import { getCookie } from '../functions/CustomCookies';
import Spinner from "../utilities/Spinner";




const NavBar = () => {
  const {cart} = useCart();
  const {auth} = useAuth();
  const [modalShow, setModalShow] = useState(false);
  const persistUser = getCookie('user');
  const logout = useLogout();

  const signOut = async () => {
    setModalShow(true);
      await logout();
      setModalShow(false);
  }

  return(
    <>
      
    <Navbar fixed="top" collapseOnSelect expand="lg" className="navP">
     
      {/* <Navbar.Brand href="/">CHECKSTALL</Navbar.Brand> */}
      <Logo />
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="mr-auto">
               <Nav.Link href="/cart">Cart (<span>{cart || 0}</span>)</Nav.Link>
      </Nav>
        <Nav className="mr-auto">
          {/* <Nav className="mr-auto" style={{zIndex:'1000'}}>
              <NavDropdownMenu title="Currency" id="collasible-nav-dropdown">
                  <NavDropdown.Item className="dropLink" href="/">$ USD</NavDropdown.Item>
                  <NavDropdown.Item className="dropLink" href="/">₦ NGN</NavDropdown.Item>
                  <NavDropdown.Item className="dropLink" href="/">¥ CNY</NavDropdown.Item>
              </NavDropdownMenu>
          </Nav>         */}
          <Nav.Link href="/help-center">Help Center</Nav.Link>
        </Nav>
        {persistUser ? (
          <>
            <Nav className="mr-auto">
              <Link 
                style={{textDecoration:'none'}}
                to="/account">Hi!( {persistUser?.username } )
              </Link>
            </Nav>
            <Nav className="mr-auto">
              <div onClick={signOut} className="logOut">Log Out</div>
            </Nav>
          </>
          
        ):(
          <>
            <Nav className="mr-auto">
              <Nav.Link href="/login">Log In</Nav.Link>
            </Nav>
            <Nav className="mr-auto">
               <Nav.Link href="/signup">Signup</Nav.Link>
            </Nav>
          </>
        )}
      </Navbar.Collapse>
    </Navbar>
    
  </>
  )
};

export default NavBar;
