import React from "react";
import "../../assets/css/footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="">
      <div
        className="footer-widget-area bg-light4"
        style={{
          paddingTop: "30px",
          paddingBottom: "20px",
          paddingLeft: "22px",
          paddingRight: "22px",
        }}
      >
        <div className="container">
          <div className="row footContent">
            <div className="row col-12 col-sm-12 col-md-12 col-lg-12 mb-sm-40 footCon">
              <div className="col-6 col-sm-16 col-md-6 col-lg-2 mb-sm-40">
                <div className="footer-widget">
                  <h5 className="widget-title mb-10 text-white">INFORMATION</h5>
                  <div className="widget-content mt-20">
                    <ul className="list-unstyled picker">
                      <li>
                        <Link className="text-white" to="/">
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link className="text-white" to="/">
                          Customer Deck
                        </Link>
                      </li>
                      <li>
                        <Link className="text-white" to="/">
                          How to use our platform?
                        </Link>
                      </li>
                      <li>
                        <Link className="text-white" to="/checkstall-partner">
                          Affliate Program
                        </Link>
                      </li>
                      <li>
                        <Link className="text-white" to="/">
                          Terms and Condition
                        </Link>
                      </li>
                      <li>
                        <Link className="text-white" to="/">
                          Registration
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* <div className="col-6 col-sm-16 col-md-6 col-lg-2 mb-sm-40">
                            <div className="footer-widget">
                                <h5 className="widget-title mb-10 text-white">HELP CENTER</h5>
                                <div className="widget-content mt-20">
                                    <ul className="list-unstyled picker">
                                        <li><Link className="text-white" to="/">Customer Deck</Link></li>
                                        <li><Link className="text-white" to="/">How to use our platform?</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-6 col-sm-16 col-md-6 col-lg-3 mb-sm-40">
                            <div className="footer-widget">
                                <h5 className="widget-title mb-10 text-white">ABOUT</h5>
                                <div className="widget-content mt-20">
                                    <ul className="list-unstyled picker">
                                        <li><Link className="text-white" to="/">About Us</Link></li>
                                        <li><Link className="text-white" to="/">Terms and Condition</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}

              <div className="col-6 col-sm-16 col-md-6 col-lg-3 mb-sm-40">
                <div className="footer-widget">
                  <h5 className="widget-title mb-10 text-white">PARTNERS</h5>
                  <div className="widget-content mt-20">
                    <ul className="list-unstyled picker">
                      <li>
                        <Link className="text-white" to="www.alibaba.com">
                          Alibaba Group
                        </Link>
                      </li>
                      <li>
                        <Link className="text-white" to="www.1688.com">
                          1688
                        </Link>
                      </li>
                      <li>
                        <Link className="text-white" to="www.taobao.com">
                          Taobao
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-6 col-sm-16 col-md-6 col-lg-3 mb-sm-40">
                <div className="footer-widget">
                  <h5 className="widget-title mb-10 text-white">CONTACT</h5>
                  <div className="widget-content mt-20">
                    <ul className="list-unstyled picker">
                      <li>
                        <Link className="text-white">
                          Nigeria: +234 705 190 5357
                        </Link>
                      </li>
                      <li>
                        <Link className="text-white">
                          China: +86 138 6492 7809
                        </Link>
                      </li>
                      <li>
                        <Link className="text-white">
                          WhatsApp: +234 705 190 5357
                        </Link>
                      </li>
                      <li>
                        <Link className="text-white">
                          WhatsApp: +86 138 6492 7809
                        </Link>
                      </li>
                      <li>
                        <Link className="text-white">
                          Support Email: support@checkstall.com
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright bg-light4 " style={{ padding: "10px" }}>
        <div className="container">
          <div className="row copySpace">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-sm-40 copyCopy">
              <p className="mb-0 text-white">
                {" "}
                &copy; Copyright. Checkstall Technology Solution Limited{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
