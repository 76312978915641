import axios from "./axios"

const TERMII_URL = "https://termii.com/"

const throwError = () => {
	const errorMessage = `Kindly visit ${TERMII_URL} to get your api key`
	if (typeof window !== "undefined") {
		//alert(errorMessage)
	} else {
		//console.log(errorMessage)
	}
}

const Termii = () => {

	const send_sms = (endpoint,to, message, from, channel = "generic") => {

			//Send POST Request to Termii's API
			fetch(endpoint, {
				method: "POST",
				body: JSON.stringify({
					to,
					sms: message,
					from,
					channel,
				}),
				headers: {
					"Content-Type": "Application/json",
					Accept: "Application/json",
				},
			})
				.then(res => res.json())
				.then(data => {
					return data
				})
				.catch(error => {
					return error
				})
		
	}

	const send_otp = async (to) => {
			try {
				const response = await axios.post('/v1/api/api-send-otp-register', 
					JSON.stringify({to}),
				);
		
				return response?.data;
			} catch (error) {
				return error;
			}
	}

	const send_verification_otp = async (to) => {
		try {
			const response = await axios.post('/v1/api/api-send-verification-otp', 
				JSON.stringify({to}),
			);
	
			return response?.data;
		} catch (error) {
			return error;
		}
}

	const send_otp_email = async (email) => {
		try {
			const response = await axios.post('/v1/api/api-send-email-otp', 
				JSON.stringify({email}),
			);
	
			return response?.data;
		} catch (error) {
			return error;
		}
	}

	const send_verify_code = async (endpoint,email) => {
		try {
			const response = await axios.post(endpoint, 
				JSON.stringify({email}),
			);
	
			return response?.data;
		} catch (error) {
			return error;
		}
	}

	const verify_otp = async (pinId, pin) => {
		try {
			const response = await axios.post('/v1/api/api-verify-otp', 
				JSON.stringify({pinId, pin}),
			);
	
			return response?.data;
		} catch (error) {
			return error;
		}
		
	}

	const verify_email_otp = async (code) => {
		try {
			const response = await axios.post('/v1/api/api-verify-email-otp', 
				JSON.stringify({code}),
			);
	
			return response?.data;
		} catch (error) {
			return error;
		}
		
	}



	return {
		send_sms,
		send_otp,
		verify_otp,
		send_otp_email,
		verify_email_otp,
		send_verify_code,
		send_verification_otp,
	}
}

export default Termii
