import React, {useEffect, useState} from 'react';
import { Link,useNavigate,useSearchParams } from 'react-router-dom';
import '../../../assets/css/payment.css';
import { Seo } from '../../utilities/Seo';
import bulkOrderBanner from '../../../assets/images/bulkOrderBanner.png';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useCheckAuthWithRedirect from "../../../hooks/useCheckAuthWithRedirect";
import Spinner from "../../utilities/Spinner";
import { RoundToTwo } from "../../utilities/RoundToTwo";
import Countdown from "react-countdown";
import getLocalStorage from '../../functions/getLocalStorage';
import { Exchanger } from '../../functions/Helpers';
import useGlobalVariable from '../../../hooks/useGlobalVariable';
import useCurrencyRate from '../../../hooks/useCurrencyRate';
import { Box, Modal } from '@mui/material';
import ProcessPayment from '../dajupay/ProcessPayment';
import { decryptData} from "../../functions/DataSecurity";
import setLocalStorage from '../../functions/setLocalStorage';


function Payment(){

  const [modalShow, setModalShow] = useState(false);
  const [isWalletPasswordSet, setIsWalletPasswordSet] = useState(null);
  const [isHovering, setIsHovering] = useState({});
  const [isClicked, setIsClicked] = useState({['0']:true});
  const [searchParams] = useSearchParams();
  const [orderInfo, setOrderInfo] = useState({});
  const [bulkOrderInfo, setBulkOrderInfo] = useState({});
  const [isOrderAvailable, setIsOrderAvailable] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState({});
  const [totalBulkOrder, setTotalBulkOrder] = useState(0);
  const [totalPayable, setTotalPayable] = useState(0);
  const [titleList, setTitleList] = useState([]);
  const {defaultCurrency} = useGlobalVariable();
  const {currency, symbol} =useCurrencyRate();
  const [openPay, setOpenPay] = useState(null);



  const orderId = searchParams.get('orderId');
  const orderIdList = getLocalStorage('orderIdList') ? decryptData(getLocalStorage('orderIdList')) : [];
  const actionType = searchParams.get('actionType');
  const fromType = searchParams.get('fromType');

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();



    const handleOpenModal = (event) => {
      let key = event.target.closest("#pm-cover")?.querySelector(".pm-wrap")?.id;
      let payment_method_id =  paymentMethod[key]?.id;

      let orderData = {};
      let link = "";
      if(actionType == "bulkOrderPayment"){
         orderData = {
          payment_method:payment_method_id,
          title: titleList,
          order_number: orderIdList,
          total_amount:totalPayable,
          action_type:"bulkOrderPayment",
          transaction_type: process.env.REACT_APP_TRANSACTION_TYPE_BUSINESS,
          endpoint: "v1/process-payment/process-payment",
        };

        link = `/dajupay/process-payment?paymentGateway=${paymentMethod[key]?.name}&actionType=singleOrderPayment` ;

      }

      if(actionType == "singleOrderPayment"){
        
        orderData = {
          payment_method: payment_method_id,
          action_type: "singleOrderPayment",
          title: orderInfo.title,
          order_number: orderInfo.order_number,
          total_amount:
            parseFloat(orderInfo.total_price) * parseFloat(orderInfo.rate),
          transaction_type: process.env.REACT_APP_TRANSACTION_TYPE_BUSINESS,
          endpoint: "v1/process-payment/process-payment",
        };

        link = `/dajupay/process-payment?paymentGateway=${paymentMethod[key]?.name}&actionType=bulkOrderPayment`;

      }
      
      setLocalStorage('orderData', JSON.stringify(orderData));
      navigate(link, {state:{refresh:true}})
    };
    // console.log(orderInfo);
    // const handleCloaseModal = () => {
    //   setOpenPay(null);
    // };

  

useEffect(() => {
  const pageTitle =  "Payment  || Checkstall.com";
  Seo({
    title: pageTitle,
    metaDescription: 'Payment',
  });
}, []);



 //check for authentication on the page
 useCheckAuthWithRedirect();
 
//get order info on page load
useEffect(() => {
  getOrderInfo();
  getPaymentMethod();
  getBulkOrderInfo();
},[]);



const getOrderInfo = () =>{
  let isMounted = true;
  const controller = new AbortController();

  const doGetSingleOrder = async () => {
      setModalShow(true);
      try {
            const response = await axiosPrivate.post("v1/order/get-single-order",
              JSON.stringify({ orderId }), {
              signal: controller.signal
            });

            if(response?.data?.message != 3001){
               isMounted && setOrderInfo(response?.data?.message);
               isMounted && setIsOrderAvailable(true); 

            }
      }catch(err){
    
          console.log(err.response?.data);
          
      }
      setModalShow(false);
  }

  doGetSingleOrder();
  return () => {
    isMounted = false;
      controller.abort();
  }
}

const getBulkOrderInfo = () =>{
  let isMounted = true;
  const controller = new AbortController();

  const doGetBulkOrder = async () => {
      setModalShow(true);
      try {
            const response = await axiosPrivate.post("v1/order/get-bulk-order",
              JSON.stringify({ orderIdList }), {
              signal: controller.signal
            });

            //handle response
            if(response?.data?.message != 3001){
                setTotalBulkOrder(response?.data?.totalOrder);
               isMounted && setBulkOrderInfo(response?.data?.message);
               isMounted && setIsOrderAvailable(true); 
               getTotalPayable(response?.data?.message);
            }
      }catch(err){
          console.log(err.response?.data);
      }
      setModalShow(false);
  }

  doGetBulkOrder();
  return () => {
    isMounted = false;
      controller.abort();
  }
}

const getPaymentMethod = () =>{
  let isMounted = true;
  const controller = new AbortController();

  const doGetPaymentMethod = async () => {
      setModalShow(true);
      try {
            const response = await axiosPrivate.get("v1/payment-method/index",
             {
              signal: controller.signal
            });
            
            //console.log(response?.data?.message);
            if(response?.data?.message){
               isMounted && setPaymentMethod(response?.data?.message);
            }

      }catch(err){
    
          console.log(err.response?.data);
          
      }
      setModalShow(false);
  }

  doGetPaymentMethod();
  return () => {
    isMounted = false;
      controller.abort();
  }
}

const handleMouseOver = ( key) => event => {
  setIsHovering(
    {
      [key]: true
    }
  );

  
};

const handleMouseOut = (key) => event =>{
  setIsHovering(
    {
      [key]: false
    }
  );
};

const handleClick = (key) => event =>{

  if(event.type === "click"){
    if(!event.target.classList.contains("pm-selected")){
      setIsClicked(
        {
          [key]: true
        }
      );
    }else{
      setIsClicked(
        {
          [key]: false
        }
      );
    }
    
  }
  
};

const Completionist = () => <span>This Order has expired!</span>;

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <div className='order-expire-at'>
          Complete the order within 
          <span className='order-limit-time'>{days}</span> days 
          <span className='order-limit-time'>{hours}</span> hrs 
          <span className='order-limit-time'>{minutes}</span> mins
          <span className='order-limit-time'>{seconds}</span> sec
      </div>
    );
  }
};


// const handlePay = (event) =>{
//   let total_amount = RoundToTwo(totalPayable * rate);
//   let key = event.target.closest("#pm-cover")?.querySelector(".pm-wrap")?.id;
//   let payment_method = paymentMethod[key].name;
//   let payment_method_id = paymentMethod[key].id;

//   let title = "";
//   if(actionType === "singleOrderPayment"){
//     title = orderInfo.title;
//   }else if(actionType === "bulkOrderPayment"){
//     title = JSON.stringify(titleList);
//   }

//   let orderId = "";
//   if(actionType === "singleOrderPayment"){
//     orderId = orderInfo.order_number;
//   }else if(actionType === "bulkOrderPayment"){
//     orderId = JSON.stringify(orderIdList);
//   }
 


//   setCookie("totalAmount", total_amount, 2);
//   setCookie("paymentMethod", payment_method_id, 2);
//   setCookie("transactionType", type, 2);
//   setCookie("orderId", orderId, 2);
//   setCookie("title", title, 2);

//   let link = "";
//   if(actionType === "singleOrderPayment"){
//     link = `/dajupay/process-payment?orderId=${orderInfo.order_number}&paymentGateway=${payment_method}&actionType=singleOrderPayment` ;
//   }else if(actionType === "bulkOrderPayment"){
//     link = `/dajupay/process-payment?paymentGateway=${payment_method}&actionType=bulkOrderPayment`;
//   }

//   deleteCookie("orderIdList");                                             
//   navigate(link, {state:{refresh:true}})
// }



const getTotalPayable = (orderList) =>{
  let totalPrice = 0;
  orderList.map((value) =>{
      totalPrice += Number(parseFloat(value.total_price) * value.rate);
      titleList.push(value.title);
  })


   setTitleList(titleList);
   setTotalPayable(totalPrice);
}



    return (
      <>
        <Spinner cssClass={"semi-dark-bg"} show={modalShow} />
        {isOrderAvailable &&
        (actionType === "singleOrderPayment" ||
          actionType === "bulkOrderPayment") ? (
          <div id="pm-cover" style={{paddingTop: '1rem'}}>
            <div className="payment-wrap shadow bg-white">
              <div className="how-to-pay text-muted">
                <h3 className="text-muted">How to pay</h3>
                <ol>
                  <li>
                    Go to
                    <Link
                      target="_blank"
                      to="/members/wallet/top-up"
                      style={{ textDecoration: "none" }}
                    >
                      wallet
                    </Link>
                  </li>
                  <li>Go to Top up and select payment method</li>
                  <li>
                    Complete your payment and receive fund into your wallet
                  </li>
                  <li>Then select daju pay to make payment for your order</li>
                  {!isWalletPasswordSet && (
                    <li>
                      Note: you need to set your wallet password on your first
                      payment.
                      <Link
                        target="_blank"
                        to="/members/wallet/set-wallet-password"
                        style={{ textDecoration: "none" }}
                      >
                        Set wallet password now
                      </Link>
                    </li>
                  )}
                </ol>
              </div>
              {actionType === "singleOrderPayment" && (
                <div className="order-info-wrap d-flex justify-content-between">
                  <div className="order-img">
                    <img src={orderInfo.pic_url} alt="" />
                  </div>
                  <div className="order-info text-muted">
                    <div className="order-id">
                      Order Number: <span>{orderInfo.order_number}</span>
                    </div>
                    <div className="order-title">{orderInfo.title}</div>
                  </div>
                  <div className="order-amt">
                    <div className="order-price">
                      Total payable: <span className="currency">{symbol}</span>
                      <span className="total">
                        {RoundToTwo(
                          Exchanger(
                            defaultCurrency,
                            currency,
                            parseFloat(orderInfo.total_price),
                            orderInfo.rate
                          )
                        )}
                      </span>
                    </div>
                    {
                      <Countdown
                        date={
                          orderInfo.created_at * 1000 + 60 * 60 * 24 * 3 * 1000
                        }
                        renderer={renderer}
                      />
                    }
                  </div>
                </div>
              )}

              {actionType === "bulkOrderPayment" && (
                <div className="order-info-wrap d-flex justify-content-between">
                  <div className="order-img">
                    <img src={bulkOrderBanner} alt="" />
                  </div>
                  <div className="order-info text-muted">
                    <div className="">
                      Total Order:{" "}
                      <span className="order-id">{totalBulkOrder}</span>
                    </div>
                  </div>
                  <div className="order-amt">
                    <div className="order-price">
                      Total payable: <span className="currency">₦</span>
                      <span className="total">

                        {RoundToTwo(parseFloat(totalPayable) )}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              <div className="d-flex flex-column ">
                {paymentMethod != null && paymentMethod.length > 0 ? (
                  paymentMethod.map((value, key) => {
                    return (
                      <div
                        className={`pm-wrap d-flex jsutify-flex-start ${
                          isHovering[key] ? "shadow pm-hover" : ""
                        } ${isClicked[key] ? "pm-selected" : ""}`}
                        onMouseOver={handleMouseOver(key)}
                        onMouseOut={handleMouseOut(key)}
                        onClick={handleClick(key)}
                        key={key}
                        id={key}
                      >
                        <div className="payment-method-logo">
                          <img src={value?.url} alt="" />
                        </div>
                        <div className="payment-method-title">
                          {value?.name}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="pm-alert">
                    <div className="alert alert-warning" role="alert">
                      Invalid Credential submitted! please try again later
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="d-flex justify-content-end  bg-white pay-now-wrap">
              <div className="d-flex justify-content-end">
                <button id="payBtn" onClick={handleOpenModal}>
                  Pay Now
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="pm-alert">
            <div className="alert alert-warning" role="alert">
              Invalid Credential submitted! please try again later
            </div>
          </div>
        )}
        {/* <Modal
          open={openPay !== null}
          onClose={handleCloaseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "none",
            outline: "none",
          }}
        >
          <Box
            width={400}
            height={300}
            bgcolor={"background.default"}
            color={"text.primary"}
            p={2}
            borderRadius={3}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {(openPay && openPay.actionType == "singleOrderPayment") && <ProcessPayment data={openPay}  />}
            {(openPay && openPay.actionType == "bulkOrderPayment") && <ProcessPayment data={openPay}  />}
          </Box>
        </Modal> */}
      </>
    );

}
export default Payment;

