import React from 'react';
import classes from './MensSize.module.css';
import {
  mensShirtSizeData,
  mensSuitSizeData,
  mensPantSizeData,
} from "./mensData";

const MensSize = () => {
  return (
    <section className={classes.wrapper}>
      <div className={classes.title}>
        <div className={classes.line} />
        <h2>Men's Size Chart</h2>
        <div className={classes.line} />
      </div>
      <div className={classes.tableContainer}>
        <h4 className={classes.shirtTitle}>
          Men's shirt size comparison table
        </h4>
        <table className={classes.tableHolder}>
          <thead>
            <tr>
              <th className={classes.shirtSize}>shirt size</th>
              <th>XS</th>
              <th>S</th>
              <th>M</th>
              <th>L</th>
              <th>XL</th>
              <th>XXL</th>
            </tr>
          </thead>
          {mensShirtSizeData.map((value, index) => {
            return (
              <tbody key={index}>
                <tr>
                  <td className={classes.tabledataTitle}>{value?.shirtSize}</td>
                  <td>{value?.xs}</td>
                  <td>{value?.s}</td>
                  <td>{value?.m}</td>
                  <td>{value?.l}</td>
                  <td>{value?.xl}</td>
                  <td>{value?.xxl}</td>
                </tr>
              </tbody>
            );
          })}
        </table>
        <div className={classes.tableTitle}>
          Men's shirt size comparison table info1
        </div>
      </div>
      <div className={classes.tableContainer}>
        <h4 className={classes.shirtTitle}>Men's suit size comparison table</h4>
        <table className={classes.tableHolder}>
          <thead>
            <tr>
              <th>size</th>
              <th>specification</th>
              <th>Version</th>
              <th>Length</th>
              <th>bust</th>
              <th className={classes.shirtSize}>Shoulder width</th>
            </tr>
          </thead>
          {mensSuitSizeData.map((value, index) => {
            return (
              <tbody key={index}>
                <tr>
                  <td className={classes.tabledataTitle}>{value?.size}</td>
                  <td>{value?.specification}</td>
                  <td>{value?.version}</td>
                  <td>{value?.length}</td>
                  <td>{value?.bust}</td>
                  <td>{value?.shoulderWidth}</td>
                </tr>
              </tbody>
            );
          })}
        </table>
        <div className={classes.tableTitle}>
          Men's shirt size comparison table info1
        </div>
      </div>
      <div className={classes.tableContainer}>
        <h4 className={classes.shirtTitle}>Male pants size comparison table</h4>
        <table className={classes.tableHolder}>
          <thead>
            <tr>
              <th className={classes.shirtSize}>Men's trousers size</th>
              <th>S</th>
              <th>M</th>
              <th>L</th>
              <th>XL</th>
              <th>XXL</th>
              <th>XXXL</th>
            </tr>
          </thead>
          {mensPantSizeData.map((value, index) => {
            return (
              <tbody key={index}>
                <tr>
                  <td className={classes.tabledataTitle}>{value?.size}</td>
                  <td>{value?.s}</td>
                  <td>{value?.m}</td>
                  <td>{value?.l}</td>
                  <td>{value?.xl}</td>
                  <td>{value?.xxl}</td>
                  <td>{value?.xxxl}</td>
                </tr>
              </tbody>
            );
          })}
        </table>
        <div className={classes.tableTitle}>
          Men's shirt size comparison table info1
        </div>
      </div>
    </section>
  );
}

export default MensSize
