import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "../../../assets/css/checkstallPartner.module.css";
import {
  ArrowDropDown,
  AccountCircle,
  Paid,
  Support,
  ThumbUp,
  Redeem,
  RedeemOutlined,
} from "@mui/icons-material";
import { Seo } from "../../utilities/Seo";
import Navbar from "../../navbar/Navbar";
import { MdOutlineSupport } from "react-icons/md";
import { GoTriangleDown } from "react-icons/go";
import { AiFillDollarCircle, AiFillLike } from "react-icons/ai";
import getLocalStorage from "../../functions/getLocalStorage";
import { getCookie } from "../../functions/CustomCookies";
import { isJSON } from "../../functions/Helpers";
import myAffiliate from "../../../assets/myAffiliate.avif";

function CheckstallPartner() {
  const navigate = useNavigate();
  //check if user is an agent
  let userObj = getLocalStorage("user");
  const userProfile =
    userObj && userObj !== undefined && isJSON(userObj)
      ? JSON.parse(userObj)
      : getCookie("user");
  // if (!(userProfile?.role == process.env.REACT_APP_ROLE_AGENT)) {
  //   navigate("/checkstall-partner/register");
  // }

  useEffect(() => {
    Seo({
      title: "Checkstall Partner || The Checkstall Affliate Program",
      metaDescription: "Checkstall Partner || The Checkstall Affliate Program",
    });
  }, []);

  // const navigate = useNavigate();

  // const goToRegistration = () => {
  //   navigate("/checkstall-partner/register");
  // };

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <Navbar />
        <div className={classes.container}>
          <h1 className={classes.title}>
            Make Money with us as a Checkstall Partner
          </h1>
          <div className={classes.flexContainer}>
            <div className={classes.topBar}>
              <span>
                Register <GoTriangleDown className={classes.icon} />
              </span>
              <span>
                Introduce users to Checkstall
                <GoTriangleDown className={classes.icon} />
              </span>
              <span>
                Get up to 50% commision for customer introduced
                <GoTriangleDown className={classes.icon} />
              </span>
            </div>
            <button className={classes.registerBtn}>
              <Link
                to="/checkstall-partner/register"
                className={classes.registerLink}
              >
                Register as a Partner
              </Link>
            </button>
            <div className={classes.boxContainer}>
              <div className={classes.box}>
                <span className={classes.iconBox}>
                  <AiFillDollarCircle size={30} className={classes.boxInBox} />
                </span>
                <h6>REGULAR PAYOUTS</h6>
                <p>
                  Get paid immediately after your introduced user completed a
                  successful purchase
                </p>
              </div>
              <div className={classes.box}>
                <span className={classes.iconBox}>
                  <AiFillLike size={30} className={classes.boxInBox} />
                </span>
                <h6>HIGN COMMISSION RATES</h6>
                <p>This program lets you earn uo to 50% from customers</p>
              </div>
              <div className={classes.box}>
                <span className={classes.iconBox}>
                  <MdOutlineSupport size={30} className={classes.boxInBox} />
                </span>
                <h6>EXCELLENT SUPPORT</h6>
                <p>Our customer support team are always ready to help you.</p>
              </div>
            </div>
            <div className={classes.numbersBox}>
              <div>
                <span className={classes.num}>1500</span>
                <span className={classes.numInfo}>
                  Partners all over the world
                </span>
              </div>
              <div>
                <span className={classes.num}>75000</span>
                <span className={classes.numInfo}>Daily Users</span>
              </div>
            </div>
            <div
              className={classes.affiliateBox}
              style={{
                backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.5)), url(${myAffiliate})`,
              }}
            >
              <h5>ON AN AVERAGE,OUR AFFLIATE EARNS</h5>
              <p> ₦52000 / month</p>
              <Link
                to={
                  userProfile?.role == process.env.REACT_APP_ROLE_AGENT
                    ? "/checkstall-partner/index"
                    : "/checkstall-partner/register"
                }
                className={classes.redem}
              >
                <RedeemOutlined />
                START EARNING
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CheckstallPartner;
