import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import App from './App';
import {BrowserRouter as Router} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/js/dist/dropdown';
import { SearchSiteProvider } from './context/SearchSiteProvider';
import { CartProvider } from './context/CartProvider';
import { AuthProvider } from './context/AuthProvider';
import {UserProvider} from './context/UserProvider';
import {CreateOrderProvider} from './context/CreateOrderProvider';
import {CompletedProvider} from './context/CompletedProvider';
import { CurrencyRateProvider } from './context/CurrencyRateProvider';
import { ProSidebarProvider } from 'react-pro-sidebar';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
      <AuthProvider>
        <UserProvider>
          <CurrencyRateProvider>
            <CartProvider>
            <CreateOrderProvider>
              <SearchSiteProvider>
                <ProSidebarProvider>
                  <CompletedProvider>
                    <App />
                  </CompletedProvider>
                </ProSidebarProvider>
              </SearchSiteProvider>
            </CreateOrderProvider>
            </CartProvider>
          </CurrencyRateProvider>
        </UserProvider>
      </AuthProvider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
