import React from 'react';
import classes from './ItemNotFound.module.css'
import Navbar from '../../navbar/Navbar';
import empty from "../../../assets/images/check.webp";
import { Link } from 'react-router-dom';

const ItemNotFound = () => {
  return (
    <>
    <Navbar />
      <main className={classes.wrapper}>
        <section className={classes.container}>
          <div className={classes.imgBox}>
            <img src={empty} alt="/" />
          </div>
          <div className={classes.infoBox}>
            <p>The product is not available in the store</p>
            <Link to='/'>Click here to view other items in our store</Link>
          </div>
        </section>
      </main>
    </>
  );
}

export default ItemNotFound;
