import React, { Component } from 'react';
import checkstallWHITE from '../../assets/images/checkstallWHITE.png';

class Logo extends Component {
  render() {
    return (
      <div className="whitelogo" style={{maxWidth: '14%'}}>
        <img style={{maxWidth: '176%', paddingTop: '2%', paddingBottom: '2%'}} src={checkstallWHITE} alt="Logo"/>
      </div>
    )
  }
}
export default Logo;



