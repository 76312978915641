import React,{useEffect} from "react";
import {useNavigate } from "react-router-dom";
import { Seo } from '../../utilities/Seo';
import '../../../assets/css/success.css';
import {FaCheckCircle} from "react-icons/fa";


export const  TransactionPending  = () => {

    const navigate = useNavigate()
    //set page title
useEffect(() => {
    const pageTitle =  "Transaction  Pending || Checkstall.com";
    Seo({
      title: pageTitle,
      metaDescription: 'Transaction  Pending',
    });
}, []);

const handleReturnHome =() =>{
    navigate('/members/home/index', {state:{refresh:true}});
}
    return (
        <>
            <div className="container" style={{height:'75vh'}}>
                <div className="payment-complted">
                        <div className="success-icon"><FaCheckCircle /></div>
                        <p className="tc"><span>Transaction Under Processing!</span><br/>Your payment will be process soon.</p>
                        <button onClick={handleReturnHome}>Return Home</button>
                </div>
            </div>
        </>
    )
};
