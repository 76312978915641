import axios from '../api/axios';
import useAuth from './useAuth';
import setLocalStorage from '../components/functions/setLocalStorage'


const REFRESH_TOKEN_URL = "/v1/auth/refresh-token";

const useRefreshToken = () => {
    const {setAuth, persist } = useAuth();

    const refresh = async () => {
        try{

            const response = await axios.post(REFRESH_TOKEN_URL,{
                withCredentials: true
            })

            if(persist){
                setLocalStorage('auth', JSON.stringify({accessToken:response?.data?.accessToken,}));
            }else{
                setAuth({accessToken: response?.data?.accessToken});
            }

            return response.data.accessToken;
        }catch(error){
            if(error.response){
                console.log(error.response.data);
            }
        }
    }
    return refresh;
};

export default useRefreshToken;
