import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function CustomBsModal(props) {
  return (
    <Modal 
       show={props.show} 
       onHide={props.onHide}
       dialogClassName={props.dialogClassName}
       backdrop={props.backdrop}
       keyboard={props.keyboard}
       centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.headingText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.bodyText}</Modal.Body>
      <Modal.Footer>
        <Button variant={props.closeButtonVariant} onClick={props.handleClose}>
          {props.closeButtonText}
        </Button>
        <Button variant={props.saveButtonVariant} onClick={props.handleAction}>
          {props.saveButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CustomBsModal
