import React from 'react';
import "../../assets/css/spinner.css";


function Spinner(props) {
    return (
        <div className={props.cssClass} style={{display:props.show ? 'block' : 'none'}}>
            <div>.</div>
        </div>
    );
  
}

export default Spinner