import React, { useState, useEffect } from "react";
import classes from "../../../assets/css/updateProfileName.module.css";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Form from "react-bootstrap/Form";
import { Seo } from "../../utilities/Seo";
import { CheckCircleOutline } from "@mui/icons-material";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Termii from "../../../api/Termii";
import Spinner from "../../utilities/Spinner";

export const UpdateFullname = ({ handleCloseModal, openName }) => {
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isTransparentLoading, setIsTransparentLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [fullName, setFullname] = useState("");
  const [nameError, setNameError] = useState("");
  const [isChecking, setIsChecking] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isCountDown, setIsCountDown] = useState(false);
  const [code, setCode] = useState("");
  const [count, setCount] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [codeError, setCodeError] = useState("");
  const [pinId, setPinId] = useState("");
   const [modalShow, setModalShow] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  useEffect(() => {
    const pageTitle = "Update full name || Checkstall.com";
    Seo({
      title: pageTitle,
      metaDescription: "Update full name ",
    });
  });

  const handleUpdateName = (e) => {
    e.preventDefault();
    if (!fullName) {
      setNameError("Please Enter Full name");
      return;
    } else{
        setModalShow(true);
         updateInfo();
        handleCloseModal();
        setModalShow(false);
      // getUserInfo();
      // handleCloseModal();
    }
  };

  const updateInfo = () => {
    let isMounted = true;
    const controller = new AbortController();
    setModalShow(true);

    const doupdateInfo = async () => {
      let full_name = fullName.split(" ");

      if (full_name != undefined && full_name != "") {
        for (let i = 0; i < full_name.length; i++) {
          full_name[i] = full_name[i][0].toUpperCase() + full_name[i].substr(1);
        }

        full_name = full_name.join(" ");
      }
      try {
        const response = await axiosPrivate.post(
          "v1/users/update-info",
          JSON.stringify({ full_name }),
          {
            signal: controller.signal,
          }
        );

        if (response?.data?.code == 2001) {
          setSuccessMessage(response?.data?.message);
          // setIsTransparentLoading(false);
          setFullname("");
          const timer = setTimeout(() => {
            setModalShow(false);
            setSuccessMessage(false);
            // getUserInfo();
            navigate("/members/account/userProfile");
          }, 1000);
          return () => clearTimeout(timer);
        } else {
          isMounted && setNameError(response?.data?.message);
        }
      } catch (err) {}
      // setIsTransparentLoading(false);
      setModalShow(false);
    };

    doupdateInfo();
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const sendCode = () => {
    setIsCountDown(true);
    countDown("counter", 60);

    const termiiInstance = Termii();

    //Send OTP
    let endpoint = "/v1/api/api-send-verify-code";
    termiiInstance
      .send_verify_code(endpoint, userInfo?.email)
      .then((response) => {});
  };

  const sendOtp = () => {
    setIsCountDown(true);
    countDown("counter", 60);

    const termiiInstance = Termii();

    //Send OTP
    termiiInstance.send_otp(userInfo.phone_number).then((response) => {
      if (parseInt(response.message.status) === 200) {
        setPinId(response.message.pinId);
      }
    });
  };

  const verifyCode = () => {
    const termiiInstance = Termii();
    setIsChecking(true);
    //verify OTP
    if (code) {
      termiiInstance.verify_email_otp(code).then((response) => {
        if (response.verified == true) {
          setIsVerified(true);
          setIsChecking(false);
          setCount("");
        } else {
          setCodeError("Incorrect / invalid code");
          setIsChecking(false);
        }
      });
    } else {
      setCodeError("Please get another code");
      setIsChecking(false);
    }
  };

  const verifyOtp = () => {
    const termiiInstance = Termii();
    setIsChecking(true);
    //verify OTP
    if (pinId && code) {
      termiiInstance.verify_otp(pinId, code).then((response) => {
        if (response.message.verified == true) {
          setIsVerified(true);
          setIsChecking(false);
        } else if (response.message.verified == false) {
          setCodeError("Incorrect code");
          setIsChecking(false);
        } else if (
          response.message.status == 400 &&
          response.message.verified == "Expired"
        ) {
          setCodeError("Code already Expired");
          setIsChecking(false);
        }
      });
    } else {
      setCodeError("Please get another code");
      setIsChecking(false);
    }
  };

  useEffect(() => {
    if (code.length === 6) {
      if (userInfo.phone_number) {
        verifyOtp();
      } else {
        verifyCode();
      }
    }
  }, [code]);

  const getUserInfo = () => {
    let isMounted = true;
    const controller = new AbortController();
    setModalShow(true);
    const doGetUser = async () => {
      try {
        const response = await axiosPrivate.post("v1/users/get-user-info", {
          signal: controller.signal,
          withCredentials: true,
        });

        isMounted && setUserInfo(response?.data?.user);
      } catch (err) {}
        setModalShow(false);
      // handleCloseModal();
      // setIsTransparentLoading(false);
    };

    doGetUser();
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    getUserInfo();
  }, [openName === false]);

  const countDown = (selectorId, limit) => {
    let counts = setInterval(() => {
      let count = document.getElementById(selectorId);
      //count.innerHTML=--limit;
      setCount(--limit);
      if (limit === 0) {
        clearInterval(counts);
        setIsCountDown(false);
        setCount("");
      }
    }, 1000);
  };

  const handleFullname = (e) => {
    setFullname(e.target.value);
  };

  return (
    <React.Fragment>
      <Spinner show={modalShow} cssClass={"semi-dark-bg"} />
      <div className={classes.wrapper}>
        <Form onSubmit={handleUpdateName} className={classes.container}>
          {/* <div className='d-flex flex-column'>
                       <div className='d-flex flex-column'>
                          <div className='setting-wrap d-flex justify-content-between'>
                            <div className='setting-name'>{userInfo.phone_number ? ("Your Phone number") : ("Your email")}</div>
                            <div className='input-field'>
                               {userInfo.phone_number ? maskPhoneNumner(userInfo.phone_number) : (userInfo.email ? maskEmail(userInfo.email) : "")  }
                            </div>
                          </div>
                      </div>
                    </div> */}
          {/* <div className='d-flex flex-column' >
                        <div className='setting-wrap d-flex justify-content-between'>
                          <div className='setting-name' onClick={userInfo.phone_number ? sendOtp : sendCode}>Get code <MailOutlined sx={{color:'crimson'}} />
                          &nbsp;&nbsp;
                          {isVerified && <span className=''><DoneOutlined sx={{color:'green'}}/></span> }
                          {isChecking && <span className=''><img src='/images/loader/reload.gif'/></span>}
                          {isCountDown && <span id='counter' style={{color:'crimson'}}>{count}</span>}
                          </div>
                          <div className='input-field'>
                            <input 
                                type="number"
                                value={code}
                                required                 
                                maxLength={6}            
                                onChange={handleSetCode}
                                placeholder="Enter code"
                            />
                          </div>
                        </div>
                        
                        <span className='regErr'>{codeError}</span>
                    </div> */}
          <div className={classes.updateInputContainer}>
            <div className={classes.inputBox}>
              <label className={classes.inputLabel}>Full Name</label>

              <input
                className={classes.nameInput}
                type="text"
                value={fullName}
                onChange={(e) => {
                  setNameError("");
                  handleFullname(e);
                }}
                required
              />
            </div>
            <div className={classes.regErr}>{nameError}</div>
          </div>
        </Form>

        <button
          type="submit"
          className={classes.btn}
          onClick={handleUpdateName}
        >
          Save
        </button>
      </div>
    </React.Fragment>
  );
};
