import React, {useContext, useEffect, useState} from "react";
import "../../assets/css/NavTwo.css";
import { FaCartPlus,FaHeart,FaUser,FaShoppingBag , FaCheck} from "react-icons/fa";
import { Link } from 'react-router-dom';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavDropdown } from "react-bootstrap";
import { NavDropdownMenu } from "react-bootstrap-submenu";
import "react-bootstrap-submenu/dist/index.css";
import Logo from './Logo2';
import useCart from "../../hooks/useCart";
import useGetCurrencyRate from "../../hooks/useGetCurrencyRate";
import Spinner from "../utilities/Spinner";
// import setLocalStorage from "../functions/setLocalStorage"
// import getLocalStorage from "../functions/getLocalStorage"
import { getCookie, setCookie } from '../functions/CustomCookies';
import useCurrencyRate from "../../hooks/useCurrencyRate";




const NavTwo = () => {
  const {cart} = useCart();
  const [modalShow, setModalShow] = useState(false);
  const getCurrencyRate = useGetCurrencyRate();
  const {currency} = useCurrencyRate();
  //const defaultCurrency = "₦";

  useEffect(() =>{
    let preffered  = decodeURIComponent(getCookie("preferredCurrency"));
    getCurrency(preffered || "");
  },[]);

  const getCurrency = async (curr) => {
    setModalShow(true);
      await getCurrencyRate(curr);
    setModalShow(false);
  }


  const handleClick = (e) =>{
    getCurrency(e);
    setCookie("preferredCurrency", encodeURIComponent(e), 24*30*12);
 }

  return (
    <>
   
        <Navbar fixed="top" collapseOnSelect expand="lg" className="navW" style={{top:'2.4em'}} >
            <Logo />
            <Navbar.Collapse id="responsive-navbar-nav" className="navTwoSpread">
            <Nav className="mr-auto" style={{zIndex:'1000'}}>
                <NavDropdownMenu  title="Currency" id="collasible-nav-dropdown">
                    <NavDropdown.Item onClick={() => handleClick("₦")} eventKey="₦" className="dropLink" href="">₦ NGN {currency == "₦" && <FaCheck />}</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => handleClick("¥")} eventKey="¥" className="dropLink" href="">¥ CNY {currency == "¥" && <FaCheck />}</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => handleClick("$")} eventKey="$" className="dropLink" href="">$ USD {currency == "$" && <FaCheck />}</NavDropdown.Item>
                </NavDropdownMenu>
             </Nav>
              <Nav className="mr-auto">
                <Nav.Link href="/">Home</Nav.Link>
              </Nav>
              <Nav className="mr-auto">
                <Nav.Link href="/buy-for-me">Buy For Me</Nav.Link>
              </Nav>
              
              <Nav className="mr-auto">
                <Nav.Link href="/gather-and-ship-for-me">Gather & ship for me</Nav.Link>
              </Nav>
              <Nav className="mr-auto">
                <Nav.Link href="/about">About Us</Nav.Link>
              </Nav>
              <Nav className="mr-auto">
                <Nav.Link href="/contact-us">Contact Us</Nav.Link>
              </Nav>
              <div className="baly">
                <div>
                  <Link title="Favourite" to="/favourite" className="nav-item navIcon"><FaHeart /></Link>
                  <Link title="My Account" to="/members/home/index" className="nav-item navIcon"><FaUser /></Link>
                  <Link title="Cart" to="/cart" className="nav-item navIcon"><FaCartPlus /> 
                    {cart > 0 && 
                      <span className="hearder-cart-counter">{cart}</span>
                    }
                  </Link>
                </div>
              </div>
            </Navbar.Collapse>
        </Navbar>
        
    </>

  )
}
  export default NavTwo;