import React from 'react';
import classes from './ContactForm.module.css';
import { useState } from 'react';

const ContactForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = (e) => {
      e.preventDefault();
    };
    const handleName = (e) => {
      setName(e.target.value);
    };
    const handleEmail = (e) => {
      setEmail(e.target.value);
    };
    const handlePhoneNumber = (e) => {
      setPhoneNumber(e.target.value);
    };
    const handleSubject = (e) => {
      setSubject(e.target.value);
    };
    const handleMessage = (e) => {
      setMessage(e.target.value);
    };

  return (
    <div className={classes.formWrapper}>
      <h3 className={classes.FormTitle}>contact us</h3>
      <form className={classes.formContainer} onSubmit={handleSubmit}>
        <div className={classes.nameBox}>
          <input
            type="text"
            placeholder="Your Full Name"
            required
            value={name}
            onChange={handleName}
            className={classes.nameTextInput}
          />
          <input
            type="email"
            placeholder="Your Email"
            required
            value={email}
            onChange={handleEmail}
            className={classes.nameTextInput}
          />
        </div>
        <input
          type="number"
          placeholder="Your Phone Number"
          required
          value={phoneNumber}
          onChange={handlePhoneNumber}
          className={classes.textInput}
        />
        <input
          type="text"
          placeholder="Your Message Title"
          required
          value={subject}
          onChange={handleSubject}
          className={classes.textInput}
        />
        <textarea
          type="text"
          placeholder="Your Message"
          required
          value={message}
          onChange={handleMessage}
          className={classes.messageArea}
        />
        <button type="submit" className={classes.submitBtn}>
          Submit
        </button>
      </form>
    </div>
  );
}

export default ContactForm
