import React from 'react';
import classes from './Address.module.css';

const Address = () => {
  return (
    <div className={classes.wrapper}>
      <h1 className={classes.title}>
        <span>know who</span> we are
      </h1>
      <div className={classes.addressBox}>
        <h2>address:</h2>
        <p>
          ML 198 kajola international market, <br /> cele express, okota, lagos
          state.
        </p>
      </div>
      <div className={classes.addressBox}>
        <h2>office hour:</h2>
        <p>
          monday - saturday: 8AM - 5PM <br /> sunday: office closed
        </p>
      </div>
      <div className={classes.addressBox}>
        <h2>telephone:</h2>
        <p>
          +2347051905357 <br /> +2348067175357
        </p>
      </div>
      <div className={classes.addressBox}>
        <h2>whatsapp:</h2>
        <p>
          +8613864927809 <br /> +2348067175357
        </p>
      </div>
      <div className={classes.addressBox}>
        <h2>email:</h2>
        <p>
          support@checkstall.com <br /> lorem@email.com
        </p>
      </div>
    </div>
  );
}

export default Address
