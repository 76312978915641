import React, { useState, useEffect } from "react";
import "../../assets/css/home.css";
import Navbar from "../navbar/Navbar";
import { Seo } from "../utilities/Seo";
import Shop from "../shop/Shop";
import HeadSection from "../headSection/HeadSection";
import { axiosPublic } from "../../api/axios";
import useGetCurrencyRate from "../../hooks/useGetCurrencyRate";
import useCurrencyRate from "../../hooks/useCurrencyRate";

const HOME_ITEMS_URL = "/v1/home-item/get-all";

const Home = () => {
  const [flashSales, setFlashSales] = useState({});
  const [hotDeals, setHotDeals] = useState({});
  const [mensClothing, setMensClothing] = useState({});
  const [womensClothing, setWomensClothing] = useState({});
  const [computerAccessories, setComputerAccessories] = useState({});
  const [kidsToys, setKidsToys] = useState({});
  const [phoneTablet, setPhoneTablet] = useState({});
  const [footWears, setFootWears] = useState({});
  const [hairAndWigs, setHairAndWigs] = useState({});
  const [homeAppliances, setHomeAppliances] = useState({});
  const [jewelryAndWatches, setJewelryAndWatches] = useState({});
  const [bagsAndLuggages, setBagsAndLuggages] = useState({});
  const getCurrencyRate = useGetCurrencyRate();
  const { currency } = useCurrencyRate();

  useEffect(() => {
    Seo({
      title:
        "Checkstall || Shopping made easy. Buy Top brand items at low price from factories around the world wholesale & retails sales available at checkstall",
      metaDescription:
        "Buy Top brand items at low price from factories around the world, electronics, clothes, collectibles & more on checkstall, the world's online marketplace. Top brands, low prices; free shipping on many items Checkstall.com",
    });
  }, []);

  useEffect(() => {
    getHomeItems();
  }, []);

  const getHomeItems = async () => {
    let isMounted = true;
    const controller = new AbortController();

    try {
      const response = await axiosPublic.get(HOME_ITEMS_URL, {
        signal: controller.signal,
      });

      setFlashSales(response?.data?.flashSales);
      setHotDeals(response?.data?.hotDeal);
      setMensClothing(response?.data?.mensClothes);
      setWomensClothing(response?.data?.womensClothe);
      setKidsToys(response?.data?.kidsToy);
      setComputerAccessories(response?.data?.phoneTablet);
      setPhoneTablet(response?.data?.computerAcc);
      setFootWears(response?.data?.footWears);
      setHairAndWigs(response?.data?.hairAndWigs);
      setHomeAppliances(response?.data?.homeAppliances);
      setJewelryAndWatches(response?.data?.jewelryAndWatches);
      setBagsAndLuggages(response?.data?.bagsAndLuggages);
    } catch (err) {
      if (err?.response) {
        //console.log(err?.response);
      }
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const getCurrency = async (curr) => {
    await getCurrencyRate(curr);
  };
  useEffect(() => {
    getCurrency('NGN');
  }, []);

  return (
    <>
      <Navbar />
      <div className="home-wrapper">
        <HeadSection />
        <Shop
          flashSales={flashSales}
          hotDeals={hotDeals}
          mensClothing={mensClothing}
          womensClothing={womensClothing}
          computerAccessories={computerAccessories}
          kidsToys={kidsToys}
          phoneTablet={phoneTablet}
          footWears={footWears}
          hairAndWigs={hairAndWigs}
          homeAppliances={homeAppliances}
          jewelryAndWatches={jewelryAndWatches}
          bagsAndLuggages={bagsAndLuggages}
        />
      </div>
    </>
  );
};
export default Home;
