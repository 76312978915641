import React from 'react';
import classes from './HeadSection.module.css';
import Header from '../header/Header';
import Category from '../category/Category';
import UserHeadProfile from '../userHeadProfile/UserHeadProfile';

const HeadSection = () => {
  return (
    <header className={classes.wrapper}>
      <Category />
      <Header />
     <UserHeadProfile />
    </header>
  );
}

export default HeadSection
