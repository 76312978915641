import React, { useEffect, useState } from 'react';
import '../../../assets/css/shippingadd.css'
import { FaTrash, FaEdit } from 'react-icons/fa';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import 'react-phone-number-input/style.css';
import Select from 'react-select';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Spinner from "../../utilities/Spinner";
import useRefreshToken from '../../../hooks/useRefreshToken';
import CustomBsModal from '../../utilities/CustomBsModal';
import useCheckAuthWithRedirect from '../../../hooks/useCheckAuthWithRedirect';
import { Seo } from '../../utilities/Seo';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';


const STATES_URL = "/v1/state/get-all-state";
const CITIES_URL = "/v1/city/get-all-cities?id=";
const ADD_ADDRESS_URL = "/v1/address/add";
const GET_ADDRESS_URL = "/v1/address/get-all";
const SINGLE_ADDRESS_URL = "/v1/address/get-one?id=";
const DELETE_ADDRESS_URL = "/v1/address/delete?id=";
const UPDATE_ADDRESS_URL = "/v1/address/update";
const SET_DEFAULT_URL = "/v1/address/set-default?id=";

function ShippingAddress (){
    
    //check for authentication on the page
    useCheckAuthWithRedirect();
   
    
    const [states, setStates] = useState({});
    const [cities, setCities] = useState({});
    const [modalShow, setModalShow] = useState(false);
    const [open, setOpen] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    const axiosPrivate = useAxiosPrivate();
    const refresh = useRefreshToken();

    const [receiverPhoneNumber, setReceiverPhoneNumber] = useState("");
    const [receiverName, setReceiverName] = useState("");
    const [receiverState, setReceiverState] = useState(0);
    const [receiverAddress, setReceiverAddress] = useState("");
    const [receiverCity, setReceiverCity] = useState(0);
    const [err, setErr] = useState({});
    const [isDefault , setIsDefault] = useState("no");
    const [activeModal, setActiveModel] = useState("");

    const [addressList, setAddressList] = useState([]);
    const [reloadOnUpdate, setReloadOnUpdate] = useState(false);
    const [hasRedirect, setHasRedirect] = useState(false);


    useEffect(()  =>{
        const getRefreshToken = async () => {
            await refresh();
        }
        
        getRefreshToken();
    },[axiosPrivate]);

     

    //set page title
useEffect(() => {
    const pageTitle =  "Shipping Address || Checkstall.com";
    Seo({
      title: pageTitle,
      metaDescription: ' Shipping Address',
    });
}, []);

   //send user back to the previous page they are before coming to add address
   let from =  "";
  const path = location.state?.from?.pathname;
  if(path){
    setHasRedirect(true);
     let query = location.state?.from?.search || "";
     if(query){
        from = path + query;
     }else{
       from = path;
     }

  }else{
     from = "/members/account/shipping-address";
  }

    //display delete modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleCloseDeleteModal = () => setShowDeleteModal(false);
    const handleShowDeleteModal = (key)  => {     
    setActiveModel(key);
    setShowDeleteModal(true);
    }

    //display add and edit modal
    const [showCreateAddressModal, setShowCreateAddressModal] = useState(false);
    const [addressId, setAddressId] = useState(0);
    const handleCloseCreateAddressModal = () => setShowCreateAddressModal(false);
    
    const handleShowCreateAddressModal = (e,key) => {

        if(key === "edit"){
            let id =  e.target.closest(".editBtn").id;
            //console.log(e?.target?.id);
            getOneAddress(id);
            setAddressId(id);
        }
        
        getStates();
        setActiveModel(key);
        setShowCreateAddressModal(true);
    }

  

    useEffect(() => {   
        getStates();      
    }, [axiosPrivate]);

    const getStates = async () => {
        setModalShow(true);
        try {
            const response = await axiosPrivate.get(STATES_URL);
            //console.log(response?.data.states);
             setStates(response?.data?.states);
        } catch (err) {
            //console.log(err.response.data);
            if(err?.response?.data?.status === 401){
                navigate('/login', { state: { from: location }, replace: true });
            }else{
                //console.log(err.response.data);
            }
        }

        setModalShow(false);
    }

    const getAllCities = (e) =>{
        
        getValues(e, "state");

        let isMounted = true;
        const controller = new AbortController();

        const getCities = async () => {
        setModalShow(true);
            try {
                 let id = e.value;
                const response = await axiosPrivate.get(CITIES_URL+id, {
                    signal: controller.signal
                });
                //console.log(response?.data.cities);
                isMounted && setCities(response?.data?.cities);
            } catch (err) {
                //console.log(err.response.data);
                if(err?.response?.data?.status === 401){
                navigate('/login', { state: { from: location }, replace: true });
                }else{
                    console.log(err.response.data);
                }
            }

            setModalShow(false);
        }

        getCities();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

   const handleAddAddress = (event) => {
        event.preventDefault();

        if(err.name || err.phone || err.address ||
            err.state || err.city ){
                return;
        }else{
            //submit form
            addAddress();

        }
   }

   const handleUpdateAddress = (event) =>{
        event.preventDefault();

        if(err.name || err.phone || err.address ||
            err.state || err.city ){
                return;
        }else{
            //submit form
            updateAddress();

        }
   }

  const handleDeleteAddress = (event) =>{
    event.preventDefault();
    //set id of address to be deleted in state
    let id =  event.target.closest(".delBtn").id;
    setAddressId(id);
    //show modal
    handleShowDeleteModal("delete");

  }

  const handleDeleteAction = () => {
    //if user choose to delete close modal and delete item,
    deleteAddress(addressId);

    //close the modal
    handleCloseDeleteModal(true)
  }

  const handleSetDefault = (event) => {
    //console.log(event.target.id);
    //set id of address to be updated in state
    setAddressId(event.target.id);

    //show modal
    handleShowDeleteModal("default")
  }

  const handleSetDefaultAction = () => {
    //if user choose to update, then update to defaultand close modal,
    setDefaultAddress(addressId);

    //close the modal
    handleCloseDeleteModal(true)
  }

   const deleteAddress = (id) =>{
    const controller = new AbortController();
    
    
    const del = async (id) => {
      setModalShow(true);
        try {
  
            const response = await axiosPrivate.delete(DELETE_ADDRESS_URL+id,
            {
                signal: controller.signal
            });
  
            if(response?.data.message === 'ok'){
              handleCloseDeleteModal(true)
              setReloadOnUpdate(true);
            }
        } catch (err) {
           // console.log(err.response.data)
        }
  
        setModalShow(false);
    }
  
    del(id);
  
    return () => {
        controller.abort();
    }
  }

   const addAddress =  () => {

    let isMounted = false;
    const controller = new AbortController();

    const doAddaddress = async () => {
      setModalShow(true);
            let receiver_name = receiverName;
            let receiver_phone = receiverPhoneNumber;
            let receiver_address = receiverAddress;
            let receiver_city_id = receiverCity;
            let receiver_state_id = receiverState;
            let is_default = isDefault == "yes" ? 1 : 0;
        try {
                let add = {receiver_name, receiver_phone, receiver_address, receiver_city_id,receiver_state_id,is_default}
                const response = await axiosPrivate.post(ADD_ADDRESS_URL,
                    JSON.stringify(add),
                 {
                    signal: controller.signal
                });

                //console.log(response?.data);
                if(response?.data.message === "ok"){
                    setShowCreateAddressModal(false);
                    setReloadOnUpdate(true);
                    navigate(from, { replace: true });
                }
        } catch (err) {
            //console.log(err.response);
            if(err?.response?.data?.status === 401){
                navigate('/login', { state: { from: location }, replace: true });
            }else{
                //console.log(err.response);
            }
        }

        setModalShow(false);
    }

    doAddaddress();

    return () => {
        isMounted = false;
        controller.abort();
    }

  }

  const updateAddress =  () => {

    let isMounted = false;
    const controller = new AbortController();

    const doUpdateaddress = async () => {
      setModalShow(true);
            let receiver_name = receiverName;
            let receiver_phone = receiverPhoneNumber;
            let receiver_address = receiverAddress;
            let receiver_city_id = receiverCity;
            let receiver_state_id = receiverState;
            let is_default = isDefault == "yes" ? 1 : 0;
            let id = addressId;
        try {
                let add = {receiver_name, receiver_phone, 
                           receiver_address, receiver_city_id,
                           receiver_state_id,is_default,id}
                const response = await axiosPrivate.patch(UPDATE_ADDRESS_URL,
                    JSON.stringify(add),
                {
                    signal: controller.signal
                });

                //console.log(response?.data);
                if(response?.data.message === "ok"){
                    setShowCreateAddressModal(false);
                    setReloadOnUpdate(true);
                }else{
                    console.log(response?.data);
                }
        } catch (err) {
                console.log(err.response?.data)
        }

        setModalShow(false);
    }

    doUpdateaddress();

    return () => {
        isMounted = false;
        controller.abort();
    }

  }

  const setDefaultAddress =  (id) => {

    let isMounted = false;
    const controller = new AbortController();

    const doSetDefault = async (id) => {
      setModalShow(true);
        try {
                const response = await axiosPrivate.post(SET_DEFAULT_URL+id,
                {
                    signal: controller.signal
                });

                //console.log(response?.data);
                if(response?.data.message === "ok"){
                    //console.log(response?.data.message);
                    handleCloseDeleteModal(false);
                    setReloadOnUpdate(true);
                }else{
                    //console.log(response?.data?.message);
                }
        } catch (err) {
                console.log(err.response?.data)
        }

        setModalShow(false);
    }

    doSetDefault(id);

    return () => {
        isMounted = false;
        controller.abort();
    }

  }

   const getValues = (e, key) =>{
      if( key === "name"){
           setReceiverName(e?.target?.value);
           if(e.target.value){
                setErr(preValue =>{
                    return{
                    ...preValue,
                    "name":""
                    }
                })
           }else{
                setErr(preValue =>{
                    return{
                    ...preValue,
                    "name":"Receiver's Name cannot be empty"
                    }
                })
           }
        
      }else if(key === "phone"){
            setReceiverPhoneNumber(e?.target?.value);
            if(e.target.value){
                
                setErr(preValue =>{
                    return{
                    ...preValue,
                    "phone":""
                    }
                })
            }else{
                setErr(preValue =>{
                    return{
                      ...preValue,
                      "phone":"Receiver's phone number cannot be empty"
                    }
                  })
            }
           
      }else if(key === "address"){
        setReceiverAddress(e?.target?.value)
        if(e.target.value){    
            setErr(preValue =>{
                return{
                ...preValue,
                "address":""
                }
            })
        }else{
            setErr(preValue =>{
                return{
                  ...preValue,
                  "address":"Receiver's  Address cannot be empty"
                }
              })
        }
           
      }else if(key === "state"){
        setReceiverState(e?.value)
        if(e.value){  
            setErr(preValue =>{
                return{
                ...preValue,
                "state":""
                }
            })
        }else{
            setErr(preValue =>{
                return{
                  ...preValue,
                  "state":"Please Select a state"
                }
            })
        }
          
      }else if(key === "city"){
        setReceiverCity(e?.value)
        if(e.value){    
            setErr(preValue =>{
                return{
                ...preValue,
                "city":""
                }
            })
        }else{
            setErr(preValue =>{
                return{
                  ...preValue,
                  "city":"Please Select a city"
                }
            })
        }
      }
   }

   useEffect(() =>{
     getAddressList();
   },[reloadOnUpdate]);
    
  

  const getAddressList = () =>{
        let isMounted = true;
        const controller = new AbortController();

        const getAddress = async () => {
            setModalShow(true);
              try {
        
                  const response = await axiosPrivate.get(GET_ADDRESS_URL, {
                      signal: controller.signal
                  });
                  //console.log(response?.data.message);
                   setAddressList(response?.data?.message);
                   setReloadOnUpdate(false);
              } catch (err) {
                  //console.log(err.response.data);
                  if(err?.response?.data?.status === 401){
                        //console.log(err.response.data);
                        //todo
                  }
              }
        
              setModalShow(false);
          }

          getAddress();

        return () => {
            isMounted = false;
            controller.abort();
        }
  }



  const getOneAddress = (id) =>{
    let isMounted = true;
    const controller = new AbortController();

    const doGetSingle = async (id) => {
    setModalShow(true);
        try {
            const response = await axiosPrivate.get(SINGLE_ADDRESS_URL+id, {
                signal: controller.signal
            });
            //console.log(response?.data.message);
            isMounted && setReceiverPhoneNumber(response?.data?.message[0]?.receiver_phone);
            isMounted && setReceiverName(response?.data?.message[0]?.receiver_name);
            isMounted && setReceiverState(response?.data?.message[0]?.s_id);
            isMounted && setReceiverAddress(response?.data?.message[0]?.receiver_address);
            isMounted && setReceiverCity(response?.data?.message[0]?.c_id);
            //isMounted && setCities(response?.data?.cities);
        } catch (err) {
            console.log(err.response.data);
        }

        setModalShow(false);
    }

    doGetSingle(id);

    return () => {
        isMounted = false;
        controller.abort();
    }
  }

    return (
      <>
        {hasRedirect && (
        <div className="item-success">
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              Item added successfully!
            </Alert>
          </Collapse>
        </div>
      )}
        <CustomBsModal 
          show={(activeModal === "delete" && showDeleteModal ) } 
          onHide={handleCloseDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleAction={handleDeleteAction}
          headingText={'Delete Item'}
          bodyText={'Do you want to delete this address?'}
          closeButtonText={'Cancel'}
          saveButtonText={'Delete'}
          closeButtonVariant={'secondary'}
          saveButtonVariant={'danger'}
          dialogClassName="modal-w"
          backdrop="static"
          keyboard={false}
       />

       <CustomBsModal 
            show={(activeModal === "default" && showDeleteModal ) } 
            onHide={handleCloseDeleteModal}
            handleClose={handleCloseDeleteModal}
            handleAction={handleSetDefaultAction}
            headingText={'Set as Default'}
            bodyText={'Set this as default address?'}
            closeButtonText={'Cancel'}
            saveButtonText={'Yes'}
            closeButtonVariant={'secondary'}
            saveButtonVariant={'danger'}
            dialogClassName="modal-w"
            backdrop="static"
            keyboard={false}
       />
        {/* add address modal */}
        <Spinner
          cssClass={'semi-dark-bg'}
          show={modalShow}
        />
        <Modal 
            show={showCreateAddressModal} 
            onHide={handleCloseCreateAddressModal}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
            <Modal.Title>Add New Shipping Address</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleAddAddress}>
                    <Form.Group className="mb-3" controlId="receiverName">
                        <Form.Label>Receiver's Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Receiver's Name"
                            value={receiverName}
                            onChange={(e) => getValues(e, "name")}
                            
                        />
                        <span className='regErr'>{err?.name}</span>
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="telephone">
                        <Form.Label>Phone number</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Phone Number"
                            value={receiverPhoneNumber}
                            onChange={(e) => getValues(e, "phone")}
                        />
                        <span className='regErr'>{err?.phone}</span>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="state">
                        <Form.Label>State</Form.Label>
                        <Select 
                            options={states} 
                            placeholder="Select State"
                            onChange={(e) =>  getAllCities(e)}
                        />
                        <span className='regErr'>{err?.state}</span>
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="state">
                        <Form.Label>City</Form.Label>
                        <Select 
                            options={cities} 
                            placeholder="Select City"
                            onChange={(e) => getValues(e , "city")}
                        />
                        <span className='regErr'>{err?.city}</span>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="Address">
                        <Form.Label>Address</Form.Label>
                        <Form.Control 
                            as="textarea" 
                            rows={2}
                            placeholder="Address"
                            value={receiverAddress}
                            onChange={(e) => getValues(e, "address")}
                            />
                            <span className='regErr'>{err?.address}</span>
                    </Form.Group>
                    
                </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseCreateAddressModal}>
                Close
            </Button>
            <Button variant="primary" onClick={handleAddAddress}>
                Save Changes
            </Button>
            </Modal.Footer>
        </Modal>
      
      {/* Edit address modal */}
        <Modal 
            show={(activeModal === "edit" && showCreateAddressModal)} 
            onHide={handleCloseCreateAddressModal}
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header closeButton>
            <Modal.Title>Edit Shipping Address</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleUpdateAddress}>
                    <Form.Group className="mb-3" controlId="receiverName">
                        <Form.Label>Receiver's Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Receiver's Name"
                            value={receiverName}
                            onChange={(e) => getValues(e, "name")}
                            
                        />
                        <span className='regErr'>{err?.name}</span>
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="telephone">
                        <Form.Label>Phone number</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Phone Number"
                            value={receiverPhoneNumber}
                            onChange={(e) => getValues(e, "phone")}
                        />
                        <span className='regErr'>{err?.phone}</span>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="state">
                        <Form.Label>State</Form.Label>
                        <Select 
                            options={states} 
                            placeholder="Select State"
                            onChange={(e) =>  getAllCities(e)}
                        />
                        <span className='regErr'>{err?.state}</span>
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="state">
                        <Form.Label>City</Form.Label>
                        <Select 
                            options={cities} 
                            placeholder="Select City"
                            onChange={(e) => getValues(e , "city")}
                        />
                        <span className='regErr'>{err?.city}</span>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="Address">
                        <Form.Label>Address</Form.Label>
                        <Form.Control 
                            as="textarea" 
                            rows={2}
                            placeholder="Address"
                            value={receiverAddress}
                            onChange={(e) => getValues(e, "address")}
                            />
                            <span className='regErr'>{err?.address}</span>
                    </Form.Group>
                    
                </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseCreateAddressModal}>
                Close
            </Button>
            <Button variant="primary" onClick={handleUpdateAddress}>
                Save Changes
            </Button>
            </Modal.Footer>
        </Modal>
        <div className="container mt5 bg-white" style={{ minHeight:'100%' }} >
        
        <div className=' spacebtw '> 
            <div className='shippingcard inlineBlock col-md-12  reduceContainer '>
                <h5 className='col-lg-8 '>Shipping Address</h5>
                {(addressList  && addressList.length  > 0 ) ? (
                    <div className='d-flex flex-column address-container'>
                        <div className='d-flex justify-content-between header-wrap'>
                            <div className='name-h'>Receiver</div>
                            <div className='region-h'>Region</div>
                            <div className='address-h'>Address</div>
                            <div className='phone-h'>Phone</div>
                            <div className='postal-h'>Postal code</div>
                            <div className='default-h'>default</div>
                            <div className='action-h'>Action</div>
                        </div>
                        <div className='d-flex flex-column content-wrap'>
                            {
                               addressList.map((value, key) =>{
                                    return(                                       
                                        <div className='d-flex justify-content-between add-content' key={key}>
                                            <div className='name-c'>{value?.receiver_name}</div>
                                            <div className='region-c'> {value?.state +" "+ value?.city}</div>
                                            <div className='address-c'>{value?.receiver_address}</div>
                                            <div className='phone-c'>{value?.receiver_phone}</div>
                                            <div className='postal-c'>{value?.postal_code || "--"}</div>
                                            <div className="default-c">
                                                <span 
                                                    className={(value?.is_default == 0) ? "no-default" : ""}
                                                    onClick={(e) => handleSetDefault( e)}
                                                    id={value?.id}
                                                >
                                                    {(value?.is_default == 1) ? "default" : "set as default"}
                                                </span>
                                            </div>
                                            <div className='action-c d-flex justify-content-center add-action'>
                                                <button 
                                                    className='editBtn'
                                                    onClick={(e) => handleShowCreateAddressModal(e, "edit")}
                                                    id={value?.id}
                                                >
                                                    <FaEdit />
                                                </button>
                                                <button 
                                                    className='delBtn'
                                                    onClick={(e) => handleDeleteAddress(e)} 
                                                    id={value?.id}
                                                >
                                                    <FaTrash />
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            
                            
                        </div>
                    </div>
                ) : ("No receiver address added yet")
                    
                }
                
                <div className='addAddress AddAdd'>
                    <button onClick={(e) => handleShowCreateAddressModal(e, "add")}><p>Add Address</p></button>
                </div>
            </div> 
        </div>       
        </div>
      </>

    )
}

export default ShippingAddress;