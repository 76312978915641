import React from 'react';
import { Outlet } from 'react-router-dom';


const Account = () => {
  
  return (
    
    <Outlet />

  )

}

export default Account;



