import React from 'react';
import { Outlet } from 'react-router-dom';


const Dajupay = () => {
  
  return (
    
    <Outlet />

  )

}

export default Dajupay;



