import React,{useState, useEffect} from 'react'
import Spinner from "../utilities/Spinner";
import "../../assets/css/cart.css";
import {FaTrashAlt} from "react-icons/fa";
import { Seo } from '../utilities/Seo';
import  CustomBsModal  from '../utilities/CustomBsModal';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation , Link} from "react-router-dom";
import { RoundToTwo } from "../utilities/RoundToTwo";
import useCart from '../../hooks/useCart';
import emptyCartImg from '../../assets/images/empty-cart.png';
import useCurrencyRate from '../../hooks/useCurrencyRate';
import Navbar from '../navbar/Navbar';
import { Checkbox } from '@mui/material';
import useBoolean from '../../hooks/useBoolean';
import { getSite,getSpecProps,Exchanger,getSkuTotalQty } from '../functions/Helpers';
import useGlobalVariable from '../../hooks/useGlobalVariable';
import setLocalStorage from '../functions/setLocalStorage';
import { encryptData } from '../functions/DataSecurity';
import useCheckAuthWithRedirect from '../../hooks/useCheckAuthWithRedirect';
import useAuth from '../../hooks/useAuth';


const CART_ITEMS_URL = "/v1/cart/index";

function Cart() {
  const [modalShow, setModalShow] = useState(false);
  const [isStickyBottom, setIsStickyBottom] = useState(false);
  const [cartCount,setCartCount] = useState(0);
  const [cartItem, setCartItem] = useState(null);
  const {currency, rate, symbol} = useCurrencyRate();
  const [skuQty, setSkuQty] = useState({});
  const [reloadOnUpdate, setReloadOnUpdate] = useState(false);
  const [itemTotalQty, setItemTotalQty] = useState(0);
  const [deleteItemSkuId, setDeleteItemSkuId] = useState([]);
  const {setCart} =useCart();
  const [selectedCartItem, setSelectedCartItem] = useState({});
  const [totalItemselected, setTotalItemselected]= useState(0);
  const [totalMarchantSelected, setTotalMarchantSelected]= useState(0);
  const [totalquantitySelected, setTotalquantitySelected]= useState(0);
  const [totalType, setTotalType] = useState(0);
  const [totalCartItemSelected, setTotalCartItemSelected] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [reload, setReload] = useState(false);

  
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const {persist} = useAuth();

  



  //display modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  //const refresh = useRefreshToken();
  const defaultCurrency = "CNY";

 


  useEffect(() => {
    Seo({
      title: 'Cart || Checkstall.com',
      metaDescription: 'Cart'
    });
  }, []);

  useEffect(() => {
   setSkuQty(skuQty);
  }, [skuQty]);


  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getCartItems = async () => {
      setModalShow(true);
        try {

            const response = await axiosPrivate.get(CART_ITEMS_URL, {
                signal: controller.signal
            });

            isMounted && setCartCount(response?.data?.total);
            isMounted && setCart(response?.data?.total);
            isMounted && setLocalStorage('cartTotal', response?.data?.total)
            isMounted && setCartItem(response?.data?.cartItems);
        } catch (err) {
            if(err?.response?.data?.status === 401){
              navigate('/login', { state: { from: location }, replace: true });
            }
        }

        setModalShow(false);
        setReloadOnUpdate(false);
    }

    getCartItems();

    return () => {
        isMounted = false;
        controller.abort();
    }
   
  }, [location,axiosPrivate, reloadOnUpdate])

const getSpecProps = (string) => {
  let spec = '';
  if(string){
     spec = string.split(';');
  }

  let prop1 = "";
  let prop2 = "";
  if(spec[0]){
    prop1 = spec[0].slice(4);
  }
   
  if(spec[1]){
    prop2 = spec[1].slice(4);
  }

  return prop1+" ; "+prop2;
}

const getSite = (Url) => {
  let site = "1688"; //default search site
  if(Url.indexOf('1688.com/') >= 0){ 
    site = "1688";
  }else if(Url.indexOf('taobao.com/') >= 0){
    site = "taobao";
  }else if(Url.indexOf('jd.com/') >= 0){
    site = "jd";
  }

  return site;
}



const getSkuTotalPrice = (sku) => {
  let total = 0; 

  sku.map((val) => {
      if(!isNaN(val.total_price)){
        total += parseFloat(val.total_price);
      }
    });

  return total;
}

const getSkuTotalQty = (sku) => {
  let total = 0; 

  sku.map((val) => {
      if(!isNaN(val.quantity)){
        total += parseFloat(val.quantity);
      }
    });

  return total;
}

//update quantity on button click
const handleQuantityUpdate = (event,count, min_num)  => {

  //if total sku quantity is lass than the min_num (MOQ) show rror message
  let el = event.target;
  let parentEle = el.closest('.cart-body');
  let skuTotalQuantity = parseInt(parentEle.getAttribute('data-item-total-qty'));
  if(count === -1 && skuTotalQuantity < min_num){
      parentEle.querySelector('.cart-min-order-error').setAttribute('style', 'display:block !important;');
      return
  }else{
    parentEle.querySelector('.cart-min-order-error').setAttribute('style', 'display:none !important;');

        let elem = count === -1 ? event.target.nextSibling : event.target.previousSibling;
      let sku_id = elem.name;
      let Qty = parseInt(elem.value) + count;

      //prevent 0 value in quantity
      if(isNaN(Qty) || Qty === "" || Qty <= 0){
        Qty = 1;
        return
      }
        const skuInfo = {
            qty:Qty,
            sku_id:sku_id,
        }

      setSkuQty({
        [sku_id]: skuInfo,
      });

      updateSkuQty(skuInfo);
      }

  

}


const handleQuantityChange = (event) => {

 
  let sku_id = event.target.name;
  let Qty = parseInt(event.target.value);

  //prevent 0 value in quantity
  if(isNaN(Qty) || Qty === "" || Qty <= 0){
    Qty = 1;
    return
  }


  const skuInfo = {
    qty:(Qty),
    sku_id:sku_id,
}

setSkuQty({
  [sku_id]: skuInfo,
});

}

//update quantity onblur
const updateQty = (event, min_num) => {
   //if total sku quantity is lass than the min_num (MOQ) show error message
   let el = event.target;
   let qty = event.target.value;
   let prevQty = event.target.getAttribute('data-prev-total-qty')
   let parentEle = el.closest('.cart-body');
   let skuTotalQuantity = parseInt(parentEle.getAttribute('data-item-total-qty'));
   if(qty  <= prevQty  ){
       if(skuTotalQuantity < min_num){
        parentEle.querySelector('.cart-min-order-error').setAttribute('style', 'display:block !important;');
        return;
       }else{
        //allow increment or decreament as long as totak sku is not less that minminum order
        parentEle.querySelector('.cart-min-order-error').setAttribute('style', 'display:none !important;');
        updateSkuQty(skuQty);
       }
       
   }else{
    parentEle.querySelector('.cart-min-order-error').setAttribute('style', 'display:none !important;');
     updateSkuQty(skuQty);
   }

  

}

const updateSkuQty = (obj) =>{
  let isMounted = true;
  const controller = new AbortController();

 
  let sku_id = obj  ? (Object.values(obj)[0].sku_id ? Object.values(obj)[0].sku_id : obj?.sku_id) : null;
  let qqty = obj  ? (Object.values(obj)[0].qty ? Object.values(obj)[0].qty : obj?.qty) : null;

  const updateSku = async () => {
    setModalShow(true);
      try {

          const response = await axiosPrivate.post('/v1/cart/update-sku',
          JSON.stringify({sku_id,qqty}), {
              signal: controller.signal
          });

          if(response?.data.status === 'ok'){
            setReloadOnUpdate(true);
          }
          //setSkuQty({});
      } catch (err) {

      }

      setModalShow(false);
  }

  updateSku();

  return () => {
      isMounted = false;
      controller.abort();
  }
}



const handleDeleteSku = event => {
  //show prompt modal
  handleShow();

  //get sku id and save in state
  let sku_id = [];
  sku_id.push(event.target.closest('.spec-delete-btn')?.id);
  setDeleteItemSkuId(sku_id);
}

const handleDeleteAction = ()=> {
  //if user choose to delete close modal and delete item,
  let itemId = getselectedItemId();
  deleteSku(deleteItemSkuId, itemId);
  handleClose();
}

const deleteSku = (skuId, itemId) =>{
  const controller = new AbortController();
  let sku_id =  skuId[0]  != undefined ? skuId : itemId;
  const del = async () => {
    setModalShow(true);
      try {

          const response = await axiosPrivate.post('/v1/cart/delete-sku',
          JSON.stringify({sku_id}), {
              signal: controller.signal
          });

          if(response?.data.status === 'ok'){
            setReloadOnUpdate(true);
          }
      } catch (err) {

      }

      setModalShow(false);
  }

  del();

  return () => {
      controller.abort();
  }
}



const getselectedItemId = () =>{
  let item = [];
  
  let keys = Object.keys(selectedCartItem);
  keys.forEach( function(key) {
    item.push(selectedCartItem[key]?.item_id);

  })
   return item;

}



const getItemTotalQty = (data) =>{
{
   let obj = {
      total:getSkuTotalQty(data.sku)
   }
    setItemTotalQty(prev =>{
      return{
        ...prev,
        [data.item_id]: obj
      }
    })}
}



// const selectAll = (e) => {
//     setModalShow(true);
//     const {checked } = e.target;
//     const rootParentNode = e.target.parentNode.classList.contains('select-all') ? 
//                                   e.target.closest('#cart-content') : null;
//     const selectAllCheckBox = rootParentNode?.querySelectorAll('input[type="checkbox"]');
//     if(checked){
//         //setSelectedCartItem(cartItem);
//         for(let i =0; i < cartItem.length; i++){
//           let itemId = cartItem[i].item_id;
//             let data = getCartItemById(itemId);
//             setSelectedCartItem(prev => {
//               return{
//                 ...prev,
//                 [itemId]:data,
//               }
//             });
//         }
//         checkAll(selectAllCheckBox);
//     }else{
//       setSelectedCartItem({});
//       uncheckAll(selectAllCheckBox);
//     }
    
//     const timer = setTimeout(() => {
//       setModalShow(false);
//     }, 500);
//     return () => clearTimeout(timer);
// }

// const selectByGroup = (e) =>{
//   setModalShow(true);
//     const {checked, value } = e.target;
//     const childCheckBoxes = e.target.closest('.cart-info-wrap')?.querySelectorAll('.item-child-checkboxes');
//     const itemId = value;
//       if(checked){//parent box is checked
//           let data = getCartItemById(itemId);
//           setSelectedCartItem(prev => {
//             return{
//               ...prev,
//               [itemId]:data,
//             }
//           });
//           //check all child checkboxes
//           checkAll(childCheckBoxes);
//       }else{//parent box is unchecked

//         delete selectedCartItem[itemId];
//         //uncheck all child checkboxes
//         uncheckAll(childCheckBoxes);
//       }

//       const timer = setTimeout(() => {
//         setModalShow(false);
//       }, 500);
//       return () => clearTimeout(timer);
// }

// const selectOne = (e) => {
//  // setModalShow(true);
//   // Destructuring
//   const { value, checked } = e.target;
//   const skuId = value;
//   const parentCheckBox = e.target.closest('.cart-info-wrap')?.querySelector('.item-child-checkboxes');
//   const itemId = parentCheckBox?.value
//   //user check the box
//       if(checked){
//         let data = getCartItemById(itemId)
//         //item doesn't exist in selectedItem
//           if(selectedCartItem[itemId] === undefined){
//             setSelectedCartItem(prev => {
//               return{
//                 ...prev,
//                 [itemId]:getCartData(data,skuId),
//               }
//             });
//             //check the parent checkBox
//             parentCheckBox.checked = true;
//             //marchantCheckbox.checked = true;
//           }

//           //item existd in selectedItem  add sku
//           else{
//               let prevItem = selectedCartItem[itemId];
//               let preSku = prevItem.sku;
//               //appen newly selected to the prevSku
//               let newSku = getSkuData(data, skuId);
//               //join previous sku with new sku using spread operator
//               preSku.push(newSku);
//               //add the sku to the preItem selected
//               prevItem['sku'] = preSku;
//               setSelectedCartItem(prev => {
//                 return{
//                   ...prev,
//                   [itemId]:prevItem,
//                 }
//               });

//               parentCheckBox.checked = true;
//               //marchantCheckbox.checked = true;
//           }
//       }
//       //user uncheck the box
//       else{
//         //if sku length is greater than 1, remove the selected sku from sku object, then update selected item
//         let prevItem = selectedCartItem[itemId];
//         let preSku = prevItem?.sku;
//         if(preSku?.length > 1){
//             let filteredSku = preSku.filter((value) => value.sku_id !== skuId);
//             prevItem['sku'] = filteredSku;
//             setSelectedCartItem(prev => {
//               return{
//                 ...prev,
//                 [itemId]:prevItem,
//               }
//             });  
//         }else{ //sku is 1 or less remove the item and uncheck the parent checkbox
//             delete selectedCartItem[itemId];
//             setSelectedCartItem(selectedCartItem);
//             //unselect parent checkBox
//             parentCheckBox.checked = false;
//            // marchantCheckbox.checked = false;
//         }
//       }


//  //calculations
 
//   const timer = setTimeout(() => {
//       setModalShow(false);
//     }, 500);
//     return () => clearTimeout(timer);
// };

const nodeArray = (selector, parent=document) => [].slice.call(parent.querySelectorAll(selector));

const selectByItem = (e) =>{
  const {checked, value, id } = e.target;
  let parentNod = e.target.closest('.cart-content');
  const itemId = value;
  let selector = `#${id} , .${id}`; //id will target selected  checkbox while .class will target the child checkbox
  
  const childCheckBoxes = nodeArray(selector, parentNod);


//  let checkbox;
//   for (let i = 0; i < childCheckBoxes?.length; i++) { //level 1
//      checkbox = childCheckBoxes[i].querySelector('input[type="checkbox"]');
//      if(checkbox){
//       //checkbox[i].checked = true;
//       checkbox.checked = checked;
//       console.log(checkbox);
//      }
    
//   }

  


      if(checked){//parent box is checked
          let data = getCartItemById(itemId);
          
          setSelectedCartItem(prev => {
            return{
              ...prev,
              [itemId]:data,
            }
          });
          checkAndUncheck(childCheckBoxes,e.target,parentNod);

      }else{//parent box is unchecked
        delete selectedCartItem[itemId];
        checkAndUncheck(childCheckBoxes,e.target,parentNod)
        setReload(true);
        const timer = setTimeout(() => {
         setReload(false);
          
        }, 100);
        return () => clearTimeout(timer);
      }
}

const selectBySeller = (e) =>{
  //return;
  const {checked,  id } = e.target;
  let parentNod = e.target.closest('.cart-content');
  let selector = `.${id}`; // .class will target the child checkbox
  const childCheckBoxes = nodeArray(selector, parentNod);
  const siblingsCheckbox = nodeArray(`#${id}`, parentNod); //target the siblings checkbox


        for(let i=0; i < childCheckBoxes.length; i++){
          const itemId = getId(childCheckBoxes[i].id)[1];
          if(checked){//parent box is checked
            let data = getCartItemById(itemId);
            setSelectedCartItem(prev => {
              return{
                ...prev,
                [itemId]:data,
              }
            });
  
            checkAndUncheck(childCheckBoxes,e.target,parentNod);
            checkAndUncheck(siblingsCheckbox,e.target,parentNod)
        }else{//parent box is unchecked
  
          //delete selectedCartItem[itemId];
          setSelectedCartItem({});
          checkAndUncheck(childCheckBoxes,e.target,parentNod);
          checkAndUncheck(siblingsCheckbox,e.target,parentNod)
        }
      }
      
      
}

const selectByProps = (e) => {
  const {checked, value, id } = e.target;
  const skuId = value;

  const itemId = getId(e.target.getAttribute('data-parent-id'))[1];
  let parentNod = e.target.closest('.cart-content');
  let selector = `#${id} , .${id}`; //id will target selected  checkbox while .class will target the child checkbox
  const childCheckBoxes = nodeArray(selector, parentNod);
  
   //user check the box
   
       if(checked){
         let data = getCartItemById(itemId);
         //item doesn't exist in selectedItem
           if(selectedCartItem[itemId] == undefined ){

             setSelectedCartItem(prev => {
               return{
                 ...prev,
                 [itemId]:getCartData(data,skuId),
               }
             });
             checkAndUncheck(childCheckBoxes,e.target,parentNod);
           }
 
           //item existd in selectedItem  add sku
           else{
               let prevItem = selectedCartItem[itemId];
               let preSku = prevItem?.sku;
               let newSku = getSkuData(data, skuId);
               preSku?.push(newSku);
               prevItem['sku'] = preSku;
               setSelectedCartItem(prev => {
                 return{
                   ...prev,
                   [itemId]:prevItem,
                 }
               });
 
               checkAndUncheck(childCheckBoxes,e.target,parentNod);
           }
       }
       //user uncheck the box
       else{
         //if sku length is greater than 1, remove the selected sku from sku object, then update selected item
         let prevItem = selectedCartItem[itemId];
         let preSku = prevItem?.sku;
         if(preSku?.length > 1){
             let filteredSku = preSku.filter((value) => value.sku_id !== skuId);
             prevItem['sku'] = filteredSku;
             setSelectedCartItem(prev => {
               return{
                 ...prev,
                 [itemId]:prevItem,
               }
             });  
            //delete selectedCartItem[itemId]?.sku[skuId];
         }else{ //sku is 1 or less remove the item and uncheck the parent checkbox
             delete selectedCartItem[itemId];
             setReload(true);
             checkAndUncheck(childCheckBoxes,e.target,parentNod);
             const timer = setTimeout(() => {
              setReload(false);
              
            }, 100);
            return () => clearTimeout(timer);
         }

         
       }
 
 
  //calculations
};

const selectAll = (e) => {
  const {checked } = e.target;
  const rootParentNode = e.target.closest('.cart-content');
  const childCheckBoxes = rootParentNode?.querySelectorAll('input[type="checkbox"]');
  if(checked){
      for(let i =0; i < cartItem.length; i++){
        let itemId = cartItem[i].item_id;
          let data = getCartItemById(itemId);
          setSelectedCartItem(prev => {
            return{
              ...prev,
              [itemId]:data,
            }
          });
      }
      checkAndUncheck(childCheckBoxes,e.target,rootParentNode);
  }else{
    setSelectedCartItem({});
    checkAndUncheck(childCheckBoxes,e.target,rootParentNode);
  }  

}
 

const checkAndUncheck = (checkboxNode, target,parentNod) =>{
  for (let i = 0; i < checkboxNode?.length; i++) { //level 1
    checkboxNode[i].checked = target.checked;

    let childNode = nodeArray(`.${checkboxNode[i].id}`, parentNod);  //level 2
    for (let i = 0; i < childNode?.length; i++) {
      childNode[i].checked = target.checked;
      //let childNode = nodeArray(`.${childNode[i].id}`, parentNode);  //level 3
    }
}


      const parentId = target.getAttribute('data-parent-id');
      const siblings = nodeArray(`.${parentId}`, parentNod);
      const level_1_parent = parentId != null ?  parentNod.querySelector(`#${parentId}`) : null;
      const level_2_parent = level_1_parent != null ? parentNod.querySelector(`#${level_1_parent?.getAttribute('data-parent-id')}`) : null;
      //for select all, 
      const level_3_parent = level_2_parent != null ? parentNod.querySelector(`#${level_2_parent?.getAttribute('data-parent-id')}`) : null;



      //  get checked state of siblings
      //  are every or some siblings checked (using Boolean as test function) 
      const checkStatus = siblings.map(check => check.checked);
      const every  = checkStatus.every(Boolean);
      const some = checkStatus.some(Boolean); 

      //  check parent if all siblings are checked
      //  set indeterminate if not all and not none are checked
      if(level_1_parent){
        level_1_parent.checked = every;   
        level_1_parent.indeterminate = !every && every !== some;
      }
      
      if(level_2_parent){
        level_2_parent.checked = every;   
        level_2_parent.indeterminate = !every && every !== some;
      }
      

      if(level_3_parent){
        level_3_parent.checked = every;   
        level_3_parent.indeterminate = !every && every !== some;
      }
      
}
const getId = (string) =>{
  return string.split('-');
}

useEffect(() => {

  setTotalItemselected(getTotalItemselected());
  setTotalquantitySelected(getTotalquantitySelected());
  setTotalType(getTotaltype());
  setTotalCartItemSelected(getTotalCartItemSelected());
  setTotalAmount(getTotalAmount());
},[selectedCartItem, reload])

const handleCreateOrder = (e) =>{
  if(Object.keys(selectedCartItem).length < 1){
    // setErrorAlart(true);
    // setErrorText('No Item selected');
    // const timer = setTimeout(() => {
    //   setErrorAlart(false);
    // }, 500);
    // return () => clearTimeout(timer);
    alert('No Item selected');
    return;
  }
  
    //setOrder(selectedCartItem);
    setLocalStorage('orderItem', encryptData(selectedCartItem));
    setSelectedCartItem({});
    navigate(`/order/create_order/?s=cart`);
}




const getCartData = (data, skuId) => {
  let cart_item = {
    cart_id:data.id,
    item_id:data.item_id,
    title:data.title,
    price:data.price,
    orginal_price:data.orginal_price,
    nick:data.nick,
    min_num:data.min_num,
    detail_url:data.detail_url,
    pic_url:data.pic_url,
    post_fee:data.post_fee,
    seller_id:data.seller_id,
    priceRange:data.priceRange,
    seller_info:data.seller_info,
    sku:[getSkuById(data,skuId)],
    rate: rate,
  };
  return cart_item;
}

const getSkuData = (data, skuId) => {
  return getSkuById(data,skuId);
}

const getCartItemById = (itemId) =>{
  
    let newObj = {};
    cartItem.map((value) =>{
      if(value.item_id == itemId){
          newObj = value;
      }
    });

    return newObj;
}

const getSkuById = (data,skuId) =>{
  let newObj = {};
  data.sku.map((value) => {
      if(value.sku_id == skuId){
        newObj = value;
      }
  })

  return newObj;
}

 const checkAll = (checkboxNode) =>{
    for (let i = 0; i < checkboxNode?.length; i++) {
      checkboxNode[i].checked = true;
    }
 }

 const uncheckAll = (checkboxNode) =>{
  for (let i = 0; i < checkboxNode?.length; i++) {
    checkboxNode[i].checked = false;
  }
}

const getTotalItemselected = () =>{
  let total = 0;
  let keys = Object.keys(selectedCartItem);
  keys.forEach( function(key) {
    total  += selectedCartItem[key].sku.length
  })
   return total;
}

const getTotalquantitySelected = () =>{
  let total = 0;
  
  let keys = Object.keys(selectedCartItem);
  keys.forEach( function(key) {
     selectedCartItem[key].sku.map((val) => {
       total  += parseInt(val.quantity);
     });
    
  })
   return total;
}

const getTotalAmount = () =>{
  let total = 0;
  
  let keys = Object.keys(selectedCartItem);
  keys.forEach( function(key) {
     selectedCartItem[key].sku.map((val) => {
       total  += parseInt(val.total_price);
     });
    
  })
   return total;
}

const getTotaltype = () =>{
  let keys = Object.keys(selectedCartItem);
  return keys.length;
}

const getTotalCartItemSelected = () =>{
  let keys = Object.keys(selectedCartItem);
  return keys.length;
}


  return (
    <>
      <CustomBsModal
        show={show}
        onHide={handleClose}
        handleClose={handleClose}
        handleAction={handleDeleteAction}
        headingText={"Delete Item"}
        bodyText={"Are you sure you want to delete this item?"}
        closeButtonText={"Cancel"}
        saveButtonText={"Delete"}
        closeButtonVariant={"secondary"}
        saveButtonVariant={"danger"}
        dialogClassName="modal-w"
        backdrop="static"
        keyboard={false}
      />
      <Spinner cssClass={"semi-dark-bg"} show={modalShow} />
      <Navbar />

      <div className="content cart-content d-flex flex-column" id="cart-content">
        <div className="cart-header d-flex flex-column shadow-sm bg-white rounded">
          <div className="cart-total">Shopping Cart({cartCount})</div>
          <div className="column-name d-flex justify-content-between">
            <div
              className="select-all"
              // style={{ border: "1px red solid", display: 'flex' }}
            >
              <input
                size="small"
                type="checkbox"
                className="selectAllItem"
                onChange={selectAll}
                name="selectAllItem"
                id={'select-all'}

              />{" "}
              All
            </div>
            <div className="cart-header-item" style={{ flexBasis: "42%" }}>
              Item
            </div>
            <div className="cart-header-quantity" style={{ flexBasis: "12%" }}>
              Quantity
            </div>
            <div
              className="cart-header-listed-price"
              style={{ flexBasis: "8%" }}
            >
              Listed Price
            </div>
            <div
              className="cart-header-discount-price"
              style={{ flexBasis: "8%" }}
            >
              Discount Price
            </div>
            <div className="cart-header-total" style={{ flexBasis: "8%" }}>
              Total
            </div>
          </div>
        </div>

        {cartItem !== null ? (
          cartItem.map((item, index) => {
            return (
              <div
                className="cart-body d-flex flex-column shadow-sm bg-white rounded"
                key={index}
                data-item-total-qty={getSkuTotalQty(item.sku)}
              >
                <div
                  className={`cart-info-wrap d-flex justify-content-between  cart-info-wrap-${item.seller_id}`}
                >
                  <div className="select-one-item">
                    <input
                      size="small"
                      type="checkbox"
                      name="selectedItemParent"
                      value={item.item_id}
                      className={`sellerSelect-${item.seller_id}`}
                      onChange={selectByItem} 
                      id={`itemId-${item.item_id}`}  
                      data-parent-id= {'sellerSelect-'+item.seller_id}

                    />
                  </div>
                  <div className="cart-item-image">
                    <img src={item.pic_url} alt={item.title} />
                  </div>
                  <div className="info-props-wrap d-flex flex-column">
                    <div className="item-title">
                      <Link
                        to={`/item/?site=${getSite(item.detail_url)}&item=${
                          item.item_id
                        }`}
                        style={{ textDecoration: "none", color: "#1a1a1a" }}
                        target="_blank"
                      >
                        {item.title}
                      </Link>
                    </div>
                    {item.sku.map((val, i) => {
                      //set all checkbox value to false on default
                      return (
                        <div
                          className="spec-wrap d-flex justify-content-between"
                          key={i}
                        >
                          <div className="spec-select">
                            <input
                              size="small"
                              type="checkbox"
                              onChange={selectByProps}
                              name="selectedItem"
                              value={val.sku_id}
                              id={`propsSelect-${val.sku_id}`} 
                              className={`itemId-${item.item_id}`}
                              data-parent-id= {'itemId-'+item.item_id}
                            />
                          </div>
                          <div className="spec-img">
                            <img src={val?.url || item.pic_url} alt="" />
                          </div>
                          <div className="spec-value">
                            {getSpecProps(val.properties_name)}
                          </div>

                          <div className="spec-qty">
                            <div className=" d-flex justify-content-between">
                              <button
                                type="button"
                                className="minus-btn cart-qty-btn"
                                onClick={(event) =>
                                  handleQuantityUpdate(event, -1, item.min_num)
                                }
                              >
                                -
                              </button>
                              <input
                                type="text"
                                name={val.sku_id}
                                onChange={(event) =>
                                  handleQuantityChange(event, item.min_num)
                                }
                                onBlur={(event) =>
                                  updateQty(event, item.min_num)
                                }
                                value={skuQty[val.sku_id]?.qty || val.quantity}
                                className="cart-qty-input"
                                data-prev-total-qty={val.quantity}
                              />
                              <button
                                type="button"
                                className=" cart-qty-btn"
                                onClick={(event) =>
                                  handleQuantityUpdate(event, 1, item.min_num)
                                }
                              >
                                +
                              </button>
                            </div>
                            <span className="cart-min-order-error">
                              Min Order is {item.min_num}
                            </span>
                          </div>
                          <div className="spec-delete-btn" id={val.sku_id}>
                            <FaTrashAlt
                              className="spec-trash"
                              onClick={handleDeleteSku}
                            />
                          </div>
                          <div className="spec-listed-price">
                            {symbol}
                            {RoundToTwo(
                              Exchanger(
                                defaultCurrency,
                                currency,
                                val.price,
                                rate
                              )
                            )}
                          </div>
                          <div className="spec-discount-price">
                            {symbol}
                            {RoundToTwo(
                              Exchanger(
                                defaultCurrency,
                                currency,
                                val.price,
                                rate
                              )
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="item-total">
                    {symbol}
                    {RoundToTwo(
                      Exchanger(
                        defaultCurrency,
                        currency,
                        getSkuTotalPrice(item.sku),
                        rate
                      )
                    )}
                  </div>
                </div>
                <div className="marchant-name-wrap d-flex justify-content-end">
                  <div className="select-all-marchant">
                    {/* <input 
                        type="checkbox"
                        name='marchant'
                        value={value.seller_id}
                        id={`marchant-checkbox-${index}`}
                        className={`marchant-checkbox-${value.seller_id}`}
                        onChange={selectBySeller}
                    />  */}
                  </div>
                  <div className="marchant-name">
                    <a
                      href={item.seller_info.shop_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.nick}
                    </a>
                  </div>
                </div>
                {/* <hr /> */}
              </div>
            );
          })
        ) : (
          <div
            className="empty-cart-wrap"
            style={{ backgroundImage: `url(${emptyCartImg})` }}
          >
            {/* <img src={emptyCartImg} alt="" /> */}
            <div className="empty-cart-info">
              <span>Your cart is empty</span>
              <span>
                You can add items from <Link to="/">Home</Link>
              </span>
            </div>
          </div>
        )}
        {cartCount && (
          <div
            className={`cart-bottom-wrapper check-out-wrap d-flex justify-content-between shadow bg-white rounded`}
          >
            <div className="check-out-inner-left  d-flex justify-content-between">
              <div className="select-all-bottom select-all">
                  <input
                    size="small"
                    type="checkbox"
                    className="selectAllItem"
                    onChange={selectAll}
                    name="selectAllItem"
                    id={'select-all'}

                  />{" "}
                All
              </div>
              <div className="delete-selected-btn">
                <button type="button" style={{color:'crimson'}} onClick={handleDeleteSku}>
                  Delete <span>{totalCartItemSelected || 0}</span> Item
                </button>
              </div>
            </div>
            <div className="check-out-inner-right d-flex justify-content-end">
              {/* <div style={{ flexBasis: "13%" }}>
                Marchant: <span>1</span>
              </div> */}
              <div style={{ flexBasis: "13%" }}>
                Types: <span>{totalType}</span>
              </div>
              <div style={{ flexBasis: "14%" }}>
                Quantity: <span>{totalquantitySelected}</span>
              </div>
              <div style={{ flexBasis: "30%" }}>
                Sub Total:
                <span className="cart-bottom-total">
                  <span style={{ fontSize: "14px" }}>{symbol}&nbsp;</span>
                  {RoundToTwo(
                    Exchanger(defaultCurrency, currency, totalAmount, rate)
                  )}
                </span>
              </div>
              <div style={{ flexBasis: "20%" }} className="cart-calculate">
                <button type="button" onClick={handleCreateOrder}>Calculate </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Cart
