import React from 'react';
import Tabs from "../utilities/tabs/Tabs";
import "../../assets/css/description.css";
import { ChevronRight, Close ,HorizontalRule} from '@mui/icons-material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';


function Description(props) {

  return (
    <div className='description-tab-wrapper'>
      <Tabs>
        <div label="Description">
          <div className="row" style={{ margin: "3em auto" }}>
            {props.data.item.props
              ? props.data.item.props.map((val, index) => {
                  return (
                    <div className="item-attr-wrap col-md-4" key={index}>
                      <div className="item-attr-name">{val.name}</div>
                      <div className="item-attr-value">{val.value}</div>
                    </div>
                  );
                })
              : ""}
          </div>
          {props.data.item.desc_img
            ? props.data.item.desc_img.map((value, index) => {
                return (
                  <div className="desc_img" key={index}>
                    <img src={value} alt="no-alt" />
                  </div>
                );
              })
            : ""}
        </div>
        <div label="Service and Commitment">
          After 'while, <em>Crocodile</em>!
        </div>
        <div label="Delivery and Payment">
          <div className="payment-delivery">
            <p>
              * Shopping is divided into two stages of Chinese purchasing and
              international delivery, including two payments. You are advised to
              do an international freight assessment before placing an order
            </p>
            <p>
              Phase one: You need to pay for the cost of the goods and the
              logistics cost of the goods in China. EShopping: Easy
              shopping,Easy go will provide you with the purchase service. The
              purchased products will be sent to the EShopping: Easy
              shopping,Easy go transit warehouse for temporary storage, and the
              quality inspection service of the products will be carried out at
              the same time. There is a 180-day free storage period after the
              goods are put into storage. You can purchase multiple times and
              package it for international shipping, which saves money
            </p>
            <p>
              Phase two: Package the goods and then generate a package order,
              pay the international logistics cost of the order, and then the
              package can be transported internationallyshopping process {">"}{" "}
              Freight calculation widget
            </p>
          </div>
        </div>
        <div label="Shipping Note ">
          After 'while, <em>Crocodile</em>!
        </div>
      </Tabs>
    </div>
  );
}

export default Description