import React from 'react';
import classes from './WomensSize.module.css';
import {
  mensShirtSizeData,
  mensSuitSizeData,
  mensPantSizeData,
} from "./womensData";

const WomensSize = () => {
  return (
    <section className={classes.wrapper}>
      <div className={classes.title}>
        <div className={classes.line} />
        <h2>Women's Size Chart</h2>
        <div className={classes.line} />
      </div>
      <div className={classes.tableContainer}>
        <h4 className={classes.shirtTitle}>
          Women's shirt size comparison table
        </h4>
        <table className={classes.tableHolder}>
          <thead>
            <tr>
              <th className={classes.shirtSize}>shirt size</th>
              <th>S</th>
              <th>M</th>
              <th>L</th>
              <th>XL</th>
              <th>XXL</th>
              <th>XXXL</th>
            </tr>
          </thead>
          {mensShirtSizeData.map((value, index) => {
            return (
              <tbody key={index}>
                <tr>
                  <td className={classes.tabledataTitle}>{value?.shirtSize}</td>
                  <td>{value?.xs}</td>
                  <td>{value?.s}</td>
                  <td>{value?.m}</td>
                  <td>{value?.l}</td>
                  <td>{value?.xl}</td>
                  <td>{value?.xxl}</td>
                </tr>
              </tbody>
            );
          })}
        </table>
        {/* <div className={classes.tableTitle}>
          Women's shirt size comparison table info1 <br />
          Women's shirt size comparison table info2 <br />
          Women's shirt size comparison table info3 <br />
        </div> */}
      </div>
      <div className={classes.tableContainer}>
        <h4 className={classes.shirtTitle}>Dress size comparison table</h4>
        <table className={classes.tableHolder}>
          <thead>
            <tr>
              <th className={classes.shirtSize}>Skirt size</th>
              <th>S</th>
              <th>M</th>
              <th>L</th>
              <th>XL</th>
              <th>XXL</th>
            </tr>
          </thead>
          {mensSuitSizeData.map((value, index) => {
            return (
              <tbody key={index}>
                <tr>
                  <td className={classes.tabledataTitle}>{value?.size}</td>
                  <td>{value?.s}</td>
                  <td>{value?.m}</td>
                  <td>{value?.l}</td>
                  <td>{value?.xl}</td>
                  <td>{value?.xxl}</td>
                </tr>
              </tbody>
            );
          })}
        </table>
        <div className={classes.tableTitle}>
          Dress size comparison table info1
        </div>
      </div>
      <div className={classes.tableContainer}>
        <h4 className={classes.shirtTitle}>
          Women's trousers size comparison table
        </h4>
        <table className={classes.tableHolder}>
          <thead>
            <tr>
              <th className={classes.shirtSize}>Pants</th>
              <th>S</th>
              <th>M</th>
              <th>L</th>
              <th>XL</th>
            </tr>
          </thead>
          {mensPantSizeData.map((value, index) => {
            return (
              <tbody key={index}>
                <tr>
                  <td className={classes.tabledataTitle}>{value?.size}</td>
                  <td>{value?.s}</td>
                  <td>{value?.m}</td>
                  <td>{value?.l}</td>
                  <td>{value?.xl}</td>
                </tr>
              </tbody>
            );
          })}
        </table>
        {/* <div className={classes.tableTitle}>
          Women's shirt size comparison table info1 <br />
          Women's shirt size comparison table info2 <br />
          Women's shirt size comparison table info3 <br />
        </div> */}
      </div>
    </section>
  );
}

export default WomensSize
