import React from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom"

const Unauthorized = () => {
    const navigate = useNavigate();
    const location = useLocation();
   

    let from =  "";
    const path = location.state?.from?.pathname;
    if(path){
       let query = location.state?.from?.search || "";
       if(query){
          from = path + query;
       }else{
         from = path;
       }
  
    }else{
       from = "/";
    }

    const goBack = () => navigate(from);

    return (
      <>
        {/* <div className="not-found">
          <h2>403</h2>
          <h5>Unauthorized</h5>
          <p>You do not have access to the requested page.</p>
          <div className="flexGrow">
            <button onClick={goBack}>Go Back</button>
          </div>
        </div> */}
        <div className="not-found-wrapper">
          <div className="not-found-container">
            <div className="not-found-title">
              <h1 className="not-found-error">403</h1>
              <h1 className="not-found-info">Oops!! You're unauthorized for this page</h1>
            </div>
            <div className="not-found-reason">
              <span>You do not have access to the requested page.</span>
              <span>Get in touch with the site owner.</span>
            </div>
            <button className="not-found-button" onClick={goBack}>
              Go Back
            </button>
          </div>
        </div>
      </>
    );
        
    
}

export default Unauthorized
