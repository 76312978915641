import React, { useEffect, useState } from "react";
import "../../../assets/css/process-payment.css";
import { Seo } from "../../utilities/Seo";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useRefreshToken from "../../../hooks/useRefreshToken";
import useCheckAuthWithRedirect from "../../../hooks/useCheckAuthWithRedirect";
import Spinner from "../../utilities/Spinner";
import Form from "react-bootstrap/Form";
import getLocalStorage from "../../functions/getLocalStorage";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Encryption from "../../functions/Encryption";
import removeLocalStorage from "../../functions/removeLocalStorage";
import Navbar from "../../navbar/Navbar";

function ProcessPayment(props) {
  //check for authentication on the page
  useCheckAuthWithRedirect();

  const [modalShow, setModalShow] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({});
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [link, setLink] = useState("");
  const [linkTitle, setLinkTitle] = useState("");
  const [searchParams] = useSearchParams();
  const actionType = searchParams.get("actionType");

  let data = {};
  if (props?.data?.fromType == "invoice") {
    data = props.data;
  } else {
    data = getLocalStorage("orderData")
      ? JSON.parse(getLocalStorage("orderData"))
      : {};
  }
  // console.log(data);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  //set page title
  useEffect(() => {
    const pageTitle =
      "DajuPay  || Reliable and secure online payment alternative";
    Seo({
      title: pageTitle,
      metaDescription: "Reliable and secure payment alternative",
    });
  }, []);

  const processPayment = (data) => {
    let isMounted = true;
    const controller = new AbortController();
    setModalShow(true);
    const doProcessPayment = async () => {
      const encryption = new Encryption();
      let pass = encryption.encrypt(password, encryption.key);
      try {
        const response = await axiosPrivate.post(
          data.endpoint,
          JSON.stringify({ data, pass }),
          {
            signal: controller.signal,
          }
        );

        //handle response
        if (response?.data?.code == 101) {
          let mes = response?.data?.message;
          setError(mes);
          setLink("/members/wallet/settings");
          setLinkTitle("SET NOW");
        } else if (response?.data?.code == 102) {
          setError(response?.data?.message);
        } else if (response?.data?.code == 103) {
          removeLocalStorage("orderIdList");
          removeLocalStorage("orderItem");
          navigate("/members/home/index", { state: { refresh: true } });
        } else if (response?.data?.code == 104) {
          let mes = response?.data?.message + " Go to";
          setError(mes);
          setLink("/members/wallet/top-up");
          setLinkTitle(" TOP UP");
        } else if (response?.data?.code == 105) {
          let mes =
            "Incorrect password, you have " +
            response?.data?.message +
            " attempt left.";
          setError(mes);
        }
      } catch (err) {}
      setModalShow(false);
    };

    doProcessPayment();
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const handlePayment = () => {
    //check if payment is coming from invoice page
    // if(props.fromType == 'invoice'){
    //     data['amount'] = props.data.amount;
    //     data['transaction_type'] = process.env.REACT_APP_TRANSACTION_TYPE_INVOICE;
    //     data['payment_method'] = process.env.REACT_APP_WALLET_PAY_METHOD;
    //     data['subject'] = props.data.subject;
    //     data['id'] = props.data.id;

    //     paymentUrl = "v1/process-payment/pay-invoice";
    // }

    //process the payment only when data is set
    if (data && data != null) {
      processPayment(data);
    }
  };

  return (
    <>
      <Spinner cssClass={"semi-dark-bg"} show={modalShow} />
      {props?.data?.fromType === "invoice" ? (
        <>
          {/* <Spinner cssClass={"semi-dark-bg"} show={modalShow} /> */}
          <div className="process-invoice-payment-wrap">
            <h2>Enter your password to complete payment</h2>
            <div className="process-invoice-payment-box">
              <h3 className="proccess-invoice-payment-title">
                You are paying the amount of <span>₦{data?.amount}</span> for
                <span className="processInvoicePayment-title-span">
                  {data?.title}
                </span>
              </h3>
              <div className="input-box">
                <Form.Group size="lg" controlId="password">
                  <label className="password-label">
                    Enter Wallet Password
                  </label>
                  <Form.Control
                    type="password"
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span className="walletErr">
                    {error}{" "}
                    {link && (
                      <Link style={{ textDecoration: "none" }} to={link}>
                        {linkTitle}
                      </Link>
                    )}
                  </span>
                </Form.Group>
              </div>
              <div className="pp-btn" onClick={handlePayment}>
                <button>Pay</button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Navbar />
          
          <div className="process-payment-wrap">
            <div className="process-payment-container">
              <h2>Enter your password to complete payment</h2>
              <div className="process-payment-box">
                <h3 className="proccess-payment-title">
                  You are paying the amount of{" "}
                  <span>₦{data?.total_amount}</span> for
                  <span className="processPayment-title-span">
                    {data?.action_type === "bulkOrderPayment"
                      ? data?.title[0]
                      : data?.title}
                  </span>
                </h3>
                <div className="input-box">
                  <Form.Group size="lg" controlId="password">
                    <label className="password-label">
                      Enter Wallet Password
                    </label>
                    <Form.Control
                      type="password"
                      value={password}
                      required
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span className="walletErr">
                      {error}{" "}
                      {link && (
                        <Link style={{ textDecoration: "none" }} to={link}>
                          {linkTitle}
                        </Link>
                      )}
                    </span>
                  </Form.Group>
                </div>
                <div className="pp-btn" onClick={handlePayment}>
                  <button>Pay</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ProcessPayment;
