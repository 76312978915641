import axios from "../api/axios";
import useCurrencyRate from "../hooks/useCurrencyRate";
import setLocalStorage from "../components/functions/setLocalStorage";

const GET_CURRENCY_URL = "/v1/helpers/get-currency-rate?currency=";

const useGetCurrencyRate = () => {
    const { setCurrency, setRate, setSymbol } = useCurrencyRate();

    const getCurrencyRate = async (currency) => {
        
        try {
            const response = await axios.get(GET_CURRENCY_URL+currency);
                setCurrency(response?.data?.currency);
                setRate(response?.data?.rate);
                setSymbol(response?.data?.symbol );
                setLocalStorage('currency', response?.data?.currency);
                setLocalStorage('rate', response?.data?.rate);
                setLocalStorage('symbol', response?.data?.symbol);
        } catch (err) {
            console.error(err);
        }
    }

    return getCurrencyRate;
}

export default useGetCurrencyRate