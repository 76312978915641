import React, { useState, useEffect } from "react";
import classes from "../../../assets/css/walletPin.module.css";
import Form from "react-bootstrap/Form";
import Spinner from "../../utilities/Spinner";
import "../../../assets/css/settings.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  NavigateNext,
  LockOutlined,
  ChevronLeft,
  MailOutlined,
  DoneOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Seo } from "../../utilities/Seo";
import { getCookie } from "../../functions/CustomCookies";
import { maskEmail, maskPhoneNumner } from "../../functions/Helpers";
import Termii from "../../../api/Termii";
import Encryption from "../../functions/Encryption";
import getLocalStorage from "../../functions/getLocalStorage";
import { isJSON } from "../../functions/Helpers";

function WalletPassword() {
  const [modalShow, setModalShow] = useState(false);
  const [isWalletPasswordSet, setIsWalletPasswordSet] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState({});
  const [successMessage, setSuccessMessage] = useState(false);
  const [code, setCode] = useState("");
  const [isCountDown, setIsCountDown] = useState(false);
  const [pinId, setPinId] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const axiosPrivate = useAxiosPrivate();
  const userModel =
    getCookie("user") || isJSON(getLocalStorage("user"))
      ? JSON.parse(getLocalStorage("user"))
      : {};
  const [codeError, setCodeError] = useState("");
  const [isChecking, setIsChecking] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [passwordError, setPasswordError] = useState();
  const [count, setCount] = useState("");
  const [passError, setPassError] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [withdrawalState, setWithdrawalState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  useEffect(() => {
    const pageTitle = "Wallet Settings || Checkstall.com";
    Seo({
      title: pageTitle,
      metaDescription: "Wallet Settings",
    });
  });

  useEffect(() => {
    getPasswordStatus();
  }, [isWalletPasswordSet]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const drawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setWithdrawalState({ ...withdrawalState, [anchor]: open });
  };

  const getPasswordStatus = () => {
    let isMounted = true;
    const controller = new AbortController();

    const doGetPasswordStatus = async () => {
      setModalShow(true);

      try {
        const response = await axiosPrivate.post("v1/wallet/is-password-set", {
          signal: controller.signal,
        });
        isMounted && setIsWalletPasswordSet(response?.data?.code);
      } catch (err) {
        console.log(err.response?.data);
      }
      setModalShow(false);
    };

    doGetPasswordStatus();
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const setWalletPassword = () => {
    let isMounted = true;
    const controller = new AbortController();
    setModalShow(true);

    const doSetPassword = async () => {
      const encryption = new Encryption();
      const pass = encryption.encrypt(password, encryption.key);
      const confirmPass = encryption.encrypt(confirmPassword, encryption.key);
      try {
        const response = await axiosPrivate.post(
          "v1/wallet/set-password",
          JSON.stringify({ pass, confirmPass }),
          {
            signal: controller.signal,
          }
        );

        if (response?.data?.code == 101) {
          setSuccessMessage(response?.data?.message);
          setPassword("");
          setConfirmPassword("");
          setCode("");
          setCount("");
          setIsVerified(false);
          setModalShow(false);
          const timer = setTimeout(() => {
            setSuccessMessage(false);
          }, 500);
          return () => clearTimeout(timer);
        } else {
          isMounted && setError(response?.data?.message);
        }
      } catch (err) {
        console.log(err.response?.data);
        if (err.response.status === 401) {
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
      setModalShow(false);
    };

    doSetPassword();
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    let anchor = "right";
    setState({ ...state, [anchor]: successMessage });
  }, [successMessage]);

  const handleSetPasswrod = (e) => {
    e.preventDefault();
    if (password.length !== 6) {
      setPassError("Password length must be 6");
    } else if (password !== confirmPassword) {
      setPasswordError("Password don't match");
      return;
    } else if (!isVerified) {
      setCodeError("Incorrect / invalid code");
    } else {
      setWalletPassword();
    }
  };

  const countDown = (selectorId, limit) => {
    let counts = setInterval(() => {
      let count = document.getElementById(selectorId);
      //count.innerHTML=--limit;
      setCount(--limit);
      if (limit === 0) {
        clearInterval(counts);
        setIsCountDown(false);
        setCount("");
      }
    }, 1000);
  };

  const sendCode = () => {
    setIsCountDown(true);
    countDown("counter", 60);

    const termiiInstance = Termii();

    //Send OTP
    let endpoint = "/v1/api/api-send-verify-code";
    termiiInstance
      .send_verify_code(endpoint, userModel?.email)
      .then((response) => {});
  };
  const verifyCode = () => {
    const termiiInstance = Termii();
    setIsChecking(true);
    //verify OTP
    if (code) {
      termiiInstance.verify_email_otp(code).then((response) => {
        if (response.verified == true) {
          setIsVerified(true);
          setIsChecking(false);
          setCount("");
        } else {
          setCodeError("Incorrect / invalid code");
          setIsChecking(false);
        }
      });
    } else {
      setCodeError("Please get another code");
      setIsChecking(false);
    }
  };

  const sendSms = () => {
    setIsCountDown(true);
    countDown("counter", 60);

    const termiiInstance = Termii();

    //Send OTP
    termiiInstance.send_verification_otp(userModel?.phone).then((response) => {
      if (response.error == 101) {
        setPhoneError(response.message);
        return;
      }

      if (parseInt(response.message.status) === 200) {
        setPinId(response.message.pinId);
      }
    });
  };

  const verifySms = () => {
    const termiiInstance = Termii();
    setIsChecking(true);
    //verify OTP
    if (pinId && code) {
      termiiInstance.verify_otp(pinId, code).then((response) => {
        if (response.message.verified == true) {
          setIsVerified(true);
          setIsChecking(false);
        } else if (response.message.verified == false) {
          setCodeError("Incorrect code");
          setIsChecking(false);
        } else if (
          response.message.status == 400 &&
          response.message.verified == "Expired"
        ) {
          setCodeError("Code already Expired");
          setIsChecking(false);
        }
      });
    } else {
      setCodeError("Please get another code");
      setIsChecking(false);
    }
  };

  useEffect(() => {
    if (code.length === 6) {
      userModel?.phone ? verifySms() : verifyCode();
    }
  }, [code]);

  const handleSetCode = (e) => {
    setCodeError("");
    setCode(e.target.value);
  };

  const verifyPassword = (e) => {
    if (e.target.value.length < 6 || e.target.value.length > 6) {
      setPassError("Password length must be 6");
    }
  };

  return (
    <React.Fragment>
      <Spinner cssClass={"semi-dark-bg"} show={modalShow} />
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div
            className={classes.settingWrap}
            onClick={toggleDrawer("right", true)}
          >
            <div className={classes.settingIcon}>
              <LockOutlined sx={{ fontSize: "3rem", color: "red" }} />
            </div>
            <div className={classes.linkBox}>
              {isWalletPasswordSet && (
                <div
                  className={classes.settingName}
                  onClick={toggleDrawer("right", true)}
                >
                  Change Your Wallet PIN
                </div>
              )}
              {!isWalletPasswordSet && (
                <div
                  className={classes.settingName}
                  onClick={toggleDrawer("right", true)}
                >
                  Set Your Wallet PIN
                </div>
              )}
            </div>
            <div className={classes.arrowForward}>
              <NavigateNext sx={{ fontSize: "3rem" }} />
            </div>
          </div>
        </div>
      </div>

      {/* set and update pin form */}
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            PaperProps={{
              sx: {
                height: "440px",
                width: "400px",
                top: "25%",
                left: "40%",
                borderRadius: "0.6rem",
                padding: "1rem",
              },
            }}
          >
            <div className={classes.modalWrapper}>
              <h3 className={classes.title}>
                {isWalletPasswordSet ? "Update Pin" : "Set Pin"}
              </h3>
              <Form
                onSubmit={handleSetPasswrod}
                className={classes.formWrapper}
              >
                {userModel?.phone ? (
                  <div className={classes.phoneNumberBox}>
                    <div className={classes.phoneNumber}>
                      Your Phone number:
                    </div>
                    <div className={classes.phoneNumber}>
                      {maskPhoneNumner(userModel?.phone)}
                    </div>
                    {/* <div className="input-field">
                      <input
                        type="text"
                        defaultValue={maskPhoneNumner(userModel?.phone)}
                        disabled
                      />
                    </div> */}
                  </div>
                ) : (
                  <div className={classes.phoneNumberBox}>
                    <div className={classes.phoneNumber}>Your email</div>
                    <div className={classes.phoneNumber}>
                      {maskEmail(userModel?.email)}
                    </div>
                    {/* <div className="input-field">
                      <input
                        type="text"
                        defaultValue={maskEmail(userModel?.email)}
                        disabled
                      />
                    </div> */}
                  </div>
                )}

                <div className={classes.getCodeWrapper}>
                  <div className={classes.getCodeContainer}>
                    <div
                      className={classes.getCode}
                      onClick={userModel?.phone ? sendSms : sendCode}
                    >
                      Get code <MailOutlined sx={{ color: "crimson" }} />
                      &nbsp;&nbsp;
                      {isVerified && (
                        <span className="">
                          <DoneOutlined sx={{ color: "green" }} />
                        </span>
                      )}
                      {isChecking && (
                        <span className="">
                          <img src="/images/loader/reload.gif" />
                        </span>
                      )}
                      {isCountDown && (
                        <span id="counter" style={{ color: "crimson" }}>
                          {count}
                        </span>
                      )}
                    </div>
                    <input
                      type="number"
                      value={code}
                      required
                      maxLength={6}
                      onChange={handleSetCode}
                      placeholder="Enter code"
                      className={classes.inputField}
                    />
                  </div>

                  <span className={classes.regErr}>{codeError}</span>
                </div>
                <div className={classes.pinInputBox}>
                  <div className={classes.pinInputContainer}>
                    <label className={classes.pinLabel}>New PIN</label>
                    <input
                      className={classes.pinInputField}
                      type="password"
                      value={password}
                      required
                      onBlur={verifyPassword}
                      onChange={(e) => {
                        setPasswordError("");
                        setPassError("");
                        setPassword(e.target.value);
                      }}
                      placeholder="Enter 6 didgit Payment PIN"
                    />
                  </div>
                  <div className={classes.regErr}>{passError}</div>
                </div>
                <div className={classes.pinInputBox}>
                  <div className={classes.pinInputContainer}>
                    <label className={classes.pinLabel}>Confirm PIN</label>
                      <input
                        className={classes.pinInputField}
                        type="password"
                        onChange={(e) => {
                          setPasswordError("");
                          setConfirmPassword(e.target.value);
                        }}
                        value={confirmPassword}
                        placeholder="Confirm PIN"
                        required
                      />
                  </div>
                  <div className={classes.regErr}>{passwordError}</div>
                </div>
                <button className={classes.btn} onClick={handleSetPasswrod} type="submit">
                  Save
                </button>
              </Form>

              {/* <div className="log-out-wrap add-new-add d-flex justify-content-center">
                <button onClick={handleSetPasswrod}>Save</button>
              </div> */}
            </div>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

export default WalletPassword;
