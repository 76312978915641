import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import getLocalStorage from "./getLocalStorage";
import {getCookie } from './CustomCookies';
import { isJSON } from "./Helpers";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();
    let userObj = getLocalStorage('user')
    const persistUser =  (userObj  && userObj !== undefined &&  isJSON(userObj) )  ?  JSON.parse(userObj) : getCookie("user");

      return (
        
        allowedRoles.find(role => { return role === persistUser?.role})
            ? <Outlet />
            : auth?.accessToken
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;