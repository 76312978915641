import styled from "styled-components";

export const SearchSite ={

    ButtonWrapper: styled.div`
      border:1px solid red;
      justify-content: center;
      
    `,

    Button: styled.button`
    border-radius: 0 !important;
    border-bottom:1px solid #E2231A;
  `,
};

export const Body = {
  Wrapper: styled.main`
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: #ffffff;
    min-height: 60vh;
    border-radius: 0.3rem;
    // border: 1px red solid;
  `,
};

export const SortWrap = {
  Wrapper: styled.div`
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    padding-top: 20px;
    background-color: #fff;
    border-bottom: 1px dashed #c9c9c9;
    height: 70px;
  `,
};

export const ItemList = {
  Wrapper: styled.div`
    //  width: 95%;
    //  margin-left:auto;
    //  margin-right:auto;
    //  background-color:#fff;
    //  min-height: 30vh;
    //  border: 1px red solid;
    // width: 100%
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    // gap: 1.2rem;
  `,
};

export const ProductList = {
  Wrapper: styled.div`
    // padding-bottom: 1rem;
    border: 1px red solid;
    // margin: 1rem;

    // width: 100%;
    // max-width: 18.2rem;
    // height: 19.5rem;
    // border-radius: 0.3rem;
    // overflow: hidden;
    // transition: all 0.3s ease-in-out;
    
  `,
  Container: styled.div`
    border: 1px solid #ddd;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    height: 45vh;
    border-radius: 10px;
    height: 100%;
  `,
  ImgContainer: styled.div`
    height: 180px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px 10px 0 0;
    width: 100%;
  `,
  Img: styled.img`
    height: 100%;
    width: 100%;
    border-radius: 10px 10px 0 0;
  `,
  Price: styled.div`
    color: #e2231a;
    font-weight: 700;
  `,
  Title: styled.div`
    color: #333;
    font-size: 1.4rem;
    font-weight: normal;
    overflow: hidden;
    height: 4rem;
    &:hover {
      color: #e2231a;
    }
  `,
};


export const Navbar = {
  Wrapper: styled.nav`
    width:80%;
    margin-left:auto;
    margin-right:auto;
    padding: 1rem 0;
    display: flex;
    justify-content: flex-center;
    align-items: center;
    background-color: #fafafa;
  `,
  Logo: styled.h1`
    border: 1px solid gray;
    padding: 0.5rem 1rem;
  `,
  Items: styled.ul`
    display: flex;
    list-style: none;
    width:100%;
    justify-content: space-between;
  `,
  Item: styled.li`
    padding: 0 1rem;
    cursor: pointer;
    padding: 1rem;
    font-weight:bold;
    font-size: 1.6rem;
    &:after { 
      display:block;
      content: '';
      border-bottom: solid 3px #E2231A;
      transform: scaleX(0);  
      transition: transform 250ms ease-in-out;
     }

     &:hover:after { 
      transform: scaleX(1);
     }
  `,
};

