import React, { useEffect, useState } from "react";
import {
  FaCreditCard,
  FaShippingFast,
  FaStore,
  FaSearch,
  FaList,
  FaTrash,
} from "react-icons/fa";
import Badge from "@material-ui/core/Badge";
import Spinner from "../../utilities/Spinner";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "../../../assets/css/transaction.css";
import { Seo } from "../../utilities/Seo";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useRefreshToken from "../../../hooks/useRefreshToken";
import useCheckAuthWithRedirect from "../../../hooks/useCheckAuthWithRedirect";

import Pagination from "@mui/material/Pagination";
import emptyOrderImg from "../../../assets/images/empty-cart.png";
import { RoundToTwo } from "../../utilities/RoundToTwo";
import CustomBsModal from "../../utilities/CustomBsModal";
import useCurrencyRate from "../../../hooks/useCurrencyRate";
import { Checkbox } from "@mui/material";
import { Exchanger } from "../../functions/Helpers";
import { pink } from '@mui/material/colors';

// import axi

function Transaction() {
  //check for authentication on the page
  useCheckAuthWithRedirect();

  const [transactionType, setTransactionType] = useState("all");
  const [modalShow, setModalShow] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [reloadOnUpdate, setReloadOnUpdate] = useState(false);

  const { currency, rate, symbol } = useCurrencyRate();

  const [searchParams] = useSearchParams();
  const urlPage = searchParams.get("page");
  const [page, setPage] = useState(parseInt(urlPage) ? parseInt(urlPage) : 1);

  const [transactionIdList, setTransactionIdList] = useState([]);
  const [transactionId, setTransactionId] = useState();
  const [show, setShow] = useState(false);
   const [showMultiple, setShowMultiple] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleMultipleShow = () => setShowMultiple(true);
  const handleMultipleClose = () => setShowMultiple(false);

  const axiosPrivate = useAxiosPrivate();
  const refresh = useRefreshToken();
  const navigate = useNavigate();
  const [feedBack, setFeedBack] = useState("");

  const defaultCurrency = "NGN";

  // select multiple checkbox
  const [showMultipleDelete, setShowMultipleDelete] = useState(false);
  const [userId, setUserId] = useState({ checkedId: [], userResponse: [] });

  const handleCheckBox = (e) => {
    const { value, checked } = e.target;
    const { checkedId } = userId;

    if (checked) {
      setUserId({
        checkedId: [...checkedId, value],
        userResponse: [...checkedId, value],
      });
    } else {
      setUserId({
        checkedId: checkedId.filter((e) => e !== value),
        userResponse: checkedId.filter((e) => e !== value),
      });
    }
  };


  const handleShowDeleteButton = () => {
    if (userId?.userResponse?.length > 0) {
      setShowMultipleDelete(true);
    } else {
      setShowMultipleDelete(false);
    }
  };

  useEffect(() => {
    if (userId?.userResponse?.length > 0) {
      setShowMultipleDelete(true);
    }
    if (userId?.userResponse?.length == 0) {
      setShowMultipleDelete(false);
    }
  }, [userId?.userResponse]);

  //get new token for the reques
  useEffect(() => {
    const getRefreshToken = async () => {
      await refresh();
    };

    getRefreshToken();
  }, []);

  //set page title
  useEffect(() => {
    const pageTitle = "Transaction History  || Checkstall.com";
    Seo({
      title: pageTitle,
      metaDescription: "Transaction History",
    });
  }, []);

  useEffect(() => {
    setTransactionType(searchParams.get("type"));
  }, [transactionType]);

  useEffect(() => {
    getTransactionList(transactionType, page);
  }, [transactionType, page, reloadOnUpdate]);

  const handleClick = (event) => {
    setPage(1);
    setTransactionType();
    setReloadOnUpdate(true);
  };

  const handleChange = (e, value) => {
    setPage(value);
    navigate(
      `/members/wallet/transaction/?type=${transactionType}&page=${value}`
    );
  };

  const getTransactionList = (transactionType, page) => {
    const doGetList = async (transactionType, page) => {
      setModalShow(true);
      try {
        const response = await axiosPrivate.post(
          "/v1/transaction/get-transaction-list?type=" +
            transactionType +
            "&page=" +
            page
        );

        //console.log(response?.data?.message)
        setTransactionList(response?.data?.message);
        setPageCount(response?.data?.pageCount);
        setReloadOnUpdate(false);
      } catch (err) {
        //console.log(err.response?.data);
      }
      setModalShow(false);
    };

    doGetList(transactionType, page);
  };

  //   delete transation

  // console.log({id: value.transaction_id});

  //const nodeArray = (selector, parent=document) => [].slice.call(parent.querySelectorAll(selector));

  const selectAll = (e) => {
    const { checked } = e.target;
    const rootParentNode = e.target
      .closest(".transaction-table")
      ?.querySelector(".tb");
    const childCheckBoxes = rootParentNode?.querySelectorAll(
      'input[type="checkbox"]'
    );

    if (checked) {

      console.log(childCheckBoxes);
      checkAndUncheck(childCheckBoxes, e.target);
    } else {

      checkAndUncheck(childCheckBoxes, e.target);
    }
  };

  const checkAndUncheck = (checkboxNode, target) => {
    for (let i = 0; i < checkboxNode?.length; i++) {
      checkboxNode[i].checked = target.checked;
    }
  };

  const selectOne = (e) => {
    const { checked, value } = e.target;
    //if checked add the id to the transaction_id array\
    let ids = transactionIdList;
    if (checked) {
      ids.push(value);
      //update the state with the new value
      setTransactionIdList(ids);
      console.log(transactionIdList);
    } else {
      let index = ids.indexOf(value);
      if (index > -1) {
        // only splice array when item is found
        ids.splice(index, 1); // 2nd parameter means remove one item only
      }
      setTransactionIdList(ids);
      //console.log(transactionIdList);
    }
  };

  const confirmDelete = () => {
    //console.log(transactionIdList);
  };

  const handleDeleteAction = () => {
    //if user choose to delete close modal and delete item,
    deleteTransaction(transactionId);
    handleClose();
  };
   const handleMultipleDeleteAction = () => {
     //if user choose to delete close modal and delete item,
     deleteMultipleTransaction(userId);
     handleMultipleClose();
   };

  const handleDelete = (event) => {
    //show prompt modal
    handleShow();

    //get sku id and save in state
    let id = event.target.closest(".data-trash")?.id;
    setTransactionId(id);
  };
   const handleMultipleDelete = (event) => {
     //show prompt modal
     handleMultipleShow();

     //get sku id and save in state
     let id = event.target.closest(".data-trash")?.id;
     setTransactionId(id);
   };

  //   delete transation
  const deleteTransaction = (id) => {
    let isMounted = true;
    const controller = new AbortController();

    const doDeleteTransaction = async (id) => {
      setModalShow(true);
      const response = await axiosPrivate.post(
        "/v1/transaction/delete-single",
        JSON.stringify({ id })
      );

      if (response.data.code == "101") {
        //no error found item deleted successfully
        setFeedBack("item deleted successfully");
        //update the page after delete
        getTransactionList(transactionType, page)
      } else if (response.data.code == "102") {
        //cannot delete item
        setFeedBack("cannot delete item");
      }

      setModalShow(false);
    };

    doDeleteTransaction(id);

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  //   delete multiple transation
const deleteMultipleTransaction = (userId) => {
  let isMounted = true;
  const controller = new AbortController();
  
  let id = userId?.checkedId

  const doDeleteTransaction = async (id) => {
    setModalShow(true);
    const response = await axiosPrivate.post(
      "/v1/transaction/delete-multiple",
      JSON.stringify({ id })
    );
     
    if (response.data.code == "101") {
      //no error found item deleted successfully
      setFeedBack("items deleted successfully");
      //reload the page after deletion
      setUserId({
        checkedId: [],
        userResponse: [],
      });
      getTransactionList(transactionType, page);
    } else {
      //cannot delete item
      setFeedBack("cannot delete items");
    }

    setModalShow(false);
    setShowMultiple(false)
  };

  doDeleteTransaction(id);

  return () => {
    isMounted = false;
    controller.abort();
  };
};
  return (
    <>
      <CustomBsModal
        show={show}
        onHide={handleClose}
        handleClose={handleClose}
        handleAction={handleDeleteAction}
        headingText={"Delete Item"}
        bodyText={"Are you sure you want to delete this item?"}
        closeButtonText={"Cancel"}
        saveButtonText={"Delete"}
        closeButtonVariant={"secondary"}
        saveButtonVariant={"danger"}
        dialogClassName="modal-w"
        backdrop="static"
        keyboard={false}
      />
      <CustomBsModal
        show={showMultiple}
        onHide={handleClose}
        handleClose={handleMultipleClose}
        handleAction={handleMultipleDeleteAction}
        headingText={"Delete selected Items"}
        bodyText={"Are you sure you want to delete selected items?"}
        closeButtonText={"Cancel"}
        saveButtonText={"Delete"}
        closeButtonVariant={"secondary"}
        saveButtonVariant={"danger"}
        dialogClassName="modal-w"
        backdrop="static"
        keyboard={false}
      />
      <Spinner cssClass={"semi-dark-bg"} show={modalShow} />
      <div className="transactions-wrapper">
        <div className="transaction-container">
          <div className="col-md-12 inlineBlock">
            <div className="">
              <div className="myOrdersCard">
                <h4>Transaction History</h4>
                <div className="orderPosition">
                  <div className="Oposit">
                    <Link
                      to="/members/wallet/transaction?type=all"
                      id="all"
                      onClick={handleClick}
                      className={transactionType === "all" ? "active-tab" : ""}
                    >
                      <FaList
                        style={{ fontSize: "x-large", fontWeight: "900" }}
                      />
                      <p>All</p>
                    </Link>
                  </div>
                  <Badge
                    badgeContent={0}
                    color="secondary"
                    max={99}
                    overlap="circular"
                  >
                    <div className="Oposit">
                      <Link
                        to="/members/wallet/transaction?type=topup"
                        id="topup"
                        onClick={handleClick}
                        className={
                          transactionType === "topup" ? "active-tab" : ""
                        }
                      >
                        <FaCreditCard
                          style={{ fontSize: "x-large", fontWeight: "900" }}
                        />
                        <p>Top Up</p>
                      </Link>
                    </div>
                  </Badge>
                  <Badge
                    badgeContent={0}
                    color="secondary"
                    max={99}
                    overlap="circular"
                  >
                    <div className="Oposit">
                      <Link
                        to="/members/wallet/transaction?type=withdraw"
                        id="withdraw"
                        onClick={handleClick}
                        className={
                          transactionType === "withdraw" ? "active-tab" : ""
                        }
                      >
                        <FaCreditCard
                          style={{ fontSize: "x-large", fontWeight: "900" }}
                        />
                        <p>withdraw</p>
                      </Link>
                    </div>
                  </Badge>
                  <Badge
                    badgeContent={0}
                    color="secondary"
                    max={99}
                    overlap="circular"
                  >
                    <div className="Oposit">
                      <Link
                        to="/members/wallet/transaction?type=refund"
                        id="refund"
                        onClick={handleClick}
                        className={
                          transactionType === "refund" ? "active-tab" : ""
                        }
                      >
                        <FaStore
                          style={{ fontSize: "x-large", fontWeight: "900" }}
                        />
                        <p>Refund</p>
                      </Link>
                    </div>
                  </Badge>
                  <Badge
                    badgeContent={0}
                    color="secondary"
                    max={99}
                    overlap="circular"
                  >
                    <div className="Oposit">
                      <Link
                        to="/members/wallet/transaction?type=income"
                        id="income"
                        onClick={handleClick}
                        className={
                          transactionType === "income" ? "active-tab" : ""
                        }
                      >
                        <FaShippingFast
                          style={{ fontSize: "x-large", fontWeight: "900" }}
                        />
                        <p>Income</p>
                      </Link>
                    </div>
                  </Badge>
                  <Badge
                    badgeContent={0}
                    color="secondary"
                    max={99}
                    overlap="circular"
                  >
                    <div className="Oposit">
                      <Link
                        to="/members/wallet/transaction?type=transfer"
                        id="transfer"
                        onClick={handleClick}
                        className={
                          transactionType === "transfer" ? "active-tab" : ""
                        }
                      >
                        <FaShippingFast
                          style={{ fontSize: "x-large", fontWeight: "900" }}
                        />
                        <p>Transfer</p>
                      </Link>
                    </div>
                  </Badge>
                  <Badge
                    badgeContent={0}
                    color="secondary"
                    max={99}
                    overlap="circular"
                  >
                    <div className="Oposit">
                      <Link
                        to="/members/wallet/transaction?type=business"
                        id="business"
                        onClick={handleClick}
                        className={
                          transactionType === "business" ? "active-tab" : ""
                        }
                      >
                        <FaShippingFast
                          style={{ fontSize: "x-large", fontWeight: "900" }}
                        />
                        <p>Business</p>
                      </Link>
                    </div>
                  </Badge>
                </div>
                <div className="transaction-content-wrapper">
                  <div className="transaction-search">
                    <div
                      className="fieldy col-lg-6 col-md-6 col-sm-6"
                      id="searchform"
                    >
                      <input
                        type="text"
                        id="searchterm"
                        placeholder="Transaction Id, Subject"
                      />
                      <button type="button" id="search">
                        <FaSearch />{" "}
                      </button>
                    </div>
                  </div>
                  <p className="delete-feedback">{feedBack}</p>
                  <div className="table-responsive">
                    {showMultipleDelete && 
                        <button
                          className={`multiple-delete`}
                          onClick={handleMultipleDelete}
                        >
                          Delete Selected Items
                        </button>
                    }
                    
                    {transactionList && transactionList.length > 0 ? (
                      <>
                        {transactionIdList.length > 0 && (
                          <button onClick={confirmDelete}>Delete</button>
                        )}
                        <table className="transaction-table">
                          <thead>
                            <tr>
                              <th className="head-checkbox">
                                All
                                <Checkbox
                                  size="small"
                                  className="selectAll"
                                  onClick={selectAll}
                                  sx={{
                                    '&.Mui-checked': {
                                      color: 'crimson',
                                    },
                                  }}
                                />
                              </th>
                              <th scope="col">Transaction ID</th>
                              <th scope="col">Date</th>
                              <th scope="col">Receiver</th>
                              <th scope="col">Subject</th>
                              <th scope="col">Type</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Payment Method</th>
                              <th scope="col">Status</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          {transactionList.map((value, index) => {
                            let statusColor = "";
                            if (value.status === "Completed") {
                              statusColor = "status-completed";
                            } else if (value.status === "Pending") {
                              statusColor = "status-pending";
                            } else {
                              statusColor = "status-rejected";
                            }
                            return (
                              <tbody key={index}>
                                <tr>
                                  <td
                                    id={value.transaction_id}
                                    onClick={handleShowDeleteButton}
                                  >
                                    <Checkbox
                                      size="small"
                                      id={value.transaction_id}
                                      onChange={handleCheckBox}
                                      value={value.transaction_id}
                                      disabled={value.status === "Pending" ? true : false}
                                      sx={{
                                        '&.Mui-checked': {
                                          color: 'crimson',
                                        },
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <div className="">
                                      {value.transaction_id}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="data-date">
                                      {value.date}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="">{value.receiver}</div>
                                  </td>
                                  <td>
                                    <div className="trans-subject">
                                      {value.subject}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="">{value.type}</div>
                                  </td>
                                  <td>
                                    <div className="">
                                      {symbol}
                                      {RoundToTwo(
                                        Exchanger(
                                          defaultCurrency,
                                          currency,
                                          value.amount,
                                          rate
                                        )
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="">
                                      {value.payment_method}
                                    </div>
                                  </td>
                                  <td>
                                    <div className={statusColor}>
                                      {value.status}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      className="data-trash"
                                      id={value.transaction_id}
                                    >
                                      <FaTrash onClick={handleDelete} />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })}
                        </table>
                      </>
                    ) : (
                      <div className="empty-order-wrap">
                        <img src={emptyOrderImg} alt="" />
                        <div>Your Transaction is empty </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    display: "block",
                    marginTop: 10,
                    backgroundColor: "#fff",
                    paddingTop: 20,
                    paddingBottom: 20,
                  }}
                >
                  <Pagination
                    count={pageCount}
                    variant="outlined"
                    shape="rounded"
                    showFirstButton
                    showLastButton
                    page={page}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Transaction;
