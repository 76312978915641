import React,{useEffect,useState} from "react";
import {useSearchParams, Link, useNavigate} from 'react-router-dom';
import "../../../assets/css/create-order.css";
import { Seo } from '../../utilities/Seo';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useAuth from '../../../hooks/useAuth';
import Spinner from "../../utilities/Spinner";
import { RoundToTwo } from "../../utilities/RoundToTwo";
import emptyCartImg from '../../../assets/images/empty-cart.png';
import Tooltip from "@material-ui/core/Tooltip";
import spinner from '../../../assets/images/Spinner.gif';
import useCheckAuthWithRedirect from "../../../hooks/useCheckAuthWithRedirect";
import { FaUser, FaLocationArrow, FaPhone } from 'react-icons/fa';
import useCurrencyRate from "../../../hooks/useCurrencyRate";
import { getSpecProps,getPostFee,Exchanger } from "../../functions/Helpers";
import setLocalStorage from "../../functions/setLocalStorage";
import { encryptData } from "../../functions/DataSecurity";
import useGlobalVariable from '../../../hooks/useGlobalVariable';




const GET_SHIPPING_ADDRESS = "/v1/address/get-default-address";



function MultipleOrder (props){
    //check for authentication on the page
    useCheckAuthWithRedirect();


    const [searchParams] = useSearchParams();
    const source = searchParams.get('s');
    const {currency, rate,symbol} = useCurrencyRate();
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPriceWithDiscount, setTotalPriceWithDiscount] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalType, setTotalType] = useState(0);
    const order = props.order;
    const [remark, setRemark] = useState('');
    const [remarkMultiple, setRemarkMultiple] = useState({});
    const [showLoadingGif, setShowLoadingGif] = useState(false);

    const [totalPayable, setTotalPayable] = useState(0);

    const [shippingAddress, setShippingAddress] = useState({});
    const [reload, setReload] = useState(false);
    const [totalDiscount, setTotalDiscount] = useState(0)
    const [modalShow, setModalShow] = useState(false);




    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const [serviceFee,setServiceFee] = useState(0);
    const [servicePercentage,setServicePercentage] = useState(0);
    const [minServiceFee, setMinServiceFee]= useState(0);

    const {defaultCurrency} = useGlobalVariable();
  
    const [discountPrice, setDiscountPrice] = useState({});
    const [totalDiscountPrice, setTotalDiscountPrice] = useState(0);
    const [youSave, setYouSave] = useState({});
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [totalPayment, setTotalPayment] = useState(0);
    const [postFee, setPostFee] = useState({});
    const [totalPostFee, setTotalPostFee] = useState(0);
    const[shippingFeeParams,setPhippingFeeParams] = useState({
        defaultFee:0,
        additionalFee:0,
    })
    const [remarkInfo, setRemarkInfo] = useState(false);
    const [open, setOpen] = useState(false);



    
   

//set page title
useEffect(() => {
    const pageTitle =  "Confirm Order || Checkstall.com";
    Seo({
      title: pageTitle,
      metaDescription: 'Confirm Order',
    });
}, []);










useEffect(() =>{
    getAddress();
    getTotalPrice();
    getTotaltype();
    getDiscountPrice()
    getTotalQuantity();
    getServiceFee();
    getFee();
    getTotalPayment();
},[]);

useEffect(() =>{
    getServiceFee();
},[totalPriceWithDiscount , totalPrice, servicePercentage]);

useEffect(() => {
    setTotalPayable((totalPriceWithDiscount || totalPrice ));
},[totalPriceWithDiscount,totalPrice]);

useEffect(() =>{
    getTotalPayment();
},[totalPayable, serviceFee , postFee])

const getTotalPayment = () =>{
    let total = totalPayable+ serviceFee + getPostFee(order.post_fee, shippingFeeParams.defaultFee,shippingFeeParams.additionalFee, totalQuantity,order.orginal_price);
   setTotalPayment(total);
}

const getTotalPrice = () =>{
    let total_price = 0;
    Object.keys(order).forEach( function(key) {
        order[key]?.sku?.map((val) => {
            if(!isNaN(val.total_price)){
                total_price += Number(val.total_price);
            }
        });
    
    })
    

    setTotalPrice(total_price);
}

const getTotalPriceMultiple = (sku) =>{
    let totalPrice = 0;
    sku?.map((val) => {
        if(!isNaN(val.total_price)){
            totalPrice += Number(val.total_price);
        }
    });
    return totalPrice;
}

const getTotaltype = () =>{
    let type = 0;

    Object.keys(order).forEach( function(key) {
        type  += order[key]?.sku?.length || 0
    })
    
    setTotalType(type);
}



const getDiscountPrice = ()=>{
    let price = {}; 

    Object.keys(order).forEach( function(key) {
        let priceRange = order[key]?.priceRange;
        let totalQty = totalQuantityMultiple(order[key]?.sku);
        if(priceRange){
            priceRange.map((range, index) =>{
  
                if(priceRange?.length == 2){ // for items with two price range
                   if(index == 1){
                     if((totalQty >=  priceRange[1][0])){
                        price[order[key]?.item_id] = range[1];
                     }else{
                        price[order[key]?.item_id] = priceRange[0][1];
                     }
                   }
                }else if(priceRange.length > 2){ // for items with more than two price range
                   if(index === 1){
                     if((totalQty >= priceRange[1][0]) && (totalQty < priceRange[2][0])){
                        price[order[key]?.item_id] = range[1];
                     }
                   }else if(index === 2){
                     if((totalQty >= range[0])){
                        price[order[key]?.item_id] = range[1];
                     }
                   }
          
                 } 
             });
        }
    });
    
   
   setDiscountPrice(price);
   return price;
  
}



  const getTotalQuantity = () =>{
    let t_q = 0;
    Object.keys(order).forEach( function(key) {
        order[key]?.sku?.map((val) => {
            t_q  += parseInt(val.quantity);
        });
    })
    setTotalQuantity(t_q);
  }

  const totalQuantityMultiple = (sku) =>{
    let q = 0;
    sku?.map((val) => {
        q  += parseInt(val.quantity);
    });

    return q;
  }






const getServiceFee = () => {

    let service_fee = 0;
    Object.keys(order).forEach( function(key) {
        service_fee += getMultipleServiceFee(order[key]?.sku);
    })
    
    setServiceFee(service_fee);
}



const getMultipleServiceFee = (sku) => {
    let totalPrice = 0;
    sku?.map((val) => {
        totalPrice  += parseInt(val.total_price);
    });
       
   
    let service_percent = servicePercentage / 100;
    let a =  service_percent * (totalPrice );

    let service_fee = a >= minServiceFee ? a : minServiceFee;
    return service_fee;
}
  
  const createMultipleOrder = (orderItem) =>{
    let isMounted = true;
    const controller = new AbortController();
    setModalShow(true);
    const doCreateMultiple = async (orderItem) => {
        try {
              const response = await axiosPrivate.post("v1/order/multiple-order",
                JSON.stringify({ orderItem }), {
                signal: controller.signal
              });

              if(parseInt(response?.data?.code) === 1000){
                setModalShow(false);

                    let orderIds = response?.data?.message.orderIdList;
                    setLocalStorage('orderIdList', encryptData(orderIds));
                    navigate(`/order/payment?actionType=bulkOrderPayment&fromType=cart`, {state:{refresh:true}});

              }
        }catch(err){
            //console.log(err)
        }
        setModalShow(false);
    }

    doCreateMultiple(orderItem);
    return () => {
        isMounted = false;
        controller.abort();
    }
  }

  

  const handleSubmit = (event) =>{

    if(!shippingAddress){
        setShowErrorModal(true);
        const timer = setTimeout(() => {
            setShowErrorModal(false);
        }, 1000);
        return () => clearTimeout(timer);
    }
    event.target.disabled = true;
    Object.keys(order).forEach( function(key) {
        order[key]['remark'] = remarkMultiple[key];
        order[key]['service_percentage'] = servicePercentage;
        order[key]['receiver_address_id'] = shippingAddress?.id;
        order[key]['total_quantity'] = totalQuantityMultiple(order[key]?.sku);
        order[key]['service_fee'] = getMultipleServiceFee(order[key]?.sku);
        order[key]['discount_price'] = discountPrice[order[key]?.item_id] || 0;
        order[key]['actual_post_fee'] = parseFloat(order[key]?.post_fee) || 0;
        order[key]['added_post_fee'] = postFee[order[key]?.item_id] - parseFloat(order[key]?.post_fee);
        order[key]['post_fee'] = postFee[order[key]?.item_id];
        //total price is item total price without discount or service fee
        order[key]['total_payable'] = getTotalPriceMultiple(order[key]?.sku);
        //total payable is total price with discount plus service or total price plus service fee 
        order[key]['total_price'] = ((parseFloat(order[key]['discount_price'] * order[key]['total_quantity']) || getTotalPriceMultiple(order[key]?.sku)) + order[key]['service_fee'] + order[key]['post_fee']);
        order[key]['rate'] = rate;
        delete order[key]?.id;
        delete order[key]?.user_id;
        
    })


            createMultipleOrder(order);

    event.target.disabled = false;
  }


  const getAddress = async () => {
    setModalShow(true);
    try {
        const response = await axiosPrivate.get(GET_SHIPPING_ADDRESS);
        setShippingAddress(response?.data?.message);
        setReload(false);
    } catch (err) {
    }

    setModalShow(false);
  }

  const getFee = () =>{
    let isMounted = true;
    const controller = new AbortController();
    setModalShow(true);
    const doGetServiceFee = async () => {
        
        try {
              const response = await axiosPrivate.post("v1/helpers/get-fee",
             {
                signal: controller.signal
              });
              if(response?.data?.code == 3001){
                 isMounted && setServicePercentage(response?.data?.fee);
                 isMounted && setMinServiceFee(response?.data?.minFee); 
                 setPhippingFeeParams(response?.data?.shippingFeeParams);
              }
        }catch(err){
            
        }
        setModalShow(false);
    }
  
    doGetServiceFee();
    return () => {
      isMounted = false;
        controller.abort();
    }
  }

  const getYouSave =()=>{
    let u = {};
    Object.keys(order).forEach( function(key) {
         u[order[key]?.item_id] = (order[key]?.orginal_price - discountPrice[order[key]?.item_id]) * totalQuantityMultiple(order[key]?.sku);
    });

    
    setYouSave(u);
 }

 useEffect(() =>{
    getYouSave();
 },[discountPrice])

 const getExpressFee = () =>{
    let fee = {}
    Object.keys(order).forEach( function(key) {
        fee[order[key]?.item_id] = getPostFee(order[key]?.post_fee, shippingFeeParams.defaultFee,shippingFeeParams.additionalFee,
             totalQuantityMultiple(order[key]?.sku),order[key]?.orginal_price);
   });

   return fee;
}

const getTotalExpressFee = (expressFee) =>{
    let fee = 0;
    Object.values(expressFee).map((val) =>{
        fee += val;
    })
   return fee;
}

useEffect(() =>{
    setPostFee(getExpressFee());
    setTotalPostFee(getTotalExpressFee(getExpressFee()));
},[shippingFeeParams]);










    return(
        <>
            <Spinner
                cssClass={'semi-dark-bg'}
                show={modalShow}
            />
            <div className="create-order-content d-flex flex-column">
                
                {(order !==null && source === 'cart') ? (
                    
                <>
                  
                    <div className="address-wrap d-flex justify-content-start">
                        <div className="add-title" style={{color:'#999999', flexBasis:'10%'}}>Receiver's Address:</div>
                        {shippingAddress && 
                            <>
                                <div className="rev-name" style={{marginRight:'15px'}}><FaUser />&nbsp;{shippingAddress?.receiver_name}</div>
                                <div className="add-info" style={{marginRight:'15px'}}>
                                <FaLocationArrow />&nbsp;{shippingAddress?.receiver_address +"  " +
                                      shippingAddress?.state +"  "+
                                      shippingAddress?.city}
                                </div>
                                <div className="rec-phone" ><FaPhone />&nbsp;{shippingAddress?.receiver_phone}</div>
                            </>
                        }

                        {!shippingAddress &&
                            <div>No receiver's address added yet. Click <Link target="_blank" to="/members/account/shipping-address">Here</Link> to add new address</div>
                        }
                        
                    </div>
                    {Object.values(order).map((value, index) =>{
                        return(
                            <React.Fragment key={index}>
                                <div className="order-info-wrap d-flex flex-column">
                                    <div className="store-name">Marchant: {value?.nick}</div>
                                    <div className="order-body d-flex justify-content-start">
                                        <div className="order-serial-num" style={{flexBasis:'10%', color:'#999999'}}>Order:{index+1}</div>
                                        <div className="order-wrap d-flex flex-column">
                                            <div className="column-title d-flex justify-content-between">
                                                <div className="" style={{flexBasis:'35%'}}>Item</div>
                                                <div className="" style={{flexBasis:'10%', textAlign:'center'}}>Listed Price</div>
                                                <div className="" style={{flexBasis:'10%', textAlign:'center'}}>Quantity</div>
                                                <div className="" style={{flexBasis:'10%', textAlign:'center'}}>Discount</div>
                                                <div className="" style={{flexBasis:'10%', textAlign:'center'}}>Total</div>
                                                <div className="" style={{flexBasis:'10%', textAlign:'center'}}>Shipping</div>
                                            </div>
                                            <div className="order-list-container d-flex justify-content-between" >
                                                <div className="left d-flex flex-column">
                                                    {Object.values(value?.sku).map((val, key) => {
                                                        
                                                        return(
                                                            <div className="inner d-flex flex-column" key={key}>
                                                                <div className="inner-child d-flex justify-content-between" >
                                                                    <div className="sku-info d-flex justify-content-between">
                                                                        <img src={val?.url || value?.pic_url} alt=""/>
                                                                        <div className="item-info d-flex flex-column">
                                                                            <div className="order-item-title">{value?.title}</div>
                                                                            <div className="spec-info">{getSpecProps(val?.properties_name)}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="sku-listed-price">{symbol}{RoundToTwo(Exchanger(defaultCurrency,currency,val?.price,rate))}</div>
                                                                    <div className="sku-qty">{val?.quantity}</div>
                                                                    <div className="discount">
                                                                        {discountPrice[value?.item_id] ? symbol+" "+ (discountPrice[value?.item_id] * rate) : '--'}
                                                                    </div>
                                                                    <div className="price">{symbol} {RoundToTwo(
                                                                            Exchanger(defaultCurrency,
                                                                                    currency,
                                                                                    (discountPrice[value?.item_id] ? (discountPrice[value?.item_id] * val?.qty) : (val?.price * val?.quantity)),
                                                                                    rate
                                                                                    )
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                        
                                                    })}

                                                </div>
                                                <div className="right" style={{marginRight:'1rem'}}>
                                                    <Tooltip             
                                                        arrow
                                                        title="Shipping fee are determined by the seller based on the quantity of items ordered
                                                        we can only get the actual shipping fee after we have submit an order with the seller
                                                        you can choose to checkout and we will deduct the total shipping fee from your account
                                                        when we get the actual shipping fee from the seller or you can choose to submit your order
                                                        and we will update the shipping fee after the seller has reviewd your order">
                                                        <span className="questionMark">?</span>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <div className="comment-and-total d-flex justify-content-between">
                                                <div className="order-comment">
                                                    <span>Leave a message:</span>
                                                    <textarea 
                                                        className="order-text-area" 
                                                        rows="2" 
                                                        cols="40" 
                                                        placeholder="Leave a message for the seller"
                                                        value={remark}
                                                        onChange={(e) => setRemark(e.target.value)}
                                                    > </textarea>
                                                </div>
                                                <div className="total-amt">
                                                    <div className="item-total-price">Item total Price:  <span style={{color:'#cf0000',fontWeight:'bolder'}}>
                                                    {symbol}
                                                    {RoundToTwo(Exchanger(defaultCurrency, currency, ((parseFloat(discountPrice[value?.item_id] * totalQuantityMultiple(value?.sku)) || getTotalPriceMultiple(value?.sku)) 
                                                    ) , rate))}
                                                        
                                                        </span>
                                                    </div>
                                                    <div className="item-total-price">Service fee: <span style={{color:'#cf0000',fontWeight:'bolder'}}>
                                                          {symbol}{RoundToTwo(Exchanger(defaultCurrency, currency, getMultipleServiceFee(value?.sku) , rate))}
                                                        
                                                        </span>
                                                    </div>
                                                    
                                                    <div className="local-shipping">Local freight:  <span style={{color:'#cf0000',fontWeight:'bolder'}}>
                                                        {symbol}{RoundToTwo(Exchanger(defaultCurrency, currency, postFee[value.item_id] , rate))}
                                                    </span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                        
                    })}
                    
                    <div className="checkout-info-wrap d-flex flex-column bg-white">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex justify-content-between" style={{flexBasis:'20%'}}>
                                <div>Types: {totalType}</div>
                                <div>Quantity: {totalQuantity}</div>
                            </div>
                            <div className="total-item-price d-flex flex-column">
                                <div className="d-flex justify-content-end"> Total Price: 
                                        <span className="amt">&nbsp;{symbol} {RoundToTwo(Exchanger(defaultCurrency,currency,totalPayable,rate))}</span>
                                </div>
                                <div className="d-flex justify-content-end">Total Service fee:
                                        <span className="amt">&nbsp;{symbol} {RoundToTwo(Exchanger(defaultCurrency,currency,serviceFee,rate))}</span>
                                </div>
                                <div className="d-flex justify-content-end">Total Shipping fee : 
                                  
                                  <span className="amt">&nbsp; {symbol}{RoundToTwo(Exchanger(defaultCurrency, currency, totalPostFee , rate))}</span>
                                </div>
                            </div>
                        </div>
                        <div className="payable-amt d-flex justify-content-end">
                            <div className="d-flex justify-content-end">Total : 
                                <span className="currency">{symbol}</span>
                                <span className="amt">{symbol}{RoundToTwo(Exchanger(defaultCurrency, currency, (totalPayable + serviceFee + totalPostFee) , rate))}</span>
                            </div>
                        </div>
                        
                    </div>
                    <div className="checkout-btn-wrap shadow-sm bg-white d-flex justify-content-end">
                        <div>Total :
                            <span className="currency">{symbol}</span>
                            <span className="amt">{symbol}{RoundToTwo(Exchanger(defaultCurrency, currency, (totalPayable + serviceFee + totalPostFee) , rate))} </span>
                        </div>
                        <div className="checkout-btn">
                            <button onClick={handleSubmit} type="button">
                                {showLoadingGif &&
                                   <img src={spinner} alt="spinner" />
                                }
                                Submit
                            </button>
                        </div>
                    </div>
                </>
                ) : (
                        <div className="order-error-wrap">
                            <img src={emptyCartImg} alt='' />
                            <div>There's an error with your order,please go back and try again</div>
                        </div>
                   )
                    
                }
                
                
            </div>
        </>
       
    )
}

export default MultipleOrder;