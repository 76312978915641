export const babysClothingSizeData = [
  {
    size: "age",
    xs: "0-3months",
    s: "3-6months",
    m: "6-9months",
    l: "9-12months",
    xl: "1-1.5years old",
  },
  {
    size: "height / cm",
    xs: 59,
    s: 66,
    m: 73,
    l: 80,
    xl: 90,
  },
  {
    size: "bust / cm",
    xs: 40,
    s: 44,
    m: 47,
    l: 50,
    xl: 50,
  },
  {
    size: "waistline / cm",
    xs: 38,
    s: 40,
    m: 42,
    l: 44,
    xl: 46,
  },
];

export const childrenChineseSizeData = [
  {
    size: "age",
    xs: "1.5years old-3years old",
    s: "3years old-6years old",
    m: "6years old-8years old",
    l: "8years old-10years old",
  },
  {
    size: "height / cm",
    xs: 100,
    s: 110,
    m: 120,
    l: 130,
  },
  {
    size: "bust / cm",
    xs: 54,
    s: 58,
    m: 62,
    l: 66,
  },
  {
    size: "waistline / cm",
    xs: 52,
    s: 54,
    m: 56,
    l: 58,
  },
];

export const bigChildrenSizeData = [
  {
    size: "age",
    xs: "1.5years old-3years old",
    s: "3years old-6years old",
    m: "6years old-8years old",
    l: "8years old-10years old",
  },
  {
    size: "height / cm",
    xs: 130,
    s: 140,
    m: 150,
    l: 160,
  },
  {
    size: "bust / cm",
    xs: 66,
    s: 69,
    m: 73,
    l: 78,
  },
  {
    size: "waistline / cm",
    xs: 58,
    s: 60,
    m: 62,
    l: 64,
  },
];

export const childrenLengthHeightSizeData = [
  {
    style: "Children's tops",
    length: "=height*50%",
  },
  {
    style: "Children's jacket",
    length: "=height*49%",
  },
  {
    style: "Children's trousers",
    length: "=height*75%",
  },
  {
    style: "Children's coat",
    length: "=height*75%",
  },
  {
    style: "dress",
    length: "=height*78%",
  },
  {
    style: "Children's shorts",
    length: "=height*30%",
  },
];



