import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "../../../assets/css/checkstallPartnerIndex.module.css";
import useCheckAuthWithRedirect from "../../../hooks/useCheckAuthWithRedirect";
import { KeyboardArrowRightOutlined, ChevronLeft } from "@mui/icons-material";
import { Seo } from "../../utilities/Seo";
import Form from "react-bootstrap/Form";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import useCurrencyRate from "../../../hooks/useCurrencyRate";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CircularProgress from "@mui/material/CircularProgress";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Navbar from "../../navbar/Navbar";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import styled from "@emotion/styled";
import Spinner from "../../utilities/Spinner";
import getLocalStorage from "../../functions/getLocalStorage";
import { getCookie } from "../../functions/CustomCookies";
import { isJSON } from "../../functions/Helpers";

function Index() {
  useCheckAuthWithRedirect();

  const navigate = useNavigate();
  //check if user is an agent
  let userObj = getLocalStorage("user");
  const userProfile =
    userObj && userObj !== undefined && isJSON(userObj)
      ? JSON.parse(userObj)
      : getCookie("user");
  if (!(userProfile?.role == process.env.REACT_APP_ROLE_AGENT)) {
    navigate("/checkstall-partner/register");
  }

  useEffect(() => {
    Seo({
      title: "Checkstall Partner || The Checkstall Affliate Program",
      metaDescription: "Checkstall Partner || The Checkstall Affliate Program",
    });
  }, []);

  const axiosPrivate = useAxiosPrivate();
  const { symbol } = useCurrencyRate();
  const [code, setCode] = useState("");
  const [referre, setReferre] = useState({});
  const [orderHistory, setOrderHistory] = useState([]);
  const defaultCurrency = "NGN";
  const [detail, setDetail] = useState();
  const [fixedBalance, setFixedBalance] = useState(0);
  const [availabeBalance, setAvailableBalance] = useState(0);
  const [modalShow, setModalShow] = useState(false);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [openModal, setOpenModal] = useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (event && event.target.closest(".commission-history-wrap")) {
      getDetails(event);
    }

    setState({ ...state, [anchor]: open });
  };

  const [withdrawalState, setWithdrawalState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const withdrawalToggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setWithdrawalState({ ...withdrawalState, [anchor]: open });
  };

  const data = [
    {
      name: "Jan",
      earning: 54798,
    },
    {
      name: "Feb",
      earning: 34768,
    },
    {
      name: "Mar",
      earning: 165235,
    },
    {
      name: "Apr",
      earning: 76325,
    },
    {
      name: "May",
      earning: 22965,
    },
    {
      name: "Jun",
      earning: 154246,
    },
    {
      name: "Jul",
      earning: 76324,
    },
    {
      name: "Aug",
      earning: 118764,
    },
    {
      name: "Sep",
      earning: 44890,
    },
    {
      name: "Oct",
      earning: 66324,
    },
    {
      name: "Nov",
      earning: 243678,
    },
    {
      name: "Dec",
      earning: 589032,
    },
  ];

  useEffect(() => {
    if (!code) {
      getCode();
    }
    getReferree();
    getOrderHistory();
    getBalance();
  }, []);

  const getCode = () => {
    const doGetCode = async () => {
      try {
        const response = await axiosPrivate.get("v1/agent/get-referral-code");
        setCode(response?.data?.code);
      } catch (err) {}
    };

    doGetCode();
  };

  const getReferree = () => {
    setModalShow(true);
    const doGetReferree = async () => {
      try {
        const response = await axiosPrivate.get("v1/agent/get-referrees");
        setReferre(response?.data?.message);
      } catch (err) {}

      setModalShow(false);
    };

    doGetReferree();
  };

  const getBalance = () => {
    const doGetBalancee = async () => {
      try {
        const response = await axiosPrivate.get("v1/agent/get-balance");
        setFixedBalance(response?.data?.fixed_balance);
        setAvailableBalance(response?.data?.available_balance);
      } catch (err) {}
    };

    doGetBalancee();
  };

  const getOrderHistory = () => {
    const doGetHistory = async () => {
      try {
        const response = await axiosPrivate.get("v1/agent/get-order-history");
        setOrderHistory(response?.data?.message);
      } catch (err) {}
    };

    doGetHistory();
  };

  const dateFormat = (timeStamp) => {
    let date = new Date(timeStamp * 1000);
    return date.toDateString();
  };

  const getDetails = (event) => {
    let elem = event.target.closest(".commission-history-wrap");
    let id;
    if (elem) {
      id = elem.getAttribute("id");
    }

    let detail = orderHistory.filter((value) => value.id == id);
    setDetail(detail);
  };

  const [amount, setAmount] = useState(0);

  const handleinputChange = (event) => {
    setAmount(event.target.value);
  };

  const handleWithdraw = () => {
    if (amount > 0 && amount <= availabeBalance) {
      commissionWithdrawal();
    }
  };

  const commissionWithdrawal = () => {
    let isMounted = true;
    const controller = new AbortController();
    setModalShow(true);

    const doWithdrawalCommssion = async () => {
      try {
        const response = await axiosPrivate.post(
          "v1/agent/withdraw-commission",
          JSON.stringify(amount),
          {
            signal: controller.signal,
          }
        );

        if (response?.data?.code == 2001) {
          setModalShow(false);
          getBalance();
          setAmount(0);
          setWithdrawalState({ ...withdrawalState, ["bottom"]: false });
        }
      } catch (err) {}
      setModalShow(false);
    };

    doWithdrawalCommssion();
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  return (
    <React.Fragment>
      <Spinner cssClass={"semi-dark-bg"} show={modalShow} />
      <Navbar />

      <div className={classes.wrapper}>
        <div className={classes.affiliateTitle}>
          <h2>Welcome to your Affiliate Dashboard!</h2>
          <div className={classes.titleIcon}>
            <AccountCircleIcon sx={{ fontSize: "3rem" }} />
          </div>
        </div>
        <div className={classes.referralContainer}>
          {code && (
            <div className={classes.referralCode}>
              Your Referral code is: <span>{code}</span>
            </div>
          )}
          <div className={classes.selectWrapper}>
            <Form.Select aria-label="Default select ">
              <option value="7">Last 15 Days</option>
              <option value="7">Last 1 Month</option>
              <option value="1">Last 3 Months</option>
              <option value="2">Last 6 Months</option>
              <option value="3">All</option>
            </Form.Select>
          </div>
        </div>
        {/* {code && (
          <div className={classes.referralCode}>
            Your Referral code is: <span>{code}</span>
          </div>
        )}
        <div className={classes.selectWrapper}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Your History</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={age}
              label="Your History"
            >
              <MenuItem value="7">Last 15 Days</MenuItem>
              <MenuItem value="7">Last 1 Month</MenuItem>
              <MenuItem value="1">Last 3 Months</MenuItem>
              <MenuItem value="2">Last 6 Months</MenuItem>
              <MenuItem value="3">All</MenuItem>
            </Select>
          </FormControl>
        </div> */}

        {/* <div className={classes.selectWrapper}>
          <Form.Select aria-label="Default select ">
            <option value="7">Last 15 Days</option>
            <option value="7">Last 1 Month</option>
            <option value="1">Last 3 Months</option>
            <option value="2">Last 6 Months</option>
            <option value="3">All</option>
          </Form.Select>
        </div> */}
        <div className={classes.balanceWrapper}>
          <div className={classes.totalBalance}>
            <span>Total Balance</span>
            <span className={classes.amount}>₦ {fixedBalance}</span>
          </div>
          <div className={classes.availabeBalance}>
            <span>Available Balance</span>
            <span className={classes.amount}>₦ {availabeBalance}</span>
          </div>
        </div>
        <button
          onClick={withdrawalToggleDrawer("bottom", true)}
          // onClick={() => setOpenModal(true)}
          className={classes.withdrawalButton}
        >
          Withdraw to Wallet
        </button>
        {/* <Link to="/members/checkstall-partner/withdraw">Withdraw to Wallet</Link> */}
        <div className={classes.chartWrapper}>
          <ResponsiveContainer width="100%" height={data.length * 30}>
            <BarChart
              width={600}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="earning" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className={classes.refereeWrapper}>
          <div className={classes.refereeTitle}>
            <h6>List of Referee</h6>

            <Link
              to="/checkstall-partner/referee-list"
              className={classes.refLink}
            >
              view all
            </Link>
          </div>

          {referre && referre.length > 0 ? (
            <div className={classes.refereeListWrapper}>
              {referre.map((value, index) => {
                if (index < 5) {
                  //show only five recors
                  return (
                    <div className={classes.refereeList} key={index}>
                      <div>{index + 1}</div>
                      <div>{value.referee_email}</div>
                      <div>{value.reffered_time}</div>
                    </div>
                  );
                }
              })}
            </div>
          ) : (
            <div className={classes.noReferee}>
              You have no referee yet, share your code with friends to invite
              them
            </div>
          )}
        </div>
        <div className={classes.historyWrapper}>
          <div className={classes.historyTitle}>History</div>
          {/* </div> */}
          {/* <div className="comm-history-title">History</div> */}
          {referre && referre.length > 0 ? (
            <table className={classes.historyListWrapper}>
              <thead>
                <tr>
                  <th>name</th>
                  <th>amount</th>
                  <th>status</th>
                  <th>date</th>
                </tr>
              </thead>
              {orderHistory.map((value, index) => {
                let statusColor = classes.ok;
                if (value.status === "Success") {
                  statusColor = classes.orderSucces;
                } else if (value.status === "Pending") {
                  statusColor = classes.orderPending;
                } else {
                  statusColor = classes.orderCancel;
                }
                return (
                  // <div
                  //   id={value.id}
                  //   className={`"commission-history-wrap"`}

                  // >
                  <tbody
                    key={index}
                    id={value.id}
                    onClick={toggleDrawer("bottom", true)}
                    className="commission-history-wrap"
                  >
                    <tr>
                      {/* <div className="logo">
                      <img src={"/images/items/money_in.jpg"} alt="" />
                    </div> */}
                      <td className={classes.historySubjectTitle}>
                        <div className={classes.test}>{value.title}</div>
                      </td>
                      <td className={classes.transAmount}>
                        {symbol} {value.total}
                      </td>
                      <td className={`${classes.historyStatus} ${statusColor}`}>
                        {value.status}
                      </td>

                      <td className={classes.transactionTime}>
                        {dateFormat(value.created_at)}
                      </td>
                      {/* <div className="d-flex flex-column info">
                      <div className="d-flex justify-content-between">
                        <div className="subject">{value.title}</div>
                        <div className="trans-amt credit">
                          {symbol} {value.total}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className='type'>{TYPE[value.type]}</div>
                        <div className="status pending">{value.status}</div>
                        <div className="trans-time">
                          {dateFormat(value.created_at)}
                        </div>
                      </div>
                    </div> */}
                      <td>
                        <KeyboardArrowRightOutlined
                          sx={{ color: "#59595a", fontSize: "20px" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                  // </div>
                );
              })}
            </table>
          ) : (
            <div className={classes.noHistory}>
              You order from your referee yet, share your code with friends to
              invite them to start shopping
            </div>
          )}
        </div>
      </div>
      {["bottom"].map((anchor) => {
        const StyledModal = styled(Modal)({
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          outline: "none",
        });

        return (
          <React.Fragment key={anchor}>
            <StyledModal
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              // PaperProps={{
              //   sx:{height: '100%',width:'100%',backgroundColor:'#f0f0f0'}
              //   sx: {
              //     height: "calc(100% - 150px)",
              //     top: 150,
              //     borderTopLeftRadius: "10px",
              //     borderTopRightRadius: "10px",
              //   },
              // }}
            >
              <div className={classes.historyModalWrapper}>
                <h4 className={classes.historyModalTitle}>Overview</h4>
                {/* <div className="arrow-with-title nav-back">
                <span className="back" onClick={toggleDrawer("bottom", false)}>
                  <ChevronLeft />
                </span>
                <div className="title">Order details</div>
              </div>
              <h3>Overview</h3> */}
                {detail && (
                  <React.Fragment>
                    <div className={classes.historyModaloverviewWrapper}>
                      <div className={classes.overviewLabelWrapper}>
                        <div className={classes.overviewValue}>
                          ₦ {detail[0].total}
                        </div>
                        <div className={classes.overviewLabel}>
                          Total Amount
                        </div>
                      </div>
                      <div className={classes.overviewLabelWrapper}>
                        <div className={classes.overviewValue}>
                          ₦ {detail[0].total_commission}
                        </div>
                        <div className={classes.overviewLabel}>
                          Total Commision
                        </div>
                      </div>
                      <div className={classes.overviewLabelWrapper}>
                        <div className={classes.overviewValue}>
                          ₦ {detail[0].commission}
                        </div>
                        <div className={classes.overviewLabel}>
                          Expected Commision
                        </div>
                      </div>
                      <div className={classes.overviewLabelWrapper}>
                        <div className={classes.overviewValue}>
                          {detail[0].comission_rate}%
                        </div>
                        <div className={classes.overviewLabel}>
                          Commision rate
                        </div>
                      </div>
                    </div>
                    <div className={classes.historyModalStatusWrapper}>
                      <div className={classes.historyModalStatus}>
                        <div>Order Status</div>
                        <div
                          className={`${
                            detail[0].status === "Success" &&
                            classes.orderSucces
                          }`}
                        >
                          {detail[0].status}
                        </div>
                      </div>
                      <div className={classes.historyModalStatus}>
                        <div>Order Time</div>
                        <div>{dateFormat(detail[0].created_at)}</div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </StyledModal>
          </React.Fragment>
        );
      })}

      {["bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={withdrawalState[anchor]}
            onClose={withdrawalToggleDrawer(anchor, false)}
            onOpen={withdrawalToggleDrawer(anchor, true)}
            PaperProps={{
              // sx:{height: '100%',width:'100%',backgroundColor:'#f0f0f0'}
              sx: {
                height: "370px",
                width: "400px",
                top: "25%",
                left: "40%",
                borderRadius: "10px",
                padding: "10px",
              },
            }}
          >
            <div className={classes.modalWithdrawalWrapper}>
              {/* <div className='arrow-with-title nav-back' >
                            <span className='back' onClick={withdrawalToggleDrawer('bottom', false)}><ChevronLeft /></span>
                            <div className='title'>Withdrawal</div>
                        </div>  */}
              <h4 className={classes.modalWithdrawalTitle}>Withdrawal</h4>
              <div className={classes.modalWithdrawalBox}>
                <div className={classes.modalInfoBox}>
                  <p>Withdraw your earning to your wallet!</p>
                  <p>
                    The available amount signifies the total amount you earn
                    from the completed order your referee purchased.
                  </p>
                  <p>
                    Available amount:
                    <span className={classes.modalBalance}>
                      {availabeBalance}
                    </span>
                  </p>
                </div>
                <div className={classes.topupform}>
                  {/* <span className={classes.modalCurrency}>₦</span> */}
                  <label htmlFor="topupBox" className={classes.modalLabel}>
                    Enter Withdrawal Amount below
                    <span className={classes.modalCurrency}>₦</span>
                  </label>
                  <input
                    type="number"
                    id="topupBox"
                    onChange={handleinputChange}
                    // value={amount}
                    className={classes.modalInput}
                  />
                </div>
                <button
                  disabled={!(amount > 0 && amount <= availabeBalance)}
                  className={`${
                    amount > 0 && amount <= availabeBalance
                      ? classes.transBtn
                      : classes.transBtnBlur
                  } `}
                  onClick={handleWithdraw}
                >
                  withdraw
                </button>
              </div>
            </div>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

export default Index;
