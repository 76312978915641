import React, { useEffect } from 'react';
import classes from './ShoppingNotice.module.css';
import { Seo } from '../../utilities/Seo';
import Navbar from '../../navbar/Navbar';
import { Link } from 'react-router-dom';

const ShoppingNotice = () => {
  useEffect(() => {
    Seo({
      title:
        "Shopping Notice || Checkstall shopping notice on how to make purchase from checkstall",
      metaDescription:
        "Checkstall buy items ranging from Electronics, footwares, baby wares and many more from around the globe and get it shipped to your doorstep in Africa",
    });
  }, []);
  return (
    <>
      <Navbar />
      <main className={classes.wrapper}>
        <section className={classes.container}>
          <h2 className={classes.title}>Shopping Notice</h2>
          <div className={classes.infoBox}>
            <div className={classes.noteContainer}>
              <div className={classes.noteTitle}>
                Note: In the context below:
              </div>
              <ul className={classes.noteItemsBox}>
                <li>
                  <b>Checkstall</b> shall refer to the company
                </li>
                <li>
                  <b>We</b> shall refer to Checkstall
                </li>
                <li>
                  <b>Our</b> shall refer to Checkstall
                </li>
                <li>
                  <b>you </b> shall refer to the customer using our services
                </li>
              </ul>
            </div>

            <ol className={classes.noticeDetailsList}>
              <li>
                Checkstall provides a purchasing services for designated
                third-party platform products, but does not assume the risk of
                third-party platform products. Checkstall only provides you with
                the service of purchasing the goods on the third-party platform,
                and does not bear the risks of infringement, quality problems
                and other related responsibilities of the goods themselves.
              </li>
              <li>
                Shopping is divided into two phases: purchasing phase and
                shipping phase
              </li>
              <li>
                <ul>
                  <li>
                    <b>Phase 1:</b> After making selection of needed Items, You
                    will need to pay for the goods , local shipping costs and
                    platform service fees of the goods in China, After payment
                    we will purchase the items from designated third-party
                    platform and the goods will be sent to Checkstall Temporary
                    storage warehouse in China.The local shipping cost from the
                    seller(third-party platform) to Checkstall warehouse will be
                    incure on the customer. You can buy goods cumulatively and
                    combine them for international delivery, which saves money.
                  </li>
                  <li>
                    <b>Phase 2:</b> Choose the goods you want to ship and submit
                    the application for delivery. We will merge and pack the
                    goods for cross-border transportation the international
                    logistics costs will be paid to the Logistics company when
                    the goods are picked up in by you.
                  </li>
                </ul>
              </li>
              <li>
                International shippment are handled by third-party logistics
                providers, which are restricted by customs policies and may have
                logistics risks, which need to be assessed and borne by
                yourselves. As a service platform, Checkstall integrates many
                third-party international logistics providers to provide you
                with parcel delivery services for pourchasing goods, but it does
                not bear the risks caused by customs policies and uncontrollable
                factors in cross-border logistics, such as fines, damage, lost
                items and delivery delays during peak periods, etc. Checkstall
                will assist you in risk estimation and reduce the probability of
                loss by providing timely risk reminders and continuous
                improvement of logistics insurance and other measures. You can
                choose from the list of third-party logistics company provided
                by us or you can also decide to use your own shipping service.
              </li>
            </ol>
          </div>
          <div className={classes.linkBox}>
            <Link to="/howtobuy/shopping-guide">
              Also read our shopping guide
            </Link>
            <Link to="/howtobuy" className={classes.backLink}>
              Go back
            </Link>
          </div>
        </section>
      </main>
    </>
  );
}

export default ShoppingNotice
