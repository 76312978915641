import React, { useState, useMemo,createContext } from 'react';

const CreateOrderContext = createContext();

export const CreateOrderProvider = ({ children }) => {
    const [order, setOrder] = useState({});
    const value = useMemo(() => ({ order, setOrder }), [order, setOrder]);

    return (
    <CreateOrderContext.Provider value={value}>{children}</CreateOrderContext.Provider>
    );
 };

 export default CreateOrderContext;