import React from 'react';
import classes from './CookiesPolicy.module.css'
import Navbar from '../../navbar/Navbar';

const CookiesPolicy = () => {
  return (
    <main className={classes.wrapper}>
      <Navbar />
      <section className={classes.container}>
        <h1 className={classes.title}>our cookies policy</h1>
        <div className={classes.policyBox}>
          <span className={classes.update}>
            our cookies policy was last updated on 24-05-2023
          </span>
          <p className={classes.policyInfo}>
            <span className={classes.para}>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id ipsam
              non alias architecto ea amet enim reiciendis harum, eaque
              explicabo!
            </span>
            <span className={classes.para}>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id ipsam
              non alias architecto ea amet enim reiciendis harum, eaque
              explicabo!Lorem ipsum dolor sit, amet consectetur adipisicing
              elit. Id ipsam non alias architecto ea amet enim reiciendis harum,
              eaque explicabo!
            </span>
            <div className={classes.cookiesDefinition}>
              <h4>What are Cookies</h4>
              <span>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id
                ipsam non alias architecto ea amet enim reiciendis harum, eaque
                explicabo!Lorem ipsum dolor sit, amet consectetur adipisicing
                elit. Id ipsam non alias architecto ea amet enim reiciendis
                harum, eaque explicabo!
              </span>
            </div>
          </p>
        </div>
      </section>
    </main>
  );
}

export default CookiesPolicy
