import React from "react";
import classes from "./LogoBox.module.css";
import logo from "../../assets/checkstall RED.png";
import logo1 from "../../assets/checkstall WHITE.png";
import { useNavigate } from "react-router";

const LogoBox = ({ isMobile }) => {
  const navigate = useNavigate();
  return (
    <div className={`${classes.logoBox} ${isMobile && classes.mobileLogoBox}`} onClick={() => navigate('/')}>
      <div className={classes.logo}>
        <img src={logo1} alt="logo" />
      </div>
    </div>
  );
};

export default LogoBox;
