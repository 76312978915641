import React,{useEffect,useState} from "react";
import {useSearchParams, useNavigate, Link, useLocation} from 'react-router-dom';
import "../../../assets/css/create-order.css";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useRefreshToken from '../../../hooks/useRefreshToken';
import { RoundToTwo } from "../../utilities/RoundToTwo";
import emptyCartImg from '../../../assets/images/empty-cart.png';
import useCheckAuthWithRedirect from "../../../hooks/useCheckAuthWithRedirect";
import useCurrencyRate from "../../../hooks/useCurrencyRate";
import useGlobalVariable from '../../../hooks/useGlobalVariable';
import { Exchanger,getPostFee,getSpecProps } from "../../functions/Helpers";
import Spinner from "../../utilities/Spinner";
import Tooltip from "@material-ui/core/Tooltip";
import spinner from '../../../assets/images/Spinner.gif';
import { FaUser, FaLocationArrow, FaPhone } from 'react-icons/fa';








const GET_SHIPPING_ADDRESS = "/v1/address/get-default-address";



function SingleOrder (props){
    //check for authentication on the page
    useCheckAuthWithRedirect();

    const [modalShow, setModalShow] = useState(false);
    const [searchParams] = useSearchParams();
    const source = searchParams.get('s');
    const {currency, rate,symbol} = useCurrencyRate();
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPriceWithDiscount, setTotalPriceWithDiscount] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalType, setTotalType] = useState(0);
    const order = props.order;
    const [remark, setRemark] = useState('');
    const [showLoadingGif, setShowLoadingGif] = useState(false);

    const [totalPayable, setTotalPayable] = useState(0);

    const [shippingAddress, setShippingAddress] = useState({});
    const [reload, setReload] = useState(false);
    const [totalDiscount, setTotalDiscount] = useState(0)
    const [checked, setChecked] = useState(true);
    const [youSave, setYouSave] = useState(0);
    const [totalPayment, setTotalPayment] = useState(0);




    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const [errMsg, setErrMsg] = useState();
    const [serviceFee,setServiceFee] = useState(0);
    const [servicePercentage,setServicePercentage] = useState(0);
    const [minServiceFee, setMinServiceFee]= useState(0);
    const {defaultCurrency} = useGlobalVariable();
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [postFee, setPostFee] = useState(0);
    const[shippingFeeParams,setPhippingFeeParams] = useState({
        defaultFee:0,
        additionalFee:0,
    })



   



useEffect(() =>{
    getAddress();
    getTotalPrice();
    getTotaltype();
    getTotalPriceWithDiscount();
    getServiceFee();
    getFee();
    //getYouSave();
    getExpressFee();
    getTotalPayment();
    setTotalQuantity(getTotalQuantity());
},[]);



useEffect(() =>{
    getServiceFee();
},[totalPriceWithDiscount , totalPrice, servicePercentage]);

useEffect(() =>{
    getExpressFee();
},[postFee]);

useEffect(() =>{
    getTotalPayment();
},[totalPayable, serviceFee , postFee])

useEffect(() => {
    setTotalPayable((totalPriceWithDiscount || totalPrice ));
},[totalPriceWithDiscount,totalPrice]);

const getTotalPrice = () =>{
    let total_price = 0;  
    Object.values(order?.sku).map((val) => {
        if(!isNaN(val.total_price)){
            total_price += Number(val.total_price);
        }
    });      
    setTotalPrice(total_price);
}


const getTotaltype = () =>{
    setTotalType(Object.keys(order?.sku).length);
}

const getTotalPayment = () =>{
    let total = totalPayable+ serviceFee + getPostFee(order.post_fee, shippingFeeParams.defaultFee,shippingFeeParams.additionalFee, totalQuantity,order.orginal_price);
   setTotalPayment(total);
}



const getTotalPriceWithDiscount = () =>{
    let price = 0;
    
    let priceRange = order?.priceRange;
    if(priceRange){
        priceRange.map((range, index) =>{
  
           if(priceRange?.length == 2){ // for items with two price range
              if(index == 1){
                if((totalQuantity >=  priceRange[1][0])){
                  price = range[1];
                }else{
                  price = priceRange[0][1];
                }
              }
           }else if(priceRange.length > 2){ // for items with more than two price range
              if(index === 1){
                if((totalQuantity >= priceRange[1][0]) && (totalQuantity < priceRange[2][0])){
                  price = range[1];
                }
              }else if(index === 2){
                if((totalQuantity >= range[0])){
                  price = range[1];
                }
              }
     
            } 
        });
      }

      setTotalPriceWithDiscount((totalQuantity * price));
  }



  const getTotalQuantity = () =>{
    let t_q = 0;
    Object.values(order?.sku).map((val) => {
        if(!isNaN(val.qty)){
            t_q += parseInt(val.qty);
        }
    });
    return t_q;
  }


const getServiceFee = () => {
    let service_percent = servicePercentage / 100;
    let a = service_percent * (totalPriceWithDiscount || totalPrice );
    let service_fee = a >= minServiceFee ? a : minServiceFee;
    setServiceFee(service_fee);
}


  const createSingleOrder = (orderItem) =>{
    let isMounted = true;
    const controller = new AbortController();
    const doCreateSingle = async (orderItem) => {
        setModalShow(true);
        try {
              const response = await axiosPrivate.post("v1/order/single-order",
                JSON.stringify({ orderItem }), {
                signal: controller.signal
              });

              if(parseInt(response?.data?.code) === 1000){
                setModalShow(false);
                    let orderId = response?.data?.message.order_number;
                    navigate(`/order/payment?orderId=${orderId}&actionType=singleOrderPayment&fromType=detail`, {state:{refresh:true}});
              }
        }catch(err){
            
        }
        setModalShow(false);
    }

    doCreateSingle(orderItem);
    return () => {
        isMounted = false;
        controller.abort();
    }
  }

  

  const handleSubmit = (event) =>{
        if(!shippingAddress){
            navigate('/members/account/shipping-address', { state: { from: location }, replace: true });
        }
        event.target.disabled = true;
        //add new property to the order object
        order['remark'] = remark;
        order['total_payable'] = Number(totalPayable);
        order['receiver_address_id'] = shippingAddress?.id;
        order['item_id'] = order.num_iid;
        order['service_percentage'] = servicePercentage;
        order['total_quantity'] = totalQuantity;
        order['service_fee'] = Number(serviceFee);
        order['total_price'] = Number(totalPayment);
        order['original_price'] = order.orginal_price;
        order['actual_post_fee'] = parseFloat(order.post_fee) || 0;
        order['added_post_fee'] = parseFloat(postFee) - parseFloat(order.post_fee);
        order['post_fee'] = postFee;
        order['rate'] = rate;

    
        createSingleOrder(order);

        event.target.disabled = false;
  }


  const getAddress = async () => {
    try {
        const response = await axiosPrivate.get(GET_SHIPPING_ADDRESS);
        setShippingAddress(response?.data?.message);
        setReload(false);
    } catch (err) {
    }

  }

  const getFee = () =>{
    let isMounted = true;
    const controller = new AbortController();
    setModalShow(true)
    const doGetServiceFee = async () => {
        try {
              const response = await axiosPrivate.post("v1/helpers/get-fee",
             {
                signal: controller.signal
              });
              if(response?.data?.code == 3001){
                 isMounted && setServicePercentage(response?.data?.fee);
                 isMounted && setMinServiceFee(response?.data?.minFee); 
                 setPhippingFeeParams(response?.data?.shippingFeeParams)
              }
        }catch(err){
            
        }
        setModalShow(false);
    }
  
    doGetServiceFee();
    return () => {
      isMounted = false;
        controller.abort();
    }
  }

 const getYouSave =()=>{
    let u = (order?.orginal_price - order?.discount_price) * totalQuantity;
    return u;
 }

const getExpressFee = () =>{
    let fee = getPostFee(order.post_fee, shippingFeeParams.defaultFee,shippingFeeParams.additionalFee, getTotalQuantity(),order.orginal_price);
    return fee;
}

useEffect(() =>{
    setPostFee(getExpressFee());
},[shippingFeeParams]);





    return(
        <>
            <Spinner
                cssClass={'semi-dark-bg'}
                show={modalShow}
            />
            <div className="create-order-content d-flex flex-column">
                
                {(order !==null && source === 'detail') ? (
                <>
                    <div className="address-wrap d-flex justify-content-start">
                        <div className="add-title" style={{color:'#999999', flexBasis:'10%'}}>Receiver's Address:</div>
                        {shippingAddress && 
                            <>
                                <div className="rev-name" style={{marginRight:'15px'}}><FaUser />&nbsp;{shippingAddress?.receiver_name}</div>
                                <div className="add-info" style={{marginRight:'15px'}}>
                                <FaLocationArrow />&nbsp;{shippingAddress?.receiver_address +"  " +
                                      shippingAddress?.state +"  "+
                                      shippingAddress?.city}
                                </div>
                                <div className="rec-phone" ><FaPhone />&nbsp;{shippingAddress?.receiver_phone}</div>
                            </>
                        }

                        {!shippingAddress &&
                            <div>No receiver's address added yet. Click <Link target="_blank" to="/members/account/shipping-address">Here</Link> to add new address</div>
                        }
                        
                    </div>
                    <div className="order-info-wrap d-flex flex-column">
                        <div className="store-name">Marchant: {order?.nick}</div>
                        <div className="order-body d-flex justify-content-start">
                            <div className="order-serial-num" style={{flexBasis:'10%', color:'#999999'}}>Order:1</div>
                            <div className="order-wrap d-flex flex-column">
                                <div className="column-title d-flex justify-content-between">
                                    <div className="" style={{flexBasis:'35%'}}>Item</div>
                                    <div className="" style={{flexBasis:'10%', textAlign:'center'}}>Listed Price</div>
                                    <div className="" style={{flexBasis:'10%', textAlign:'center'}}>Quantity</div>
                                    <div className="" style={{flexBasis:'10%', textAlign:'center'}}>Discount</div>
                                    <div className="" style={{flexBasis:'10%', textAlign:'center'}}>Total</div>
                                    <div className="" style={{flexBasis:'10%', textAlign:'center'}}>Shipping</div>
                                </div>
                                <div className="order-list-container d-flex justify-content-between" >
                                    <div className="left d-flex flex-column">
                                        {Object.values(order?.sku).map((val, key) => {
                                            
                                            return(
                                                <div className="inner d-flex flex-column" key={key}>
                                                    <div className="inner-child d-flex justify-content-between" >
                                                        <div className="sku-info d-flex justify-content-between">
                                                            <img src={val?.url || order?.pic_url} alt=""/>
                                                            <div className="item-info d-flex flex-column">
                                                                <div className="order-item-title">{order?.title}</div>
                                                                <div className="spec-info">{getSpecProps(val?.properties_name)}</div>
                                                            </div>
                                                        </div>
                                                        <div className="sku-listed-price">{symbol}{RoundToTwo(Exchanger(defaultCurrency,currency,val?.price,rate))}</div>
                                                        <div className="sku-qty">{val?.qty}</div>
                                                        <div className="discount">
                                                            {order?.discount_price ? symbol+" "+ (RoundToTwo(Exchanger(defaultCurrency,currency,order?.discount_price,rate))) : '--'}
                                                        </div>
                                                        <div className="price">{symbol} {RoundToTwo(
                                                                Exchanger(defaultCurrency,
                                                                           currency,
                                                                           (order?.discount_price ? (order?.discount_price * val?.qty) : (val?.price * val?.qty)),
                                                                           rate
                                                                        )
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            
                                        })}

                                    </div>
                                    <div className="right" style={{marginRight:'1rem'}}>
                                        <Tooltip             
                                            arrow
                                            title="Shipping fee are determined by the seller based on the quantity of items ordered
                                            we can only get the actual shipping fee after we have submit an order with the seller
                                            you can choose to checkout and we will deduct the total shipping fee from your account
                                            when we get the actual shipping fee from the seller or you can choose to submit your order
                                            and we will update the shipping fee after the seller has reviewd your order">
                                            <span className="questionMark">?</span>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="comment-and-total d-flex justify-content-between">
                                    <div className="order-comment">
                                        <span>Leave a message:</span>
                                        <textarea 
                                            className="order-text-area" 
                                            rows="2" 
                                            cols="40" 
                                            placeholder="Leave a message for the seller"
                                            value={remark}
                                            onChange={(e) => setRemark(e.target.value)}
                                        > </textarea>
                                    </div>
                                    <div className="total-amt">
                                        <div className="item-total-price">Item total Price:  {symbol}<span style={{color:'#cf0000',fontWeight:'bolder'}}>
                                            {RoundToTwo(Exchanger(defaultCurrency,currency,(totalPriceWithDiscount || totalPrice ),rate))}
                                            </span></div>
                                        <div className="local-shipping">Local freight:  
                                           <span style={{color:'#cf0000',fontWeight:'bolder'}}>
                                              {symbol}{RoundToTwo(Exchanger(defaultCurrency, currency, postFee , rate))}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="checkout-info-wrap d-flex flex-column bg-white">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex justify-content-between" style={{flexBasis:'20%'}}>
                                <div>Types: {totalType}</div>
                                <div>Quantity: {totalQuantity}</div>
                            </div>
                            <div className="total-item-price d-flex flex-column">
                                <div className="d-flex justify-content-end">Item Total Price: {symbol}
                                        <span className="amt">&nbsp; {RoundToTwo(Exchanger(defaultCurrency,currency,totalPayable,rate))}</span>
                                </div>
                                {parseFloat(order.discount_price) > 0 ? (
                                   <React.Fragment>
                                        <div className="d-flex justify-content-end">Discount price: {symbol}
                                            <span className="amt">&nbsp; {RoundToTwo(Exchanger(defaultCurrency, currency, order.discount_price , rate))}</span>
                                        </div> 
                                        <div className="d-flex justify-content-end">You save: {symbol}
                                            <span className="amt">&nbsp; {RoundToTwo(Exchanger(defaultCurrency, currency, getYouSave() , rate))}</span>
                                        </div>
                                   </React.Fragment> 
                                        ) : ("")
                                }
                                <div className="d-flex justify-content-end">Service fee: {symbol}
                                        <span className="amt">&nbsp; {RoundToTwo(Exchanger(defaultCurrency,currency,serviceFee,rate))}</span>
                                </div>
                                <div className="d-flex justify-content-end">Total Local freight : 
                                    <span style={{color:'#cf0000',fontWeight:'bolder'}}>
                                        {symbol}{RoundToTwo(Exchanger(defaultCurrency, currency, postFee , rate))} 
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="payable-amt d-flex justify-content-end">
                            <div className="d-flex justify-content-end">Total Payable: 
                                <span className="currency">{symbol}</span>
                                <span className="amt">{RoundToTwo(Exchanger(defaultCurrency,currency,(totalPayment),rate))}</span>
                            </div>
                        </div>
                       
                    </div>
                    <div className="checkout-btn-wrap shadow-sm bg-white d-flex justify-content-end">
                        <div>Total Payable:
                            <span className="currency">{symbol}</span>
                            <span className="amt">{RoundToTwo(Exchanger(defaultCurrency,currency,(totalPayment),rate))} </span>
                        </div>
                        <div className="checkout-btn">
                            <button onClick={handleSubmit} type="button">
                                {showLoadingGif &&
                                   <img src={spinner} alt="spinner" />
                                }
                                Submit
                            </button>
                        </div>
                    </div>
                </>
                ) : (
                        <div className="order-error-wrap">
                            <img src={emptyCartImg} alt='' />
                            <div>There's an error with your order,please go back and try again</div>
                        </div>
                   )
                    
                }
                
                
            </div>
        </>
       
    )
}

export default SingleOrder;