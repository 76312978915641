export const mensShirtSizeData = [
  {
    shirtSize: "International Size",
    xs: 36,
    s: 37,
    m: 38,
    l: 39,
    xl: 40,
    xxl: 41,
  },
  {
    shirtSize: "bust / cm",
    xs: "79-82",
    s: "83-86",
    m: "87-90",
    l: "91-94",
    xl: "95-98",
    xxl: "99-103",
  },
  {
    shirtSize: "waistline / cm",
    xs: "62-66",
    s: "67-70",
    m: "71-74",
    l: "75-78",
    xl: "79-82",
    xxl: "83-86",
  },
  {
    shirtSize: "Shoulder width / cm",
    xs: 37,
    s: 38,
    m: 39,
    l: 40,
    xl: 41,
    xxl: 42,
  },
  {
    shirtSize: "height / bust",
    xs: "155 / 82A",
    s: "160 / 86A",
    m: "165 / 90A",
    l: "170 / 94A",
    xl: "172 / 98A",
    xxl: "175 / 102A",
  },
];

export const mensSuitSizeData = [
  {
    size: "Clothing Size",
    s: 36,
    m: 37,
    l: 38,
    xl: 39,
    xxl: 40,
  },
  {
    size: "bust / cm",
    s: "79-82",
    m: "83-86",
    l: "87-90",
    xl: "91-94",
    xxl: "95-98",
  },
  {
    size: "waistline / cm",
    s: "62-66",
    m: "67-70",
    l: "71-74",
    xl: "75-78",
    xxl: "79-82",
  },
  {
    size: "Shoulder width / cm",
    s: 37,
    m: 38,
    l: 39,
    xl: 40,
    xxl: 41,
  },
  {
    size: "height / bust",
    s: "155 / 82A",
    m: "160 / 86A",
    l: "165 / 90A",
    xl: "170 / 94A",
    xxl: "172 / 98A",
  },
];

export const mensPantSizeData = [
  {
    size: "Pants size (feet)",
    s: "25-26",
    m: "27-28",
    l: "29-30",
    xl: "31-32",
  },
  {
    size: "International model",
    s: "155/62A - 159/64A",
    m: "160/66A - 164/68A",
    l: "165/70A - 169/72A",
    xl: "170/74A - 170/76A",
  },
  {
    size: "Hip circumference / cm",
    s: "85-87.5",
    m: "90-92.5",
    l: "95-97.5",
    xl: "100-102.5",
  },
  {
    size: "waistline / cm",
    s: "62-64.5",
    m: "67-69.5",
    l: "72-74.5",
    xl: "77-79.5",
  },
];
