import React,{useEffect, useState, useRef} from 'react';
import Form from "react-bootstrap/Form";
import { Seo } from '../../utilities/Seo';
import {  useNavigate, Link } from "react-router-dom";
import useCheckAuthWithRedirect from '../../../hooks/useCheckAuthWithRedirect';
import getLocalStorage from '../../functions/getLocalStorage';
import { isJSON } from '../../functions/Helpers';
import classes from '../../../assets/css/checkstallPartnerRegister.module.css';
import { getCookie } from '../../functions/CustomCookies';
import {MailOutlined,DoneOutlined} from '@mui/icons-material';
import Termii from '../../../api/Termii';
import PhoneInput from 'react-phone-input-2';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Navbar from '../../navbar/Navbar';
import { TextField } from '@mui/material';





function Register() {
  useCheckAuthWithRedirect();

  useEffect(() => {
    Seo({
        title: 'Checkstall Partner Registration || The Checkstall Affliate Program',
        metaDescription: 'Submit a form and become a checkstall partner'
    });
  }, []);

  let userObj = getLocalStorage('user')
  const persistUser =  (userObj  && userObj !== undefined &&  isJSON(userObj) )  ?  JSON.parse(userObj) : getCookie("user");
  const [isChecking, setIsChecking] = useState(false);
  const [isVerified,setIsVerified] = useState(false);
  const [isCountDown ,setIsCountDown] = useState(false);
  const [code, setCode] = useState("");
  const [count, setCount] = useState("");
  const [codeError, setCodeError] = useState("");
  const [pinId, setPinId] = useState('');
  const [phone_number, setPhoneNumber] = useState("");
  const [phoneFormatError, setPhoneFormartError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const phoneRef = useRef(null);
  const [email, setEmail] = useState("");
  const [fullName, setFullname] = useState("");
  const [nameError, setNameError] = useState();
  const [emailError, setEmailError] = useState();
  const[isTransparentLoading, setIsTransparentLoading] = useState(false);
  const [errorText, setErrorText] = useState("loading");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [refferalCode, setRefferalCode] = useState("");
  const [refferalCodeError, setRefferalCodeError] = useState("");

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  


  const sendOtp = ()=>{
      setIsCountDown(true);
      countDown("counter", 60);

      const termiiInstance = Termii();

      //Send OTP
      termiiInstance.send_otp(phone_number).then(response => {
      if(response.error == 101){
        setPhoneError(response.message);
        return;
      }

      if(parseInt(response.message.status) === 200){
        setPinId(response.message.pinId);
      }

    });
  }

const verifyCode = ()=>{
    const termiiInstance = Termii();
    setIsChecking(true);
    //verify OTP
    if(pinId && code){
        termiiInstance.verify_otp(pinId, code).then(response => {
  
           if(response.message.verified == true){
               setIsVerified(true);
               setIsChecking(false);
           }else if(response.message.verified == false){
                setCodeError("Incorrect code");
                setIsChecking(false);
          }else if(response.message.status == 400 && response.message.verified == "Expired"){
               setCodeError("Code already Expired");
               setIsChecking(false);
           }
  
        });
    }else{
      setCodeError("Please get another code");
      setIsChecking(false);
    }
  
    
  }

  const countDown = (selectorId, limit) =>{
    let counts=setInterval(()=>{
      let count= document.getElementById(selectorId);
        //count.innerHTML=--limit;
        setCount(--limit)
        if(limit === 0) {
            clearInterval(counts);
            setIsCountDown(false);
            setCount("");
            
        }
    },1000);
  
  }

  const handleSetCode =(e) =>{
    setCodeError("");
    setCode(e.target.value)
  }

  useEffect(() =>{
    if(code.length === 6){
       
       verifyCode();
    }
  },[code]);

const africaCode = ['ng','gh','ke','ug','za'];
const africaDailingCode = {'ng':'234','gh':'233', 'ke':'254', 'ug':'256', 'za':'27'};
const handleOnChange = (value, data, event, formattedValue) =>{
   let phone = "";
   let country = data.countryCode;
   let val = value.target.value.replace(/ +/g, "").replace("+", "");;
   let withOutDailingCode = val.slice(data.dialCode.length);
   //console.log(withOutDailingCode);
   if(africaCode.includes(country) ){
        let count = withOutDailingCode.replace(/ +/g, "").length
        if(count === 11){//user prefix  0 to number, we slice it off
            let t = withOutDailingCode.slice(0, 0) + withOutDailingCode.slice(1, withOutDailingCode.length);
            phone = africaDailingCode[country]+t;
            setPhoneNumber(phone);
            setPhoneFormartError("");
            setPhoneError("");
        }else if(count === 10){  //user doesn't prefix  0 to number, we set the value to phone number
            phone = africaDailingCode[country]+withOutDailingCode;
            setPhoneNumber(phone);
            setPhoneFormartError("");
            setPhoneError("");
        }else{
          setPhoneFormartError("Invalid format");
      }      
    }else if(country ==='cn' ){// we set the value to phone number
        let count = withOutDailingCode.replace(/ +/g, "").replace("-", "").length
        if(count === 11){
            let t = withOutDailingCode.slice(0, 2) + withOutDailingCode.slice(3, withOutDailingCode.length);
            phone = "86"+t;
            setPhoneNumber(phone);
            setPhoneFormartError("");
            setPhoneError("");
        }else{
           setPhoneFormartError("Invalid format");
        } 
    }else if(country ==='gb' ){// we set the value to phone number
      let count = withOutDailingCode.length;
      if(count === 10){
          phone = "44"+withOutDailingCode;
          setPhoneNumber(phone);
          setPhoneFormartError("");
          setPhoneError("");
      }else{
         setPhoneFormartError("Invalid format");
      } 
  }else if(country ==='us' || country ==='ca' ){// we set the value to phone number
      let count = withOutDailingCode.length;
      if(count === 10){
          phone = "1"+withOutDailingCode;
          setPhoneNumber(phone);
          setPhoneFormartError("");
          setPhoneError("");
      }else{
        setPhoneFormartError("Invalid format");
      } 
  }else if(country ==='ae' ){// we set the value to phone number
      let count = withOutDailingCode.length;
      if(count === 9){
          phone = "971"+withOutDailingCode;
          setPhoneNumber(phone);
          setPhoneFormartError("");
          setPhoneError("");
      }else{
        setPhoneFormartError("Invalid format");
      } 
  }
}

const handleEmailChange = (e) =>{
  setEmail(e.target.value);
}

const handleEmailBlur = (e) =>{
  setEmail(e.target.value)
  if(!e.target.value){
    setEmailError("Email cannot be empty")
  }else if(!e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
    setEmailError("Invalid email")
  }else{
    setEmailError("")
  }
}

const handleFullname = (e) =>{
  setFullname(e.target.value);
}

const handleRegister = (e) =>{
  e.preventDefault();

  if(email != persistUser?.email){
    setEmailError("Incorrect email, please enter same email as your checkstall login");
    return;
  }

  if(!refferalCode){
    setRefferalCodeError("Refferal code cannot be empty");
    return;
  }
  //if phone is not check
  if(!persistUser?.phone){
    if(!phone_number){
      setPhoneError("please input a phone number");
      return;
    }

      if(!code ){
          setCodeError("Code not set ");
          return;
      }

       if(!isVerified){
          setCodeError("Code not invalid ");
          return;  
       }
  }

  if(!persistUser?.full_name){
    if(!fullName){
      setNameError("Please Enter Full name");
      return;
    }
  }
  
  registerAgent();
  
}

const registerAgent = () =>{
  let isMounted = true;
  const controller = new AbortController();
  setErrorText("Please...")
  setIsTransparentLoading(true);
  
  const register = async () => {
        let full_name = "";
        if(!persistUser?.full_name){
             full_name = fullName.split(" ");

            if(full_name != undefined && full_name != "" ){
                for (let i = 0; i < full_name.length; i++) {
                  full_name[i] = full_name[i][0].toUpperCase() + full_name[i].substr(1);
                }
                
                full_name = full_name.join(" ");
            }
        }else{
          full_name =  fullName;
        }
        
        
      try {
            const response = await axiosPrivate.post("v1/agent/register-agent",
            JSON.stringify({full_name ,email, phone_number,refferalCode}),
            {
              signal: controller.signal
            });

            if(response?.data?.code == 2001){
                setIsTransparentLoading(false);
                setFullname("")
                setPhoneNumber("");
                setEmail("");
                
                navigate('/checkstall-partner/index');
            }else{
              isMounted && setError(response?.data?.message);
            }
               
      }catch(err){  
      }
      setIsTransparentLoading(false);
  }

  register();
  return () => {
    isMounted = false;
    controller.abort();
  }
}

const handleRefferalCodeChange = (e) =>{
  setRefferalCode(e.target.value);
}

const handleRefferalCodeBlur = (e) =>{
  
  if(!e.target.value){
    setRefferalCodeError("Refferal code cannot be empty");
  }else if(!e.target.value.match(/^[a-zA-Z0-9_]+$/i)){
    setRefferalCodeError("Refferal code can only contain A-Z, a-z, 0-9, _")
  }else if(e.target.value.length < 4){
    setRefferalCodeError("Refferal code must be 4 or longer")
  }else{
    setRefferalCode(e.target.value);
    isRefferalCodeAvailable(e.target.value);
    setRefferalCodeError("");
  }
}

const isRefferalCodeAvailable = (code) =>{

  const checkCode = async (code) => {
  
      try {
            const response = await axiosPrivate.get("v1/agent/is-code-available?code="+code);

            if(response?.data?.message == 101){
              setRefferalCodeError("The selected code already exist, please choose another one!");
            }else{
              setRefferalCodeError("")
            }
               
      }catch(err){  
      }
  }

  checkCode(code);

}
const phoneInputStyle = {
  border: "none",
  width: "100%",
  outline: 'none'
};
  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <Navbar />
        <div className={classes.container}>
          <h1 className={classes.title}>
            Complete the form to become a Partner
          </h1>
          <div className="regErr">{error}</div>
          <form className={classes.formContainer} onSubmit={handleRegister}>
            <div className={classes.inputBox}>
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                sx={{ width: "100%" }}
                value={email}
                required
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
              />
              <span className="regErr">{emailError}</span>
            </div>
            {!persistUser?.full_name && (
              <div className={classes.inputBox}>
                <TextField
                  id="outlined-basic"
                  label="Full Name"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  value={fullName}
                  onChange={(e) => {
                    setNameError("");
                    handleFullname(e);
                  }}
                  required
                />
                <div className="regErr">{nameError}</div>
              </div>
            )}
            {!persistUser?.phone && (
              <React.Fragment>
                <div className={classes.inputBox}>
                  <Form.Group
                    size="lg"
                    controlId="phone_number"
                    inputStyle={phoneInputStyle}
                  >
                    <PhoneInput
                      country={"ng"}
                      value={phone_number}
                      onBlur={handleOnChange}
                      onlyCountries={[
                        "ng",
                        "gb",
                        "gh",
                        "ke",
                        "ca",
                        "us",
                        "ug",
                        "ae",
                        "za",
                        "cn",
                      ]}
                      preferredCountries={["ng"]}
                      countryCodeEditable={false}
                      ref={phoneRef}
                      inputStyle={phoneInputStyle}
                    />
                    <span className="regErr">{phoneError}</span>
                    <span className="regErr">{phoneFormatError}</span>
                  </Form.Group>
                </div>
                  <div className={classes.codeWrapper}>
                    <div className={classes.codeBox}>
                      <div className={classes.code} onClick={sendOtp}>
                        Get code <MailOutlined sx={{ color: "crimson" }} />
                        &nbsp;&nbsp;
                        {isVerified && (
                          <span className="">
                            <DoneOutlined sx={{ color: "green" }} />
                          </span>
                        )}
                        {isChecking && (
                          <span className="">
                            <img src="/images/loader/reload.gif" />
                          </span>
                        )}
                        {isCountDown && (
                          <span id="counter" style={{ color: "crimson" }}>
                            {count}
                          </span>
                        )}
                      </div>
                      <div className={classes.codeinput}>
                        <TextField
                          type="number"
                          id="outlined-basic"
                          label="Enter code"
                          variant="outlined"
                          value={code}
                          required
                          maxLength={6}
                          onChange={handleSetCode}
                          sx={{ width: "100%" }}
                        />
                      </div>
                    </div>

                    <span className="regErr">{codeError}</span>
                  </div>
              </React.Fragment>
            )}

            <div className={classes.inputBox}>
              <TextField
                id="outlined-basic"
                label="Set Referral Code"
                variant="outlined"
                sx={{ width: "100%" }}
                value={refferalCode}
                required
                onChange={handleRefferalCodeChange}
                onBlur={handleRefferalCodeBlur}
              />
              <span className="regErr">{refferalCodeError}</span>
            </div>
            <button className={classes.registerButton} onClick={handleRegister}>
              Register
            </button>
          </form>
          <p className={classes.terms}>
            By clicking Registration, you agree to checkstall partner terms and
            condition. for more information see
            <Link to="/checkstall-partner/terms-and-condition">
              Terms and Conditions , Privacy Policy
            </Link>
            <Link to="/checkstall-partner/privacy-policy">Privacy Policy</Link>
          </p>
          <p className={classes.cookies}>
            Checkstall uses cookies to enhance your website experience. By
            continue to this browse this website, you consent to the use of
            these cookies.
            <Link to="/checkstall-partner/cookie-policy">
              Find out more for Cookie Policy.
            </Link>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Register;