import React, { useState, useMemo,createContext } from 'react';

const SearchSiteContext = createContext();

export const SearchSiteProvider = ({ children }) => {
    const [site, setSite] = useState("1688");
    const value = useMemo(() => ({ site, setSite }), [site, setSite]);

    return (
    <SearchSiteContext.Provider value={value}>{children}</SearchSiteContext.Provider>
    );
 };

 export default SearchSiteContext;