import {  useLocation} from 'react-router-dom';

export const  isJSON = (json) => {
    try {
        var obj = JSON.parse(json)
        if (obj && typeof obj === 'object' && obj !== null) {
          return true
        }
      } catch (err) {}
      return false
}

export const Exchanger = (defaultCurrency, preferedCurrency, price, rate) => {


  let total = 0;
  
  if(defaultCurrency === preferedCurrency){//if defaultCurrent equal preferedCurrency => no need to exchange retrn the original price
     return price;
  }

  if(defaultCurrency !== preferedCurrency ){//if defaultCurrent is not equal preferedCurrency and defaultCurrent is Naira=> apply exchange
    
      if(defaultCurrency === "NGN"){
          total = price/rate;
      }else{
          total = price*rate;
      }
  }

  return total;

}

//get the property module e.g color, sizes, memory e.t.c
export const getModule = (Data,props) => {
  return Data?.item?.props_list[props] ? Data?.item?.props_list[props]?.split(':')[0]  : "";
}

export const getPropsList = (Data) => {
  return Data ? Data?.item?.props_list : {};
}

export const getPropsImg = (Data) => {
  return Data ? Data?.item?.prop_imgs?.prop_img : [];
}

export const getFirstImg = (Data,prop) => {
  return (Data && Data?.item?.props_img[prop]) ? Data?.item?.props_img[prop] : '';
}

//if no price range get the biggest price from sku as price rage
export const getMaxPrice = (Data) => {
  let max = parseFloat(0);
  Data?.item?.skus?.sku.map((value) =>{
    if(value?.price > max){
      max = parseFloat(value?.price)
   }
  });
   //if max is bigger that item price show it in range else return null
  if(max > Data.item.price){
    return max;
  }else{
    return null
  }

  
}

export const getCleanString = (string, delimeter, index) => {
  //string: the string to be splitted and clean
  //the delimeter to split the string
  //the index(position) of the new value we should return
  return string.split(delimeter)[index];
}

//send user back to the previous target where they were coming from
export const LocationTarget = () => {
    const location = useLocation();

    let from =  "";
    const path = location.state?.from?.pathname;
    if(path){
      let query = location.state?.from?.search || "";
      if(query){
          from = path + query;
      }else{
        from = path;
      }

    }else{
      from = "/";
    }

    return from;
}

export const getSite = (Url) => {
  let site = "1688"; //default search site
  if(Url.indexOf('1688.com/') >= 0){ 
    site = "1688";
  }else if(Url.indexOf('taobao.com/') >= 0){
    site = "taobao";
  }else if(Url.indexOf('jd.com/') >= 0){
    site = "jd";
  }

  return site;
}

export const getSpecProps = (string) => {
  let spec = '';
  if(string){
     spec = string.split(';');
  }

  let prop1 = "";
  let prop2 = "";
  if(spec[0]){
    prop1 = spec[0].slice(4);
  }
   
  if(spec[1]){
    prop2 = spec[1].slice(4);
  }

  return prop1+" ; "+prop2;
}

export const getSkuTotalQty = (sku) => {
  let total = 0; 

  sku.map((val) => {
      if(!isNaN(val.quantity)){
        total += parseFloat(val.quantity);
      }
    });

  return total;
}

export const maskEmail = (email) => {              
  let split = email.split('@')
  return email.substr(0,1) + new Array(3).fill('*').join('') + "@" + split[1];
}

export const maskPhoneNumner = (phoneNumber) => {

  return phoneNumber.substr(0,4) + new Array(7).fill('*').join('')  + phoneNumber.substr(10,2);
}

export const getPostFee = (postFee,defaultFee,additionalFee,qty,price) => {
   //if item have no post fee add 2 yuan as default post fee
   //if item have post fee.... if quantity is 1 post fee will be original post fee if quantify more than 1 for every additional
   //item add 1.5 to post fee
   //if item price is less than 5 yuan add 0.5 for additional item if price more that 5 add 1 yuan
   let post_fee = 0;
   if(parseFloat(postFee) === 0 || postFee == null){
     post_fee = defaultFee;
   }else if(parseFloat(postFee) > 0){
      if(qty === 1){
        post_fee = parseFloat(postFee);
      }else if(qty > 1){
       // post_fee = ((qty-1) * parseFloat(additionalFee)) + parseFloat(postFee);
         if(parseFloat(price) <= 10){
             post_fee = ((qty-1) * (parseFloat(additionalFee)/2)) + parseFloat(postFee);
         }else{
             post_fee = ((qty-1) * parseFloat(additionalFee)) + parseFloat(postFee);
         }
        
      }
   }

   return post_fee;

}





