import React, {useEffect, useState} from 'react';
import '../../../assets/css/wallet.css';
import { Seo } from '../../utilities/Seo';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useRefreshToken from '../../../hooks/useRefreshToken';
import useCheckAuthWithRedirect from "../../../hooks/useCheckAuthWithRedirect";
import Spinner from "../../utilities/Spinner";
import Form from "react-bootstrap/Form";
import {getCookie ,checkCookie, deleteCookie, setCookie} from "../../functions/CustomCookies";
import {Link, useNavigate, useLocation} from 'react-router-dom';
import Unauthorized from '../Unauthorized';
import images1 from '../../../assets/data/images1';
import { FaCopy } from 'react-icons/fa';


function WalletTopup(){
 //check for authentication on the page
   useCheckAuthWithRedirect();

   const [modalShow, setModalShow] = useState(false);
   const amount = getCookie("topUpAmount");
   const payment_method = getCookie("topUpMethod");
   const paymentMethodId = getCookie("topUpMethod");
   const [paymentDetails, setPaymentDetails] = useState({});
   

   const axiosPrivate = useAxiosPrivate();
   const refresh = useRefreshToken();
   const navigate = useNavigate();
   const location = useLocation();

   

     //set page title
useEffect(() => {
    const pageTitle =  "Wallet topup || DajuPay  || Reliable and secure online payment alternative";
    Seo({
      title: pageTitle,
      metaDescription: 'Reliable and secure payment alternative',
    });
  }, []);

  //get new token for the reques
 useEffect(()  =>{
    const getRefreshToken = async () => {
        await refresh();
    }
    getRefreshToken();
  },[]);

  //check if cookie is set if not return unauthorized request page
  useEffect(() =>{
    if(!checkCookie("topUpAmount") && !checkCookie("topUpMethod")){
        navigate("/unauthorized", { state: { from: location, page:-2}, replace: true });
      }

  },[]);

  useEffect(() =>{
    getPaymentinfo();
  },[]);


  useEffect(() =>{
    //if isExist cookie is not set it means transaction hasn't being created we run topup() 
    //to create transaction else transaction already created don't run top up
    if(!checkCookie("isExist") ){
        Topup();
    }
    
  },[]);

  const getPaymentinfo = () =>{
    let isMounted = true;
    const controller = new AbortController();
  
    const doGetinfo = async () => {
        setModalShow(true);
        try {
              const response = await axiosPrivate.post("v1/payment-information/get-payment-details",
                JSON.stringify({paymentMethodId}), {
                signal: controller.signal
              });
  
            //handle response
               isMounted && setPaymentDetails(response?.data?.message);

        }catch(err){
      
            console.log(err.response?.data);
            
        }
        setModalShow(false);
    }
  
    doGetinfo();
    return () => {
      isMounted = false;
        controller.abort();
    }
  }

  const Topup = () =>{

    let isMounted = true;
    const controller = new AbortController();
  
    const doTopup = async () => {
      let data = {amount, payment_method};
        setModalShow(true);
        try {
              const response = await axiosPrivate.post("v1/transaction/wallet-topup",
                JSON.stringify({data}), {
                signal: controller.signal
              });

              if(parseInt(response?.data.code) === 1000){
                 setCookie("isExist", true, 0.5);
              }
        }catch(err){
      
            console.log(err.response?.data);
            
        }
        setModalShow(false);
    }
  
    doTopup();
    return () => {
      isMounted = false;
        controller.abort();
    }
  }

  const handleSubmit = () =>{
    deleteCookie("isExist");
    deleteCookie("topUpAmount") 
    deleteCookie("topUpMethod");
  
    navigate("/dajupay/transaction-pending", {state:{refresh:true}});
  }

  const copyToClipboard =(event) => {
    let text = event.target.closest(".dp-info-tr")?.querySelector(".depo-info").innerHTML;
    navigator.clipboard.writeText(text);
    let popup = event.target.closest(".bank_info")?.querySelector(".copyNotice");
    popup.style.display="block";
    
    const timer = setTimeout(() => {
      popup.style.display="none";
    }, 1000);
    return () => clearTimeout(timer);
  }

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    window.addEventListener('unload', handleTabClosing)
    return () => {
        window.removeEventListener('beforeunload', alertUser)
        window.removeEventListener('unload', handleTabClosing)
    }
})

const handleTabClosing = () => {
  deleteCookie("isExist");
  deleteCookie("topUpAmount") 
  deleteCookie("topUpMethod");
}

const alertUser = (event) => {
    event.preventDefault()
    event.returnValue = ''
}


    return(

        <>
            <Spinner
                cssClass={'semi-dark-bg'}
                show={modalShow}
            />
            
            <div className="d-flex justify-content-center">
                <div className="payment-info">
                  <div className="payment-info-header">
                      <img  src={images1[0].src} alt="logo" /> 
                      <span>Bank Transfer</span>
                  </div>
                  <div className="code-container">
                      <div className="dep-amt">
                          ₦
                          <span>{amount}</span>
                      </div>

                      <div className="bank_info" style={{position:'relative'}}>
                        <span className={`copyNotice`}>Copied</span>
                          <table className="table table-striped">
                              <tbody>
                                <tr className='dp-info-tr'>
                                  <td>Account Name</td>
                                  <td className="depo-info">{paymentDetails.account_name}</td>
                                  <td >
                                      {/* <i className="copy-info fa fa-copy" data-toggle="tooltip" data-placement="top" title="click to copy"></i> */}
                                      <FaCopy className='copy-info' onClick={copyToClipboard} />
                                  </td>
                                </tr>
                                <tr className='dp-info-tr'>
                                  <td>Account Number</td>
                                  <td className="depo-info">{paymentDetails.account_number}</td>
                                  <td>
                                      {/* <i className="copy-info fa fa-copy" data-toggle="tooltip" data-placement="top" title="click to copy"></i> */}
                                      <FaCopy className='copy-info' onClick={copyToClipboard} />
                                  </td>
                                      
                                </tr>
                                <tr className='dp-info-tr'>
                                  <td>Bank Name</td>
                                  <td className="depo-info">{paymentDetails.bank_name}</td>
                                  <td>
                                      {/* <i className="copy-info fa fa-copy" data-toggle="tooltip" data-placement="top" title="click to copy"></i> */}
                                      <FaCopy className='copy-info' onClick={copyToClipboard} />
                                  </td>
                                      
                                </tr>
                              </tbody>
                    </table>
                  </div>
                        
                  </div>
                  <div className="instriction-box">
                      <p>How to make Payment:</p>
                      <ol>
                          <li>Send money to our bank account above.</li>
                          <li>Put correct amount as your deposit ask.</li>
                          <li>Enter your username or phone number in the remark box.</li>
                          <li>After transfer completed please click "done" button.</li>
                          <li>If you need to carry out this transaction in in bank, copy the bank information and click on done.</li>
                            <li>Email us your username and the copy of your transfer receipt to checkstall@email.com.</li>
                          <li>Note this transaction is performed by our operator in a manual mode and may take 30 minutes to 1 hour to complete.</li>
                      </ol>
                  </div>
                  <div className="complete-transfer">
                    <button onClick={handleSubmit}>done</button>
                  </div>
                </div>
            </div>
            
        </>
    )
}

export default WalletTopup;