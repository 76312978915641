import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import { Seo } from '../../utilities/Seo';
import '../../../assets/css/success.css';


export const  Completed  = () => {

    //set page title
useEffect(() => {
    const pageTitle =  "Order Submitted Successfully || Checkstall.com";
    Seo({
      title: pageTitle,
      metaDescription: 'Order Submitted Successfully',
    });
}, []);

    return (
        <>
            <div className='success-alert'>
                <div className="alert alert-success" role="alert">
                    <div>Order submitted successfully! your order will be process as soon as possible, you can make payment after your order has being reviewed </div>
                    <div className="d-flex justify-content-start">
                        <div>  Go  <Link to="/" style={{textDecoration:'none'}}>Home &nbsp;</Link> </div>
                        <div>  or go to&nbsp;<Link to="/members/orders/index?orderStatus=all" style={{textDecoration:'none'}}>My Order</Link> to know  your order status</div>
                    </div>
                </div>
            </div>
        </>
    )
};
