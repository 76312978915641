import React,{useEffect, useState} from "react";
import { Seo } from '../../utilities/Seo';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useCheckAuthWithRedirect from '../../../hooks/useCheckAuthWithRedirect';


export const  Withdraw = () => {

    useCheckAuthWithRedirect();
    //set page title
    useEffect(() => {
        const pageTitle =  "Withdrawal || Checkstall.com";
        Seo({
        title: pageTitle,
        metaDescription: 'Withdrawal',
        });
    }, []);

    useEffect(() => {
        getBalance();
     }, []);

    const axiosPrivate = useAxiosPrivate();
    const [availabeBalance, setAvailableBalance] = useState(0);
    const [amount, setAmount] = useState(0);
    
    const handleinputChange =(event) =>{
        setAmount(event.target.value);
     }

     const handleWithdraw = () =>{
        // setCookie("topUpAmount", amount, 0.5);
        // setCookie("topUpMethod", paymentMethodId, 0.5);
        // navigate('/dajupay/wallet-topup', {state:{refresh:true}});
      }

      const getBalance= () =>{

        const doGetBalancee = async () => {
        
            try {
                  const response = await axiosPrivate.get("v1/agent/get-balance");
                  setAvailableBalance(response?.data?.available_balance);
            }catch(err){  
            }
        }
      
        doGetBalancee();
      
      }
    return (
            <div >
                {/* <div className='arrow-with-title nav-back' >
                    <span className='back' ><ChevronLeft /></span>
                    <div className='title'>Top up</div>
                </div>  */}
                <div className="d-flex flex-column topup-form-wrap">
                    <div>Withdraw your earning to your wallet!</div>
                    <div>The available amount signifies the total amount you earn from the completed order your referee purchased.</div>
                    <div>Available amount: <b style={{fontWeight:'bold'}}>{availabeBalance}</b></div>
                    <br/>
                    <div className="d-flex flex-column topup-form" >
                        <span className="currency">₦</span>
                        <label htmlFor='topupBox'>Enter Withdraw Amount bemow</label>
                        <input 
                            type="number" 
                            id="topupBox" 
                            onChange={handleinputChange}
                            
                        />
                    </div>
                    <div className="topup-btn d-flex justify-content-center">
                    <button 
                        disabled= {!(amount > 0 ) } 
                        className={`${amount > 0  ? 'trans-btn-active' : ""} `}
                        onClick={handleWithdraw}>OK
                    </button>
                    </div>
                </div>
            </div>
    )
};

