import React from "react";
import { Link } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const FixedSideBar = () => {
  return (
      <div className="fixed-side-bar">
        <div className="fixed-side-bar-link-box">
          <Link to="/members/home/index" className="fixed-side-bar-link">
            <AccountBoxIcon sx={{ fontSize: "2rem", color: "white" }} />
          </Link>
          <div className="fixed-hover-box">
            <span className="box-content">User</span>
            <span className="arrow-direct">
              <ArrowRightIcon sx={{ fontSize: "2.5rem", color: "#444851" }} />
            </span>
          </div>
        </div>

        <div className="fixed-side-bar-link-box">
          <Link to="/cart" className="fixed-side-bar-link">
            <ShoppingCartIcon sx={{ fontSize: "2rem", color: "white" }} />
          </Link>
          <div className="fixed-hover-box">
            <span className="box-content">Cart</span>
            <span className="arrow-direct">
              <ArrowRightIcon sx={{ fontSize: "2.5rem", color: "#444851" }} />
            </span>
          </div>
        </div>
        <div className="fixed-side-bar-link-box">
          <Link to="/checkstall-partner" className="fixed-side-bar-link">
            <HandshakeIcon sx={{ fontSize: "2rem", color: "white" }} />
          </Link>
          <div className="fixed-hover-box">
            <span className="box-content">Partner Program</span>
            <span className="arrow-direct">
              <ArrowRightIcon sx={{ fontSize: "2.5rem", color: "#444851" }} />
            </span>
          </div>
        </div>
      </div>
  );
};

export default FixedSideBar;
