import React from "react";
import NavBar from './NavBar';
import NavTwo from './NavTwo';


const Header = () => {
  return (
    <div className="">
        <NavBar />
        <NavTwo />       
    </div>
  );
};

export default Header;