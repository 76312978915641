import React from 'react';
import '../../assets/css/notfound.css';
import { useNavigate } from 'react-router';

const NotFound = () => {
    const navigate = useNavigate();
return (
  <div className="not-found-wrapper">
    <div className="not-found-container">
      <div className="not-found-title">
        <h1 className="not-found-error">404</h1>
        <h1 className="not-found-info">Oops!! we couldn't find this page</h1>
      </div>
      <div className="not-found-reason">
        <span>We looked everywhere for this page.</span>
        <span>Are you sure the website URL is correct?</span>
        <span>Get in touch with the site owner.</span>
      </div>
      <button className='not-found-button' onnClick={() => navigate('/')}>go back Home</button>
    </div>
  </div>
);

};
export default NotFound;