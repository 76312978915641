
const images1 = [{
    id: 1,
    src: "/images/items/1.jpg",
    alt: "Image 1"
},
{
    id: 2,
    src: "/images/items/2.png",
    alt: "Image 2 "
},
{
    id: 3,
    src: "/images/items/3.jpg",
    alt: "Image 3"
},
{
    id: 4,
    src: "/images/items/4.jpg",
    alt: "Image 4"
},
{
    id: 5,
    src: "/images/items/5.jpg",
    alt: "Image 5"
}
];
export default images1;