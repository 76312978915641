import React ,{ createContext, useState, useMemo } from "react";
import getLocalStorage from "../components/functions/getLocalStorage";


const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(getLocalStorage('auth') || {} );
    const [persist, setPersist] = useState(Boolean(getLocalStorage("persist")) || true);
    const value = useMemo(() => ({ 
        auth, setAuth ,persist ,setPersist
    }), [auth, setAuth, persist,setPersist]);

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    )
}

export default AuthContext;