import React,{useEffect, useState} from "react";
import classes from "./MiniNavbar.module.css";
import { useLocation } from "react-router-dom";
import getLocalStorage from "../functions/getLocalStorage";
import {getCookie } from '../functions/CustomCookies';
import { isJSON } from "../functions/Helpers";
import useLogout from '../../hooks/useLogout';
import { maskEmail } from "../functions/Helpers";
import Spinner from "../utilities/Spinner";
import {
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiFillLinkedin,
  AiFillFacebook,
} from "react-icons/ai";
import AccountBox from "../accountBox/AccountBox";
import NavbarAuth from "../navAuth/NavbarAuth";
import Currency from "../currency/Currency";
import { Link } from "react-router-dom";
import Tools from "../tools/Tools";

const MiniNavbar = () => {
  let userObj = getLocalStorage('user')
  const userInfo =  (userObj  && userObj !== undefined &&  isJSON(userObj) )  ?  JSON.parse(userObj) : getCookie("user");
   let email = userInfo?.email;
   let username = userInfo?.username;
   let fullName = userInfo?.full_name ? userInfo.full_name.split(" ")[0] : null;
   let userRole = userInfo?.role;
  //  console.log(userInfo);

  const [modalShow, setModalShow] = useState(false);
  const [path, setPathName] = useState("");
  const { pathname } = useLocation();

  const logout = useLogout();

  const signOut = async () => {
    setModalShow(true);
      await logout();
    setModalShow(false);
  }
    useEffect(() => {
      setPathName(pathname);
    }, [pathname]);

  return (
    <React.Fragment>
      <Spinner cssClass={"semi-dark-bg"} show={modalShow} />
      <nav className={classes.wrapper}>
        <main className={classes.container}>
          <div className={classes.navInfo}>
            {/* <div className={classes.option}>
                home
                <span className={`${classes.underline} ${classes.active}`} />
              </div>
              <span>|</span>
              <div className={classes.option}>
                about us
                <span className={classes.underline} />
              </div>
              <span>|</span> */}
            {/* <Link className={classes.option}>
              seller center
              <span className={classes.underline} />
            </Link>
            <span>|</span>
            <Link className={classes.option}>
              start selling
              <span className={classes.underline} />
            </Link> */}
            <Link
              to="/checkstall-partner"
              className={`${classes.option} ${
                path.includes("/checkstall-partner") ? classes.active : ""
              }`}
            >
              become a partner
              <span className={classes.underline} />
            </Link>
            <span>|</span>
            <Link
              to="/howtobuy"
              className={`${classes.option} ${
                path.includes("/howtobuy") ? classes.active : ""
              }`}
            >
              how to buy
              <span className={classes.underline} />
            </Link>
            <span>|</span>
            <div>
              <span className={classes.socialOptions}>
                follow us on&nbsp;
                <AiOutlineInstagram className={classes.socialIcon} />
                &nbsp;
                <AiOutlineTwitter className={classes.socialIcon} />
                &nbsp;
                <AiFillLinkedin className={classes.socialIcon} />
                &nbsp;
                <AiFillFacebook
                  className={classes.socialIcon}
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/lightliftimport",
                      "_blank"
                    )
                  }
                />
              </span>
            </div>
          </div>
          <div className={classes.authBox}>
            <Currency />
            <div className={classes.helpBox}>
              help center
              <span className={classes.underline} />
            </div>
            <Tools />
            <Link
              to="/messages"
              className={`${classes.helpBox} ${
                path.includes("/messages") ? classes.active : ""
              }`}
            >
              messages(0)
              <span className={classes.underline} />
            </Link>
            <AccountBox userRole={userRole} />
            {userInfo ? (
              <div className={classes.authNameBox}>
                <span>
                  {fullName || maskEmail(username) || maskEmail(email)}
                </span>
                <button onClick={signOut} className={classes.logoutBtn}>
                  Log out
                </button>
              </div>
            ) : (
              <NavbarAuth />
            )}
          </div>

          {/* <div className={classes.contactBox}>
              <div className={classes.info}>
                <IoMdCall />
                <p>+234 980 939 3031</p>
              </div>
              <span>|</span>
              <div className={classes.info}>
                <AiOutlineMail />
                <p>support@checkstall.com</p>
              </div>
            </div> */}
        </main>
      </nav>
    </React.Fragment>
  );
};

export default MiniNavbar;
