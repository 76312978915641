import React, { useEffect, useState } from "react";
import classes from "./Calculator.module.css";
import Navbar from "../../navbar/Navbar";
import { FaTshirt } from "react-icons/fa";
import { GiConverseShoe } from "react-icons/gi";
import { BsFillHandbagFill } from "react-icons/bs";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import axios from "../../../api/axios";
import { RoundToTwo } from "../../utilities/RoundToTwo";
import { Seo } from "../../utilities/Seo";

const Calculator = () => {
  const [country, setCountry] = useState("");
  const [goods, setGoods] = useState("");
  const [shippment, setShippment] = useState("");
  const [weight, setWeight] = useState();
  const [result, setResult] = useState(0);
  const [error, setError] = useState("");
  const [shippmentFee, setShippmentFee] = useState({});
  const [clearance, setClearance] = useState(0);
  const [total, setTotal] = useState(0);
  const [length, setLength] = useState(0);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [showBtn, setShowBtn] = useState(false);

  const handleCountry = (e) => {
    setCountry(e.target.value);
    setShowBtn(true);
  };
  const handleWeight = (e) => {
    setWeight(e.target.value);
    setShowBtn(true);
  };
  const handleTypeOfGoods = (e) => {
    setGoods(e.target.value);
    setShowBtn(true);
  };
  const handleShippment = (e) => {
    setShippment(e.target.value);
    setShowBtn(true);
  };

  const handleCalculation = () => {
    if (shippment === "Sea") {
      seaCalculation();
    } else if (shippment === "Flight") {
      airCalculation();
    } else {
      setError("please set correct values");
      return;
    }
  };
  const airCalculation = () => {
    let shippingFee = 0;
    let clearanceFee = 0;
    if (goods === "Special goods") {
      shippingFee =
        shippmentFee?.special?.fee * weight * shippmentFee?.special?.rate;
      clearanceFee = shippmentFee?.special?.clearance * weight;
    } else {
      shippingFee =
        shippmentFee?.normal?.fee * weight * shippmentFee?.normal?.rate;
      clearanceFee = shippmentFee?.normal?.clearance * weight;
    }

    setResult(shippingFee);
    setClearance(clearanceFee);
    setTotal(shippingFee + clearanceFee);
  };

  const seaCalculation = () => {
    let shippingFee = 0;
    let clearanceFee = 0;

    let cbm = (length * height * width) / 1000000;
    shippingFee = shippmentFee?.sea?.fee * shippmentFee?.sea?.rate * cbm;
    clearanceFee = shippmentFee?.sea?.clearance * cbm;

    setResult(shippingFee);
    setClearance(clearanceFee);
    setTotal(shippingFee + clearanceFee);
  };
  const getShipmentFee = async () => {
    try {
      const response = await axios.get("/v1/helpers/get-shipping-fee", {
        withCredentials: false,
      });

      setShippmentFee(response?.data);
    } catch (err) {}
  };

  const handleClearInput = () => {
    setWeight(0);
    setCountry("please choose");
    setGoods("please choose");
    setShippment("please choose");
    setLength(0);
    setHeight(0);
    setWeight(0);
    setTotal(0);
    setClearance(0);
    setResult(0);
    setShowBtn(false);
  };

  useEffect(() => {
    getShipmentFee();
  }, []);

  useEffect(() => {
    Seo({
      title:
        "Freight calculation System  || Estimate the cost of the shippment of your parcel in the easiest and simple way",
      metaDescription:
        "Our Freight calculation helps you estimate the cost of the shippment of your parcel in the easiest and simple way",
    });
  }, []);

  return (
    <>
      <Navbar />
      <main className={classes.wrapper}>
        <section className={classes.container}>
          <h1 className={classes.pageTitle}>Freight calculation</h1>
          {/* <div className={classes.amountNoticeBox}>
            <p className={classes.noticeInfo}>
              <b>Please Note</b>, starting from the 29th of May, our fixed rates
              for air cargo from China to Lagos will be as follows:
            </p>
            <div className={classes.amountNotice}>
              <span className={classes.notice}>
                Normal/copy brand goods through Guangzhou airport, about 7 to 10
                days: <b>$6.3/kg</b>
              </span>
              <span className={classes.notice}>
                Special goods through Hong Kong airport about 2weeks (rejects
                powerbank, phone and laptop): <b>$6.6/kg</b>
              </span>
              <span className={classes.notice}>
                Normal goods Custom clearing in Nigeria: <b>₦600/kg</b> 
              </span>
              <span className={classes.notice}>
                The price in Guangzhou shall be subject to the day of reception
                of the goods!
              </span>
              <span className={classes.notice}>
                The price in Hong Kong is calculated on the day the goods fly
                away, at present, only the warehouse price is considered
              </span>
            </div>
          </div> */}
          <div className={classes.boxesContainer}>
            <div className={classes.box1}>
              <h3 className={classes.title}>Freight Calculator</h3>
              {result === 0 ? (
                ""
              ) : (
                <p className={classes.result}>
                  <span>
                    Estimated Freight cost :<b>₦{RoundToTwo(result)}</b>
                  </span>
                  <span>
                    Estimated Clearance cost : <b>₦{RoundToTwo(clearance)}</b>
                  </span>

                  <span>
                    Estimated Total: <b>₦{RoundToTwo(total)}</b>
                  </span>
                </p>
              )}
              {error && <p className={classes.error}>{error}</p>}
              <div className={classes.calculatorBox}>
                <div className={classes.inputBox}>
                  <label className={classes.inputLabel}>
                    destination country
                  </label>
                  <select
                    className={classes.inputdetail}
                    value={country}
                    onChange={handleCountry}
                    required
                  >
                    <option>Please choose</option>
                    <option value="Nigeria">Nigeria</option>
                    {/* <option value="China">China</option> */}
                  </select>
                </div>
                <div className={classes.inputBox}>
                  <label className={classes.inputLabel}>
                    commodity weight(kg/cbm)
                  </label>
                  <input
                    className={classes.inputdetail}
                    type="number"
                    value={weight}
                    onChange={handleWeight}
                    required
                  />
                </div>
                <div className={classes.inputBox}>
                  <label className={classes.inputLabel}>type of goods</label>
                  <select
                    className={classes.inputdetail}
                    value={goods}
                    onChange={handleTypeOfGoods}
                  >
                    <option value="choose">Please choose</option>
                    <option value="Normal goods">Normal goods</option>
                    <option value="Special goods">Special goods</option>
                  </select>
                </div>
                <div className={classes.inputBox}>
                  <label className={classes.inputLabel}>shippment type</label>
                  <select
                    className={classes.inputdetail}
                    value={shippment}
                    onChange={handleShippment}
                  >
                    <option value="choose">Please choose</option>
                    <option value="Flight">Flight</option>
                    <option value="Sea">Sea</option>
                  </select>
                </div>
              </div>
              <div className={classes.otherparamsBox}>
                <div className={classes.otherparams}>
                  <span>Commodity volume</span>(Some express lines need to
                  calculate freight based on package volume (length * width *
                  height))
                </div>
                <div className={classes.otherparamsInputBox}>
                  <div className={classes.otherparamsInput}>
                    <label>long(cm)</label>
                    <input
                      type="number"
                      placeholder="please enter length"
                      value={length}
                      onChange={(e) => {
                        setLength(e.target.value);
                        setShowBtn(true);
                      }}
                    />
                  </div>
                  <div className={classes.otherparamsInput}>
                    <label>width(cm)</label>
                    <input
                      type="number"
                      placeholder="please enter width"
                      value={width}
                      onChange={(e) => {
                        setWidth(e.target.value);
                        setShowBtn(true);
                      }}
                    />
                  </div>
                  <div className={classes.otherparamsInput}>
                    <label>height(cm)</label>
                    <input
                      type="number"
                      placeholder="please enter height"
                      value={height}
                      onChange={(e) => {
                        setHeight(e.target.value);
                        setShowBtn(true);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.btnHolder}>
                <button
                  className={classes.btn}
                  onClick={() => handleCalculation()}
                >
                  Estimate
                </button>
                <button
                  className={`${classes.btnClear} ${
                    !showBtn && classes.hideClearBtn
                  }`}
                  onClick={handleClearInput}
                >
                  Clear
                </button>
              </div>
            </div>
            <div className={classes.box2}>
              <h3 className={classes.title}>Common item weight</h3>
              <div className={classes.box2ContentBox}>
                <div className={classes.box2Content}>
                  <div
                    className={classes.iconBox}
                    onClick={() => setWeight(200)}
                  >
                    {" "}
                    <FaTshirt size={30} />{" "}
                  </div>
                  <span>
                    clothes <br /> 200kg
                  </span>
                </div>
                <div className={classes.box2Content}>
                  <div className={classes.iconBox}>
                    <GiConverseShoe size={30} onClick={() => setWeight(300)} />
                  </div>
                  <span>
                    shoes <br /> 300kg
                  </span>
                </div>
                <div className={classes.box2Content}>
                  <div
                    className={classes.iconBox}
                    onClick={() => setWeight(250)}
                  >
                    <PhoneAndroidIcon sx={{ fontSize: "2rem" }} />
                  </div>
                  <span>
                    cell phone <br /> 250kg
                  </span>
                </div>
                <div className={classes.box2Content}>
                  <div
                    className={classes.iconBox}
                    onClick={() => setWeight(150)}
                  >
                    <BsFillHandbagFill size={30} />
                  </div>
                  <span>
                    bags <br /> 150kg
                  </span>
                </div>
              </div>
              <p className={classes.note}>
                *Click the icon to quickly select the weight*
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Calculator;
