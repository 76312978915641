import React, { useEffect, useState } from 'react';
import classes from './CartIcon.module.css';
import { AiOutlineShoppingCart } from "react-icons/ai";
import { Link } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const CartIcon = () => {
  const [total, setTotal] = useState(0);
  const axiosPrivate = useAxiosPrivate();

  const getTotalCart = async () => {
    try {
          const response = await axiosPrivate.post("v1/cart/get-total-cart");

          setTotal(response.data.total);
             
    }catch(err){  
    }
    
}

useEffect(() =>{
  getTotalCart();
},[total]);

  return (
    <div className={classes.cartBox}>
      <Link to='/cart' className={classes.cartLink}>
        <div className={classes.iconBox}>
          <AiOutlineShoppingCart className={classes.cartIcon} />
          <span className={classes.circle}>{total ? total : 0}</span>
        </div>
        <span className={classes.shop}>Cart</span>
      </Link>
    </div>
  );
}

export default CartIcon
