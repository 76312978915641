import React from "react";
import Header from "../functions/Header";
import Footer from "../functions/Footer";
import Layout from "./Layout";

function Members (){
    return(
        <>
            <Layout />
        </>
    )
}

export default Members;