import React ,{ createContext, useState, useMemo } from "react";

const GlobalVariableContext = createContext({});

export const GlobalVariableProvider = ({ children }) => {
    const [selectedCartItem, setSelectedCartItem] = useState({});
    const [defaultCurrency, setDefaultCurrency] = useState("CNY");
    const [searchString, setSearchString] = useState("");
    const [searchType, setSearchType] = useState("");
    const [page, setPage] = useState(1);
    const [translatedTitle, setTranslatedTitle] = useState("");
    
    const value = useMemo(() => ({ selectedCartItem, setSelectedCartItem,defaultCurrency, setDefaultCurrency,
        searchString, setSearchString,page, setPage,translatedTitle, setTranslatedTitle,searchType, setSearchType }),
     [selectedCartItem, setSelectedCartItem,defaultCurrency, setDefaultCurrency,searchString, setSearchString,
        page, setPage,translatedTitle, setTranslatedTitle,searchType, setSearchType]);

    return (
        <GlobalVariableContext.Provider value={value}>{children}</GlobalVariableContext.Provider>
    )
}

export default GlobalVariableContext;