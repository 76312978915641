import 'react-phone-number-input/style.css'
import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Form from "react-bootstrap/Form";
import { Link } from 'react-router-dom';
import axios from "../../../api/axios";
import { VisibilityOff, Visibility, DoneOutlined} from '@mui/icons-material';
import 'react-phone-input-2/lib/style.css';
import Termii from '../../../api/Termii';
import { setCookie } from '../../functions/CustomCookies';
import Encryption from '../../functions/Encryption';
import useAuth from "../../../hooks/useAuth";
import useCart from "../../../hooks/useCart";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Spinner from "../../utilities/Spinner";
import classes from './AllowSignup.module.css';
import LoginNavbar from '../../loginNavbar/LoginNavbar';
import bannerImg from "../../../assets/images/bannerImg.png";


const REGISTER_URL = "/v1/users/register";




const AllowEmailSignup = () => {
  const [modalShow, setModalShow] = useState(false);
  const userRef = useRef(null);
  const errRef = useRef(null);

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const [password, setPassword] = useState('');
  const [password_repeat, setPasswordRepeat] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [payment_pin, setPaymentPin] = useState("");


  
  const [errMsg, setErrMsg] = useState(''); 

 


  const [emailError, setEmailError] = useState('');
  const [codeError, setCodeError] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [paymentPinError, setPaymentPinError] = useState('');

  const [isCountDown, setIsCountDown] = useState(false);

  const [passwordVisible, setPasswordVisible] = useState(false);


  const [isChecking, setIsChecking] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [allChecked, setAllChecked] = useState(true);

  const [userValid, setUserValid] =  useState(false);
  const [passwordValid, setPasswordValid] =  useState(false);
  const [passwordRepeatValid, setPasswordRepeatValid] =  useState(false);

  const { setAuth, persist, setPersist } = useAuth();

  const {setCart} = useCart();
  const [refferalCode, setRefferalCode] = useState("");
  const [refferalCodeError, setRefferalCodeError] = useState("");

 



 



useEffect(() => {
    setErrMsg('');
}, [username, password, password_repeat])

const submitHandler = async (e) => {
  e.preventDefault();
  
  if(!payment_pin){
    setPaymentPinError("Payment Pin cannot be empty");
    return;
  }else if(payment_pin.length < 6 || payment_pin.length > 6 ){
    setPaymentPinError("password field must contain only 6 figure");
    return;
  }else{
    setPaymentPinError("")
  }

  let encryption = new Encryption();

  
  let _username = username;
  let _password = encryption.encrypt(password, encryption.key);
  let _password_repeat = encryption.encrypt(password_repeat, encryption.key);
  let _email = email;
  let _refferal_code = refferalCode;
  let _payment_pin = encryption.encrypt(payment_pin, encryption.key);

 

  try{
    setModalShow(true);
      let items = {_username, _password, _password_repeat, _email,_refferal_code,_payment_pin}
      const response = await axios.post(REGISTER_URL,
          JSON.stringify(items),
          
      );
      
      if(response?.data?.code === 2000){
          
          setUsername('');
          setPassword('');
          setEmail('');
          setPasswordRepeat('');

          const accessToken = response?.data?.accessToken;
          const _user = response?.data?.user;
          const cartTotal = response?.data?.cartTotal;

          setCookie('user', _user, 43800);
          setCookie('isLoggedIn', true, 43800);
          setCookie('cartTotal', cartTotal, 43800);
          setAuth({
            accessToken:accessToken,
          });
          setCart(cartTotal);
          //setCookie('email',email, 0.5);
          navigate("/", {state:{refresh:true}})
      }else if(response?.data?.code === 4001){
          setEmailError(response?.data?.message.email || '');
          setRepeatPasswordError(response?.data?.message.password_repeat || '');

      }else{
        setErrMsg('Registration fail, please try again');
        // errRef.current.focus();
      }

      setModalShow(false);
  }catch(error){
    if(error.response && error.response.data.code == 4001){
      setEmailError(error.response.data.message.email);
    }
    setModalShow(false);
 }
}

const toggleVisibility = () =>{
    let el = document.getElementsByClassName("passwordInput");
    if(!passwordVisible){
        el[0].type = "text";
        el[1].type = "text";
        setPasswordVisible(true);
    }else{
        el[0].type = "password";
        el[1].type = "password";
        setPasswordVisible(false);
    }
}




const handleUserChange = (e) =>{
  setEmail(e.target.value)
  setUsername(e.target.value)
}

const handleUserBlur = (e) =>{
  setEmail(e.target.value)
  setUsername(e.target.value)
  if(!e.target.value){
    setEmailError("Email cannot be empty")
  }else if(!e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
    setEmailError("Invalid email")
  }else{
    setEmailError("")
  }
}

const checkPassword =(e)=>{
  const {value} = e.target;
  if(!value){
    setPasswordError("password cannot be empty")
  }else if(value.length < 6  ){
    setPasswordError("password field must contain atleast 6 characters")
  }else if(value.length > 16){
    setPasswordError("password field can contain atmost 16 characters")
  }else{
    setPasswordError("")
  }
}

const checkConfirmPassword =(e)=>{
  const {value} = e.target;
  if(password === value){
    setRepeatPasswordError("")
  }else{
    setRepeatPasswordError("Repeat password don't match")
  }
}

const countDown = (selectorId, limit) =>{
  let counts=setInterval(()=>{
    let count= document.getElementById(selectorId);
      if(count){
        count.innerHTML=--limit;
      }
      if(limit === 0) {
          clearInterval(counts);
          setIsCountDown(false);
      }
  },1000);

}

const sendCode = ()=>{
      setIsCountDown(true);
      countDown("counter", 60);

      const termiiInstance = Termii();

      //Send OTP
    termiiInstance.send_otp_email(email).then(response => {
      if(response.error == 101){
        setEmailError(response.message);
        return;
      }
    });
}


const verifyCode = ()=>{
  const termiiInstance = Termii();
  setIsChecking(true);
  //verify OTP
  if(code){
      termiiInstance.verify_email_otp(code).then(response => {

         if(response.verified == true){
             setIsVerified(true);
             setIsChecking(false);
         }else{
              setCodeError("Incorrect / invalid code");
              setIsChecking(false);
        }

      });
  }else{
    setCodeError("Please get another code");
    setIsChecking(false);
  }

  
}

const checkPaymentPin =(e)=>{
  const {value} = e.target;
  if(!value){
    setPaymentPinError("Payment Pin cannot be empty");
  }else if(value.length < 6 || value.length > 6 ){
    setPaymentPinError("password field must contain only 6 figure")
  }else{
    setPaymentPinError("")
  }
}


useEffect(() =>{
  if(!emailError && !passwordError && !repeatPasswordError){
    setAllChecked(true);
  }else{
    setAllChecked(false)
  }
},[emailError ,passwordError,repeatPasswordError])


useEffect(() =>{
  if(code.length === 6){
     
     verifyCode();
  }
},[code]);

useEffect(() =>{
  if(emailError && passwordValid && passwordRepeatValid ) {
    setAllChecked(true);
  }else{
    setAllChecked(false)
  }
},[emailError , passwordValid , passwordRepeatValid])

const handleSetCode =(e) =>{
  setCodeError("");
  setCode(e.target.value)
}

const handleRefferalCodeChange = (e) =>{
  setRefferalCode(e.target.value);
}

const handleRefferalCodeBlur = (e) =>{
  checkCodeValidity(e.target.value);
}

const checkCodeValidity = (code) =>{
  let isMounted = true;
  const controller = new AbortController();

  setModalShow(true);
  
  const doCheckCode = async (code) => {
      try {
            const response = await axios.post("v1/users/check-code-validity",
            JSON.stringify({code}),
            {
              signal: controller.signal
            });

            if(response?.data?.message == 2001){
              setRefferalCodeError("");

            }else{
              isMounted && setRefferalCodeError("Incorreect code please check the code again or continue without code");
            }
               
      }catch(err){  
      }
      setModalShow(false);
  }

  doCheckCode(code);
  return () => {
    isMounted = false;
    controller.abort();
  }
}

  return (
    <>
      <Spinner cssClass={"semi-dark-bg"} show={modalShow} />
      <LoginNavbar title="sign up" />
      <div className={classes.wrapper}>
        <div className={classes.logImgBox}>
          <Link to="/" title="Go Home">
            <img src={bannerImg} alt="bannerImg" />
          </Link>
        </div>
        <div className={classes.formBox}>
          <h4 className={classes.loginTitle}>Register</h4>
          <Form onSubmit={submitHandler} className={classes.formContainer}>
            <p
              ref={errRef}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <div>
              <Form.Group
                size="lg"
                controlId="email"
                className={classes.emailBox}
              >
                <Form.Control
                  type="email"
                  value={email}
                  ref={userRef}
                  required
                  onChange={handleUserChange}
                  onBlur={handleUserBlur}
                  placeholder="Enter Email"
                />
                <span style={{ top: "-1rem" }} className="regErr">
                  {emailError}
                </span>
              </Form.Group>
            </div>
            {/* <div className='phone_number' >
                  <Form.Group size="lg" controlId="phone_number">
                    <PhoneInput
                        country={'ng'}
                        value={phone_number}
                        onBlur={handleOnChange}
                        onlyCountries={['ng']}
                        preferredCountries={['ng']}
                        countryCodeEditable={false}
                        ref={phoneRef}
                    />
                    <span className='regErr'>{phoneError}</span>
                    <span className='regErr'>{phoneFormatError}</span>
                  </Form.Group>
              </div> */}
            <div className={classes.getCode}>
              <Form.Group
                size="sm"
                controlId="code"
                className={classes.getCodeInput}
              >
                <Form.Control
                  type="text"
                  value={code}
                  required
                  maxLength={6}
                  onChange={handleSetCode}
                  placeholder="Enter code"
                />
                <span className="regErr">{codeError}</span>
              </Form.Group>
              {isVerified && (
                <div className="">
                  <DoneOutlined sx={{ color: "green" }} />
                </div>
              )}
              {isChecking && (
                <div className="">
                  <img src="/images/loader/reload.gif" />
                </div>
              )}
              {!isCountDown && !isVerified && (
                <div
                  id="getCode"
                  onClick={sendCode}
                  className={classes.codebtn}
                >
                  Get code
                </div>
              )}
              {isCountDown && (
                <div id="counter" style={{ color: "crimson" }}></div>
              )}
            </div>

            {isVerified && (
              <React.Fragment>
                <div className={classes.passwordBox}>
                  <Form.Group
                    size="lg"
                    controlId="password"
                    className={classes.password}
                  >
                    <span
                      className={classes.visible}
                      onClick={toggleVisibility}
                    >
                      {passwordVisible ? <VisibilityOff /> : <Visibility />}
                    </span>
                    <Form.Control
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      onBlur={checkPassword}
                      placeholder="Enter Password"
                      required
                      className="passwordInput"
                    />
                    <span className="regErr">{passwordError}</span>
                  </Form.Group>
                </div>
                <div className={classes.passwordBox}>
                  <Form.Group
                    size="lg"
                    controlId="password_repeat"
                    className={classes.password}
                  >
                    <Form.Control
                      type="password"
                      onChange={(e) => setPasswordRepeat(e.target.value)}
                      value={password_repeat}
                      placeholder="Enter Password again"
                      required
                      onBlur={checkConfirmPassword}
                      className="passwordInput"
                    />
                    <span className="regErr">{repeatPasswordError}</span>
                  </Form.Group>
                </div>
                <div className={classes.paymentPinBox}>
                  <Form.Group
                    size="lg"
                    controlId="payment_pin"
                    className={classes.paymentPin}
                  >
                    <Form.Label>
                      Set six digit payment pin: E.g 123456
                      <b className="text-danger">*</b>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      onChange={(e) => setPaymentPin(e.target.value)}
                      value={payment_pin}
                      placeholder="Set Payment Pin"
                      required
                      onBlur={checkPaymentPin}
                      className="passwordInput"
                    />
                    <span className="regErr">{paymentPinError}</span>
                  </Form.Group>
                </div>
              </React.Fragment>
            )}
            <Form.Group>
              <Form.Label>
                Enter Refferal Code
              </Form.Label>
              <Form.Control
                type="text"
                value={refferalCode}
                onChange={handleRefferalCodeChange}
                onBlur={handleRefferalCodeBlur}
                placeholder="Enter referral code"
              />
              <span className="regErr">{refferalCodeError}</span>
            </Form.Group>
            <div className={classes.signupBtnWrapper}>
              <button
              //  disabled={!allChecked}
              //  style={{backgroundColor:(allChecked) ? "crimson" : '#dddddd'}}
              >
                Sign Up
              </button>
            </div>
            <div className={classes.signuplink}>
              <span>Already a Member?</span>
              <Link to="/login" className={classes.signupPageLink}>
                Login
              </Link>
            </div>
            <div className={classes.privacyBox}>
              <span>By using this website you agree to our:</span>
              <Link to="/terms-and-condition" className={classes.privacyLink}>
                Terms and Conditions
              </Link>
              <Link to="/privacy-policy" className={classes.privacyLink}>
                Privacy Policy
              </Link>
              <Link to="/cookie-policy" className={classes.privacyLink}>
                Cookie Policy
              </Link>
            </div>
          </Form>
        </div>
      </div>
    </>
  );

}

export default AllowEmailSignup;