export const braSizeData = [
  {
    lower: "68-72",
    upper: 80,
    international: "32/70A",
  },
  {
    lower: "68-72",
    upper: 83,
    international: "32/70B",
  },
  {
    lower: "68-72",
    upper: 85,
    international: "32/70C",
  },
  {
    lower: "68-72",
    upper: 88,
    international: "32/70D",
  },
  {
    lower: "73-77",
    upper: 85,
    international: "34/75A",
  },
  {
    lower: "73-77",
    upper: 88,
    international: "34/75B",
  },

  {
    lower: "73-77",
    upper: 90,
    international: "34/75C",
  },
  {
    lower: "73-77",
    upper: 95,
    international: "34/75D",
  },
  {
    lower: "73-77",
    upper: 98,
    international: "34/75E",
  },
  {
    lower: "78-82",
    upper: 90,
    international: "36/80A",
  },
  {
    lower: "78-82",
    upper: 93,
    international: "36/80B",
  },
  {
    lower: "78-82",
    upper: 95,
    international: "36/80C",
  },
  {
    lower: "78-82",
    upper: 98,
    international: "36/80D",
  },
  {
    lower: "78-82",
    upper: 103,
    international: "36/80AE",
  },
  {
    lower: "83-87",
    upper: "95-97",
    international: "38/85A",
  },
  {
    lower: "83-87",
    upper: "99-101",
    international: "38/85B",
  },
  {
    lower: "83-87",
    upper: "101-103",
    international: "38/85C",
  },
  {
    lower: "83-87",
    upper: "103-105",
    international: "38/85D",
  },
  {
    lower: "88-92",
    upper: 103,
    international: "40/90B",
  },
  {
    lower: "88-92",
    upper: 105,
    international: "40/90C",
  },
  {
    lower: "88-92",
    upper: 108,
    international: "40/90D",
  },
  {
    lower: "113",
    upper: 103,
    international: "40/90E",
  },
];


export const womensUnderwearSizeData = [
  {
    size: "waistline / cm",
    s: "55-61",
    m: "61-67",
    l: "67-73",
    xl: "73-79",
  },
  {
    size: "waistline / City ruler",
    s: "1.8-1.9",
    m: "1.9-2.1",
    l: "2.1-2.2",
    xl: "2.2-2.3",
  },
  {
    size: "Hip circumference / cm",
    s: "80-86",
    m: "85-93",
    l: "90-98",
    xl: "95-103",
  },
];

export const mensUnderwearSizeData = [
  {
    size: "waistline / cm",
    m: "62-70",
    l: "66-74",
    xl: "72-82",
    xxl: "76-84",
  },
  {
    size: "waistline / City ruler",
    m: "2.0-2.2",
    l: "2.2-2.4",
    xl: "2.4-2.5",
    xxl: ">2.6",
  },
  {
    size: "Hip circumference / cm",
    m: "82-90",
    l: "86-94",
    xl: "88-96",
    xxl: "94-102",
  },
  {
    size: "height / cm",
    m: "160-170",
    l: "165-175",
    xl: "170-180",
    xxl: "175-185",
  },
];


