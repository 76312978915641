import axios from "../api/axios";
import useAuth from "./useAuth";
import removeLocalStorage from "../components/functions/removeLocalStorage"
import {deleteCookie} from "../components/functions/CustomCookies"
import useCart from "./useCart";
import { useNavigate } from "react-router-dom";

const LOGOUT_URL = "/v1/auth/refresh-token";
const useLogout = () => {
    const { setAuth } = useAuth();
    const {setCart} = useCart();
    const navigate = useNavigate();

    const logout = async () => {
        
        try {
            const response = await axios.delete(LOGOUT_URL, {
                withCredentials: true
            });
            //delete auth if response return ok
            //if(response?.data?.status === 'ok'){
                setAuth({});
                setCart(0);
                deleteCookie('user');
                deleteCookie('isLoggedIn');
                deleteCookie('cartTotal');

                removeLocalStorage('user');
                removeLocalStorage('cartTotal');
                removeLocalStorage("auth");
            //}
            //send your back to home page after logout
            navigate('/', {replace: true });
        } catch (err) {
            console.error(err);
        }
    }

    return logout;
}

export default useLogout