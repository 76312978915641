import React, { useState } from "react";
import classes from "./Currency.module.css";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { BiDollar } from "react-icons/bi";
import { TbCurrencyNaira } from "react-icons/tb";
import { BsCurrencyYen } from "react-icons/bs";
import useGetCurrencyRate from "../../hooks/useGetCurrencyRate";
import useCurrencyRate from '../../hooks/useCurrencyRate';
import Spinner from "../utilities/Spinner";

const Currency = () => {
  const [open, setOpen] = useState(false);
  const getCurrencyRate = useGetCurrencyRate();
  const [modalShow, setModalShow] = useState(false);
  const {currency} = useCurrencyRate();

  const getCurrency = async (curr) => {
    setModalShow(true);
    await getCurrencyRate(curr);
    setModalShow(false);
  }

  const handleClick = (currency) =>{
    getCurrency(currency);
    setOpen(!open);
 }

  return (
    <React.Fragment>
        <Spinner cssClass={"semi-dark-bg"} show={modalShow} />
        <div className={classes.wrapper}>
        <div className={classes.accountBox} onClick={() => setOpen(!open)}>
          <span>currency {currency}</span>
          {open ? <FaAngleUp size={20} /> : <FaAngleDown size={20} />}
        </div>
        <div
          className={`${classes.options} ${open && classes.openBox}`}
          onClose={() => setOpen(false)}
        >
          <span className={classes.option} onClick={() => handleClick("NGN")}>
            <TbCurrencyNaira size={17} /> NGN
          </span>
          <span className={classes.option} onClick={() => handleClick("CNY")}>
            <BsCurrencyYen size={17} /> CNY
          </span>
          {/* <span className={classes.option} onClick={() => handleClick("USD")}>
            <BiDollar size={17} /> USD
          </span> */}
        </div>
        </div>
    </React.Fragment>
   
  );
};

export default Currency;
