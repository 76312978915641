import React, {useState, useEffect, useContext} from 'react';
import {useSearchParams, useLocation,useNavigate} from 'react-router-dom';
import '../../assets/css/item.css';
import images1 from "../../assets/data/images1";
import Description from '../functions/Description';
import { RoundToTwo } from "../utilities/RoundToTwo";
import Spinner from "../utilities/Spinner";
import useCart from '../../hooks/useCart';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {setCookie} from '../functions/CustomCookies';
import { Seo } from '../utilities/Seo';
import useAuth from '../../hooks/useAuth';
import useRefreshToken from '../../hooks/useRefreshToken';
import CreateOrderContext from "../../context/CreateOrderProvider";
import useCurrencyRate from "../../hooks/useCurrencyRate";
import Navbar from '../navbar/Navbar';
import {Exchanger } from '../functions/Helpers';
import axios from "../../api/axios";
import Translate from "../../api/Translate";
import { FaAngleDown } from 'react-icons/fa';
import setLocalStorage from '../functions/setLocalStorage';
import {encryptData} from '../functions/DataSecurity';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import ReactPlayer from 'react-player';
import PlayIcon from '../functions/playIcon';




function Item() {

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


  const [isLeftActive, setIsLeftActive] = useState(0);
  const [property, setProperty] = useState('0:0');
  const [ispropActive, setIspropActive] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [qtyBadge, setQtyBadge] = useState({});
  const [badgeCount, setBadgeCount] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceWithDiscount, setTotalPriceWithDiscount] =useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [searchParams] = useSearchParams();
  const {setCart} = useCart();
  const [disableButon, setDisableButon] = useState(true)
  const [Data, setItemData] = useState(null)
  const {currency , rate, symbol} = useCurrencyRate();
  const defaultCurrency = "CNY";
  const [successMessage,setSuccessMessage] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [translatedTitle, setTranslatedTitle] = useState("");
  const [isTranslated, setIsTranslated] = useState(false);
  const [reloadLimit, setReloadLimit] = useState(1);

  const location = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const refresh = useRefreshToken();

  const numId = searchParams.get('item');
  const site = searchParams.get('site');

  const {setOrder} = useContext(CreateOrderContext);
  const [openSpec, setOpenSpec] = useState(false);
  const[isImage, setIsImage]= useState(false);
  const[isVideo, setIsVideo]= useState(false);



  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessMessage(false);
  };
 

//get new token for the request
  useEffect(()  =>{
    getRefreshToken();
  },[]);

  const getRefreshToken = async () => {
    await refresh();
  }



  const getItem = async () => {
    setModalShow(true);
    try{
        
        let params = {site, numId}
        const response = await axios.post('/v1/api/api-get-one-item',
          JSON.stringify({params}),
        );
        if(response.data.message.error_code == "5000" ){
            
          while(reloadLimit < 5){
            getItem();
           }
           setReloadLimit(reloadLimit + 1);
         
       }else if (response.data.message.error_code == "2000") {
        navigate('/item/notfound')
       } else {
         setItemData(response.data.message);
         setReloadLimit(1);
       }
         
    }catch(error){
      if(error.response){
       //console.log(error.response.data)
      }
   }
   setModalShow(false);
  }


    useEffect(() => {    
      getItem();
    }, [location.state,reloadLimit]);

 //set page title
 useEffect(() => {
  const pageTitle = Data?.item ? ((translatedTitle && translatedTitle != undefined) ? translatedTitle : Data.item.title)  : "Checkstall || Item page";
  Seo({
    title: pageTitle,
    metaDescription: 'Item'
  });
}, [Data,translatedTitle]);

useEffect(() =>{
if(Data !=null && !isTranslated){
  let string = Data.item.title;
  let fromLang = 'zh-CN';
  let toLang = 'en';
  Translate(string, fromLang,toLang).then(response => {
    setTranslatedTitle(response);
    setIsTranslated(true);
  });
}
},[Data]);


    //get the property module e.g color, sizes, memory e.t.c
    const getModule = (props) => {
      return Data.item.props_list[props] ? Data.item.props_list[props].split(':')[0]  : "";
    }

    const getPropsList = () => {
      return Data ? Data.item.props_list : {};
    }

    const getPropsImg = () => {
      return Data ? Data.item.prop_imgs.prop_img : [];
    }

    //if no price range get the biggest price from sku as price rage
    const getMaxPrice = () => {
      let max = parseFloat(0);
      Data.item.skus.sku.map((value) =>{
        if(value.price > max){
          max = parseFloat(value.price)
       }
      });
       //if max is bigger that item price show it in range else return null
      if(max > Data.item.price){
        return max;
      }else{
        return null
      }

      
    }

 

  const handleQuantityUpdate = (event, count) => {
    let elem = count === -1 ? event.target.nextSibling : event.target.previousSibling;
    let sku_id = elem.name;
    let Qty = parseInt(elem.value);
    let price = parseFloat(elem.getAttribute('data-price'));
    let quantity = elem.getAttribute('data-quantity');
    let props = elem.getAttribute('data-property');
    let props_name = elem.getAttribute('data-property-name');
    let url = elem.getAttribute('data-sku-url');

   
    //check if there are enough quantity to order
    if(Qty > quantity ){
      event.target.parentNode.querySelector('.qty-exceed').setAttribute('style', 'display:block !important; color:#E2231A; font-size:12px');
      Qty = quantity;
    }else{
      event.target.parentNode.querySelector('.qty-exceed').setAttribute('style', 'display:none !important; color:#E2231A; font-size:12px');
    }

    const skuInfo = {
        price:price,
        qty:(Qty + count),
        total_price:RoundToTwo(price * (Qty + count)),
        sku_id:sku_id,
        properties:props,
        properties_name:props_name,
        url:url
    }

     //enable and disable minus button
     
     if(skuInfo.qty > 0){
      let element =  event.target.parentNode.querySelector('.minus-btn');
      element.setAttribute('style', 'cursor:pointer;');
      element.removeAttribute('disabled');
      setDisableButon(false);
    }else{
      let element =  event.target.parentNode.querySelector('.minus-btn');
      element.setAttribute('style', 'cursor:not-allowed;');
      element.setAttribute('disabled', '');
      setDisableButon(true);
    }

    if(parseInt(Qty) === 0 && count === -1){
      setSelectedItem(prevSelectedItem =>{
        return{
          ...prevSelectedItem,
          [sku_id]: {}
        }
      }); 
    }else{
      setSelectedItem(prevSelectedItem =>{
        return{
          ...prevSelectedItem,
          [sku_id]: skuInfo
        }
      }); 

      if(count === -1){
        setQtyBadge(prevQtyBadge => {
            let prevValue = prevQtyBadge[skuInfo.properties] ? prevQtyBadge[skuInfo.properties] : 0;
            return{
              ...prevQtyBadge,
              [skuInfo.properties]:(parseInt(prevValue) -  1),
            }
        });

      }else{
        setQtyBadge(prevQtyBadge => {
          let prevValue = prevQtyBadge[skuInfo.properties] ? prevQtyBadge[skuInfo.properties] : 0;
          return{
            ...prevQtyBadge,
            [skuInfo.properties]:(parseInt(prevValue) +  1),
          }
        });
      }
    }
  }

 
  const handleQuantityChange = event => {
    let sku_id = event.target.name;
    let Qty = parseInt(event.target.value);
    let price = parseFloat(event.target.getAttribute('data-price'));
    let quantity = event.target.getAttribute('data-quantity');
    let props = event.target.getAttribute('data-property');;
    let props_name = event.target.getAttribute('data-property-name');
    let url = event.target.getAttribute('data-sku-url');

    if(isNaN(Qty) || Qty === "" || Qty <= 0){
      Qty = 0;
    }

    //check if there are enough quabtity to order
    if(Qty > quantity){
      event.target.parentNode.querySelector('.qty-exceed').setAttribute('style', 'display:block !important; color:#E2231A; font-size:12px');
      Qty = quantity;
    }else{
      event.target.parentNode.querySelector('.qty-exceed').setAttribute('style', 'display:none !important; color:#E2231A; font-size:12px');
    }

    const skuInfo = {
        price:price,
        total_price:RoundToTwo(price * Qty),
        qty:Qty,
        sku_id:sku_id,
        properties:props,
        properties_name:props_name,
        url:url
    }
  
    setSelectedItem(prevSelectedItem =>{
      return{
        ...prevSelectedItem,
        [sku_id]: skuInfo
      }
    }); 

    setQtyBadge(prevQtyBadge => {    
      return{
        ...prevQtyBadge,
        [props]:Qty,
      }
    });
  }



useEffect(() => {  
  getTotalQuantity();
  getTotalPrice();
  getBadgeCount();
  
  getTotalPriceWithDiscount()
}, [selectedItem,qtyBadge, totalQuantity]) 

const getTotalPrice = () =>{
    let total_price = 0;
    Object.entries(selectedItem).map((val) => {
      if(!isNaN(val[1].total_price)){
        total_price += parseFloat(val[1].total_price);
      }
    });
    setTotalPrice(total_price);
}

const getTotalPriceWithDiscount = () =>{
  let price = 0;
  let priceRange = Data?.item?.priceRange;
    if(priceRange){
      priceRange.map((range, index) =>{

         if(priceRange?.length == 2){ // for items with two price range
            if(index == 1){
              if((totalQuantity >=  priceRange[1][0])){
                price = range[1];
              }else{
                price = priceRange[0][1];
              }
            }
         }else if(priceRange.length > 2){ // for items with more than two price range
            if(index === 1){
              if((totalQuantity >= priceRange[1][0]) && (totalQuantity < priceRange[2][0])){
                price = range[1];
              }
            }else if(index === 2){
              if((totalQuantity >= range[0])){
                price = range[1];
              }
            }
   
         }
         

          
      });
    }

    setDiscountPrice(price);
    setTotalPriceWithDiscount((totalQuantity * price));
}

const getTotalQuantity = () =>{
  let t_q = 0;
  Object.entries(qtyBadge).map((val) => {
    t_q = t_q+val[1];
  });
  setTotalQuantity(t_q);
}

const getBadgeCount = () =>{
  let total=0;
    Object.entries(qtyBadge).map((val, index) =>{
      if(val[0].split(';')[0] == property){
        total = total + val[1]
      }
    });

    setBadgeCount(preValue =>{
      return{
        ...preValue,
        [property]:total
      }
    })
}
  const handleClick = (index) => event =>  {

    const name  = event.target.closest('.imglist').getAttribute("name");
    
    if(name == "video"){
      setIsVideo(true);
      setIsImage(false);
    }else{
      setIsVideo(false);
      setIsImage(true);
    }
    let url = event.target.getAttribute("src");
    let elm = document.getElementById('large-image').firstChild;
    if(elm != null){
      elm.setAttribute("src", url);
      setIsLeftActive(index);
    }
    
  };

  const handlePropsClick = (index) => event =>  {
    let parent = event.target.nodeName == 'DIV' ? event.target.parentNode : event.target.parentNode.parentNode;
    let img = parent.querySelector('img');
    if(img){
      let url = img.getAttribute("src");
      let elm = document.getElementById('large-image').firstChild;
      if(elm != null){
        elm.setAttribute("src", url);
      }
    }
    setIspropActive(index);
    setProperty(parent.getAttribute('data-id'));
  };

  const handleChangeImage = (event) =>  {
    let img = event.target;
    if(img){
      let url = img.getAttribute("src");
      let elm = document.getElementById('large-image').firstChild;
      if(elm != null){
        elm.setAttribute("src", url);
      }
    }
  };


  const handleAddToCart = () =>{
    //update the value of cart conext with selectedItem state
    //let totalItemSelected = Object.keys(selectedItem).length;
    //setCart(cart + totalItemSelected);
    //clear off selected item after cart has being set
    let cartItem = getCartData(Data.item);
    addToCart(cartItem);
    
  }

  const handleBuyNow = () =>{

    setModalShow(true)
    let cartItem = getCartData(Data.item);
    setOrder(cartItem);
    setLocalStorage('orderItem', encryptData(cartItem));

    const timer = setTimeout(() => {
      setModalShow(false);
      navigate(`/order/create_order/?s=detail`);
    }, 500);
    return () => clearTimeout(timer);
 
  }


  const getcleanString = (string, delimeter, index) => {
    //string: the string to be splitted and clean
    //the delimeter to split the string
    //the index(position) of the new value we should return
    return string.split(delimeter)[index];
  }

  
  const getCartData = (data) => {
      //let data = Data.item;
      let cart_item = {
        num_iid:data.num_iid,
        title:((translatedTitle && translatedTitle != undefined) ? translatedTitle : data?.title),
        price:data.price,
        orginal_price:data.orginal_price,
        nick:data.nick,
        min_num:data.min_num,
        detail_url:data.detail_url,
        pic_url:data.pic_url,
        post_fee:data.post_fee,
        seller_id:data.seller_id,
        priceRange:data.priceRange,
        seller_info:data.seller_info,
        discount_price:discountPrice,
        sku:selectedItem,
      };
      return cart_item;
  }

  const controller = new AbortController();
  const addToCart = async (cartItem) => {
    setModalShow(true);
    try {
          const response = await axiosPrivate.post("v1/cart/add",
            JSON.stringify({ cartItem }), {
            signal: controller.signal
          });
      
         // console.log(response?.data);
      const cartTotal = response?.data?.cartTotal;
      setCookie('cartTotal', cartTotal, 24);
      setCart(cartTotal);
      setSuccessMessage(true);
      setSelectedItem({});
      setQtyBadge({});
      //console.log('Item Added Successfully');
      setModalShow(false);
  }catch(err){
  
      if (err.response) {
        if (err.response.status === 400) {
          setErrMsg(err.response.data);
          //console.log(err.response.data)
        } else if (err.response.status === 401) {
            setErrMsg(err.response.data);
            //console.log(err.response.data)
        }else{
          setErrMsg('Fail to add item.');
          //console.log(err.response.data)
        }
          
      } else{
          setErrMsg('No Server Response');
          //console.log(err);
        }
        setModalShow(false);
    }
    
    
  }

  useEffect(() =>{
     if(Data?.item?.video){
        setIsVideo(true);
        setIsImage(false);
     }else{
        setIsImage(true);
        setIsVideo(false);
     }
  },[Data])
  
  return (
    <>
      <Navbar />
      {/* <SearchBar /> */}
      <Spinner cssClass={"semi-dark-bg"} show={modalShow} />

      <Snackbar open={successMessage} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
              Added successfully!
        </Alert>
      </Snackbar>
      {Data != null && (
        <div className="item-wrapper">
          <div className="item-container d-flex flex-column">
            <div className="item-box d-flex justify-content-between">
              <div className="item-image">
                {isImage &&
                   <div className="large-image" id="large-image">
                    <img
                        src={Data.item.pic_url ? Data.item.pic_url : ""}
                        alt=""
                      />
                  </div>
                }
                {isVideo && 
                    <div className="large-image" id="large-image">
                      <ReactPlayer
                        url={Data.item.video}
                        className='react-player'
                        playing
                        controls
                        width='100%'
                        height='100%'
                        light={Data.item.pic_url}
                        playIcon={<PlayIcon />}
                      />
                  </div>
                }
                <ul id="scroller">
                {Data.item.video &&
                   <li 
                      className={`imglist ${
                        isLeftActive === 0 ? "active-items" : ""
                      }`}
                      onClick={handleClick(0)}
                      name="video"
                  >
                      <ReactPlayer
                        url={Data.item.video}
                        className='react-player'
                        playing={false}
                        width='100%'
                        height='100%'
                        light={Data.item.pic_url}
                        playIcon={<PlayIcon size="sm" />}
                      />
                  </li>
                }
                  
                  
                  {Data.item.item_imgs.length > 0
                    ? Data.item.item_imgs.map((ele, index) => {
                        return (
                          <li
                            key={index+1}
                            className={`imglist ${
                              isLeftActive === index+1 ? "active-items" : ""
                            }`}
                            onClick={handleClick(index+1)}
                            name="image"
                          >
                            
                            <img src={ele.url} alt="" />
                          </li>
                        );
                      })
                    : ""}
                </ul>
              </div>
              <div className="item-spec-buyNow-wrapper">
                <div className={`item-props ${openSpec && "item-props-open"}`}>
                  <h4 className="item-title">
                    {translatedTitle && translatedTitle != undefined
                      ? translatedTitle
                      : Data.item.title}
                  </h4>
                  <div className="price-rage-wrap d-flex justify-content-between">
                    <div className="price-rage-inner-left">
                      <div>Price</div>
                      <div>Batch</div>
                    </div>
                    <div className="price-rage-inner-right d-flex justify-content-start">
                      {Data.item.priceRange ? (
                        Data.item.priceRange.map((range, index) => {
                          let arr = Data.item.priceRange;
                          if (index === 0) {
                            return (
                              <div className="price-wrap" key={index}>
                                <div>
                                  {symbol}
                                  {RoundToTwo(
                                    Exchanger(
                                      defaultCurrency,
                                      currency,
                                      range[1],
                                      rate
                                    )
                                  )}
                                </div>
                                <div>{range[0] + " pieces min order"}</div>
                              </div>
                            );
                          } else if (index === 1) {
                            //if we have just only two price in array
                            if (Data.item.priceRange.length == 2) {
                              return (
                                <div className="price-wrap" key={index}>
                                  <div>
                                    {symbol}
                                    {RoundToTwo(
                                      Exchanger(
                                        defaultCurrency,
                                        currency,
                                        range[1],
                                        rate
                                      )
                                    )}
                                  </div>
                                  <div>{"≥" + range[0] + " " + "pieces"}</div>
                                </div>
                              );
                            } else {
                              return (
                                <div className="price-wrap" key={index}>
                                  <div>
                                    {symbol}
                                    {RoundToTwo(
                                      Exchanger(
                                        defaultCurrency,
                                        currency,
                                        range[1],
                                        rate
                                      )
                                    )}
                                  </div>
                                  <div>
                                    {range[0] +
                                      "~" +
                                      (arr[2][0] - 1) +
                                      " " +
                                      "pieces"}
                                  </div>
                                </div>
                              );
                            }
                          } else {
                            return (
                              <div className="price-wrap" key={index}>
                                <div>
                                  {symbol}
                                  {RoundToTwo(
                                    Exchanger(
                                      defaultCurrency,
                                      currency,
                                      range[1],
                                      rate
                                    )
                                  )}
                                </div>
                                <div>{"≥" + range[0] + " " + "pieces"}</div>
                              </div>
                            );
                          }
                        })
                      ) : (
                        //if we have only 1 price make the div take full width
                        <div
                          className="price-wrap"
                          style={{ flexBasis: "60%" }}
                        >
                          <div>
                            {symbol +
                              RoundToTwo(
                                Exchanger(
                                  defaultCurrency,
                                  currency,
                                  Data.item.price,
                                  rate
                                )
                              )}
                            {getMaxPrice()
                              ? " ~ " +
                                symbol +
                                RoundToTwo(
                                  Exchanger(
                                    defaultCurrency,
                                    currency,
                                    getMaxPrice(),
                                    rate
                                  )
                                )
                              : ""}
                          </div>
                          <div>{Data.item.min_num + " pieces min order"}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="spec-wrap d-flex flex-column">
                    {/*if props module 2 is set then we display two props displaying props module 1 first then props module 2
                        otherwise we display only 1 props module */}
                    {getModule("1:0") ? (
                      <>
                        <div className="props-wrap d-flex justify-content-start">
                          <div className="props-name">{getModule("0:0")}</div>
                          <div className="props-value-wrap d-flex justify-content-start">
                            {Object.entries(getPropsList()).map(
                              (val, index) => {
                                let key = val[0].split(":")[0];
                                if (parseInt(key) === 0) {
                                  return (
                                    <div
                                      key={index}
                                      style={{}}
                                      className={`value-wrap d-flex justify-content-between ${
                                        ispropActive === index
                                          ? "active-items"
                                          : ""
                                      }`}
                                      onClick={handlePropsClick(index)}
                                      title={getcleanString(val[1], ":", 1)}
                                      data-id={val[0]}
                                    >
                                      {/*only show imags for props when item have props img*/}
                                      {getPropsImg().length > 0 ? (
                                        <div className="value-img">
                                          {getPropsImg().map((value, key) => {
                                            if (value.properties == val[0]) {
                                              return (
                                                <img
                                                  key={key}
                                                  src={value.url}
                                                  alt={getcleanString(
                                                    val[1],
                                                    ":",
                                                    1
                                                  )}
                                                />
                                              );
                                            }
                                          })}
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      <div className="value-text">
                                        {getcleanString(val[1], ":", 1)}
                                      </div>
                                      {badgeCount[val[0]] > 0 && (
                                        <span className="qty-badge">
                                          {badgeCount[val[0]]}
                                        </span>
                                      )}
                                    </div>
                                  );
                                }
                              }
                            )}
                          </div>
                        </div>
                        <div className="props-2-wrap d-flex justify-content-start">
                          {/*show sku information*/}
                          <div className="props-name">{getModule("1:0")}</div>
                          <div className="props-value-wrap d-flex flex-column">
                            {Data.item.skus.sku.map((val, index) => {
                              let props_1 = val.properties.split(";")[0];
                              if (props_1 == property) {
                                return (
                                  <div
                                    className="props-2-container d-flex justify-content-between"
                                    key={index}
                                  >
                                    <div className="props-2-value">
                                      <div
                                        style={{ minWidth: "7em" }}
                                        className="prop-name"
                                      >
                                        {
                                          val.properties_name
                                            .split(";")[1]
                                            .split(":")[3]
                                        }
                                      </div>
                                    </div>
                                    <div className="props-price">
                                      {symbol}{" "}
                                      {RoundToTwo(
                                        Exchanger(
                                          defaultCurrency,
                                          currency,
                                          val.price,
                                          rate
                                        )
                                      )}{" "}
                                    </div>
                                    <div className="props-quantity-available">
                                      {val.quantity} pieces
                                    </div>
                                    {val.quantity > 0 ? (
                                      <div className="props-qty">
                                        <button
                                          type="button"
                                          className="minus-btn qty-btn"
                                          style={{ cursor: "not-allowed" }}
                                          disabled={disableButon}
                                          onClick={(event) =>
                                            handleQuantityUpdate(event, -1)
                                          }
                                        >
                                          -
                                        </button>
                                        <input
                                          type="text"
                                          className="qty-input"
                                          name={val.sku_id}
                                          value={
                                            selectedItem[val.sku_id]
                                              ? selectedItem[val.sku_id].qty
                                              : 0
                                          }
                                          onChange={handleQuantityChange}
                                          data-property={val.properties}
                                          data-property-name={
                                            val.properties_name
                                          }
                                          data-price={val.price}
                                          data-quantity={val.quantity}
                                          data-sku-url={
                                            Data.item.props_img[props_1]
                                          }
                                        />
                                        <button
                                          type="button"
                                          className=" qty-btn"
                                          onClick={(event) =>
                                            handleQuantityUpdate(event, 1)
                                          }
                                        >
                                          +
                                        </button>
                                        <div className="d-none qty-exceed">
                                          Max. order is {val.quantity}
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="props-qty">
                                        Out of Stock
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="props-2-wrap d-flex justify-content-start">
                        {/*only one module is present*/}
                        <div className="props-name">{getModule("0:0")}</div>
                        <div className="props-value-wrap d-flex flex-column">
                          {Data.item.skus.sku.map((val, index) => {
                            return (
                              <div
                                className="props-2-container d-flex justify-content-between"
                                key={index}
                              >
                                <div className="props-2-value">
                                  {/*if props images are set show it*/}
                                  {getPropsImg().length > 0 ? (
                                    <span className="props-2-img">
                                      {getPropsImg().map((value, key) => {
                                        if (
                                          value.properties === val.properties
                                        ) {
                                          return (
                                            <img
                                              src={value.url}
                                              alt={
                                                val.properties_name.split(
                                                  ":"
                                                )[3]
                                              }
                                              onClick={handleChangeImage}
                                              key={key}
                                            />
                                          );
                                        }
                                      })}
                                    </span>
                                  ) : (
                                    ""
                                  )}

                                  <span className="prop-name">
                                    {val.properties_name.split(":")[3]}
                                  </span>
                                </div>
                                <div className="props-price">
                                  {" "}
                                  {symbol}{" "}
                                  {RoundToTwo(
                                    Exchanger(
                                      defaultCurrency,
                                      currency,
                                      val.price,
                                      rate
                                    )
                                  )}{" "}
                                </div>
                                <div className="props-quantity-available">
                                  {val.quantity} pieces
                                </div>
                                {val.quantity > 0 ? (
                                  <div className="props-qty">
                                    <button
                                      type="button"
                                      className="minus-btn qty-btn"
                                      style={{ cursor: "not-allowed" }}
                                      disabled={disableButon}
                                      onClick={(event) =>
                                        handleQuantityUpdate(event, -1)
                                      }
                                    >
                                      -
                                    </button>
                                    <input
                                      type="text"
                                      className="qty-input"
                                      name={val.sku_id}
                                      value={
                                        selectedItem[val.sku_id]
                                          ? selectedItem[val.sku_id].qty
                                          : 0
                                      }
                                      onChange={handleQuantityChange}
                                      data-property={val.properties}
                                      data-property-name={val.properties_name}
                                      data-price={val.price}
                                      data-quantity={val.quantity}
                                      data-sku-url={
                                        Data.item.props_img[
                                          val.properties.split(";")[0]
                                        ]
                                      }
                                    />
                                    <button
                                      type="button"
                                      className=" qty-btn"
                                      onClick={(event) =>
                                        handleQuantityUpdate(event, 1)
                                      }
                                    >
                                      +
                                    </button>
                                    <div className="d-none qty-exceed">
                                      Max. order is {val.quantity}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="props-qty">Out of Stock</div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                  {totalQuantity > 0 && (
                    <div className="d-flex justify-content-start total-summary   rounded">
                      <div className="total-qty">
                        Total Qauntity: <span>{totalQuantity}</span>
                      </div>
                      <div className="total-amt">
                        Total Amount: &nbsp;
                        <span id="amount">
                          {RoundToTwo(
                            Exchanger(
                              defaultCurrency,
                              currency,
                              (totalPriceWithDiscount > 0 ? totalPriceWithDiscount : totalPrice),
                              rate
                            )
                          )}
                        </span>&nbsp;
                        {symbol}
                      </div>
                      <div className="local-shipping-fee">
                        Local Shipping Fee: <span id="shipping-fee">?</span>&nbsp;
                        {symbol}
                      </div>
                    </div>
                  )}
                  
                </div>
                {openSpec ? null : (
                  <div
                    className="spec-wrap-box-arrow"
                    onClick={() => setOpenSpec(!openSpec)}
                  >
                    <FaAngleDown />
                  </div>
                )}
                <div className="d-flex justify-content-start buyNow-addTotCart shadow bg-white rounded">
                  <button className="btn add-cart" onClick={handleAddToCart}>
                    Add to Cart
                  </button>
                  <button className="btn buy-now" onClick={handleBuyNow}>
                    Buy Now
                  </button>
                  {/* <div className='add-favourite'><FaHeart className='fa-heart'/> favourite <span> (4)</span></div> */}
                </div>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="d-flex flex-column recommend">
                <div className="d-flex justify-content-between recommend-head">
                  <div>Seller Recommend</div>
                  <div>Accumulated sales</div>
                </div>
                <div className="recommend-body d-flex justify-content-start">
                  <div className="recommend-card-wrapper">
                    <div className="recommend-card d-flex flex-column">
                      <img src={images1[0].src} alt="no-alt" />
                      <div className="title">some title some title </div>
                      <div className="d-flex justify-content-between">
                        <div className="amount">324</div>
                        <div className="sales">124354 sales</div>
                      </div>
                    </div>
                  </div>
                  <div className="recommend-card-wrapper">
                    <div className="recommend-card d-flex flex-column">
                      <img src={images1[0].src} alt="no-alt" />
                      <div className="title">some title some title </div>
                      <div className="d-flex justify-content-between">
                        <div className="amount">324</div>
                        <div className="sales">124354 sales</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="related-wrapper d-flex justify-content-between">
                <div className="left">
                  <div className="related-head">RELATED</div>
                  <div className="recommend-card-wrapper">
                    <div className="recommend-card d-flex flex-column">
                      <img src={images1[0].src} alt="no-alt" />
                      <div className="title">some title some title </div>
                      <div className="d-flex justify-content-between">
                        <div className="amount">324</div>
                        <div className="sales">124354 sales</div>
                      </div>
                    </div>
                  </div>
                  <div className="recommend-card-wrapper">
                    <div className="recommend-card d-flex flex-column">
                      <img src={images1[0].src} alt="no-alt" />
                      <div className="title">some title some title </div>
                      <div className="d-flex justify-content-between">
                        <div className="amount">324</div>
                        <div className="sales">124354 sales</div>
                      </div>
                    </div>
                  </div>
                  <div className="recommend-card-wrapper">
                    <div className="recommend-card d-flex flex-column">
                      <img src={images1[0].src} alt="no-alt" />
                      <div className="title">some title some title </div>
                      <div className="d-flex justify-content-between">
                        <div className="amount">324</div>
                        <div className="sales">124354 sales</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right ">
                  <Description data={Data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <Footer /> */}
    </>
  );
}

export default Item

